import classNames, { clsx } from "clsx"
import { isNil } from "lodash"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { RiPlayLine } from "react-icons/ri"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../../contexts/AnalyticsContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"

import {
  GetMovementRecommendationQuery,
  SqualoFollowAlongVideo,
} from "../../../generated/graphql"
import { MICO, NAME_SPACES } from "../../../locales/constants"
import {
  formatBodyTree,
  formatEquipment,
  formatLanguage,
  formatSessionDuration,
} from "../../../utils/format"
import { getModalityTextColor } from "../../../utils/movementUtils"
import Content from "../../Core/Content"
import {
  DurationIcon,
  EquipmentIcon,
  LanguageIcon,
  TargetBodyPartIcon,
} from "../../Core/Icons"
import Modal, { ModalContext, ModalProps, ModalSize } from "../../Core/Modal"
import Button from "../../Forms/Button"
import MovementAgendaItemFormModal from "../../Movement/Modals/MovementAgendaItemFormModal"
import VideoPlayer from "../VideoPlayer"
import { IoCalendarOutline } from "react-icons/io5"

export interface MicoFollowAlongPreviewModalBodyProps {
  followAlong: Exclude<
    GetMovementRecommendationQuery["getMovementRecommendation"],
    null | undefined
  >[0]
  followAlongUuid: SqualoFollowAlongVideo["uuid"]
}

const MicoFollowAlongPreviewModalBody: React.FC<
  MicoFollowAlongPreviewModalBodyProps
> = ({ followAlong }) => {
  const { t } = useTranslation(NAME_SPACES.MICO)
  const TEXT = t(MICO.FOLLOW_ALONG, { returnObjects: true })

  const { name } = useContext(ModalContext)
  const { captureEvent } = useAnalyticsContext()
  const { closeModal, openModal } = useModalOrchestrationContext()

  const handleStartSession = () => {
    captureEvent(AnalyticsEvent.MicoResourcePreviewModalStartSessionClicked, {
      resourceType: followAlong.__typename,
      resourceUuid: followAlong.uuid,
    })

    closeModal(name, "started", followAlong)
  }

  const handleScheduleSession = () => {
    captureEvent(
      AnalyticsEvent.MicoResourcePreviewModalScheduleSessionClicked,
      {
        resourceType: followAlong.__typename,
        resourceUuid: followAlong.uuid,
      }
    )

    openModal(
      ModalOrchestrationName.MovementAgendaItemForm,
      {
        followAlong,
      },
      {
        onClose: (reason?: string, data?: any) => {
          if (reason === "added") {
            closeModal(name, "scheduled", data)
          }
        },
      }
    )
  }

  return (
    <Content scrollY={false}>
      <div className="flex flex-col h-full">
        <div
          className={classNames(
            "flex flex-col gap-y-2",
            `bg-${followAlong.movementModality}`,
            getModalityTextColor(followAlong.movementModality)
          )}
        >
          <div className="flex flex-col p-4 text-left gap-y-2">
            <div className="w-full max-w-md py-2 mx-auto overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow-lg">
              <VideoPlayer src={followAlong.url || ""} />
            </div>

            <span className="text-lg font-semibold text-white">
              {followAlong.creatorName}
            </span>
            <span className="text-2xl font-bold leading-5 tracking-tighter text-white line-clamp-2">
              {followAlong.title}
            </span>
          </div>

          <div className="flex flex-row items-center justify-start w-full p-4 border-t border-white/30">
            <div className="flex flex-row flex-wrap items-center justify-start w-full overflow-x-scroll gap-y-2 gap-x-4">
              <div className={clsx("flex flex-row items-center gap-x-1")}>
                <LanguageIcon className="w-5 h-5" />
                <span className="font-semibold">
                  {formatLanguage(followAlong?.language)}
                </span>
              </div>

              <div className={clsx("flex flex-row items-center gap-x-1")}>
                <DurationIcon className="w-5 h-5" />
                <span className="font-semibold">
                  {formatSessionDuration(followAlong?.duration)}
                </span>
              </div>

              <div className={clsx("flex flex-row items-center gap-x-1")}>
                <TargetBodyPartIcon className="w-5 h-5" />
                <span className="font-semibold">
                  {formatBodyTree(followAlong?.movementTargetBodyParts)}
                </span>
              </div>

              {!isNil(followAlong.environmentalEquipmentRequired) &&
                followAlong.environmentalEquipmentRequired.length > 0 && (
                  <div className={clsx("flex flex-row items-center gap-x-1")}>
                    <EquipmentIcon className="w-5 h-5" />
                    <span className="font-semibold">
                      {formatEquipment(
                        followAlong.environmentalEquipmentRequired
                      )}
                    </span>
                  </div>
                )}
            </div>
          </div>
        </div>

        <div
          className={classNames(
            "flex flex-col items-center justify-center h-full",
            "px-4 py-2",
            "bg-gradient-to-t from-neutral-100 to-neutral-100/0"
          )}
        >
          <div className="flex flex-col justify-center w-full h-full pb-safe gap-y-2">
            <Button
              type="submit"
              expand="block"
              onClick={handleStartSession}
              label={TEXT.CTA.START}
              icon={RiPlayLine}
              iconClassName="absolute left-0"
              className={classNames("w-full m-0 shadow-xl")}
            />

            <Button
              type="submit"
              fill="solid"
              color="white"
              expand="block"
              iconClassName="absolute left-0"
              onClick={handleScheduleSession}
              label={TEXT.CTA.SCHEDULE}
              className="w-full"
              icon={IoCalendarOutline}
              iconSlot="start"
            />
          </div>
        </div>
      </div>

      <MovementAgendaItemFormModal />
    </Content>
  )
}

export const FollowAlongPreviewModal: React.FC<Partial<ModalProps>> = ({
  name = ModalOrchestrationName.FollowAlongPreview,
  ...props
}) => {
  return (
    <Modal name={name} isSheet initialSize={ModalSize.Most} {...props}>
      <Modal.Body>
        {(props: any) => <MicoFollowAlongPreviewModalBody {...props} />}
      </Modal.Body>
    </Modal>
  )
}

export default FollowAlongPreviewModal
