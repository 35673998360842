import { OAuth2Client } from "@byteowls/capacitor-oauth2"

export const authenticateWithApple = async ({
  codeChallenge,
  codeVerifier,
  handleFulfilled,
  handleRejected,
}: {
  codeVerifier: string
  codeChallenge: string
  handleFulfilled: (codeVerifier: string, response: any) => void
  handleRejected?: (error: any) => void
  forcePrompt?: boolean
}) => {
  await OAuth2Client.authenticate({
    appId: "io.behale.crush",
    authorizationBaseUrl: "https://appleid.apple.com/auth/authorize",
    pkceEnabled: false,
    additionalParameters: {
      // code_challenge: codeChallenge,
      nonce: codeChallenge,
      code_challenge_method: "S256",
      grant_type: "authorization_code",
    },
    ios: {
      responseType: "code",
      siwaUseScope: true,
      scope: "fullName email",
    },
  }).then((response) => handleFulfilled(codeVerifier, response), handleRejected)
}
