import {
  MovementActivity,
  MovementAgendaItemSummaryFragment,
  SqualoAdventureElement,
} from "../../../generated/graphql"

export enum DailyMoveCollectionType {
  Planned = "Planned",
  Suggestion = "Suggestion",
  Adventure = "Adventure",
  Favorite = "Favorite",
}

export interface DailyMoveCollection {
  type: DailyMoveCollectionType
  items: (MovementAgendaItemSummaryFragment | MovementActivity)[]
  uuid?: string
  name?: string
  element?: SqualoAdventureElement
}
