import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz"
import { SessionExpectedDuration } from "../../generated/graphql"

export type Time = {
  hours: number
  minutes: number
  seconds?: number
}

export const getTimeZoneValue = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const convertToUTC = (
  date: string | number | Date,
  timezone?: string
): Date => {
  timezone = timezone || getTimeZoneValue()

  return zonedTimeToUtc(date, timezone)
}

export const convertToLocalTime = (
  date: string | number | Date,
  timezone?: string
): Date => {
  timezone = timezone || getTimeZoneValue()

  return utcToZonedTime(date, timezone)
}

export const convertExpectedDurationToExactDuration = (
  expectedDuration: SessionExpectedDuration
): Time => {
  switch (expectedDuration) {
    case SessionExpectedDuration.Mini:
      return { hours: 0, minutes: 10 }
    case SessionExpectedDuration.Short:
      return { hours: 0, minutes: 20 }
    case SessionExpectedDuration.Medium:
      return { hours: 0, minutes: 30 }
    case SessionExpectedDuration.Long:
      return { hours: 0, minutes: 45 }
    case SessionExpectedDuration.VeryLong:
      return { hours: 1, minutes: 0 }
    case SessionExpectedDuration.HourAndHalf:
      return { hours: 1, minutes: 30 }
    case SessionExpectedDuration.TwoHours:
      return { hours: 2, minutes: 0 }
    case SessionExpectedDuration.ThreeHours:
      return { hours: 3, minutes: 0 }
    case SessionExpectedDuration.FourHours:
      return { hours: 4, minutes: 0 }

    default:
      return { hours: 0, minutes: 0 }
  }
}

export const dateFromTime = (time: Time, baseDate?: Date): Date => {
  const { hours, minutes, seconds } = time

  const date = baseDate || new Date()

  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(seconds || 0)

  return date
}

export const timeFromDate = (date: Date): Time => {
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  return { hours, minutes, seconds }
}
