import { format, getISODay, parseISO } from "date-fns"
import addDays from "date-fns/addDays"
import { findKey, isNil } from "lodash"

import {
  AssessmentRatingOfPerceivedExertion,
  MovementWeekScheduleFragment,
  PhaseOfDay,
  SessionExpectedDuration,
  Weekday,
} from "../generated/graphql"
import { createBreakpoint } from "react-use"

export const useBreakpoint = createBreakpoint({
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
})

export function enumKeys<E extends Record<string, any>>(e: E): (keyof E)[] {
  return Object.keys(e).filter((item) => isNaN(Number(item))) as (keyof E)[]
}

export function enumValues<E extends Record<string, any>>(e: E): E[keyof E][] {
  return enumKeys(e).map((key) => e[key])
}

export const Link = ({ children, href, ...props }: any) => (
  <a href={href} className="text-white underline" {...props}>
    {children}
  </a>
)

export const buildURL = (path?: string, defaultUrl?: string) => {
  let baseUrl = defaultUrl || process.env.REACT_APP_CRUSH_URL

  if (getCurrentEnvironment() === "preview") {
    baseUrl =
      defaultUrl ||
      process.env.REACT_APP_VERCEL_URL ||
      process.env.REACT_APP_CRUSH_URL
  }

  const url = new URL(path || "", baseUrl)

  return url.href
}

export type CallResultStatus =
  | "success"
  | "error"
  | "conflict"
  | "notFound"
  | "badRequest"

export const postRequest = async (
  url: string,
  body: any
): Promise<{ status: CallResultStatus }> => {
  const request = new Request(url, {
    method: "POST",
    body: JSON.stringify(body),
  })

  try {
    const response = await fetch(request, {
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })

    switch (response.status) {
      case 200:
        return { status: "success" }
      case 409:
        return { status: "conflict" }
      case 404:
        return { status: "notFound" }
      case 400:
        return { status: "badRequest" }
      default:
        console.warn(response.body)
        return { status: "error" }
    }
  } catch (error) {
    console.warn(error)
    return { status: "error" }
  }
}

export const weekdayOrder = [
  Weekday.Monday,
  Weekday.Tuesday,
  Weekday.Wednesday,
  Weekday.Thursday,
  Weekday.Friday,
  Weekday.Saturday,
  Weekday.Sunday,
]

export const phaseOfDayOrder = [
  PhaseOfDay.Morning,
  PhaseOfDay.Afternoon,
  PhaseOfDay.Evening,
]

export const rpeOrder = [
  AssessmentRatingOfPerceivedExertion.NoEffort,
  AssessmentRatingOfPerceivedExertion.ExtremelyEasy,
  AssessmentRatingOfPerceivedExertion.VeryEasy,
  AssessmentRatingOfPerceivedExertion.Easy,
  AssessmentRatingOfPerceivedExertion.SomewhatEasy,
  AssessmentRatingOfPerceivedExertion.Moderate,
  AssessmentRatingOfPerceivedExertion.SomewhatHard,
  AssessmentRatingOfPerceivedExertion.Hard,
  AssessmentRatingOfPerceivedExertion.VeryHard,
  AssessmentRatingOfPerceivedExertion.ExtremelyHard,
  AssessmentRatingOfPerceivedExertion.MaxEffort,
]

export const expectedDurationOrder = [
  SessionExpectedDuration.Nano,
  SessionExpectedDuration.Micro,
  SessionExpectedDuration.Mini,
  SessionExpectedDuration.Short,
  SessionExpectedDuration.Medium,
  SessionExpectedDuration.Long,
  SessionExpectedDuration.VeryLong,
  SessionExpectedDuration.HourAndHalf,
  SessionExpectedDuration.TwoHours,
  SessionExpectedDuration.ThreeHours,
  SessionExpectedDuration.FourHours,
]

export const weekdayIndex: Record<Weekday, number> = {
  [Weekday.Monday]: 0,
  [Weekday.Tuesday]: 1,
  [Weekday.Wednesday]: 2,
  [Weekday.Thursday]: 3,
  [Weekday.Friday]: 4,
  [Weekday.Saturday]: 5,
  [Weekday.Sunday]: 6,
}

export const getWeekdaySchedule = (
  weekSchedule: MovementWeekScheduleFragment["schedule"],
  weekday: Weekday
): MovementWeekScheduleFragment["schedule"]["monday"] => {
  return weekSchedule[
    weekday.toLowerCase() as
      | "monday"
      | "tuesday"
      | "wednesday"
      | "thursday"
      | "friday"
      | "saturday"
      | "sunday"
  ]
}

export const toWeekday = (isoDay: number): Weekday => {
  if (isoDay === 0) {
    return Weekday.Sunday
  } else if (isoDay > 6) {
    throw new Error("Invalid iso weekday")
  }

  return weekdayOrder[isoDay - 1]
}

export const convertDateToWeekday = (date?: Date) => {
  if (isNil(date)) {
    return
  }

  return toWeekday(getISODay(date))
}

export const toIsoWeekday = (weekday?: Weekday): number | undefined => {
  if (isNil(weekday)) {
    return undefined
  }

  switch (weekday) {
    case Weekday.Sunday:
      return 1
    case Weekday.Monday:
      return 2
    case Weekday.Tuesday:
      return 3
    case Weekday.Wednesday:
      return 4
    case Weekday.Thursday:
      return 5
    case Weekday.Friday:
      return 6
    case Weekday.Saturday:
      return 7
  }
}

export const getWeekdayDate = (
  startDate: Date | string,
  weekday: Weekday | number | null | undefined
): Date => {
  if (typeof startDate === "string") {
    startDate = parseISO(startDate)
  }

  if (isNil(weekday)) {
    return startDate
  }

  // convert weekdays to ISO weekdays
  if (typeof weekday !== "number") {
    weekday = weekdayIndex[weekday]
  }

  const weekdayDate = addDays(startDate, weekday)

  return weekdayDate
}

export const getWeekdayISODate = (
  startDate: Date | string,
  weekday: Weekday | number
): string => {
  return format(getWeekdayDate(startDate, weekday), "yyyy-MM-dd")
}

export const META_BROWSER_USER_AGENT_PATTERNS = {
  messenger: /\bFB[\w_]+\/(Messenger|MESSENGER)/,
  facebook: /\bFB[\w_]+\//,
  instagram: /\bInstagram/i,
}

export const isInMetaAppBrowser = (navigator: Navigator) => {
  const userAgent = navigator.userAgent || navigator.vendor

  const browser =
    findKey(META_BROWSER_USER_AGENT_PATTERNS, (regex) =>
      regex.test(userAgent)
    ) || null

  return !isNil(browser)
}

export const getCurrentEnvironment = () =>
  process.env.REACT_APP_CRUSH_ENVIRONMENT || process.env.NODE_ENV
