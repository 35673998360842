import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonRouter,
} from "@ionic/react"
import {
  calendarNumber,
  calendarNumberOutline,
  compass,
  compassOutline,
  home,
  homeOutline,
  person,
  personOutline,
} from "ionicons/icons"
import { useTranslation } from "react-i18next"
import { Redirect, Route } from "react-router-dom"
import { COMMON, NAME_SPACES } from "../locales/constants"
import ProfileHomePage from "../pages/Profile/ProfileHomePage"
import AgendaHomePage from "../pages/Agenda/AgendaHomePage"
import AgendaItemPage from "../pages/Agenda/AgendaItemPage"
import PastSessionPage from "../pages/Agenda/PastSessionPage"
import { useEffect, useMemo, useState } from "react"
import { useAuthenticatedClientContext } from "../contexts/AuthenticatedClientContext"
import VideoViewerPage from "../pages/Mico/VideoViewerPage"
import PingContainer from "../components/Core/Ping"
import JourneyHomePage from "../pages/Today/TodayHomePage"
import { IonTabsCustomEvent } from "@ionic/core"
import { useAgendaSchedulingContext } from "../contexts/AgendaSchedulingContext"
import MicoHomePage from "../pages/Mico/MicoHomePage"
import AdventureTemplatePage from "../pages/Adventure/AdventureTemplatePage"
import MemberPublicProfilePage from "../pages/Public/MemberPublicProfilePage"
import { BehaleFab } from "../components/Core/BehaleFab"

type HubTabName = "home" | "agenda" | "discover" | "profile"

export const FabPaths = [
  "/app/hub/home",
  "/app/hub/agenda",
  "/app/hub/discover",
  "/app/hub/profile",
]

const HubRouter: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.COMMON)
  const TABS = t(COMMON.TABS, { returnObjects: true })

  const router = useIonRouter()

  const { refetchAgenda, suggestionInboxItems } = useAgendaSchedulingContext()
  const { currentUser, refetchActiveQueries } = useAuthenticatedClientContext()

  const showFab = useMemo(() => currentUser?.isAdmin, [currentUser])

  const hasSuggestions = suggestionInboxItems.length > 0

  const parseActiveTab = (pathname: string) => {
    const pathnameTab = pathname.split("/")[3] as HubTabName

    return pathnameTab
  }

  const [activeTab, setActiveTab] = useState<HubTabName>(
    parseActiveTab(router.routeInfo.pathname)
  )

  const handleTabChange = (e: IonTabsCustomEvent<{ tab: string }>) => {
    refetchActiveQueries()

    setActiveTab(e.detail.tab as HubTabName)
  }

  useEffect(() => {
    if (router.routeInfo.pathname) {
      const pathnameTab = parseActiveTab(router.routeInfo.pathname)

      setActiveTab(pathnameTab)

      refetchAgenda()
    }
  }, [router.routeInfo.pathname])

  return (
    <>
      {currentUser?.isAdmin && <BehaleFab hidden={!showFab} />}
      <IonTabs onIonTabsDidChange={handleTabChange}>
        <IonRouterOutlet>
          <Route exact path="/app/hub/home">
            <JourneyHomePage />
          </Route>

          <Route exact path="/app/hub/agenda">
            <AgendaHomePage />
          </Route>

          <Route
            exact
            path="/app/hub/agenda/items/:uuid"
            component={AgendaItemPage}
          />

          <Route
            exact
            path="/app/hub/agenda/sessions/:uuid"
            component={PastSessionPage}
          />

          <Route exact path="/app/hub/discover">
            <MicoHomePage />
          </Route>

          <Route
            exact
            path="/app/hub/discover/adventures/:uuid"
            component={AdventureTemplatePage}
          />

          <Route
            exact
            path="/app/hub/discover/videos/:uuid"
            component={VideoViewerPage}
          />

          <Route path="/app/hub/members/:uuid/buddy_up" exact>
            <MemberPublicProfilePage />
          </Route>

          <Route exact path="/app/hub/discover/adventures">
            <Redirect to="/app/hub/discover?tab=ADVENTURES" />
          </Route>

          <Route exact path="/app/hub/discover/videos">
            <Redirect to="/app/hub/discover?tab=VIDEOS" />
          </Route>

          <Route path="/app/hub/profile" exact>
            <ProfileHomePage />
          </Route>

          <Route exact path="/app/hub/resources">
            <Redirect to="/app/hub/discover" />
          </Route>

          <Route exact path="/app/hub/journey">
            <Redirect to="/app/hub/profile" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar
          slot="bottom"
          className="pt-1 mb-1 border-t border-t-neutral-200"
        >
          <IonTabButton tab="home" href="/app/hub/home">
            <IonIcon
              className="w-[30px] h-[30px]"
              icon={activeTab === "home" ? home : homeOutline}
            />
            <IonLabel>{TABS.HOME}</IonLabel>
          </IonTabButton>
          <IonTabButton tab="agenda" href="/app/hub/agenda">
            <PingContainer
              showPing={hasSuggestions && activeTab !== "agenda"}
              className="top-0 flex flex-col -right-1"
              containerClassName="h-[30px] my-[2px]"
              // color="primary"
            >
              <IonIcon
                className="w-[30px] h-[30px]"
                icon={
                  activeTab === "agenda"
                    ? calendarNumber
                    : calendarNumberOutline
                }
              />
            </PingContainer>
            <IonLabel>{TABS.AGENDA}</IonLabel>
          </IonTabButton>
          <IonTabButton tab="discover" href="/app/hub/discover">
            <IonIcon
              icon={activeTab === "discover" ? compass : compassOutline}
            />
            <IonLabel>{TABS.DISCOVER}</IonLabel>
          </IonTabButton>
          <IonTabButton tab="profile" href="/app/hub/profile">
            <IonIcon icon={activeTab === "profile" ? person : personOutline} />
            <IonLabel className="text-[10px]">{TABS.PROFILE}</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </>
  )
}

export default HubRouter
