import { Preferences } from "@capacitor/preferences"
import { PushNotifications } from "@capacitor/push-notifications"
import { addBreadcrumb, captureMessage } from "@sentry/capacitor"
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin"
import { filter } from "lodash"
import isNil from "lodash/isNil"
import { enumKeys } from "../../utils"
import {
  ReminderStatus,
  ReminderTemplate,
  NotificationContextState,
} from "./notificationTypes"

export const registerPushNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions()

  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions()
  }

  if (permStatus.receive === "denied") {
    captureMessage("[PushNotifications] user denied notifications", "log")

    return
  }

  await PushNotifications.register()
}

export const findRemindersByTemplate = (
  reminders: NotificationContextState["reminders"],
  template: ReminderTemplate
) => {
  return filter(reminders, (reminder) => reminder.template === template)
}

export const storeTemplatesAllowed = async (
  templatesAllowed: ReminderTemplate[]
) => {
  return await Preferences.set({
    key: "localNotificationTemplatesAllowed",
    value: JSON.stringify(templatesAllowed),
  })
    .then((_value) => true)
    .catch((_error) => false)
}

export const loadTemplatesAllowed = async (): Promise<
  ReminderTemplate[] | null
> => {
  return await Preferences.get({
    key: "localNotificationTemplatesAllowed",
  })
    .then(({ value: templatesAllowed }) => {
      if (!isNil(templatesAllowed)) {
        return JSON.parse(templatesAllowed)
      }

      return null
    })
    .catch((_error) => null)
}

export const loadPushNotificationToken = async (): Promise<string | null> => {
  return await SecureStoragePlugin.get({
    key: "pushNotificationToken",
  })
    .then(({ value: token }) => token)
    .catch((_error) => null)
}

export const storePushNotificationToken = async (token: string) => {
  return await SecureStoragePlugin.set({
    key: "pushNotificationToken",
    value: token,
  })
    .then(() => {
      console.debug("[PushNotifications] token saved")

      addBreadcrumb({
        category: "pushNotification",
        message: "push notification token saved",
        type: "info",
      })

      return true
    })
    .catch((_error) => {
      addBreadcrumb({
        category: "pushNotification",
        message: "push notification token save failed",
        type: "error",
      })

      return false
    })
}

export const getDeliveredNotifications = async () => {
  const notificationList = await PushNotifications.getDeliveredNotifications()

  console.debug("delivered notifications", notificationList)
}

export const getAllStaticLocalNotificationTemplates = (): string[] => {
  return enumKeys(ReminderTemplate)
}

export const buildLocalNotificationPermissions = (isAllowed = true) => {
  return getAllStaticLocalNotificationTemplates().reduce(
    (acc, template) => ({
      ...acc,
      [template]: isAllowed,
    }),
    {}
  ) as Record<ReminderTemplate, boolean>
}

export const buildLocalNotificationStatuses = (status: ReminderStatus) => {
  return getAllStaticLocalNotificationTemplates().reduce(
    (acc, template) => ({
      ...acc,
      [template]: status,
    }),
    {}
  ) as Record<ReminderTemplate, ReminderStatus>
}
