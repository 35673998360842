import { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import * as Yup from "yup"

import { AUTH, NAME_SPACES } from "../../locales/constants"
import i18n from "../../locales/i18n"
import { Link } from "../../utils"
import { buildPravoURL } from "../../utils/pravo"
import Checkbox from "../Forms/CheckboxItem"
import { Form } from "../Forms/Form"
import Input from "../Forms/Input"

export const getPasswordSchema = () => {
  return Yup.string()
    .password()
    .minSymbols(0)
    .minUppercase(0)
    .minNumbers(0)
    .min(
      8,
      i18n.t(`${NAME_SPACES.AUTH}:${AUTH.VALIDATION}.PASSWORD_MIN_LENGTH`)
    )
    .max(
      100,
      i18n.t(`${NAME_SPACES.AUTH}:${AUTH.VALIDATION}.PASSWORD_MAX_LENGTH`)
    )
}

export const RegistrationForm: React.FC<{
  registerUser: (data: any) => Promise<void>
}> = ({ registerUser }) => {
  const { t } = useTranslation(NAME_SPACES.AUTH)

  const TEXT_VALIDATION = t(AUTH.VALIDATION, {
    returnObjects: true,
  })

  const TEXT = t(AUTH.REGISTRATION, {
    returnObjects: true,
  })

  const [acceptsTerms, setAcceptsTerms] = useState(false)

  const formSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(TEXT_VALIDATION.FIRST_NAME_REQUIRED)
      .min(2, TEXT_VALIDATION.SHORT),
    lastName: Yup.string().required(TEXT_VALIDATION.LAST_NAME_REQUIRED),
    email: Yup.string()
      .email(TEXT_VALIDATION.INVALID_EMAIL)
      .required(TEXT_VALIDATION.EMAIL_REQUIRED),
    password: getPasswordSchema().required(),
  })

  return (
    <Form
      mode="onBlur"
      schema={formSchema}
      onSubmit={registerUser}
      submitButtonProps={{
        label: TEXT.SUBMIT,
        color: "secondary",
        disabled: !acceptsTerms,
      }}
    >
      <div className="flex flex-row justify-between w-full gap-x-4">
        <Input
          name="firstName"
          label={TEXT.FITST_NAME}
          type="text"
          autocomplete="given-name"
          color="white"
          className="w-full"
          required
        />
        <Input
          name="lastName"
          label={TEXT.LAST_NAME}
          type="text"
          autocomplete="family-name"
          color="white"
          className="w-full"
          required
        />
      </div>

      <Input
        name="email"
        label={t("EMAIL_LABEL")}
        type="email"
        color="white"
        inputmode="email"
        autocomplete="email"
        required
      />

      <Input
        name="password"
        label={t("PASSWORD_LABEL")}
        type="password"
        color="white"
        autocomplete="new-password"
        required
      />

      <Checkbox
        name="acceptsTerms"
        color="white"
        className="pt-4 text-sm"
        onIonChange={(e) => setAcceptsTerms(e.detail.checked)}
        label={
          <Trans
            t={t}
            i18nKey={"REGISTRATION.ACCEPTS_TERMS"}
            values={{
              termsOfService: i18n.t("COMMON:TERMS_AND_CONDITIONS"),
              privacyPolicy: i18n.t("COMMON:PRIVACY_POLICY"),
            }}
            components={[
              <Link
                key={0}
                href={buildPravoURL("/docs/terms")}
                target="_blank"
                rel="noreferrer"
              />,
              <Link
                key={1}
                href={buildPravoURL("/docs/privacy")}
                target="_blank"
                rel="noreferrer"
              />,
            ]}
          />
        }
      />
    </Form>
  )
}
