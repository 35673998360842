import clsx from "clsx"
import * as React from "react"

interface IConcentricDisksProps {
  className?: string
  children?: React.ReactNode
  color?: string
  opacity?: number
}

export const ConcentricDisks: React.FunctionComponent<
  IConcentricDisksProps
> = ({ className = "", color = "white", children = null, opacity = 10 }) => {
  const background = `bg-${color}`

  return (
    <div className={clsx("aspect-square aspect-1", className)}>
      <div
        className={clsx(
          "flex items-center justify-center w-full h-full p-20 rounded-full aspect-square",
          background,
          `bg-opacity-${opacity}`
        )}
      >
        <div
          className={clsx(
            "flex items-center justify-center w-full h-full p-20 rounded-full aspect-square",
            background,
            `bg-opacity-${opacity}`
          )}
        >
          <div className="flex flex-col items-center justify-center w-full h-full max-w-sm px-4 mx-auto text-center gap-y-8">
            {children || <></>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConcentricDisks
