import { useTranslation } from "react-i18next"
import * as Yup from "yup"

import { AUTH, NAME_SPACES } from "../../locales/constants"
import { Form } from "../Forms/Form"
import Input from "../Forms/Input"

export const LoginForm: React.FC<{
  loginUser: (data: any) => Promise<void>
}> = ({ loginUser }) => {
  const { t } = useTranslation(NAME_SPACES.AUTH)

  const TEXT = t(AUTH.LOGIN, {
    returnObjects: true,
  })

  const formSchema = Yup.object()
    .shape({
      email: Yup.string().email().required(),
      password: Yup.string().required(),
    })
    .required()

  return (
    <Form
      mode="onSubmit"
      schema={formSchema}
      onSubmit={loginUser}
      submitButtonProps={{
        label: TEXT.SUBMIT,
        color: "secondary",
        disabled: false,
      }}
    >
      <Input
        name="email"
        label={t("EMAIL_LABEL")}
        type="email"
        color="white"
        inputmode="email"
        autocomplete="email"
        required
      />
      <Input
        name="password"
        label={t("PASSWORD_LABEL")}
        type="password"
        color="white"
        autocomplete="current-password"
        required
      />
    </Form>
  )
}
