import { Switch } from "@headlessui/react"
import classNames from "clsx"
import isNil from "lodash/isNil"
import { useEffect, useState } from "react"
import { MovementModality } from "../../generated/graphql"

export interface ToggleProps {
  label?: string
  description?: string
  color?: MovementModality | "primary"
  isEnabled?: boolean
  onToggle?: (state: boolean) => Promise<boolean | void> | boolean | void
  className?: string
  labelClassName?: string
  locked?: boolean
}

const Toggle: React.FC<ToggleProps> = ({
  label,
  description,
  isEnabled = false,
  color = "primary",
  onToggle,
  className = "",
  labelClassName = "",
  locked = false,
}) => {
  const [enabled, setEnabled] = useState(isEnabled)

  const handleToggle = async () => {
    if (locked) {
      return
    }

    if (isNil(onToggle)) {
      setEnabled(!enabled)

      return
    }

    const result = await onToggle(!enabled)

    if (result !== false) {
      setEnabled(!enabled)
    }
  }

  useEffect(() => {
    if (isEnabled !== enabled) {
      setEnabled(isEnabled)
    }
  }, [isEnabled])

  return (
    <Switch.Group
      as="div"
      className={classNames("flex items-center justify-between", className)}
    >
      {label && (
        <span className={"flex flex-col flex-grow"}>
          <Switch.Label
            as="span"
            className={classNames(
              "text-base font-medium text-neutral-600",
              labelClassName
            )}
            passive
          >
            {label}
          </Switch.Label>
          <Switch.Description as="span" className="text-sm text-neutral-500">
            {description}
          </Switch.Description>
        </span>
      )}

      <Switch
        checked={enabled}
        onChange={handleToggle}
        className={classNames(
          enabled ? `bg-${color}-500` : "bg-slate-300",
          "relative inline-flex flex-shrink-0 h-7 w-11 p-1 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200"
          // `focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-neutral-400 focus:ring-${color}-500 ring-opacity-30`
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-4" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
    </Switch.Group>
  )
}

export default Toggle
