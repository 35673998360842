import { IonPage } from "@ionic/react"
import React from "react"
import Content from "../components/Core/Content"

const NotFoundPage: React.FC = () => {
  return (
    <IonPage>
      <Content className="ion-padding">Page not found.</Content>
    </IonPage>
  )
}

export default NotFoundPage
