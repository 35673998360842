import { useTranslation } from "react-i18next"
import { ModalOrchestrationName } from "../../../contexts/ModalOrchestrationContext"

import { Language } from "../../../generated/graphql"
import { languageLabels } from "../../../labels"
import { MICO, NAME_SPACES } from "../../../locales/constants"
import { formatLanguage } from "../../../utils/format"
import CardSelect, { CardSelectProps } from "../../Forms/CardSelect"
import { FieldModalBodyProps, GridFormField } from "../../Forms/GridFormField"
import { LanguageIcon } from "../../Core/Icons"

export type LanguageSelectProps = CardSelectProps<Language> &
  FieldModalBodyProps<Language>

export const LanguageSelect: React.FC<LanguageSelectProps> = ({
  state,
  setState,
}) => {
  return (
    <CardSelect<Language>
      options={[
        { value: Language.En, label: languageLabels.EN },
        { value: Language.Fr, label: languageLabels.FR },
      ]}
      selected={state as Language}
      onSelect={(value) => setState(value)}
    />
  )
}

export const LanguageSelectFormField: React.FC<{ name: string }> = ({
  name,
}) => {
  const { t } = useTranslation(NAME_SPACES.MICO)
  const TEXT = t(MICO.FORM, { returnObjects: true })

  const Body = (props: LanguageSelectProps) => <LanguageSelect {...props} />

  return (
    <GridFormField<Language>
      name={name}
      modalName={ModalOrchestrationName.FormFieldLanguageSelect}
      label={TEXT.LANGUAGE}
      Body={Body}
      Icon={LanguageIcon}
      formatValue={formatLanguage}
      showClearButton
    />
  )
}
