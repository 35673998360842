import { App, URLOpenListenerEvent } from "@capacitor/app"
import React, { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"

import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../contexts/AnalyticsContext"

const AppUrlListener: React.FC<any> = () => {
  const { captureEvent } = useAnalyticsContext()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const url = new URL(event.url)
      const query = new URLSearchParams(url.search)
      const path = url.pathname

      if (path) {
        history.push(path)
      }

      captureEvent(AnalyticsEvent.AppUrlOpened, {
        url: url,
        path: path,
        iosSourceApplication: event.iosSourceApplication,
        query: query.toString(),
      })
    })
  }, [])

  useEffect(() => {
    captureEvent("$pageview")
  }, [location.pathname])

  return null
}

export default AppUrlListener
