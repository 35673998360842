import { Route, Switch } from "react-router-dom"
import BaseLoadingPage from "../pages/BaseLoadingPage"
import NotFoundPage from "../pages/NotFoundPage"
import AppRouter from "./AppRouter"
import AuthRouter from "./AuthRouter"

import PublicRouter from "./PublicRouter"

const MainRouter = () => {
  return (
    <Switch>
      <Route path="/auth">
        <AuthRouter />
      </Route>

      <Route path="/app">
        <AppRouter />
      </Route>

      <Route path="/public">
        <PublicRouter />
      </Route>

      <Route exact path="/" component={BaseLoadingPage} />

      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  )
}

export default MainRouter
