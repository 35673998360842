import React from "react"
import { useTranslation } from "react-i18next"
import { IoVideocamOutline } from "react-icons/io5"
import { ModalOrchestrationName } from "../../../../contexts/ModalOrchestrationContext"

import {
  SearchFollowAlongsQuery,
  SqualoFollowAlongVideo,
} from "../../../../generated/graphql"
import { MOVEMENT, NAME_SPACES } from "../../../../locales/constants"
import {
  FieldModalBodyProps,
  GridFormField,
  GridFormFieldProps,
} from "../../../Forms/GridFormField"
import { MicoVideoGalleryContent } from "../../../Mico/MicoVideoGalleryTab"

export type MicoVideoSelectData =
  | SearchFollowAlongsQuery["searchFollowAlongs"][0]
  | SqualoFollowAlongVideo

export type MicoVideoSelectProps = FieldModalBodyProps<MicoVideoSelectData>

const MicoVideoSelect: React.FC<MicoVideoSelectProps> = ({ setState }) => {
  const handleSelect = (
    video: SearchFollowAlongsQuery["searchFollowAlongs"][0]
  ) => {
    setState(video)
  }

  return <MicoVideoGalleryContent onSelect={handleSelect} />
}

export interface MicoVideoSelectGridFormFieldProps
  extends Partial<Omit<GridFormFieldProps<MicoVideoSelectData>, "modalName">> {
  name?: string
}

export const MicoVideoSelectGridFormField: React.FC<
  MicoVideoSelectGridFormFieldProps
> = ({ name = "micoVideoUuid", ...props }) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.FORM, {
    returnObjects: true,
  })

  return (
    <GridFormField<MicoVideoSelectData>
      name={name}
      modalName={ModalOrchestrationName.FormFieldMicoVideoSelect}
      modalClassName="p-0"
      label={TEXT.FOLLOW_ALONG.LABEL}
      formatValue={(followAlong) => followAlong?.title || "-"}
      showClearButton
      showDismiss
      Body={MicoVideoSelect}
      Icon={IoVideocamOutline}
      {...props}
    />
  )
}

export default MicoVideoSelect
