import clsx from "clsx"
import { BehaleIcon } from "./BehaleIcon"

export interface LoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string
  background?: string
  size?: "small" | "medium" | "large"
  className?: string
  fullScreen?: boolean
  overlay?: boolean
  hidden?: boolean
}

const Loading: React.FC<LoadingProps> = ({
  label,
  background = "primary",
  size = "large",
  fullScreen = false,
  overlay = false,
  hidden = false,
  className,
  children,
}) => {
  const isLightBackground =
    background.startsWith("neutral") ||
    background === "transparent" ||
    background === "white"

  let iconSize

  switch (size) {
    case "small":
      iconSize = 24
      break
    case "medium":
      iconSize = 48
      break
    case "large":
      iconSize = 96
      break
  }

  return (
    <div
      className={clsx(
        fullScreen ? "w-screen h-screen inset-0 z-30" : "w-full h-full",
        `bg-${background}`,
        overlay && "backdrop backdrop-blur-sm backdrop-filter bg-opacity-70",
        hidden && "hidden",
        className
      )}
    >
      <div
        className={clsx(
          "flex flex-col items-center justify-center w-full h-full gap-y-8"
        )}
      >
        <BehaleIcon
          variant="yin"
          className={clsx(
            "animate-spin",
            size === "small" && "w-10 h-10",
            size === "medium" && "w-20 h-20",
            size === "large" && "w-40 h-40",
            isLightBackground ? "fill-primary" : "fill-white"
          )}
          width={iconSize}
          height={iconSize}
        />
        {label && (
          <span
            className={clsx(
              "text-center text-lg",
              isLightBackground ? "text-primary" : "text-white"
            )}
          >
            {label}
          </span>
        )}
        {children}
      </div>
    </div>
  )
}

export default Loading
