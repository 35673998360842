import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import { NAME_SPACES } from "./constants"
import en from "./en"
import fr from "./fr"

export const defaultNS = NAME_SPACES.COMMON
export const resources = { en, fr } as const

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: {
      "en-US": ["en"],
      "en-GB": ["en"],
      default: ["en"],
    },
    defaultNS,
    resources,
    detection: {
      order: ["navigator", "localStorage"],
      lookupLocalStorage: "CapacitorStorage.i18nextLng",
    },
    debug: process.env.NODE_ENV === "development",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
