import { OAuth2Client } from "@byteowls/capacitor-oauth2"
import { buildURL } from "../../../utils"

export enum GoogleScope {
  Calendar = "calendar",
  Authentication = "authentication",
}

export const GOOGLE_SCOPES = {
  [GoogleScope.Authentication]: "openid profile email",
  [GoogleScope.Calendar]:
    "openid profile email https://www.googleapis.com/auth/calendar.app.created https://www.googleapis.com/auth/calendar.freebusy",
}

export const authenticateWithGoogle = async ({
  scope,
  codeVerifier,
  codeChallenge,
  handleFulfilled,
  handleRejected,
  forcePrompt = false,
}: {
  scope: GoogleScope
  codeVerifier: string
  codeChallenge: string
  handleFulfilled: (codeVerifier: string, response: any) => void
  handleRejected?: (error: any) => void
  forcePrompt?: boolean
}) => {
  const isInSafari = /Safari/.test(navigator.userAgent)

  await OAuth2Client.authenticate({
    authorizationBaseUrl: "https://accounts.google.com/o/oauth2/auth",
    accessTokenEndpoint: "",
    scope: GOOGLE_SCOPES[scope],
    pkceEnabled: false,
    web: {
      appId: process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID,
      responseType: "code",
      logsEnabled: process.env.NODE_ENV === "development",
      redirectUrl: buildURL("/auth/google/callback"),
      windowReplace: false,
      windowTarget: isInSafari ? "_self" : "_blank",
      additionalParameters: {
        code_challenge: codeChallenge,
        code_challenge_method: "S256",
        access_type: forcePrompt ? "offline" : "online",
        approval_prompt: forcePrompt ? "force" : "auto",
        include_granted_scopes: "true",
      },
    },
    ios: {
      appId: process.env.REACT_APP_GOOGLE_IOS_CLIENT_ID,
      responseType: "code",
      redirectUrl: "io.behale.crush:/",
      additionalParameters: {
        code_challenge: codeChallenge,
        code_challenge_method: "S256",
        access_type: forcePrompt ? "offline" : "online",
        approval_prompt: forcePrompt ? "force" : "auto",
        include_granted_scopes: "true",
      },
    },
    android: {
      appId: process.env.REACT_APP_GOOGLE_ANDROID_CLIENT_ID,
      responseType: "code",
      redirectUrl: "io.behale.crush:/",
      pkceEnabled: false,
      additionalParameters: {
        access_type: forcePrompt ? "offline" : "online",
        approval_prompt: forcePrompt ? "force" : "auto",
        include_granted_scopes: "true",
      },
    },
  }).then((response) => handleFulfilled(codeVerifier, response), handleRejected)
}
