import { useMemo } from "react"
import { IonProgressBar } from "@ionic/react"
import clsx from "clsx"
import { getModalityContrastColor } from "../../../utils/movementUtils"
import useMoveTimer from "../../../hooks/useMoveTimer"
import {
  MoveSnackFrame,
  MoveSnackFrameTimerType,
  MoveSnackTemplate,
} from "./type"
import { isNil, round } from "lodash"
import { MovementModality } from "../../../generated/graphql"
import { formatCountdown } from "./util"

export const MoveSnackFrameContent = ({
  template,
  frame,
  goToNextFrame,
  currentSessionProgress,
}: {
  template: MoveSnackTemplate
  frame: MoveSnackFrame
  goToNextFrame: () => void
  playSession: () => void
  pauseSession: () => void
  currentSessionProgress: number
}) => {
  const frameTimer = useMoveTimer(
    {
      delay: (frame.duration || 0) * 1000,
      repetitionDuration: frame.repetitionDuration,
      repetitionCount: frame.repetitionCount,
      startImmediately: true,
    },
    goToNextFrame
  )

  const formattedText = useMemo(() => {
    if (
      frame.type === MoveSnackFrameTimerType.Repetitions &&
      !isNil(frameTimer.repetitionsRemaining)
    ) {
      return round(frameTimer.repetitionsRemaining)
    } else {
      return formatCountdown(frameTimer.secondsRemaining)
    }
  }, [frameTimer])

  const progressBarColor = useMemo(() => {
    switch (template.movementModality) {
      case MovementModality.Mobility:
      case MovementModality.Breathing:
      case MovementModality.Strength:
        return getModalityContrastColor(template.movementModality)

      default:
        return template.movementModality
    }
  }, [template.movementModality])

  return (
    <>
      <div
        className={clsx(
          "flex flex-col items-start justify-end p-8 gap-y-4 text-left w-full"
        )}
      >
        <h1 className={clsx("font-bold tracking-tighter text-8xl")}>
          {formattedText}
        </h1>

        <span className="text-5xl font-bold tracking-tight">
          {frame.title || frame.name}
        </span>

        {frame.description && (
          <span
            className={clsx("font-semibold text-2xl mt-4 overflow-y-scroll")}
          >
            {frame.description}
          </span>
        )}
      </div>

      <div className="absolute flex flex-col items-center justify-center w-full py-4 gap-y-8 bottom-4">
        <div className="flex flex-row items-center justify-around w-full text-4xl">
          {/* <Button
            icon={
              sessionStatus === MoveSnackSessionStatus.Paused
                ? playOutline
                : pauseOutline
            }
            color={template.movementModality}
            fill="clear"
            iconSlot="icon-only"
            paddingWidth="thin"
            className={clsx(
              "rounded-full aspect-1",
              "shadow-xl",
              "h-20 w-20",
              `bg-${getModalityContrastColor(template.movementModality)}`
            )}
            iconClassName={clsx("text-5xl md:text-6xl")}
            onClick={
              sessionStatus === MoveSnackSessionStatus.Paused
                ? playSession
                : pauseSession
            }
          /> */}
        </div>

        <div
          className={clsx(
            "flex flex-col items-center justify-center w-full gap-y-4"
          )}
        >
          <div className="flex flex-row-reverse items-center justify-between w-full px-2 gap-x-4">
            {/* <div className="flex flex-col items-start">
              <span className="font-semibold opacity-80">Elapsed</span>

              <span className="text-lg font-bold">
                {formatDuration(sessionTimer.secondsPassed)}
              </span>
            </div> */}

            {/* <div className="flex flex-col items-end">
              <span className="font-semibold opacity-80">Remaining</span>

              <span className="text-lg font-bold">
                {formatDuration(sessionTimer.secondsRemaining, true)}
              </span>
            </div> */}
          </div>

          <IonProgressBar
            color={progressBarColor}
            value={currentSessionProgress}
            className="h-8"
          />
        </div>
      </div>
    </>
  )
}
