import { format, intervalToDuration } from "date-fns"
import { get, isArray, isNil, isNumber } from "lodash"

import { rpeOrder } from "."
import {
  dateFromTime,
  Time,
} from "../contexts/AgendaSchedulingContext/agendaTimeUtils"
import {
  AssessmentRatingOfPerceivedExertion,
  BodyJointEnum,
  BodyMuscle,
  BodyMuscleEnum,
  BodyMuscleGroup,
  BodyMuscleGroupEnum,
  BodyPartEnum,
  BodyRegion,
  BodyRegionEnum,
  BodyTree,
  EnvironmentalEquipment,
  Language,
  Maybe,
  MovementModality,
  MovementStyle,
  PhaseOfDay,
  SessionExpectedDuration,
  SqualoAdventure,
  SqualoAdventureElement,
  SqualoAdventureType,
  TrainingZone,
  Weekday,
} from "../generated/graphql"
import { expectedDurationLabels } from "../labels"
import { ADVENTURE, COMMON, MOVEMENT, NAME_SPACES } from "../locales/constants"
import i18n from "../locales/i18n"
import { BodyPart } from "./types"

export const formatAdventureDifficulty = (difficulty: number) => {
  return i18n.t(
    `${NAME_SPACES.ADVENTURE}:${ADVENTURE.DIFFICULTY}.${difficulty}`
  )
}

export const formatAdventureType = (adventure: SqualoAdventure) => {
  if (isNil(adventure.type)) {
    if (adventure.difficulty < 1) {
      return i18n.t(`${NAME_SPACES.ADVENTURE}:${ADVENTURE.TYPE}.COMMUNITY`)
    } else {
      return i18n.t(`${NAME_SPACES.ADVENTURE}:${ADVENTURE.TYPE}.PREMIUM`)
    }
  } else if (adventure.type === SqualoAdventureType.Basic) {
    return i18n.t(`${NAME_SPACES.ADVENTURE}:${ADVENTURE.TYPE}.COMMUNITY`)
  } else {
    return i18n.t(`${NAME_SPACES.ADVENTURE}:${ADVENTURE.TYPE}.PREMIUM`)
  }
}

export const formatAdventureDuration = (phaseCount: number) => {
  return `~${i18n.t(`${NAME_SPACES.COMMON}:WEEK_COUNT`, { count: phaseCount })}`
}

export const formatElement = (element: SqualoAdventureElement) => {
  return i18n.t(`${NAME_SPACES.COMMON}:${COMMON.ELEMENTS}.${element}`)
}

export const formatMuscleGroup = (muscleGroup: BodyMuscleGroup) => {
  return formatBodyPart(muscleGroup.name)
}

export const formatMuscle = (muscle: BodyMuscle) => {
  return formatBodyPart(muscle.name)
}

export const formatBodyRegion = (region: BodyRegion) => {
  return formatBodyPart(region.name)
}

export const formatFullBody = () => {
  return formatBodyPart(BodyPartEnum.FullBody)
}

export const getBodyPartLabels = (bodyTree?: Maybe<BodyTree>): string[] => {
  if (isNil(bodyTree)) {
    return [formatBodyPart(undefined)]
  } else if (bodyTree.isFullBody) {
    return [formatFullBody()]
  }

  const labels: string[] = []

  for (const region of bodyTree.regions) {
    if (region.muscleGroups.length === 0) {
      labels.push(formatBodyRegion(region))
      continue
    }

    for (const muscleGroup of region.muscleGroups) {
      if (muscleGroup.muscles.length === 0) {
        labels.push(formatMuscleGroup(muscleGroup))

        continue
      }

      for (const muscle of muscleGroup.muscles) {
        labels.push(formatMuscle(muscle))
      }
    }
  }

  return labels
}

export const formatBodyTree = (
  bodyTree?: Maybe<BodyTree>,
  maxPartsShown = 3
) => {
  if (maxPartsShown < 1) {
    throw new Error("maxPartsShown must be greater than 0")
  }

  const result = getBodyPartLabels(bodyTree)

  if (result.length == 0) {
    return i18n.t(`${NAME_SPACES.MOVEMENT}:${MOVEMENT.TARGET_BODY_PARTS}.NONE`)
  } else if (result.length > maxPartsShown) {
    return `${result.slice(0, maxPartsShown).join(", ")}...`
  }

  return result.join(", ")
}

export const formatLowImpact = (lowImpact: boolean) => {
  return i18n.t(
    `${NAME_SPACES.MOVEMENT}:${MOVEMENT.LOW_IMPACT}.${new String(lowImpact)}`
  )
}

export const formatBodyPart = (
  bodyPart?:
    | BodyPartEnum
    | BodyMuscleEnum
    | BodyMuscleGroupEnum
    | BodyRegionEnum
    | BodyJointEnum
) => {
  if (isNil(bodyPart))
    return i18n.t(`${NAME_SPACES.MOVEMENT}:${MOVEMENT.TARGET_BODY_PARTS}.NONE`)

  return i18n.t(
    `${NAME_SPACES.MOVEMENT}:${MOVEMENT.TARGET_BODY_PARTS}.${bodyPart}`
  )
}

export const formatBodyParts = (bodyParts?: BodyPart[]) => {
  if (isNil(bodyParts)) return formatBodyPart(undefined)

  return bodyParts.map(formatBodyPart).join(", ")
}

export const formatStyle = (
  style: MovementStyle | MovementStyle[] | undefined | null
): string => {
  if (isNil(style) || style.length === 0) {
    return i18n.t(`${NAME_SPACES.MOVEMENT}:${MOVEMENT.STYLE}.ALL_STYLES`)
  }

  if (isArray(style)) {
    return style
      .map((s) => formatStyle(s as MovementStyle))
      .sort()
      .join(", ")
  }

  return i18n.t(`${NAME_SPACES.MOVEMENT}:${MOVEMENT.STYLE}.${style}`)
}

export const formatModality = (modality: MovementModality | undefined) => {
  if (isNil(modality)) {
    return i18n.t(`${NAME_SPACES.MOVEMENT}:${MOVEMENT.MODALITY}.ALL_MODALITIES`)
  }

  return i18n.t(`${NAME_SPACES.MOVEMENT}:${MOVEMENT.MODALITY}.${modality}`)
}

export const formatLanguage = (language: Language | null | undefined) => {
  if (isNil(language) || language.length === 0) {
    return i18n.t(`${NAME_SPACES.COMMON}:${COMMON.LANGUAGES}.ALL`)
  }

  return i18n.t(`${NAME_SPACES.COMMON}:${COMMON.LANGUAGES}.${language}`)
}

export const formatSessionDuration = (
  duration: number | SessionExpectedDuration | null | undefined,
  includeSeconds = true
) => {
  if (isNil(duration)) return "-"

  if (duration === 0) return "0m"

  if (isNumber(duration)) {
    const { hours, minutes, seconds } = intervalToDuration({
      start: 0,
      end: duration * 1000,
    })

    let result = `${minutes?.toString().padStart(2, "0")}m`

    if (hours && hours > 0) {
      result = `${hours}h ${result}`

      if (minutes === 0) {
        result = `${hours}h`
      }
    }

    if (seconds && seconds > 0 && includeSeconds) {
      result = `${result} ${seconds.toString().padStart(2, "0")}s`
    }

    return result
  }

  return expectedDurationLabels[duration]
}

export const formatEquipment = (
  equipment?: EnvironmentalEquipment[] | null | undefined
): string => {
  const TEXT = i18n.t(`${NAME_SPACES.MOVEMENT}:${MOVEMENT.EQUIPMENT}`, {
    returnObjects: true,
  }) as Record<EnvironmentalEquipment & "NONE", string>

  if (isNil(equipment) || equipment.length === 0) {
    return get(TEXT, "NONE", "")
  }

  return equipment.map((e) => get(TEXT, e)).join(", ")
}

export const formatDistance = (distance: number | null | undefined) => {
  if (isNil(distance) || distance.toString().length === 0) {
    return i18n.t(`${NAME_SPACES.MOVEMENT}:${MOVEMENT.DISTANCE}.NONE.LABEL`)
  }

  if (distance < 1000) {
    return `${distance} m`
  }

  return `${(distance / 1000).toFixed(1)} km`
}

export const formatPace = (pace: number | null | undefined) => {
  if (isNil(pace) || pace.toString().length === 0) {
    return "-"
  }

  return `${pace.toFixed(2)} min/km`
}

export const formatRPE = (
  rpe: AssessmentRatingOfPerceivedExertion | null | undefined,
  withEmoji = true
) => {
  const TEXT = i18n.t(`${NAME_SPACES.MOVEMENT}:${MOVEMENT.RPE}`, {
    returnObjects: true,
  })

  if (isNil(rpe)) return get(TEXT, "NONE.LABEL")

  const label = get(TEXT, `${rpe}.LABEL`, "")

  if (withEmoji) {
    const emoji = get(TEXT, `${rpe}.EMOJI`, "")

    return `${emoji} ${label}`
  }

  return label
}

export const formatRelativeRPE = (
  rpe: AssessmentRatingOfPerceivedExertion | null | undefined
): string => {
  const TEXT = i18n.t(`${NAME_SPACES.MOVEMENT}:${MOVEMENT.RPE}`, {
    returnObjects: true,
  })

  const NONE = i18n.t(`${NAME_SPACES.MOVEMENT}:${MOVEMENT.RPE}.NONE.LABEL`)

  if (isNil(rpe)) return NONE

  const relativeRPE = rpeOrder.indexOf(rpe)

  if (relativeRPE < 0) {
    return NONE
  }

  const emoji = get(TEXT, `${rpe}.EMOJI`, NONE)
  const label = get(TEXT, `${rpe}.LABEL`, NONE)

  return `${emoji} - ${label}`
}

export const formatWeekday = (
  weekday: Weekday | Weekday[] | null | undefined,
  isRecurring = false,
  time?: Time
): string => {
  if (isNil(weekday)) return ""

  if (isArray(weekday)) {
    if (weekday.length === 0) {
      return i18n.t(`${NAME_SPACES.CALENDAR}:WEEKDAYS.NONE`)
    }

    if (
      weekday.length === 2 &&
      weekday.includes(Weekday.Saturday) &&
      weekday.includes(Weekday.Sunday)
    ) {
      return i18n.t(`${NAME_SPACES.CALENDAR}:WEEKDAYS.WEEKEND`)
    }

    if (
      weekday.length === 5 &&
      !weekday.includes(Weekday.Saturday) &&
      !weekday.includes(Weekday.Sunday)
    ) {
      return i18n.t(`${NAME_SPACES.CALENDAR}:WEEKDAYS.WEEKDAYS`)
    }

    if (weekday.length === 7) {
      return i18n.t(`${NAME_SPACES.CALENDAR}:WEEKDAYS.EVERYDAY`)
    }

    return weekday
      .map((w) => formatWeekday(w as Weekday, isRecurring))
      .join(", ")
  }

  let value = i18n.t(`${NAME_SPACES.CALENDAR}:WEEKDAYS.${weekday}`)

  if (isRecurring) {
    value = i18n.t("EVERY", {
      value: i18n.t(`${NAME_SPACES.CALENDAR}:WEEKDAYS.${weekday}`),
    })
  }

  if (!isNil(time)) {
    value = i18n.t("WITH_TIME", {
      value,
      time: format(dateFromTime(time), "HH:mm"),
    })
  }

  return value
}

export const formatIntensity = (intensity: TrainingZone | undefined) => {
  if (isNil(intensity)) return ""

  return i18n.t(`${NAME_SPACES.MOVEMENT}:${MOVEMENT.INTENSITY}.${intensity}`)
}

export const formatAdventurePhaseTitle = (
  title: string | null | undefined,
  index: number
) => {
  if (isNil(title) || title.length === 0) {
    return i18n.t(`${NAME_SPACES.ADVENTURE}:TEMPLATE.TABS.PHASES.PHASE_N`, {
      index: index + 1,
    })
  } else {
    return title
  }
}

export const formatPhaseOfDay = (phase: PhaseOfDay | undefined | null) => {
  if (isNil(phase)) return ""

  return i18n.t(`${NAME_SPACES.MOVEMENT}:${MOVEMENT.PHASE_OF_DAY}.${phase}`)
}
