import { IonSkeletonText } from "@ionic/react"
import classNames from "clsx"
import React from "react"
import { Draggable } from "react-beautiful-dnd"
import { useTranslation } from "react-i18next"
import { RiAddLine, RiRestTimeLine } from "react-icons/ri"
import {
  AgendaDay,
  useAgendaSchedulingContext,
} from "../../../contexts/AgendaSchedulingContext"
import { NAME_SPACES } from "../../../locales/constants"

export const AgendaTimelineRestDaySlot = ({
  day,
  baseIndex,
  handleAddClick,
  isPast = false,
}: {
  day: AgendaDay
  baseIndex: number
  handleAddClick: (day: AgendaDay) => void
  isPast?: boolean
}) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)

  const { isSelectedWeekStale: selectedWeekLoading } =
    useAgendaSchedulingContext()

  if (selectedWeekLoading) {
    return <IonSkeletonText animated className="w-full h-10" />
  }

  return (
    <Draggable
      draggableId={"rest:" + day.weekday}
      index={baseIndex + 1}
      isDragDisabled
    >
      {(provided) => (
        <button
          name={day.weekday}
          title={day.weekday}
          ref={provided.innerRef}
          className={classNames(
            "h-10 flex items-center justify-center z-0",
            "justify-center pointer-events-auto",
            "rounded-sm",
            "text-neutral-500",
            isPast ? "bg-neutral-300" : "bg-neutral-300/50",
            "shadow-inner"
          )}
          type="button"
          onClick={() => {
            handleAddClick(day)
          }}
        >
          <div className="flex flex-row items-center font-medium gap-x-2">
            {isPast ? (
              <>
                <RiRestTimeLine />
                <span>{t("AGENDA_HOME.REST_DAY.PAST")}</span>
              </>
            ) : (
              <>
                <RiAddLine />
                <span>{t("AGENDA_HOME.REST_DAY.FUTURE")}</span>
              </>
            )}
          </div>
        </button>
      )}
    </Draggable>
  )
}
