import clsx from "clsx"
import { isBefore, parseISO } from "date-fns"
import { get, maxBy, reduce, sumBy } from "lodash"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { IoCheckmarkCircle } from "react-icons/io5"
import { RiRestTimeLine } from "react-icons/ri"

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from "recharts"

import Loading from "../Loading"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import { useLocaleContext } from "../../contexts/LocaleContext"
import {
  ListMovementWeeksDocument,
  ListMovementWeeksQuery,
  ListMovementWeeksQueryVariables,
  MovementModality,
} from "../../generated/graphql"
import { modalityLabels } from "../../labels"
import { NAME_SPACES } from "../../locales/constants"
import { weekdayIndex, weekdayOrder } from "../../utils"
import { formatSessionDuration, formatWeekday } from "../../utils/format"
import { useQuery } from "@apollo/client"
import { DiscreteCarousel } from "../Core/DiscreteCarousel"
import { useIonRouter } from "@ionic/react"

export const WeekLog = ({
  week,
  isActive,
}: {
  week: ListMovementWeeksQuery["listMovementWeeks"][0]
  isActive?: boolean
}) => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)

  const { agenda } = useAgendaSchedulingContext()
  const { formatDateInterval } = useLocaleContext()

  const startDate = parseISO(week.startDate)
  const endDate = parseISO(week.endDate)

  const label = formatDateInterval(startDate, endDate)

  const numberOfSessions = week.completedSessions?.length || 0

  const emptyWeekData = weekdayOrder.map((weekday) => ({
    name: formatWeekday(weekday).slice(0, 3),
    [MovementModality.Mobility]: 0,
    [MovementModality.Strength]: 0,
    [MovementModality.Cardio]: 0,
    [MovementModality.Sport]: 0,
    [MovementModality.Breathing]: 0,
    [MovementModality.Knowledge]: 0,
    [MovementModality.Other]: 0,
  }))

  const wasGoalReached =
    numberOfSessions >= (agenda?.regularityIdealWeeklySessionGoal || 1)

  const sessionsPerWeekday = reduce(
    week.completedSessions,
    (acc, session) => {
      const item = acc[weekdayIndex[session.weekday]]

      const sessionMinutes = session.duration || 0

      item[session.movementModality] += sessionMinutes

      return acc
    },
    emptyWeekData
  )

  const totalTime = sumBy(week.completedSessions, "duration")

  const maxSessionDuration =
    maxBy(week.completedSessions, "duration")?.duration || 1800

  const durationTicks = Array.from(
    { length: Math.ceil(maxSessionDuration / 1800) },
    (_, i) => (i + 1) * 1800
  )

  return (
    <div className="w-full h-full px-4 sm:px-6 lg:px-8">
      <div
        className={clsx(
          "flex flex-col items-start bg-white rounded-lg shadow-md gap-y-4 select-none"
        )}
      >
        <div className="flex flex-row items-start justify-between w-full p-4">
          <div className="flex flex-col items-start gap-y-1">
            <span className="font-medium tracking-tighter text-neutral-500">
              {label}
            </span>
            <div className="inline-flex items-baseline gap-x-1">
              <h2 className="text-4xl font-bold text-neutral-800">
                {numberOfSessions}
              </h2>
              <span className="text-4xl font-medium text-neutral-600">
                / {agenda?.regularityIdealWeeklySessionGoal}
              </span>
              <span className="text-2xl font-medium text-neutral-600">
                {t("PROGRESS.SESSIONS_COMPLETED")}
              </span>
            </div>
            <div className="inline-flex items-baseline gap-x-1">
              <span className="text-lg text-neutral-800">
                {formatSessionDuration(totalTime, false)}
              </span>
              <span className="text-lg font-semilight text-neutral-600">
                {t("PROGRESS.TOTAL_TIME")}
              </span>
            </div>
          </div>
          {numberOfSessions > 0 && wasGoalReached && (
            <IoCheckmarkCircle className="w-16 h-16 text-green-500" />
          )}
        </div>

        <div
          className={clsx(
            "w-full p-1 h-72",
            // "transition-opacity",
            isActive ? "visible" : "hidden"
          )}
        >
          {numberOfSessions > 0 && (
            <ResponsiveContainer minWidth={100} minHeight={100}>
              <BarChart
                data={sessionsPerWeekday}
                margin={{
                  top: 0,
                  right: 4,
                  left: 16,
                  bottom: 4,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="category" dataKey="name" className="text-sm" />
                <YAxis
                  type="number"
                  domain={[0, "dataMax + 100"]}
                  orientation="right"
                  allowDecimals={false}
                  ticks={durationTicks}
                  scale="linear"
                  padding={{ top: 20, bottom: 0 }}
                  width={40}
                  tickFormatter={(value) => formatSessionDuration(value)}
                />
                <Bar
                  type="monotone"
                  dataKey={MovementModality.Mobility}
                  stackId={week.uuid}
                  fill="#107496"
                  stroke="#107496"
                />

                <Bar
                  type="monotone"
                  dataKey={MovementModality.Strength}
                  stackId={week.uuid}
                  fill="var(--ion-color-strength)"
                  stroke="var(--ion-color-strength)"
                />

                <Bar
                  type="monotone"
                  dataKey={MovementModality.Cardio}
                  stackId={week.uuid}
                  fill="var(--ion-color-cardio)"
                  stroke="var(--ion-color-cardio)"
                />

                <Bar
                  type="monotone"
                  dataKey={MovementModality.Sport}
                  stackId={week.uuid}
                  fill="var(--ion-color-sport)"
                  stroke="var(--ion-color-sport)"
                />

                <Bar
                  type="monotone"
                  dataKey={MovementModality.Breathing}
                  stackId={week.uuid}
                  fill="var(--ion-color-breathing)"
                  stroke="var(--ion-color-breathing)"
                />
                <Legend
                  formatter={(value) => {
                    return get(modalityLabels, value)
                  }}
                />
              </BarChart>
            </ResponsiveContainer>
          )}
          {numberOfSessions === 0 && (
            <div className="flex flex-col items-center justify-center w-full h-full p-8 text-xl font-medium gap-y-4 text-neutral-500">
              <RiRestTimeLine className="w-14 h-14" />
              <span>{t("PROGRESS.NO_SESSIONS_COMPLETED_WEEK")}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const LoadingWeekLog = () => {
  return (
    <div className="flex flex-col m-4 border rounded-lg border-neutral-300 bg-neutral-200 h-72">
      <div className="flex flex-row items-center justify-center w-full h-full p-4">
        <Loading background="transparent" />
      </div>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface WeekLogCarouselProps {}

export const WeekLogCarousel: React.FC<WeekLogCarouselProps> = () => {
  const router = useIonRouter()
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)

  const [activeWeekIndex, setActiveWeekIndex] = useState(0)

  const [weeks, setWeeks] = useState<
    ListMovementWeeksQuery["listMovementWeeks"]
  >([])

  const { isReady } = useAgendaSchedulingContext()

  useQuery<ListMovementWeeksQuery, ListMovementWeeksQueryVariables>(
    ListMovementWeeksDocument,
    {
      skip: !isReady,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      onCompleted: (data) => {
        const pastOrPresentWeeks = data.listMovementWeeks.filter((week: any) =>
          isBefore(parseISO(week.startDate), new Date())
        )

        setWeeks(pastOrPresentWeeks)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const handleChange = (index: number) => {
    setActiveWeekIndex(index)
  }

  useEffect(() => {
    if (weeks.length > 0) {
      setActiveWeekIndex(weeks.length - 1)
    }
  }, [weeks.length])

  if (!router.routeInfo.pathname.includes("profile")) {
    return null
  }

  if (weeks.length === 0) {
    return null
  }

  return (
    <div className="flex flex-col h-full gap-y-3">
      <span className="px-4 font-medium text-neutral-500 sm:px-6 lg:px-8">
        {t("PROGRESS.ACTIVITY_LOG")}
      </span>
      <DiscreteCarousel
        wrapAround={false}
        afterSlide={handleChange}
        slideIndex={weeks.length - 1}
        maxSlidesToShow={1}
      >
        {weeks.map((week, index) => (
          <WeekLog
            key={week.uuid}
            week={week}
            isActive={Math.abs(activeWeekIndex - index) <= 1}
          />
        ))}
      </DiscreteCarousel>
    </div>
  )
}
