import { useMutation } from "@apollo/client"
import { IonLoading, useIonAlert } from "@ionic/react"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { RiAccountCircleLine, RiDeleteBin2Line } from "react-icons/ri"

import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"
import { ModalOrchestrationName } from "../../contexts/ModalOrchestrationContext"
import { DeleteUserDocument } from "../../generated/graphql"
import useToast from "../../hooks/useToast"
import { MEMBERSHIP, NAME_SPACES } from "../../locales/constants"
import Button from "../Forms/Button"
import { Grid } from "../Forms/GridForm"
import { GridFormField } from "../Forms/GridFormField"
import { useRedirectionState } from "../../routers/AuthRouter"

export const AccountSettingsField: React.FC = () => {
  const { t, i18n } = useTranslation(NAME_SPACES.MEMBERSHIP)
  const {
    SETTINGS: {
      FIELDS: { ACCOUNT },
    },
  } = t(MEMBERSHIP.PROFILE, {
    returnObjects: true,
  })

  const { currentMember } = useAuthenticatedClientContext()

  const SettingsAccountBody = () => {
    const { logout } = useAuthenticatedClientContext()

    const { showInfo, showError } = useToast()
    const [showAlert] = useIonAlert()

    const [deleteUser, { loading }] = useMutation(DeleteUserDocument)

    const [, setRedirectionState] = useRedirectionState({
      shouldRedirect: false,
    })

    const handleDeleteAccount = () => {
      showAlert({
        header: ACCOUNT.DELETION.CONFIRMATION.HEADER,
        subHeader: ACCOUNT.DELETION.CONFIRMATION.SUBHEADER,
        buttons: [
          i18n.t("FORMS.CANCEL"),
          {
            text: i18n.t("FORMS.CONFIRM"),
            role: "destructive",
            handler: async () => {
              deleteUser({
                onCompleted: () => {
                  showInfo(ACCOUNT.DELETION.STATUS.SUCCESS)

                  logout()

                  setRedirectionState({
                    shouldRedirect: false,
                    to: undefined,
                    search: undefined,
                  })

                  window.location.reload()
                },
                onError: (error) => {
                  console.error(error)

                  showError(ACCOUNT.DELETION.STATUS.ERROR)
                },
              })
            },
          },
        ],
      })
    }

    if (loading) {
      return <IonLoading isOpen />
    }

    return (
      <Grid className="w-full">
        <div className="col-span-2 px-1">
          <Button
            color="danger"
            type="button"
            className="w-full"
            fill="outline"
            onClick={handleDeleteAccount}
            icon={RiDeleteBin2Line}
            label={ACCOUNT.DELETION.CTA}
          />
        </div>
      </Grid>
    )
  }

  const formatAccountName = useCallback(
    (_val: any) => {
      return currentMember?.firstName || ACCOUNT.TITLE
    },
    [currentMember]
  )

  return (
    <GridFormField
      name={"account"}
      modalName={ModalOrchestrationName.AccountSettings}
      label={ACCOUNT.LABEL}
      Body={SettingsAccountBody}
      Icon={RiAccountCircleLine}
      title={ACCOUNT.TITLE}
      formatValue={formatAccountName}
      showSubmitButton={false}
      required
    />
  )
}
