import { IonIcon, IonInput, IonItem, IonLabel } from "@ionic/react"
import classNames from "clsx"
import { warning } from "ionicons/icons"
import isNil from "lodash/isNil"
import React from "react"

import { FormFieldProps } from "./Field"

interface InputProps
  extends Omit<React.ComponentProps<typeof IonInput>, "name" | "value">,
    FormFieldProps {
  label: string
}

const Input: React.FC<InputProps> = ({
  name,
  label,
  color,
  className,
  required,
  register,
  error,
  ...rest
}) => {
  if (isNil(register)) {
    return <></>
  }

  const { ref, onBlur, onChange } = register(name, { required })

  return (
    <>
      <IonItem lines="none">
        <IonLabel position="floating" color={color} className="ml-2">
          {label}
        </IonLabel>
        <IonInput
          color={color}
          className={classNames(
            "bg-white/20 rounded-sm shadow-sm px-2",
            // "border-white/20 border-b border-x border-t p-2 rounded-sm",
            className
          )}
          style={{
            "--padding-start": "0.5rem",
          }}
          name={name}
          ref={ref}
          onIonBlur={onBlur}
          onIonChange={onChange}
          {...rest}
        />
        {error && (
          <div className="flex flex-col w-full my-1 gap-y-1">
            {error.types &&
              Object.entries(error.types).map(([type, message]) => {
                return (
                  <div
                    className="flex flex-row p-2 bg-red-400 rounded-sm gap-x-2"
                    key={type}
                  >
                    <IonIcon icon={warning} color="white" />
                    <p
                      className="flex-grow text-sm font-semibold text-white"
                      id={`${name}-error`}
                    >
                      {message as string}
                    </p>
                  </div>
                )
              })}
          </div>
        )}
      </IonItem>
    </>
  )
}

export default Input
