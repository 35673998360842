import { MovementWizardQuestion } from "../../components/Movement/Wizard/MovementWizardQuestion"
import {
  AssessmentRatingOfPerceivedExertion,
  BodyJointEnum,
  BodyMuscleEnum,
  BodyMuscleGroupEnum,
  BodyPartEnum,
  BodyRegionEnum,
  EnvironmentalEquipment,
  MovementModality,
  MovementSessionSatisfaction,
  MovementStyle,
  PhaseOfDay,
  TrainingZone,
} from "../../generated/graphql"
import { MOVEMENT } from "../constants"

export default {
  [MOVEMENT.SESSION_LOGGING]: {
    ERROR_MESSAGE:
      "An error occurred when logging the session. Please try again.",
    SUCCESS_MESSAGE: "Well done!",
    STYLE_REQUIRED: "The movement style is required",
    WEEKDAY_REQUIRED: "The weekday is required",
    DURATION_REQUIRED: "A duration is required",
    REGISTER_SESSION: "Log session",
    SUBTITLE: "All your movement, one agenda.",
    SECTIONS: {
      MOVEMENT: "Movement Details",
      SCHEDULE: "Scheduling Details",
    },
  },
  [MOVEMENT.ITEM_PLANNING]: {
    SESSION_ADDED_SUCCESS_MESSAGE:
      "Successfully planned session in your agenda.",
    SESSION_MODIFIED_SUCCESS_MESSAGE: "Successfully modified session.",
    ADD_TO_AGENDA: "Add to agenda",
    SAVE_MODIFICATION: "Save changes",
    SESSION_DELETED_SUCCESS_MESSAGE: "Successfully removed session.",
    TITLE: {
      PLAN_NEW: "Plan a session",
      ADAPT_EXISTING: "Adapt the session",
    },
    ADD_NEW_SEESION: "Add a new session",
    MODIFY_SESSION: "Modify session",
    GET_HELP: "Need help?",
    RECURRING_ITEM_MESSAGE: "This session is recurring.",
    MODIFY_ITEM_OCCURRENCE_MESSAGE: "Modify only this week",
    MODIFY_RECURRING_ITEM_MESSAGE: "Modify all occurrences",
    DELETE_ITEM: "Delete session",
    DELETE_ITEM_OCCURRENCE_MESSAGE: "Skip for this week",
    DELETE_RECURRING_ITEM_MESSAGE: "Delete recurring session",
    CANCEL: "Cancel",
    CLEAR: "Clear",
    SECTIONS: {
      MOVEMENT: "What?",
      SCHEDULE: "When?",
    },
  },
  [MOVEMENT.AGENDA_HOME]: {
    REST_DAY: {
      PAST: "rest day",
      FUTURE: "plan a session",
    },
    CONVERSION_HOOK: {
      TITLE: "Want to see what your future holds, {{ firstName }}?",
      DESCRIPTION:
        "Hint: it's full of movement. Become a Hero now to unlock the full potential of the movement agenda.",
      CTA: "Unlock Full Agenda",
    },
    ADD_SESSION: "Add session",
    LOG_SESSION: "Log a completed session",
    PLAN_SESSION: "Plan an upcoming session",
    CANCEL: "Cancel",
    AGENDA: "Agenda",
    SEE_PREVIOUS_WEEK: "See previous week",
    SEE_NEXT_WEEK: "See next week",
    WARNING_PLAN_SESSION_IN_PAST: "You can't plan a session in the past.",
    NEXT_UP: "Next move",
    LETS_GO: "Let's move!",
    AVAILABLE_TIME: "How much time do you have?",
  },
  [MOVEMENT.AGENDA_SUGGESTION]: {
    MESSAGE_FROM_COACH: "A message from your coach",
    CTAS: {
      PLAN: "Add to agenda",
      SKIP: "Skip session",
    },
    SUGGESTION_REJECTED: {
      TITLE: "It's feedback time!",
      DESCRIPTION:
        "Let me know what was wrong with this suggestion through the chat below. The more feedback you give, the better my suggestions are.",
      CHAT_CTA: "Give feedback",
      SKIP_CTA: "Skip",
    },
  },
  [MOVEMENT.AGENDA_ITEM]: {
    CTA: {
      START_SESSION: "Start Session",
      LOG_SESSION: "I did it!",
    },
    SESSION_NOT_FOUND: "This session wasn't found.",
    SUCCESS_MESSAGE: "Well done!",
    AN_ERROR_HAS_OCCURED: "Something went wrong... Please try again.",
    RECURRING_SESSION: "Recurring session",
    PLANNED_SESSION: "Planned session",
    NO_VIDEO_MESSAGE:
      "If you would like a hand-picked video for this session style, please contact your assistant.",
    VALIDATE_SESSION: "Mark as done",
    DETAILS: "Details",
    DURATION: "Duration",
    STYLE: "Style",
    RECURRENCE: "Recurrence",
    PLANNED_START_TIME: "Start time",
    TARGET_AREA: "Target area",
    INTENSITY: "Intensity",
    DESCRIPTION: {
      PLACEHOLDER: "Add notes...",
    },
    SUGGESTED_BY_COACH: "Suggested by your coach",
    SUGGESTED_FOLLOW_ALONG: "Curated video",
    SUGGESTED_EXTERNAL_LINK: "Open external link",
  },
  [MOVEMENT.CURRENT_MOVEMENT_SESSION]: {
    START_SEESION_ERROR_MESSAGE: "An error occurred when starting the session.",
    ABANDON_SESSION_ERROR_MESSAGE:
      "An error occurred when abandoning the session.",
    COMPLETE_SESSION_ERROR_MESSAGE:
      "An error occurred when completing the session.",
    ABANDON_SESSION_CONFIRMATION_MESSAGE:
      "Are you sure you want to discard this session? If you're done, you can save it as completed.",
    ABANDON: "I'm done",
    CONTINUE: "Keep moving",
    SUCCESS_MESSAGE: "Well done!",
    BEST_SUGGESTION: "Curating the best video for you...",
    TITLE: "Your session for",
    SHUFFLE_VIDEO: "Discover different video",
    SCHEDULE_VIDEO_FOR_LATER: "Schedule for later",
    COMPLETE_SESSION: "Complete session",
    MISLABELED_VIDEO_MESSAGE:
      "This YouTube video was curated for our free Open Movement library. Any contribution to improve the labeling is more than welcome.",
  },
  [MOVEMENT.PAST_MOVEMENT_SESSION]: {
    TITLE: "Session - {{ date }}",
    ACTIONS: {
      DELETE: {
        LABEL: "Delete session",
        RESULT: {
          SUCCESS: "Session deleted",
          ERROR: "An error occurred when deleting the session.",
        },
      },
      MODIFY: {
        LABEL: "Edit details",
        RESULT: {
          SUCCESS: "Session details modified",
          ERROR: "An error occurred when modifying the session.",
        },
      },
      CANCEL: "Cancel",
    },
    DESCRIPTION: {
      PLACEHOLDER: "This session was great...",
    },
  },
  [MOVEMENT.FORM]: {
    TITLE: {
      PLACEHOLDER: "Untitled...",
    },
    SCHEDULE: {
      LABEL: "Date",
      FUTURE: {
        TITLE: "Date of the session",
        // SUBTITLE: "Schedule the session for a future date.",
      },
      PAST: {
        TITLE: "Date of the session",
        // SUBTITLE: "When did you do this session?",
      },
      EVERY_WEEK: "Every week",
      ANY_DAY: "Any day",
    },
    STYLE: {
      LABEL: "Activity",
      TITLE: "",
      PLACEHOLDER: "Choose activity",
      SUBTITLE: "",
    },
    BODY_PART: {
      LABEL: "Target",
      TITLE: "Target a body part",
      // SUBTITLE: "Choose a body part to target.",
    },
    DURATION: {
      LABEL: "Duration",
      TITLE: "Choose a duration",
      // SUBTITLE: "How long should this session last?",
    },
    RPE: {
      LABEL: "Intensity",
      TITLE: "How intense was it?",
      // SUBTITLE: "Rate your level of effort.",
    },
    FOLLOW_ALONG: {
      LABEL: "Video",
    },
    PACE: {
      LABEL: "Pace",
    },
    DISTANCE: {
      LABEL: "Distance",
      TITLE: "How far did you go?",
      // SUBTITLE: "Select the distance you covered.",
      EMPTY: "No distance",
    },
    START_TIME: {
      LABEL: "Start time",
      PLACEHOLDER: "Add start time",
      PAST: {
        TITLE: "When did you start?",
        // SUBTITLE: "Select the approximate start time for your session.",
      },
      FUTURE: {
        TITLE: "When do you want to start?",
        // SUBTITLE: "Select the approximate start time for your session.",
      },
    },
    PHASE_OF_DAY: {
      LABEL: "Phase of day",
      PLACEHOLDER: "Select phase",
      TITLE: "When do you want to do this session?",
    },
    TARGET_BODY_PARTS: "Target Area",
    CHOOSE_TARGET_BODY_PARTS: "Choose target body part",
    DATE: "Date",
    CONFIRM: "Confirm",
    CANCEL: "Cancel",
    CHOOSE: "Select",
    TIME_AVAILABLE: "Time available",
    MOVEMENT_STYLE: "Style",
    MOVEMENT_STYLE_SEARCH_PLACEHOLDER: "Search a style...",
    NO_MOVEMENT_STYLE_SELECTED: "All styles",
    SELECT_MOVEMENT_STYLE: "Select a style",
    CLEAR: "Clear",
    SESSION_STYLE_CHOOSE: "Select a movement style for this session.",
    WEEKDAY: "Weekday",
  },
  [MOVEMENT.POST_SESSION_FEEDBACK]: {
    SESSION_COMPLETED: "Session completed!",
    RATING_LABEL: "How did it go?",
    DESCRIPTION_PLACEHOLDER: "Add notes about your session...",
    CONTINUE_BUTTON: "Continue",
    SATISFACTION: {
      LABELS: {
        [MovementSessionSatisfaction.Love]: "Loved it",
        [MovementSessionSatisfaction.Like]: "Liked it",
        [MovementSessionSatisfaction.Dislike]: "Disliked it",
      },
      SUPPORT: {
        LABEL: "Need help or have a question?",
        BUTTON: "Contact us",
      },
    },
    // old
    SHARE_BUTTON: "Share",
    TITLE: "behale",
    WEEK_SESSION: "Sessions this week",
    GOAL_INPROGRESS_MESSAGE: "Bravo! You're almost there, ",
    GOAL_COMPLETED_MESSAGE: "Excellent! You've reached your weekly goal!",
    SHARE_MESSAGE:
      "I've done {{completedSession}} session(s) with Behale this week!",
    SHARE_TITLE: "I've finished my {{movementStyle}} session with Behale",
  },
  [MOVEMENT.INTENSITY]: {
    [TrainingZone.Recovery]: "Recovery",
    [TrainingZone.Light]: "Light",
    [TrainingZone.Moderate]: "Moderate",
    [TrainingZone.Vigorous]: "Vigorous",
    [TrainingZone.Maximal]: "Maximal",
  },
  [MOVEMENT.TARGET_BODY_PARTS]: {
    NONE: "No target",
    [BodyPartEnum.FullBody]: "Full body",
    // regions
    [BodyRegionEnum.UpperBody]: "Upper body",
    [BodyRegionEnum.Core]: "Core",
    [BodyRegionEnum.LowerBody]: "Lower body",
    // muscle groups
    [BodyMuscleGroupEnum.NeckMuscles]: "Neck",
    [BodyMuscleGroupEnum.ChestMuscles]: "Chest",
    [BodyMuscleGroupEnum.ShoulderMuscles]: "Shoulders",
    [BodyMuscleGroupEnum.ArmMuscles]: "Arms",
    [BodyMuscleGroupEnum.BackMuscles]: "Back",
    [BodyMuscleGroupEnum.TrunkMuscles]: "Abdominals",
    [BodyMuscleGroupEnum.HipMuscles]: "Hip Flexors",
    [BodyMuscleGroupEnum.ThighMuscles]: "Thighs",
    [BodyMuscleGroupEnum.LegMuscles]: "Calves",
    // muscles
    [BodyMuscleEnum.Abdominals]: "Abdominals",
    [BodyMuscleEnum.Adductors]: "Adductors",
    [BodyMuscleEnum.Biceps]: "Biceps",
    [BodyMuscleEnum.Calves]: "Calves",
    [BodyMuscleEnum.Forearms]: "Forearms",
    [BodyMuscleEnum.Glutes]: "Glutes",
    [BodyMuscleEnum.Hamstrings]: "Hamstrings",
    [BodyMuscleEnum.Iliopsoas]: "Iliopsoas",
    [BodyMuscleEnum.LowerBack]: "Lower back",
    [BodyMuscleEnum.MiddleBack]: "Mid back",
    [BodyMuscleEnum.Obliques]: "Obliques",
    [BodyMuscleEnum.Quadriceps]: "Quadriceps",
    [BodyMuscleEnum.Tibias]: "Tibias",
    [BodyMuscleEnum.Triceps]: "Triceps",
    [BodyMuscleEnum.UpperBack]: "Upper back",
    // joints
    [BodyJointEnum.Feet]: "Feet",
    [BodyJointEnum.Ankles]: "Ankles",
    [BodyJointEnum.Elbows]: "Elbows",
    [BodyJointEnum.Wrists]: "Wrists",
    [BodyJointEnum.Hips]: "Hips",
    [BodyJointEnum.Knees]: "Knees",
    [BodyJointEnum.ShoulderComplex]: "Shoulders",
    [BodyJointEnum.CervicalSpine]: "Neck",
    [BodyJointEnum.LumbarSpine]: "Lower back",
    [BodyJointEnum.ThoracicSpine]: "Upper back",
    [BodyJointEnum.Sacroiliac]: "Tailbone",
  } as unknown as Record<BodyPartEnum | "NONE", string>,
  [MOVEMENT.STYLE]: {
    ALL_STYLES: "All styles",
    [MovementStyle.Yoga]: "Yoga",
    [MovementStyle.Pilates]: "Pilates",
    [MovementStyle.Running]: "Running",
    [MovementStyle.Cycling]: "Cycling",
    [MovementStyle.Swimming]: "Swimming",
    [MovementStyle.CircuitTraining]: "Strength & Conditioning",
    [MovementStyle.Hiit]: "HIIT",
    [MovementStyle.Dancing]: "Dancing",
    [MovementStyle.FunctionalStretching]: "Stretching",
    [MovementStyle.Rowing]: "Rowing",
    [MovementStyle.Walking]: "Walk",
    [MovementStyle.Weightlifting]: "Weightlifting",
    [MovementStyle.Basketball]: "Basketball",
    [MovementStyle.Football]: "Football",
    [MovementStyle.Rugby]: "Rugby",
    [MovementStyle.Tennis]: "Tennis",
    [MovementStyle.Golf]: "Golf",
    [MovementStyle.Bjj]: "Brazilian Jiu Jitsu",
    [MovementStyle.Judo]: "Judo",
    [MovementStyle.Boxing]: "Boxing",
    [MovementStyle.Volleyball]: "Volleyball",
    [MovementStyle.TaiChi]: "Tai Chi",
    [MovementStyle.Karate]: "Karaté",
    [MovementStyle.Surfing]: "Surf",
    [MovementStyle.Skiing]: "Ski",
    [MovementStyle.Climbing]: "Climbing",
    [MovementStyle.HorseRiding]: "Horse Riding",
    [MovementStyle.Hiking]: "Hiking",
    [MovementStyle.TableTennis]: "Ping Pong",
    [MovementStyle.Handball]: "Handball",
    [MovementStyle.Consultation]: "Consultation",
    [MovementStyle.Article]: "Article",
    [MovementStyle.Breathwork]: "Breathwork",
    [MovementStyle.Meditation]: "Meditation",
    [MovementStyle.Calisthenics]: "Calisthenics",
    [MovementStyle.Skateboarding]: "Skateboarding",
    [MovementStyle.Crossfit]: "Crossfit",
    [MovementStyle.Other]: "Other",
    [MovementStyle.EducationalVideo]: "Tutorial",
    [MovementStyle.Reading]: "Reading",
    [MovementStyle.Writing]: "Writing",
  } as Record<MovementStyle | "ALL_STYLES", string>,
  [MOVEMENT.MODALITY]: {
    [MovementModality.Cardio]: "Cardio",
    [MovementModality.Strength]: "Strength",
    [MovementModality.Mobility]: "Mobility",
    [MovementModality.Breathing]: "Breathing",
    [MovementModality.Sport]: "Sport",
    [MovementModality.Knowledge]: "Knowledge",
    [MovementModality.Other]: "Other",
  } as Record<MovementModality, string>,
  [MOVEMENT.RPE]: {
    NONE: {
      LABEL: "Not reported",
    },
    [AssessmentRatingOfPerceivedExertion.NoEffort]: {
      LABEL: "No effort",
      EMOJI: "😎",
      DESCRIPTION: "Like sitting on the couch. Or being asleep.",
    },
    [AssessmentRatingOfPerceivedExertion.ExtremelyEasy]: {
      LABEL: "Extremely easy",
      EMOJI: "😄",
      DESCRIPTION: "Barely noticeable effort, like brushing your teeth",
    },
    [AssessmentRatingOfPerceivedExertion.VeryEasy]: {
      LABEL: "Very easy",
      EMOJI: "😁",
      DESCRIPTION: "You could keep this up the whole day no sweat",
    },
    [AssessmentRatingOfPerceivedExertion.Easy]: {
      LABEL: "Easy",
      EMOJI: "😊",
      DESCRIPTION: "Easy to breathe and carry a conversation",
    },
    [AssessmentRatingOfPerceivedExertion.SomewhatEasy]: {
      LABEL: "Somewhat easy",
      EMOJI: "🙂",
      DESCRIPTION: "Still comfortable, but you're starting to sweat",
    },
    [AssessmentRatingOfPerceivedExertion.Moderate]: {
      LABEL: "Moderate effort",
      EMOJI: "😐",
      DESCRIPTION:
        "Somewhat challenging, but you can still carry a short conversation",
    },
    [AssessmentRatingOfPerceivedExertion.SomewhatHard]: {
      LABEL: "Somewhat hard",
      EMOJI: "😟",
      DESCRIPTION:
        "Breathing heavily, it's getting challenging and uncomfortable.",
    },
    [AssessmentRatingOfPerceivedExertion.Hard]: {
      LABEL: "Hard",
      EMOJI: "😰",
      DESCRIPTION:
        "No conversations possible, only short sentences. Shortness of breath",
    },
    [AssessmentRatingOfPerceivedExertion.VeryHard]: {
      LABEL: "Very hard",
      EMOJI: "🥵",
      DESCRIPTION:
        "Getting really uncomfortable. Intense focus needed to maintain this effort.",
    },
    [AssessmentRatingOfPerceivedExertion.ExtremelyHard]: {
      LABEL: "Extremely hard",
      EMOJI: "😵‍💫",
      DESCRIPTION: "'F*@k' is probably the only word you could say",
    },
    [AssessmentRatingOfPerceivedExertion.MaxEffort]: {
      LABEL: "Maximum efffort",
      EMOJI: "😵",
      DESCRIPTION: "Impossible to keep going. Completely out of breath.",
    },
  } as Record<
    AssessmentRatingOfPerceivedExertion,
    { LABEL: string; DESCRIPTION: string; EMOJI: string }
  >,
  [MOVEMENT.EQUIPMENT]: {
    NONE: "No equipment",
    [EnvironmentalEquipment.ElasticBand]: "Elastic band",
    [EnvironmentalEquipment.Barbell]: "Barbell",
    [EnvironmentalEquipment.Dumbbells]: "Dumbbells",
    [EnvironmentalEquipment.Kettlebell]: "Kettlebell",
    [EnvironmentalEquipment.Chair]: "Chair",
    [EnvironmentalEquipment.FoamRoller]: "Foam roller",
    [EnvironmentalEquipment.JumpRope]: "Jump rope",
    [EnvironmentalEquipment.Block]: "Yoga block",
    [EnvironmentalEquipment.Mat]: "Yoga mat",
    [EnvironmentalEquipment.PullUpBar]: "Pull up bar",
    [EnvironmentalEquipment.Stepper]: "Stepper",
  } as Record<EnvironmentalEquipment & "NONE", string>,
  [MOVEMENT.DISTANCE]: {
    NONE: {
      LABEL: "Not reported",
    },
  },
  [MOVEMENT.LOW_IMPACT]: {
    true: "Low impact",
    false: "",
  },
  [MOVEMENT.WIZARD]: {
    INTRODUCTION: {
      TITLE: "Not sure what do?",
      DESCRIPTION: "Let Move AI help you plan your next movement.",
      CTAS: {
        TRY: "Try MoveAI",
      },
    },
    QUESTIONS: {
      CTAS: {
        SKIP: "Skip",
        NEXT: "Next",
      },
      [MovementWizardQuestion.LatestMovement]: {
        TITLE: "Recent movement",
        PROMPT: "How much have you moved recently?",
        OPTIONS: [
          "Sitting all day",
          "A bit of movement",
          "A lot of movement",
          "Did some cardio",
        ],
      },
      [MovementWizardQuestion.CurrentMovementVibe]: {
        TITLE: "Current movement vibe",
        PROMPT: "What's your context and mood right now?",
        OPTIONS: [
          "Not feeling it",
          "Chill and relaxed",
          "Ready to move",
          "Up for a Challenge",
        ],
      },
      [MovementWizardQuestion.PhyiscalDiscomforts]: {
        TITLE: "Physical discomforts",
        PROMPT: "Do you feel any discomforts?",
        SELECT: {
          LABEL: "Areas of discomfort",
          TITLE: "Select an area",
          PLACEHOLDER: "No discomforts",
        },
        OPTIONS: [] as string[],
      },
    },
    RECAP: {
      CTA: "Let's go!",
    },
  },
  [MOVEMENT.PHASE_OF_DAY]: {
    [PhaseOfDay.Morning]: "Morning",
    [PhaseOfDay.Afternoon]: "Afternoon",
    [PhaseOfDay.Evening]: "Evening",
  },
}
