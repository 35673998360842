import { useMutation } from "@apollo/client"
import { useIonRouter } from "@ionic/react"
import { useTranslation } from "react-i18next"

import BaseAuthPage, {
  BaseAuthPageLinkProps,
} from "../../components/Auth/BaseAuthPage"
import { ForgotPasswordForm } from "../../components/Auth/ForgotPasswordForm"
import { RequestPasswordResetDocument } from "../../generated/graphql"
import useToast from "../../hooks/useToast"
import { AUTH, NAME_SPACES } from "../../locales/constants"

const ForgotPasswordPage: React.FC = () => {
  const { showSuccess, showError } = useToast()

  const { t } = useTranslation(NAME_SPACES.AUTH)
  const TEXT = t(AUTH.FORGOT_PASSWORD, {
    returnObjects: true,
  })

  const router = useIonRouter()

  const [mutate] = useMutation(RequestPasswordResetDocument)

  const requestPasswordReset = async (data: { email: string }) => {
    await mutate({
      variables: { email: data.email },
      onCompleted: async () => {
        showSuccess(TEXT.RESET_MAIL_SENT_MESSAGE)

        router.goBack()
      },
      onError: async (error) => {
        let text

        switch (error.message) {
          case "Authentication failed":
            text = t("INCORRECT_CREDENTIALS")
            break

          default:
            text = `${t("AN_ERROR_HAS_OCCURED")}: ` + error.message
            break
        }

        showError(text)
      },
      refetchQueries: [],
    })
  }

  const links = [
    {
      routerLink: "/auth/login",
      routerDirection: "back",
      label: TEXT.PASSWOD_FOUND,
    },
  ]

  return (
    <BaseAuthPage links={links as BaseAuthPageLinkProps[]} showBackButton>
      <span className="text-2xl font-semibold text-center">{TEXT.TITLE}</span>
      <span>{TEXT.EMAIL_INPUT_LABEL}</span>

      <ForgotPasswordForm requestPasswordReset={requestPasswordReset} />

      <div className="relative my-2">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300" />
        </div>
      </div>

      <span className="text-sm">{TEXT.HINT}</span>
    </BaseAuthPage>
  )
}

export default ForgotPasswordPage
