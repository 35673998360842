import { Redirect, Route, Switch, useHistory } from "react-router-dom"

import OAuthCallbackPage from "../pages/Auth/OAuthCallbackPage"
import ForgotPasswordPage from "../pages/Auth/ForgotPasswordPage"
import LoginPage from "../pages/Auth/LoginPage"
import RegistrationPage from "../pages/Auth/RegistrationPage"
import ResetPasswordPage from "../pages/Auth/ResetPasswordPage"
import AuthHomePage from "../pages/Auth/AuthHomePage"
import { useAuthenticatedClientContext } from "../contexts/AuthenticatedClientContext"
import { useIonRouter } from "@ionic/react"
import { useCallback, useEffect, useState } from "react"
import { isInAuthRoute } from "../utils/routes"

import createPersistedState from "use-persisted-state"
import useMarketingOptimizer from "../hooks/useMarketingOptimizer"
import { UserSession } from "../generated/graphql"
import isNil from "lodash/isNil"
import useRouterQueryParams from "../hooks/useRouterQueryParams"
import { Preferences } from "@capacitor/preferences"

interface RedirectionState {
  shouldRedirect?: boolean
  to?: string
  search?: string
}

export const setReferralCode = async (referralCode: string) => {
  await Preferences.set({
    key: "referralCode",
    value: referralCode,
  })
}

export const getReferralCode = async () => {
  const { value } = await Preferences.get({ key: "referralCode" })

  return value
}

export const useRedirectionState =
  createPersistedState<RedirectionState>("authRedirection")

const AuthRouter: React.FC = () => {
  const router = useIonRouter()
  const history = useHistory()
  const query = useRouterQueryParams()

  const { isSessionActive, isInitialized, platform, login } =
    useAuthenticatedClientContext()

  const { reportMarketingEvent } = useMarketingOptimizer()

  const [completingRegistration, setCompletingRegistration] = useState(false)

  const [redirectionState, setRedirectionState] = useRedirectionState({
    shouldRedirect: false,
  })

  const applyRedirect = (pathname: string, search?: string) => {
    console.debug("Redirecting to", pathname, search)

    let path = pathname

    if (!isNil(search) && search.length > 0) {
      path = `${path}?${search}`
    }

    history.push({ pathname: path })
  }

  const handleRegistration = useCallback(
    (userSession: UserSession) => {
      reportMarketingEvent({
        name: "CompleteRegistration",
        userData: {
          email: userSession.user?.email,
        },
        force: true,
      })

      if (redirectionState.shouldRedirect) {
        // redirect directly to the adventure if the user was redirected to the registration page
        if (
          !isNil(redirectionState.to) &&
          redirectionState.to.includes("adventures")
        ) {
          applyRedirect(redirectionState.to, redirectionState.search)

          return
        }
      }

      router.push("/app/welcome", "forward")
    },
    [platform, redirectionState, reportMarketingEvent, router, query]
  )

  const handleLogin = useCallback(
    (_userSession: UserSession) => {
      if (redirectionState.shouldRedirect && !isNil(redirectionState.to)) {
        applyRedirect(redirectionState.to, redirectionState.search)
      } else {
        router.push("/app/hub/home", "root")
      }
    },
    [redirectionState, router]
  )

  const handleCompleted = (userSession: UserSession) => {
    setCompletingRegistration(true)

    login(userSession.token, userSession.refreshToken)

    if (userSession?.isFirstSession) {
      handleRegistration(userSession)
    } else {
      handleLogin(userSession)
    }

    setCompletingRegistration(false)
  }

  useEffect(() => {
    if (!isInitialized) return
    if (!isInAuthRoute(router)) return

    if (completingRegistration || redirectionState.shouldRedirect) return

    if (!isSessionActive) return

    router.push("/app/hub/home", "root", "replace")
  }, [
    isInitialized,
    isSessionActive,
    router,
    redirectionState,
    completingRegistration,
  ])

  useEffect(() => {
    // set referral code in preferences
    if (query.has("referralCode")) {
      setReferralCode(query.get("referralCode") as string)
    }

    if (query.has("redirectTo")) {
      const to = query.get("redirectTo") as string

      query.delete("redirectTo")

      const search = new URLSearchParams(query.toString())

      setTimeout(() => {
        setRedirectionState({
          shouldRedirect: true,
          to: decodeURIComponent(to),
          search: search.toString(),
        })
      }, 200)
    }
  }, [query, redirectionState, setRedirectionState])

  return (
    <Switch>
      <Route exact path="/auth/home">
        <AuthHomePage onCompleted={handleCompleted} />
      </Route>

      <Route exact path="/auth/:provider/callback">
        <OAuthCallbackPage onCompleted={handleCompleted} />
      </Route>

      <Route exact path="/auth/login">
        <LoginPage onCompleted={handleCompleted} />
      </Route>

      <Route exact path="/auth/register">
        <RegistrationPage onCompleted={handleCompleted} />
      </Route>

      <Route exact path="/auth/forgot_password">
        <ForgotPasswordPage />
      </Route>

      <Route exact path="/auth/reset_password">
        <ResetPasswordPage />
      </Route>

      <Route path="/auth">
        <Redirect to="/auth/home" />
      </Route>
    </Switch>
  )
}

export default AuthRouter
