import classNames, { clsx } from "clsx"
import { IconType } from "react-icons/lib"
import { Link } from "react-router-dom"

export interface TabBarElement {
  name: string
  label: string
  icon?: IconType
  hidden?: boolean
  pathname?: string
}

export interface TabBarProps {
  tabs: TabBarElement[]
  activeTab: TabBarElement["name"]
  onTabChange: (tab: TabBarElement["name"]) => void
  className?: string
  color?: string
}

const TabBar: React.FC<TabBarProps> = ({
  tabs,
  activeTab,
  onTabChange,
  className,
  color,
}) => {
  color = color || "white"

  return (
    <div className={clsx("flex flex-row justify-around w-full", className)}>
      <nav
        className="flex w-full max-w-3xl px-4 mx-auto -mb-px space-x-8"
        aria-label="Tabs"
      >
        {tabs.map((tab) => (
          <Link
            to={tab.pathname || "#"}
            onClick={() => onTabChange(tab.name)}
            key={tab.name}
            className={classNames(
              tab.name === activeTab
                ? `border-${color} text-${color}`
                : `border-transparent text-${color} text-opacity-80 hover:border-opacity-80 hover:border-${color}`,
              "font-medium",
              "group flex flex-row justify-center text-center items-center py-4 px-1 border-b-4 w-full",
              "cursor-pointer"
            )}
            aria-current={tab.name === activeTab ? "page" : undefined}
            hidden={tab.hidden}
          >
            {tab.icon && (
              <tab.icon
                className={classNames(
                  tab.name === activeTab
                    ? `text-${color}`
                    : `text-${color}/80 group-hover:text-${color}`,
                  "-ml-0.5 mr-2 h-5 w-5"
                )}
                aria-hidden="true"
              />
            )}
            <span>{tab.label}</span>
          </Link>
        ))}
      </nav>
    </div>
  )
}

export default TabBar
