import { useQuery } from "@apollo/client"
import { IonInput } from "@ionic/react"
import FuzzySearch from "fuzzy-search"
import isNil from "lodash/isNil"
import React, { useCallback } from "react"
import { useEffect, useState } from "react"
import { useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { IoSearchOutline } from "react-icons/io5"
import { ModalOrchestrationName } from "../../../../contexts/ModalOrchestrationContext"

import {
  GetStyleDocument,
  GetStyleQuery,
  ListStylesDocument,
  ListStylesQuery,
  MovementModality,
  MovementStyle,
} from "../../../../generated/graphql"
import { MOVEMENT, NAME_SPACES } from "../../../../locales/constants"
import { enumKeys } from "../../../../utils"
import { formatStyle } from "../../../../utils/format"
import CardSelect, {
  CardSelectOption,
  CardSelectProps,
} from "../../../Forms/CardSelect"
import {
  GridFormField,
  GridFormFieldModal,
  GridFormFieldModalProps,
  GridFormFieldProps,
  RequiredFieldModalBodyProps,
} from "../../../Forms/GridFormField"
import { getIcon } from "../../../Core/Icons"
import { find, uniq } from "lodash"
import { useAgendaSchedulingContext } from "../../../../contexts/AgendaSchedulingContext"
import PingContainer from "../../../Core/Ping"
import clsx from "clsx"
import { modalityLabels } from "../../../../labels"
import { useAuthenticatedClientContext } from "../../../../contexts/AuthenticatedClientContext"

export interface MovementStyleSelectProps
  extends Partial<CardSelectProps<MovementStyle | MovementStyle[]>>,
    RequiredFieldModalBodyProps<MovementStyle | undefined> {
  recommend?: "popular" | "favorite" | "none"
}

export interface StyleOption {
  name: MovementStyle
  label: string
  modality: MovementModality
  modalityLabel: string
}

const popularStyles = [
  // cardio
  MovementStyle.Running,
  MovementStyle.Swimming,
  // strength
  MovementStyle.CircuitTraining,
  // mobility
  MovementStyle.Yoga,
  // sports
  MovementStyle.Football,
  MovementStyle.Tennis,
]

const modalitiesAvailable = [
  MovementModality.Cardio,
  MovementModality.Strength,
  MovementModality.Mobility,
  MovementModality.Sport,
  MovementModality.Breathing,
  MovementModality.Other,
]

const MovementStyleSelect: React.FC<MovementStyleSelectProps> = ({
  state,
  setState,
  filterBy,
  recommend = "none",
  ...props
}) => {
  const { currentMember } = useAuthenticatedClientContext()
  const { agenda } = useAgendaSchedulingContext()

  const { data, loading } = useQuery<ListStylesQuery>(ListStylesDocument, {
    fetchPolicy: "cache-first",
  })

  const buildStyleOption = useCallback(
    (style: MovementStyle): CardSelectOption<MovementStyle> | null => {
      const activityStyle = data?.listActivityStyles.find(
        (s) => s.name === style
      )

      const modality = activityStyle?.modality

      let recommended = false

      switch (recommend) {
        case "popular":
          recommended = popularStyles.includes(style)

          break
        case "favorite":
          recommended = !isNil(
            find(
              agenda?.favoriteMovementActivities || [],
              (a) => a.movementStyle === style
            )
          )
          break
        case "none":
          recommended = false
          break
      }

      if (!isNil(modality) && !modalitiesAvailable.includes(modality)) {
        return null
      }

      const result = {
        value: style,
        label: formatStyle(style),
        Icon: getIcon({
          movementStyle: style,
          movementModality: modality,
        }),
        recommended,
        extra: {
          modality,
          isFollowAlongVideoAvailable:
            activityStyle?.isFollowAlongVideoAvailable,
        },
        information: modality ? modalityLabels[modality] : undefined,
      }

      if (style === MovementStyle.Other) {
        setOtherOption(result)
      }

      return result
    },
    [data, currentMember, agenda]
  )

  const resetSearchOptions = useCallback(() => {
    let searchOptions = enumKeys(MovementStyle).map((s) =>
      buildStyleOption(MovementStyle[s])
    )

    if (!isNil(data)) {
      searchOptions = data.listActivityStyles.map((s) =>
        buildStyleOption(s.name)
      )
    }

    const filteredSearchOptions = searchOptions.filter(
      (s) => !isNil(s)
    ) as CardSelectOption<MovementStyle>[]

    setAllOptions(filteredSearchOptions)
    setOptionsShown(filteredSearchOptions)
  }, [data])

  const [allOptions, setAllOptions] = useState<
    CardSelectOption<MovementStyle>[]
  >([])

  const [otherOption, setOtherOption] =
    useState<CardSelectOption<MovementStyle>>()

  const [optionsShown, setOptionsShown] = useState<
    CardSelectOption<MovementStyle>[]
  >([])

  const [searchQuery, setSearchQuery] = React.useState("")
  const [fuzzySearch, setFuzzySearch] =
    React.useState<FuzzySearch<CardSelectOption<MovementStyle>>>()

  const handleFilterBy = useCallback(
    (option: CardSelectOption<MovementStyle | MovementStyle[]>) => {
      let result = true

      if (!isNil(filterBy)) {
        result = result && filterBy(option)
      }

      return result
    },
    [filterBy]
  )

  const handleGroupBy = (
    option: CardSelectOption<MovementStyle | MovementStyle[]>
  ) => {
    if (isNil(option.extra.modality)) {
      return "default"
    }

    return option.extra.modality
  }

  useEffect(() => {
    if (!fuzzySearch) return

    if (searchQuery && searchQuery.length > 0) {
      const results = fuzzySearch.search(searchQuery)

      if (otherOption) results.push(otherOption)

      setOptionsShown(uniq(results))
    } else {
      setOptionsShown(allOptions)
    }
  }, [searchQuery])

  useEffect(() => {
    const search = new FuzzySearch(allOptions, ["label", "information"], {
      caseSensitive: false,
      sort: true,
    })

    setSearchQuery("")
    setFuzzySearch(search)
  }, [allOptions])

  useEffect(() => {
    if (loading) return

    resetSearchOptions()
  }, [loading])

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col items-center justify-center w-full gap-y-2">
        <div className="relative z-50 w-full p-4 rounded-md">
          <div className="absolute inset-y-0 flex items-center pl-4 pointer-events-none left-2">
            <IoSearchOutline
              className="w-6 h-6 text-primary"
              aria-hidden="true"
            />
          </div>
          <IonInput
            type="text"
            style={{
              "--padding-start": "2.5rem",
            }}
            className="z-0 w-full p-2 pl-8 rounded-md shadow-inner text-neutral-600 bg-neutral-300/40 outline-primary"
            onIonChange={(e) => setSearchQuery(e.detail.value || "")}
          />
        </div>
      </div>
      <div className="flex-grow w-full overflow-y-auto">
        <CardSelect<MovementStyle | MovementStyle[]>
          options={optionsShown}
          onSelect={setState}
          selected={state}
          filterBy={handleFilterBy}
          groupBy={handleGroupBy}
          groupLabels={modalityLabels}
          {...props}
        />
      </div>
    </div>
  )
}

export const MovementStyleSelectModal = ({
  ...props
}: Partial<GridFormFieldModalProps<MovementStyle>>) => {
  return (
    <GridFormFieldModal
      name={ModalOrchestrationName.FormFieldMovementStyleSelect}
      Body={MovementStyleSelect}
      {...props}
    />
  )
}

export interface MovementStyleGridFormFieldProps
  extends Partial<GridFormFieldProps<MovementStyle>> {
  name?: string
  filterBy?: (option: CardSelectOption<MovementStyle>) => boolean
  multi?: boolean
  recommend?: "popular" | "favorite" | "none"
}

export const MovementStyleGridFormField: React.FC<
  MovementStyleGridFormFieldProps
> = ({ name = "movementStyle", filterBy, multi, recommend, ...props }) => {
  const { t, i18n } = useTranslation(NAME_SPACES.MOVEMENT)

  const { STYLE } = t(MOVEMENT.FORM, {
    returnObjects: true,
  })

  const state = useWatch({ name })
  const movementStyle = multi ? state?.[0] : state
  const { data } = useQuery<GetStyleQuery>(GetStyleDocument, {
    skip: isNil(movementStyle),
    variables: { style: movementStyle },
  })

  const movementModality = data?.activityStyle.modality

  const Icon = getIcon({ movementStyle, movementModality })

  const Body = (props: any) => (
    <MovementStyleSelect
      multi={multi}
      filterBy={filterBy}
      recommend={recommend}
      recommendedLabel={i18n.t("COMMON:FAVORITES")}
      {...props}
    />
  )

  const formatValue = (value?: MovementStyle) => {
    if (isNil(value)) return STYLE.PLACEHOLDER

    return formatStyle(value)
  }

  return (
    <PingContainer
      color={props.pingColor}
      showPing={isNil(state) || state.length === 0}
      className={clsx("top-1 right-1 w-full", props.pingClassName)}
      containerClassName={clsx("w-full p-0", props.hidden && "hidden")}
    >
      <GridFormField<MovementStyle | undefined>
        name={name}
        modalName={ModalOrchestrationName.FormFieldMovementStyleSelect}
        label={STYLE.LABEL}
        title={STYLE.TITLE}
        Body={Body}
        Icon={Icon}
        formatValue={formatValue}
        showDismiss
        background={multi ? undefined : movementModality}
        {...props}
      />
    </PingContainer>
  )
}

export default MovementStyleSelect
