import { IconType } from "react-icons/lib"

export const GoogleIcon: IconType = ({ ...props }) => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
      <path
        fill="#4285F4"
        d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
      />
      <path
        fill="#34A853"
        d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
      />
      <path
        fill="#FBBC05"
        d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
      />
      <path
        fill="#EA4335"
        d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
      />
    </g>
  </svg>
)

export const GoogleCalendarIcon: IconType = ({ ...props }) => (
  <svg
    height="36"
    viewBox="0 0 36 36"
    width="36"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="m0 0h36v36h-36z" />
      <g fillRule="nonzero" transform="translate(3.75 3.75)">
        <path
          d="m21.75 6.75-6.75-.75-8.25.75-.75 7.5.75 7.5 7.5.9375 7.5-.9375.75-7.6875z"
          fill="#fff"
        />
        <path
          d="m9.826875 18.38625c-.560625-.37875-.94875-.931875-1.160625-1.663125l1.30125-.53625c.118125.45.324375.79875.61875 1.04625.2925.2475.64875.369375 1.065.369375.425625 0 .79125-.129375 1.096875-.388125s.459375-.58875.459375-.988125c0-.40875-.16125-.7425-.48375-1.00125s-.7275-.388125-1.21125-.388125h-.751875v-1.288125h.675c.41625 0 .766875-.1125 1.051875-.3375s.4275-.5325.4275-.924375c0-.34875-.1275-.62625-.3825-.834375s-.5775-.313125-.969375-.313125c-.3825 0-.68625.10125-.91125.305625s-.388125.455625-.49125.751875l-1.288125-.53625c.170625-.48375.48375-.91125.943125-1.280625s1.04625-.555 1.75875-.555c.526875 0 1.00125.10125 1.42125.305625s.75.4875.988125.8475c.238125.361875.35625.766875.35625 1.216875 0 .459375-.110625.8475-.331875 1.16625s-.493125.5625-.815625.733125v.076875c.425625.178125.7725.45 1.04625.815625.271875.365625.40875.8025.40875 1.3125s-.129375.965625-.388125 1.365-.616875.714375-1.070625.943125c-.455625.22875-.9675.3450138-1.535625.3450138-.658125.0018612-1.265625-.1875138-1.82625-.5662638z"
          fill="#1a73e8"
        />
        <path
          d="m17.8125 11.92875-1.42125 1.033125-.714375-1.08375 2.563125-1.84875h.9825v8.720625h-1.41z"
          fill="#1a73e8"
        />
        <path
          d="m21.75 28.5 6.75-6.75-3.375-1.5-3.375 1.5-1.5 3.375z"
          fill="#ea4335"
        />
        <path d="m5.25 25.125 1.5 3.375h15v-6.75h-15z" fill="#34a853" />
        <path
          d="m2.25 0c-1.243125 0-2.25 1.006875-2.25 2.25v19.5l3.375 1.5 3.375-1.5v-15h15l1.5-3.375-1.5-3.375z"
          fill="#4285f4"
        />
        <path
          d="m0 21.75v4.5c0 1.243125 1.006875 2.25 2.25 2.25h4.5v-6.75z"
          fill="#188038"
        />
        <path d="m21.75 6.75v15h6.75v-15l-3.375-1.5z" fill="#fbbc04" />
        <path
          d="m28.5 6.75v-4.5c0-1.243125-1.006875-2.25-2.25-2.25h-4.5v6.75z"
          fill="#1967d2"
        />
      </g>
    </g>
  </svg>
)
