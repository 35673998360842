import { IonRouterLink } from "@ionic/react"

import { BehaleIcon } from "../../components/BehaleIcon"
import { buildPravoURL } from "../../utils/pravo"
import Content, { ContentProps } from "../Core/Content"
import Page from "../Core/Page"

export interface BaseAuthPageLinkProps
  extends React.ComponentProps<typeof IonRouterLink> {
  label: string
}

export interface BaseAuthPageProps extends ContentProps {
  title?: string
  showBackButton?: boolean
  links?: BaseAuthPageLinkProps[]
}

const BaseAuthPage: React.FC<BaseAuthPageProps> = ({
  children,
  title,
  showBackButton = false,
  links,
  ...props
}) => {
  return (
    <Page showBackButton={showBackButton} backButtonRoot="/auth/home">
      <Content scrollY className="bg-primary-500" {...props}>
        <div className="flex flex-col h-full overflow-hidden text-white">
          <main className="flex flex-col items-center flex-grow overflow-hidden justify-evenly md:justify-center bg-primary-500 md:py-8">
            <div className="flex flex-col">
              <a
                className="flex flex-col w-full text-white sm:mx-auto md:max-w-md gap-y-2"
                href={buildPravoURL()}
              >
                <div className="flex flex-row items-center justify-center w-full h-20 gap-x-4">
                  <BehaleIcon
                    width={48}
                    height={48}
                    variant="yang"
                    className="w-12 h-12 fill-white"
                  />
                  <span className="text-4xl font-medium tracking-tight align-bottom md:text-5xl">
                    behale
                  </span>
                </div>
              </a>
              <span className="text-3xl text-center">{title}</span>
            </div>

            <div className="w-full sm:px-0 sm:mx-auto sm:max-w-md">
              <div className="flex flex-col px-4 py-4 text-white md:py-8 sm:px-10 gap-y-4">
                {children}
              </div>
            </div>
            {links && (
              <div className="flex flex-col items-center justify-center my-4 gap-y-4">
                {links.map((link) => (
                  <IonRouterLink
                    key={link.label}
                    color="white"
                    className="underline"
                    {...link}
                  >
                    {link.label}
                  </IonRouterLink>
                ))}
              </div>
            )}
          </main>
        </div>
      </Content>
    </Page>
  )
}

export default BaseAuthPage
