import { useIonRouter } from "@ionic/react"
import PostSessionFeedbackModal from "../components/Movement/Modals/PostSessionFeedbackModal"
import { useAuthenticatedClientContext } from "../contexts/AuthenticatedClientContext"
import usePersistedFlag from "../hooks/usePersistedFlag"
import { ConnectCalendarModal } from "../components/Profile/ConnectCalendarModal"
import { useAgendaSchedulingContext } from "../contexts/AgendaSchedulingContext"

export const FeedbackListener = () => {
  const router = useIonRouter()

  const { client } = useAuthenticatedClientContext()
  const { agenda } = useAgendaSchedulingContext()

  const { isDirty: hasShownCalendarConnectionModal } = usePersistedFlag(
    "hasShownCalendarConnectionModal"
  )

  const isInWelcome = router.routeInfo.pathname.includes("welcome")

  const handleClose = () => {
    client.refetchQueries({ include: "active" })

    if (isInWelcome) {
      return
    } else {
      router.push("/app/hub/home", "forward", "replace")
    }
  }

  const shouldShowCalendarConnectionModal =
    !hasShownCalendarConnectionModal && !agenda?.schedulingCalendarConnected

  return (
    <>
      <PostSessionFeedbackModal onClose={handleClose} />
      <ConnectCalendarModal />
      {shouldShowCalendarConnectionModal && <ConnectCalendarModal />}
    </>
  )
}
