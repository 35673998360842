import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ModalOrchestrationName } from "../../../../contexts/ModalOrchestrationContext"

import {
  BodyMuscleEnum,
  BodyMuscleGroupEnum,
  BodyPartEnum,
  BodyRegionEnum,
} from "../../../../generated/graphql"
import { MOVEMENT, NAME_SPACES } from "../../../../locales/constants"
import { formatBodyPart, formatBodyParts } from "../../../../utils/format"
import CardSelect, { CardSelectProps } from "../../../Forms/CardSelect"
import {
  GridFormField,
  GridFormFieldProps,
  RequiredFieldModalBodyProps,
} from "../../../Forms/GridFormField"
import Toggle from "../../../Forms/Toggle"
import { TargetBodyPartIcon } from "../../../Core/Icons"
import useActivityStyle from "../../../../hooks/useActivityStyle"
import { BodyPart } from "../../../../utils/types"
import { useCallback, useEffect, useState } from "react"
import { isNil, uniq } from "lodash"

export type MultiBodyPartSelectState = BodyPart[]

export type MultiBodyPartSelectProps =
  CardSelectProps<MultiBodyPartSelectState> &
    RequiredFieldModalBodyProps<MultiBodyPartSelectState>

const bodyRegions = [
  BodyRegionEnum.UpperBody,
  BodyRegionEnum.Core,
  BodyRegionEnum.LowerBody,
]
const bodyRegionOptions = bodyRegions.map((region) => ({
  label: formatBodyPart(region),
  value: region,
}))

const upperBodyMuscles = [
  BodyMuscleGroupEnum.ShoulderMuscles,
  BodyMuscleGroupEnum.ArmMuscles,
  BodyMuscleGroupEnum.BackMuscles,
]
const upperBodyMuscleOptions = upperBodyMuscles.map((muscle) => ({
  label: formatBodyPart(muscle),
  value: muscle,
}))

const coreMuscles = [
  BodyMuscleEnum.Abdominals,
  BodyMuscleEnum.Obliques,
  BodyMuscleEnum.LowerBack,
]
const coreMuscleOptions = coreMuscles.map((muscle) => ({
  label: formatBodyPart(muscle),
  value: muscle,
}))

const lowerBodyMuscles = [
  BodyMuscleGroupEnum.HipMuscles,
  BodyMuscleEnum.Glutes,
  BodyMuscleEnum.Hamstrings,
  BodyMuscleEnum.Quadriceps,
  BodyMuscleEnum.Calves,
]
const lowerBodyMuscleOptions = lowerBodyMuscles.map((muscle) => ({
  label: formatBodyPart(muscle),
  value: muscle,
}))

const getSubBodyParts = (regionName: BodyRegionEnum) => {
  switch (regionName) {
    case BodyRegionEnum.UpperBody:
      return upperBodyMuscleOptions
    case BodyRegionEnum.Core:
      return coreMuscleOptions
    case BodyRegionEnum.LowerBody:
      return lowerBodyMuscleOptions
  }
}

const getMuscleRegion = (muscle: BodyMuscleEnum | BodyMuscleGroupEnum) => {
  switch (muscle) {
    case BodyMuscleGroupEnum.ShoulderMuscles:
    case BodyMuscleGroupEnum.ArmMuscles:
    case BodyMuscleGroupEnum.BackMuscles:
      return BodyRegionEnum.UpperBody
    case BodyMuscleEnum.Abdominals:
    case BodyMuscleEnum.Obliques:
    case BodyMuscleEnum.LowerBack:
      return BodyRegionEnum.Core
    case BodyMuscleGroupEnum.HipMuscles:
    case BodyMuscleEnum.Glutes:
    case BodyMuscleEnum.Hamstrings:
    case BodyMuscleEnum.Quadriceps:
    case BodyMuscleEnum.Calves:
      return BodyRegionEnum.LowerBody
  }
}

const MultiBodyPartSelect: React.FC<MultiBodyPartSelectProps> = ({
  state,
  setState,
}) => {
  const [parts, setParts] = useState<BodyPart[] | undefined>()
  const [isFullBody, setIsFullBody] = useState(false)
  const [selectedRegions, setSelectedRegions] = useState<BodyRegionEnum[]>([])
  const [selectedMuscles, setSelectedMuscles] = useState<
    (BodyMuscleEnum | BodyMuscleGroupEnum)[]
  >([])

  const toggleFullBody = useCallback(() => {
    if (isFullBody) {
      return false
    }

    setIsFullBody((prev) => !prev)
  }, [isFullBody])

  const handleSelectRegion = (region: BodyRegionEnum) => {
    if (selectedRegions.includes(region)) {
      setSelectedRegions((prev) => prev.filter((r) => r !== region))
      setParts((prev) => prev?.filter((part) => part !== region))
    } else {
      setSelectedRegions((prev) => uniq([...prev, region]))
      setParts((prev) => uniq([...(prev || []), region]))
    }
  }

  useEffect(() => {
    if (selectedMuscles.length === 0) {
      return
    }

    const muscleRegions = uniq(
      selectedMuscles.map((muscle) => getMuscleRegion(muscle))
    ) as BodyRegionEnum[]

    const leafRegions = selectedRegions.filter(
      (region) => !muscleRegions.includes(region)
    )

    setParts(uniq([...leafRegions, ...selectedMuscles]) as BodyPart[])
  }, [selectedMuscles])

  useEffect(() => {
    if (isNil(parts)) {
      return
    }

    setIsFullBody(selectedRegions.length === 0)
  }, [selectedRegions])

  useEffect(() => {
    if (isNil(parts)) {
      return
    }

    if (isFullBody) {
      setParts([BodyPartEnum.FullBody])
      setSelectedRegions([])
      setSelectedMuscles([])
    } else if (parts.includes(BodyPartEnum.FullBody)) {
      setParts((prev) => prev?.filter((part) => part !== BodyPartEnum.FullBody))
    }
  }, [isFullBody])

  useEffect(() => {
    if (state === parts || isNil(parts)) {
      return
    }
    console.debug("setting state", parts)
    setState(parts)
  }, [parts])

  useEffect(() => {
    if (isNil(parts)) {
      setParts(state)

      setIsFullBody(state.includes(BodyPartEnum.FullBody))

      const muscles = state.filter(
        (part) =>
          upperBodyMuscles.includes(part as any) ||
          coreMuscles.includes(part as any) ||
          lowerBodyMuscles.includes(part as any)
      ) as (BodyMuscleEnum | BodyMuscleGroupEnum)[]

      const muscleRegions = uniq(
        muscles.map((muscle) => getMuscleRegion(muscle))
      ) as BodyRegionEnum[]

      const regions = state.filter((part) =>
        bodyRegions.includes(part as any)
      ) as BodyRegionEnum[]

      setSelectedRegions(
        uniq([...regions, ...muscleRegions]) as BodyRegionEnum[]
      )
      setSelectedMuscles(muscles)
    }
  }, [parts, state])

  return (
    <div className="flex flex-col w-full h-full overflow-scroll">
      <Toggle
        label={formatBodyPart(BodyPartEnum.FullBody)}
        className={"px-4 pt-4"}
        onToggle={toggleFullBody}
        isEnabled={isFullBody}
      />
      {bodyRegionOptions.map(({ label, value }, index) => (
        <div key={index}>
          <Toggle
            label={label}
            className={"px-4 pt-4"}
            onToggle={() => handleSelectRegion(value)}
            isEnabled={selectedRegions.includes(value)}
          />
          {selectedRegions.includes(value) && (
            <CardSelect<BodyPart>
              multi
              selected={selectedMuscles}
              onSelect={setSelectedMuscles}
              options={getSubBodyParts(value)}
            />
          )}
        </div>
      ))}
    </div>
  )
}

const formatBodyPartSelectState = (state?: MultiBodyPartSelectState) => {
  return formatBodyParts(state)
}

export interface MultiBodyPartSelectGridFormFieldProps
  extends Omit<
    GridFormFieldProps<MultiBodyPartSelectState>,
    "Body" | "label" | "formatValue" | "modalName"
  > {
  name: string
  multi?: boolean
  alwaysShow?: boolean
}

export const MultiBodyPartSelectGridFormField: React.FC<
  MultiBodyPartSelectGridFormFieldProps
> = ({ name, multi, alwaysShow = false, ...props }) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)

  const { BODY_PART } = t(MOVEMENT.FORM, { returnObjects: true })

  const movementStyle = useWatch({ name: "movementStyle" })
  const { setValue } = useFormContext()
  const movementTargetBodyParts = useWatch({ name: "movementTargetBodyParts" })

  const activityStyle = useActivityStyle(movementStyle)
  const isAvailable = activityStyle?.isTargetBodyPartAvailable

  const Body = (props: any) => <MultiBodyPartSelect multi={multi} {...props} />

  useEffect(() => {
    if (isAvailable) {
      if (
        isNil(movementTargetBodyParts) ||
        movementTargetBodyParts.length === 0
      ) {
        setValue(name, [BodyPartEnum.FullBody], { shouldValidate: true })
      }
    } else {
      setValue(name, [], { shouldValidate: true })
    }
  }, [isAvailable])

  return (
    <GridFormField<MultiBodyPartSelectState>
      name={name}
      modalName={ModalOrchestrationName.FormFieldBodyPartSelect}
      label={BODY_PART.LABEL}
      title={BODY_PART.TITLE}
      Body={Body}
      Icon={TargetBodyPartIcon}
      formatValue={formatBodyPartSelectState}
      showDismiss
      showClearButton
      hidden={!isAvailable && !alwaysShow}
      {...props}
    />
  )
}

export default MultiBodyPartSelect
