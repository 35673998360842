import { IonInput } from "@ionic/react"
import clsx from "clsx"
import isNil from "lodash/isNil"
import * as React from "react"
import { useEffect, useState } from "react"
import { useFormContext, useFormState, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { RiEditLine } from "react-icons/ri"
import { MovementStyle } from "../../../../generated/graphql"

import { MOVEMENT, NAME_SPACES } from "../../../../locales/constants"
import { formatStyle } from "../../../../utils/format"

interface MovementTitleInputProps {
  name: string
  state: string
  setState: (state: string) => void
  disabled?: boolean
  className?: string
}

export const MovementTitleInput = ({
  name,
  state,
  setState,
  disabled = false,
  className,
}: MovementTitleInputProps) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const { TITLE } = t(MOVEMENT.FORM, { returnObjects: true })

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)

    return () => {
      setMounted(false)
    }
  }, [])

  return (
    <div className="relative rounded-md">
      <IonInput
        name={name}
        disabled={disabled}
        type="text"
        className={clsx(
          "z-0 w-full p-2 pr-10 font-semibold bg-transparent text-neutral-600",
          className
        )}
        autoCapitalize="on"
        autoCorrect="on"
        value={state}
        style={{
          "--padding-start": "0.5rem",
          "--padding-end": "2.25rem",
        }}
        onIonChange={(e) => {
          if (e.detail.value && mounted) {
            setState(e.detail.value || "")
          }
        }}
        placeholder={TITLE.PLACEHOLDER}
      />
      {!disabled && (
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <RiEditLine
            className="w-6 h-6 sm:w-8 sm:h-8 text-primary"
            aria-hidden="true"
          />
        </div>
      )}
    </div>
  )
}

export const AgendaItemTitleInput: React.FC<{
  name: string
  disabled?: boolean
  className?: string
}> = ({ name, disabled, className }) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)

  const STYLES = t(MOVEMENT.STYLE, { returnObjects: true })

  const { register, getValues, setValue } = useFormContext()

  const formState = useFormState({ name: ["title", "movementStyle"] })
  const movementStyle: MovementStyle = useWatch({ name: "movementStyle" })

  register(name)

  const [title, setTitle] = useState<string>("")

  useEffect(() => {
    if (!formState.dirtyFields.movementStyle) return

    if (isNil(title) || title.length === 0) {
      setTitle(formatStyle(movementStyle))
    } else if (
      Object.values(STYLES).includes(title) &&
      title !== STYLES[movementStyle]
    ) {
      setTitle(formatStyle(movementStyle))
    }
  }, [formState.dirtyFields.movementStyle, movementStyle, title])

  useEffect(() => {
    setValue(name, title)
  }, [title])

  useEffect(() => {
    setTitle(getValues(name) || "")
  }, [])

  return (
    <MovementTitleInput
      name={name}
      state={title}
      disabled={disabled}
      setState={setTitle}
      className={className}
    />
  )
}

export const SessionTitleInput: React.FC<{
  name: string
  className?: string
}> = ({ name, className }) => {
  const { register, getValues, setValue } = useFormContext()

  const formState = useFormState({ name: ["title", "movementStyle"] })
  const movementStyle = useWatch({ name: "movementStyle" })

  register(name)

  const [title, setTitle] = useState<string>("")

  useEffect(() => {
    if (
      formState.dirtyFields.movementStyle &&
      (isNil(title) || title.length === 0)
    ) {
      setTitle(formatStyle(movementStyle))
    }
  }, [formState.dirtyFields.movementStyle, movementStyle, title])

  useEffect(() => {
    setValue(name, title)
  }, [title])

  useEffect(() => {
    setTitle(getValues(name) || "")
  }, [])

  return (
    <MovementTitleInput
      name={name}
      state={title}
      setState={setTitle}
      className={className}
    />
  )
}
