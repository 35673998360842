import { useTranslation } from "react-i18next"

import Page from "../../components/Core/Page"
import { MICO, NAME_SPACES } from "../../locales/constants"
import MicoVideoGalleryTab from "../../components/Mico/MicoVideoGalleryTab"

const MicoHomePage: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.MICO)
  const TEXT = t(MICO.HOME, { returnObjects: true })

  // const query = useRouterQueryParams()

  // const micoTabs = [
  //   {
  //     name: "ADVENTURES",
  //     label: TEXT.TABS.ADVENTURES,
  //     icon: IoCompassOutline,
  //   },
  //   {
  //     name: "MOVES",
  //     label: TEXT.TABS.MOVES,
  //     icon: SportIcon,
  //     hidden: true,
  //   },
  //   {
  //     name: "VIDEOS",
  //     label: TEXT.TABS.VIDEOS,
  //     icon: IoLibrary,
  //   },
  // ] as TabBarElement[]

  return (
    <Page className="bg-neutral-100">
      <Page.Header title={TEXT.TITLE} color="primary">
        {/* <TabBar
          tabs={micoTabs}
          activeTab={activeTab}
          onTabChange={handleTabChange}
          className="overflow-x-scroll overflow-y-hidden shadow-lg"
        /> */}
      </Page.Header>

      {/* {activeTab === "ADVENTURES" && <MicoAdventureGalleryTab />} */}
      {/* {activeTab === "MOVES" && <MicoMovesGalleryTab />} */}
      <MicoVideoGalleryTab />
    </Page>
  )
}

export default MicoHomePage
