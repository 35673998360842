import { useIonRouter } from "@ionic/react"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import Content from "../../components/Core/Content"
import Page from "../../components/Core/Page"
import Button from "../../components/Forms/Button"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { NAME_SPACES, WELCOME } from "../../locales/constants"
import { useCallback, useMemo } from "react"
import MovementSessionFormModal from "../../components/Movement/Modals/MovementSessionFormModal"
import { RiCheckDoubleLine } from "react-icons/ri"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import { BsEmojiSunglasses } from "react-icons/bs"
import StorySetIcon, { StorySetIconType } from "../../components/StorySetIcon"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import { isSameDay } from "date-fns"

const LatestActivityPage: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.WELCOME)
  const TEXT = t(WELCOME.ACTIVITIES, { returnObjects: true })

  const router = useIonRouter()

  const isInWelcome = router.routeInfo.pathname.includes("welcome")

  const nextPath = useMemo(() => {
    return "/app/welcome/elements"
  }, [isInWelcome])

  const { captureEvent } = useAnalyticsContext()

  const { today } = useAgendaSchedulingContext()
  const { openModal, closeModal } = useModalOrchestrationContext()

  const handleContinue = () => {
    router.push(nextPath, "forward")
  }

  const handleLogSession = () => {
    captureEvent(AnalyticsEvent.WelcomeLatestActivityLogClicked)

    openModal(ModalOrchestrationName.MovementSessionForm, {
      onClose: handleSessionModalClosed,
      showFeedbackModal: false,
    })
  }

  const handleSkip = () => {
    captureEvent(AnalyticsEvent.WelcomeLatestActivitySkipped)
  }

  const handleSessionModalClosed = useCallback(
    (reason?: string, data?: any) => {
      if (reason === "logged") {
        captureEvent(AnalyticsEvent.WelcomeLatestActivityLogged, {
          style: data.movementStyle,
          isToday: isSameDay(data.reportedDate, today.date),
        })

        setTimeout(() => handleContinue(), 500)
      }
    },
    [captureEvent, closeModal]
  )

  return (
    <Page className="bg-primary" showBackButton backButtonRoot="/app/welcome">
      <Content
        color="primary"
        scrollY={false}
        className="relative flex flex-col items-center justify-center h-full"
      >
        <div
          className={clsx(
            "flex flex-col items-center justify-start max-w-3xl h-full mx-auto gap-y-4 py-4"
          )}
        >
          <div className="flex flex-col w-full px-4">
            <div className="flex flex-row items-center justify-start w-full my-4 gap-x-2">
              <span className="w-full text-3xl font-semibold text-center">
                {TEXT.LATEST.TITLE}
              </span>
            </div>
          </div>

          <div className=""></div>

          <div className="flex flex-col items-center justify-center flex-grow w-full max-w-xl p-4 mx-auto aspect-w-4 aspect-h-3">
            <StorySetIcon type={StorySetIconType.Stretch} />
          </div>

          <div className="flex flex-col items-center justify-end flex-shrink-0 w-full max-w-xl px-4 mx-auto pb-safe gap-y-2">
            <Button
              fill="outline"
              className="w-full opacity-75"
              expand="block"
              color="white"
              size="large"
              onClick={handleSkip}
              icon={BsEmojiSunglasses}
              iconSlot="start"
              routerLink={nextPath}
              routerDirection="forward"
              label={TEXT.LATEST.CTA.SKIP}
            />

            <Button
              fill="solid"
              className="w-full"
              expand="block"
              textColor="navy"
              color="white"
              size="large"
              icon={RiCheckDoubleLine}
              iconSlot="start"
              onClick={handleLogSession}
              label={TEXT.LATEST.CTA.LOG}
            />
          </div>
        </div>

        <MovementSessionFormModal onClose={handleSessionModalClosed} />
      </Content>
    </Page>
  )
}

export default LatestActivityPage
