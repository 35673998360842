import { Device } from "@capacitor/device"
import { useEffect, useState } from "react"
import { DevicePlatform } from "../generated/graphql"

export { DevicePlatform } from "../generated/graphql"

export const getDevicePlatform = async () => {
  const { platform } = await Device.getInfo()

  switch (platform) {
    case "ios":
      return DevicePlatform.Ios
    case "android":
      return DevicePlatform.Android
    default:
    case "web":
      return DevicePlatform.Web
  }
}

const useDevicePlatform = () => {
  const [platform, setPlatform] = useState<DevicePlatform | undefined>(
    undefined
  )

  useEffect(() => {
    getDevicePlatform().then(setPlatform)
  }, [])

  return platform
}

export default useDevicePlatform
