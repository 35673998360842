import { Capacitor } from "@capacitor/core"
import { setUser } from "@sentry/capacitor"
import isNil from "lodash/isNil"
import posthog, { PostHog } from "posthog-js"
import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react"
import { getCurrentEnvironment } from "../utils"

import { useAuthenticatedClientContext } from "./AuthenticatedClientContext"

export enum AnalyticsEvent {
  AppOpened = "AppOpened",
  AppUrlOpened = "AppUrlOpened",
  AppBackgrounded = "AppBackgrounded",
  AllLocalNotificationsEnabled = "AllNotificationsEnabled",
  AllLocalNotificationsDisabled = "AllNotificationsDisabled",
  LocalNotificationReceived = "LocalNotificationReceived",
  SessionAbandoned = "SessionAbandoned",
  SessionCompleted = "SessionCompleted",
  SessionStarted = "SessionStarted",
  DailyMovePrimaryActionClicked = "DailyMoveCTAClicked",
  UserLoggedIn = "UserLoggedIn",
  ChatOpened = "ChatWidgetOpened",
  ChatMessageSent = "ChatMessageSent",
  ChatClosed = "ChatClosed",
  UserOnboardingCompleted = "UserOnboardingCompleted",
  AgendaItemSessionLogged = "AgendaItemSessionLogged",
  LocalNotificationEnabled = "NotificationEnabled",
  LocalNotificationDisabled = "NotificationDisabled",
  LocalNotificationActionPerformed = "LocalNotificationActionPerformed",
  NotificationPermissionGranted = "NotificationPermissionGranted",
  NotificationPermissionRejected = "NotificationPermissionRejected",
  PastSessionActionsClicked = "PastSessionActionsClicked",
  PastSessionDeleted = "PastSessionDeleted",
  PastSessionReportedDataEdited = "PastSessionReportedDataEdited",
  PastSessionDescriptionEdited = "PastSessionDescriptionEdited",
  AgendaAddElementButtonClicked = "AgendaAddElementButtonClicked",
  GridFormFieldClicked = "GridFormFieldClicked",
  SessionDetailBlockClicked = "SessionDetailBlockClicked",
  AgendaItemMovedToAnotherDay = "AgendaItemMovedToAnotherDay",
  AgendaItemReorderedInDay = "AgendaItemReorderedInDay",
  AgendaItemAddedToRestDay = "AgendaItemAddedToRestDay",
  MovementSessionFormModalOpened = "MovementSessionFormModalOpened",
  MovementAgendaItemFormModalOpened = "MovementAgendaItemFormModalOpened",
  MicoVideoExplorerFilterModalOpened = "MicoVideoExplorerFilterModalOpened",
  MicoExplorerFiltersOpened = "MicoExplorerFiltersOpened",
  SessionSkipped = "SessionSkipped",
  PushNotificationReceived = "PushNotificationReceived",
  PushNotificationRegistered = "PushNotificationRegistered",
  PushNotificationRegistrationError = "PushNotificationRegistrationError",
  PushNotificationActionPerformed = "PushNotificationActionPerformed",
  UserInitialPasswordSet = "UserInitialPasswordSet",
  AgendaSuggestionAccepted = "AgendaSuggestionAccepted",
  AgendaSuggestionRejected = "AgendaSuggestionRejected",
  MovementAgendaItemAdded = "MovementAgendaItemAdded",
  MovementAgendaItemModified = "MovementAgendaItemModified",
  ChatMessageReceived = "ChatMessageReceived",
  MicoVideoFeedbackSubmitted = "MicoVideoFeedbackSubmitted",
  MicoVideoFeedbackModalOpened = "MicoVideoFeedbackModalOpened",
  SessionVideoShuffled = "SessionVideoShuffled",
  MicoVideoPlayed = "MicoVideoPlayed",
  UserRegistered = "UserRegistered",
  AdHocMovementSessionLogged = "AdHocMovementSessionLogged",
  RecurringAgendaItemSkipped = "RecurringAgendaItemSkipped",
  AgendaItemDeleted = "AgendaItemDeleted",
  PlanFirstAgendaItemSignpostClosed = "PlanFirstAgendaItemSignpostClosed",
  DownloadAppLinkClicked = "DownloadAppLinkClicked",
  ContinueWithGoogleCancelled = "ContinueWithGoogleCancelled",
  ContinueWithGoogleClicked = "ContinueWithGoogleClicked",
  UserIdentifiedWithProvider = "UserIdentifiedWithProvider",
  UserConnectedExternalCalendar = "UserConnectedExternalCalendar",
  UserSkippedExternalCalendarConnection = "UserSkippedExternalCalendarConnection",
  ContinueWithAppleClicked = "ContinueWithAppleClicked",
  ContinueWithAppleCancelled = "ContinueWithAppleCancelled",
  ContinueWithGoogleAccessDenied = "ContinueWithGoogleAccessDenied",
  InAppPurchaseInitiated = "InAppPurchaseInitiated",
  InAppPurchaseFeatureToggled = "InAppPurchaseFeatureToggled",
  InAppPurchaseModalOpened = "InAppPurchaseModalOpened",
  InAppPurchaseCompleted = "InAppPurchaseCompleted",
  ElementCardClicked = "ElementCardClicked",
  WelcomeElementSkipped = "WelcomeElementSkipped",
  EmptyAdventureCardClicked = "EmptyAdventureCardClicked",
  CurrentAdventureCardClicked = "CurrentAdventureCardClicked",
  ShareButtonClicked = "ShareButtonClicked",
  AdventurePreviewCardClicked = "AdventurePreviewCardClicked",
  AdventureTemplateUnlockClicked = "AdventureTemplateUnlockClicked",
  AdventureTemplateChooseClicked = "AdventureOnboardingOpened",
  AgendaHomeTodayButtonClicked = "AgendaHomeTodayButtonClicked",
  InAppPurchaseModalClosed = "InAppPurchaseModalClosed",
  AgendaHomeTimelineIndexSwiped = "AgendaHomeTimelineIndexSwiped",
  AgendaHomeTimelineIndexWeekdayClicked = "AgendaHomeTimelineIndexWeekdayClicked",
  InAppPurchaseCancelled = "InAppPurchaseCancelled",
  AgendaSuggestionRescheduled = "AgendaSuggestionRescheduled",
  PostSessionFeedbackModalContinueClicked = "PostSessionFeedbackModalContinueClicked",
  AgendaItemFollowAlongPreviewClicked = "AgendaItemFollowAlongPreviewClicked",
  SuggestedExternalLinkClicked = "AgendaItemExternalLinkClicked",
  PostSessionSatisfactionOptionClicked = "PostSessionSatisfactionOptionClicked",
  AgendaItemSessionStarted = "AgendaItemSessionStarted",
  HubFABClicked = "HubFABClicked",
  CurrentAdventureResumed = "CurrentAdventureResumed",
  CurrentAdventurePaused = "CurrentAdventurePaused",
  CurrentAdventureActivityCardClick = "CurrentAdventureActivityCardClick",
  CurrentAdventureNextAdventureClicked = "CurrentAdventureNextAdventureClicked",
  AdventurePhaseLockedConversionHookCTAClicked = "AdventurePhaseLockedConversionHookCTAClicked",
  VideoExplorerModalVideoSelected = "VideoExplorerModalVideoSelected",
  VideoThumbnailButtonClicked = "VideoThumbnailButtonClicked",
  MicoResourcePreviewModalStartSessionClicked = "MicoResourcePreviewModalStartSessionClicked",
  MicoResourcePreviewModalScheduleSessionClicked = "MicoResourcePreviewModalScheduleSessionClicked",
  MicoResourceCardClicked = "MicoResourceCardClicked",
  MovementWizardNextClicked = "MovementWizardNextClicked",
  MovementWizardStartClicked = "MovementWizardStartClicked",
  MovementWizardGenerateClicked = "MovementWizardGenerateClicked",
  MicoVideoPaused = "MicoVideoPaused",
  MicoVideoFinished = "MicoVideoFinished",
  WelcomeFavoriteActivitiesAddActivityClicked = "WelcomeFavoriteActivitiesAddActivityClicked",
  WelcomeFavoriteActivitiesActivitySelected = "WelcomeFavoriteActivitiesActivitySelected",
  WelcomeFavoriteActivitiesActivityCleared = "WelcomeFavoriteActivitiesActivityCleared",
  WelcomeFavoriteActivitiesActivityFrequencyIncreased = "WelcomeFavoriteActivitiesActivityFrequencyIncreased",
  WelcomeFavoriteActivitiesActivityFrequencyDecreased = "WelcomeFavoriteActivitiesActivityFrequencyDecreased",
  WelcomeFavoriteActivitiesActivityWeekdayToggled = "WelcomeFavoriteActivitiesActivityWeekdayToggled",
  ElementFilterRowElementSelected = "ElementFilterRowElementSelected",
  WelcomeFavoriteActivitiesSubmitted = "WelcomeFavoriteActivitiesSubmitted",
  TodayActivityChecked = "TodayActivityChecked",
  WelcomeFavoriteActivitiesActivityPrimaryToggled = "WelcomeFavoriteActivitiesActivityPrimaryToggled",
  RecommendedAdventureCarouselSlide = "RecommendedAdventureCarouselSlide",
  AgendaHomeSettingsButtonClicked = "AgendaHomeSettingsButtonClicked",
  DailyMoveCollectionSelected = "DailyMoveCollectionSelected",
  StaminaMeterClicked = "StaminaMeterClicked",
  DailyMoveCollectionEmptyStateClicked = "DailyMoveCollectionEmptyStateClicked",
  ProfileCardShareLinkClicked = "ProfileCardShareLinkClicked",
  ProfileCardBuddyUp = "ProfileCardBuddyUp",
  ProfileCardLinkCopied = "ProfileCardLinkCopied",
  ProfileCardBuddyCardClicked = "ProfileCardBuddyCardClicked",
  AdventureTemplatePartnershipClicked = "AdventureTemplatePartnershipClicked",
  AdventureTemplateProductClicked = "AdventureTemplateProductClicked",
  AgendaHomeInboxButtonClicked = "AgendaHomeInboxButtonClicked",
  AgendaSuggestionAddedFromInbox = "AgendaSuggestionAddedFromInbox",
  AdventureBannerClicked = "AdventureBannerClicked",
  WelcomeNextActivitySessionPlanned = "WelcomeLatestActivitySessionLogged",
  WelcomeNextActivityRecommendationStarted = "WelcomeNextActivityRecommendationStarted",
  WelcomeNextActivityRecommendationScheduled = "WelcomeNextActivityRecommendationScheduled",
  WelcomeNextActivityRecommendationAdventureChosen = "WelcomeNextActivityRecommendationAdventureChosen",
  WelcomeLatestActivityLogged = "WelcomeLatestActivityLogged",
  WelcomeLatestActivitySkipped = "WelcomeLatestActivitySkipped",
  WelcomeLatestActivityLogClicked = "WelcomeLatestActivityLogClicked",
  WelcomeFavoriteActivitiesSkipped = "WelcomeFavoriteActivitiesSkipped",
  WelcomeElementSelected = "WelcomeElementSelected",
  WelcomeNextActivitySkipped = "WelcomeNextActivitySkipped",
  WelcomeNextActivityPlanClicked = "WelcomeNextActivityPlanClicked",
  WelcomeNextActivityRecommendClicked = "WelcomeNextActivityRecommendClicked",
  TodayAddClicked = "TodayAddClicked",
  TodayActivityPressed = "CoreHabitPrimaryActionClicked",
  TodayActivityAddClicked = "TodayActivityAddClicked",
  TodayActivityStartSessionClicked = "TodayActivityStartSessionClicked",
  TodayActivityUnchecked = "TodayActivityUnchecked",
  TodayActivityPreviewEditPressed = "TodayActivityPreviewEditPressed",
  TodayActivityPreviewViewPressed = "TodayActivityPreviewViewPressed",
  TodayActivityPreviewStartSessionPressed = "TodayActivityPreviewStartSessionPressed",
  WelcomeFavoriteActivitiesActivityPhaseOfDaySelected = "WelcomeFavoriteActivitiesActivityPhaseOfDaySelected",
}

export interface AnalyticsContextState {
  isEnabled: boolean
  isIdentified: boolean
  isInitialized: boolean

  instance: PostHog | undefined
}

export interface AnalyticsContextActions {
  identify: (props: { userUuid: string; email: string }) => void

  captureEvent: (
    event: AnalyticsEvent | "$pageview" | "$autocapture",
    properties?: Record<string, any>
  ) => void

  setPersonProperties: (
    properties: Record<string, any>,
    oneTime?: boolean
  ) => void
}

export const AnalyticsContext = createContext<
  (AnalyticsContextState & AnalyticsContextActions) | undefined
>(undefined)

export const enum AnalyticsReducerActionType {
  Disable = "DISABLE",
  Enable = "ENABLE",
  Identify = "IDENTIFY",
  Initialized = "Loaded",
}

const analyticsReducer = (
  state: AnalyticsContextState,
  action: any
): AnalyticsContextState => {
  const { type, payload } = action

  switch (type) {
    case AnalyticsReducerActionType.Disable:
      return { ...state, isEnabled: false }

    case AnalyticsReducerActionType.Enable:
      return { ...state, isEnabled: true }

    case AnalyticsReducerActionType.Identify:
      return { ...state, isIdentified: true }

    case AnalyticsReducerActionType.Initialized:
      return {
        ...state,
        isInitialized: true,
        isEnabled: payload.isEnabled,
        instance: payload.instance,
      }

    default:
      return state
  }
}

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext)

  if (context === undefined) {
    throw new Error(
      "useAnalyticsContext must be used within a AnalyticsContext provider"
    )
  }

  return context
}

const initialAnalyticsState: AnalyticsContextState = {
  isInitialized: false,
  isEnabled: false,
  isIdentified: false,
  instance: undefined,
}

export const AnalyticsProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    analyticsReducer,
    initialAnalyticsState
  ) as [
    AnalyticsContextState,
    Dispatch<{ type: AnalyticsReducerActionType; payload?: any }>
  ]

  const { currentUser, currentMember, platform, isPlatformInitialized } =
    useAuthenticatedClientContext()

  const initialize = () => {
    posthog.init(process.env.REACT_APP_POSTHOG_API_KEY as string, {
      api_host: "https://eu.posthog.com",
      capture_pageview: false,
      disable_session_recording: true,
      autocapture: false,
      persistence: "localStorage+cookie",
      opt_out_capturing_by_default: getCurrentEnvironment() !== "production",
      loaded: (instance) => {
        console.debug("[Analytics] initialized", {
          isEnabled: !instance.has_opted_out_capturing(),
        })

        dispatch({
          type: AnalyticsReducerActionType.Initialized,
          payload: {
            instance,
            isEnabled: !instance.has_opted_out_capturing(),
          },
        })
      },
    })
  }

  const identify = (identity: { userUuid: string; email: string }) => {
    dispatch({ type: AnalyticsReducerActionType.Identify })

    setUser({ id: identity.userUuid, email: identity.email })

    state.instance?.identify(identity.userUuid, { email: identity.email })

    state.instance?.register({
      platform,
      source: "crush",
      version: process.env.REACT_APP_VERSION,
      environment: getCurrentEnvironment(),
    })

    console.debug("[Analytics] identified user")
  }

  const captureEvent = useCallback(
    (event: string, properties?: Record<string, any>) => {
      if (!state.isEnabled) {
        console.debug("[Analytics] disabled: captureEvent", event, properties)

        return
      }

      state.instance?.capture(event, properties)
    },
    [state.isInitialized, state.isEnabled, state.instance]
  )

  const setPersonProperties = (
    properties: Record<string, any>,
    oneTime?: boolean
  ) => {
    if (oneTime) {
      state.instance?.people.set_once(properties)
    } else {
      state.instance?.people.set(properties)
    }
  }

  useEffect(() => {
    if (!state.isEnabled || !state.isInitialized) return

    if (isNil(currentMember)) return

    setPersonProperties({
      hasCurrentAdventure: !isNil(currentMember.currentAdventureUuid),
      currentElement: currentMember.elements?.sort(),
      primarySport: currentMember.adventurePrimarySport,
    })
  }, [state.isEnabled, state.isInitialized, currentMember])

  useEffect(() => {
    if (!state.isEnabled || state.isIdentified) {
      return
    }

    if (isNil(currentUser) || !isPlatformInitialized) {
      return
    }

    console.debug("[Analytics] identify", currentUser)

    identify(currentUser)

    setPersonProperties({
      platform,
      isNativePlatform: Capacitor.isNativePlatform(),
      version: process.env.REACT_APP_VERSION,
    })

    setPersonProperties(
      {
        initialPlatform: platform,
        environment: getCurrentEnvironment(),
      },
      true
    )
  }, [state.isInitialized, currentUser?.userUuid, isPlatformInitialized])

  useEffect(() => {
    if (state.isInitialized) return

    initialize()
  }, [state.isInitialized])

  const value = {
    ...state,
    captureEvent,
    setPersonProperties,
    identify,
  }

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  )
}
