import { AUTH } from "../constants"

export default {
  INCORRECT_CREDENTIALS: "An account with these credentials does not exist",
  AN_ERROR_HAS_OCCURED: "An error has occured",
  EMAIL_LABEL: "Email",
  PASSWORD_LABEL: "Password",
  LOGOUT: "Logout",
  CONTINUE_WITH_PROVIDER: "Continue with {{provider}}",
  CONNECT: "Connect {{provider}}",
  RECONNECT: "Reconnect {{provider}}",
  ERRORS: {
    ACCOUNT_EMAIL_MISMATCH: "Your email must match your account's email",
  },
  [AUTH.FORGOT_PASSWORD]: {
    SUBMIT: "Get reset instructions",
    TITLE: "Forgot your password?",
    RESET_MAIL_SENT_MESSAGE:
      "If you are registered in the platform, an email has been sent!",
    PASSWOD_FOUND: "I've found it!",
    EMAIL_INPUT_LABEL:
      "Enter your email address below and we'll send you the password reset instructions.",
    HINT: "If you don't see your reset email, check your spam filter for an email from support@behale.io",
  },
  [AUTH.HOME]: {
    WELCOME_MESSAGE: "It's time to move.",
  },
  [AUTH.LOGIN]: {
    SUBMIT: "Login",
    SUCCESS_MESSAGE: "You're logged in!",
    FORGOT_PASSWORD: "Forgot your password?",
    SIGN_UP: "Not registered yet?",
    TITLE: "Login",
  },
  [AUTH.ONBOARDING]: {
    ALREADY_REGISTRED: "You've already completed the onboarding",
    TITLE: "Time to move",
    DESCRIPTION:
      "It's time for you to move with our adapted and dynamic training program that fits right into your schedule.",
    FORM_ID: "Tl5eAuZ8",
  },
  [AUTH.REGISTRATION]: {
    SUCCESS_MESSAGE: "Registered successfully !",
    ALREADY_REGISTRED: "Already have an account?",
    EMAIL_TAKEN: "This email is already taken",
    TITLE: "Register to Behale",
    SUBMIT: "Register",
    FITST_NAME: "First Name",
    LAST_NAME: "Last Name",
    ACCEPTS_TERMS:
      "I accept the <0>{{termsOfService}}</0> and the <1>{{privacyPolicy}}</1>.",
  },
  [AUTH.VALIDATION]: {
    PASSWORD_MIN_DIGIT_LENGTH: "Password must contain at least one number",
    PASSWORD_MIN_LENGTH: "Password must be 8 characters or longer",
    PASSWORD_MAX_LENGTH: "Password must be fewer than 100 characters",
    FIRST_NAME_REQUIRED: "First name required",
    SHORT: "Too short!",
    LAST_NAME_REQUIRED: "Last name required",
    INVALID_EMAIL: "Email is invalid",
    EMAIL_REQUIRED: "Email required",
    PASSWOD_REQUIRED: "Password required",
    UNMATCHED_PASSWORDS: "Password doesn't match confirmation field",
  },

  [AUTH.RESET_PASSWORD]: {
    TITLE: "Reset your password",
    SUBMIT: "Reset password",
    SUCCESS_MESSAGE: "Your password has been reset successfully!",
    INVALID_RESET_TOKEN: "The reset token is invalid, try again!",
    ERROR_MESSAGE:
      "An error occurred. Please try again or contact our support.",
    NEW_PASSWORD: "New password",
    CONFIRM_NEW_PASSWORD: "Confirm new password",
  },
  [AUTH.WELCOME_SET_PASSWORD]: {
    TITLE: "Welcome to Behale !",
    SUBTILE: "Finish your registration by setting your password",
    SUBMIT: "Start moving",
    SUCCESS_MESSAGE: "Your account has been created successfully!",
  },
}
