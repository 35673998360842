import {
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
  useIonActionSheet,
} from "@ionic/react"
import { addOutline } from "ionicons/icons"
import { useTranslation } from "react-i18next"
import { RiRecordCircleLine } from "react-icons/ri"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import { MOVEMENT, NAME_SPACES } from "../../locales/constants"
import { YangBehaleIcon } from "../BehaleIcon"
import { GameplanSessionModal } from "../Movement/MovementSnack"

interface BehaleFabProps {
  hidden?: boolean
}

export const BehaleFab: React.FC<BehaleFabProps> = ({ hidden = false }) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.AGENDA_HOME, { returnObjects: true })

  const { openModal } = useModalOrchestrationContext()
  const { captureEvent } = useAnalyticsContext()
  const { today } = useAgendaSchedulingContext()

  const handleFabClick = () => {
    captureEvent(AnalyticsEvent.HubFABClicked)

    openModal(ModalOrchestrationName.MoveSnackModal)
  }

  const [present] = useIonActionSheet()

  const handleAddClick = () => {
    // day is today
    present({
      header: TEXT.ADD_SESSION,
      buttons: [
        {
          text: TEXT.LOG_SESSION,
          handler: () =>
            openModal(ModalOrchestrationName.MovementSessionForm, {
              day: today,
            }),
        },
        {
          text: TEXT.PLAN_SESSION,
          handler: () =>
            openModal(ModalOrchestrationName.MovementAgendaItemForm, {
              day: today,
            }),
        },
        {
          text: TEXT.CANCEL,
          role: "cancel",
        },
      ],
    })
  }

  return (
    <>
      <IonFab
        slot="fixed"
        vertical="bottom"
        horizontal="center"
        hidden={hidden}
      >
        <IonFabButton
          color="primary"
          className="w-[60px] h-[60px] rounded-[30px] bg-primary-500 mb-10"
        >
          <YangBehaleIcon fill="white" className="w-[30px] h-[30px]" />
        </IonFabButton>
        <IonFabList side="top" className="pb-10">
          <IonFabButton
            color="CARDIO"
            className="w-[60px] h-[60px] rounded-[30px] bg-CARDIO-500"
          >
            <IonIcon
              icon={addOutline}
              className="w-[30px] h-[30px]"
              onClick={handleAddClick}
            >
              Plan a session
            </IonIcon>
          </IonFabButton>
          <IonFabButton
            onClick={handleFabClick}
            className="w-[60px] h-[60px] rounded-[30px] bg-neutral-100"
          >
            <RiRecordCircleLine className="w-[40px] h-[40px] text-primary-500" />
          </IonFabButton>
        </IonFabList>
        <GameplanSessionModal />
      </IonFab>
    </>
  )
}
