import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { BaseReactPlayerProps } from "react-player/base"
import ReactPlayer from "react-player/youtube"

import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { MICO, NAME_SPACES } from "../../locales/constants"

type IRoutineContentPlayerProps = {
  src: string
  onProgress?: (duration: number) => void
  onComplete?: () => Promise<void>
  completionThreshold?: number
} & Omit<BaseReactPlayerProps, "url">

const VideoPlayer = ({
  src,
  onComplete,
  onStart,
  onProgress,
  completionThreshold = 1.0,
  light = true,
  ...props
}: IRoutineContentPlayerProps) => {
  const { t } = useTranslation(NAME_SPACES.MICO)

  const { captureEvent } = useAnalyticsContext()
  const [validSrc, setValidSrc] = useState<boolean>(false)

  const handleProgress = async ({
    played,
  }: {
    played: number
    loaded: number
  }) => {
    if (played > completionThreshold) {
      await handleComplete()
    }

    if (onProgress) {
      onProgress(played)
    }
  }

  const handleComplete = async () => {
    captureEvent(AnalyticsEvent.MicoVideoFinished)

    if (onComplete) {
      await onComplete()
    }
  }

  const handleStart = async () => {
    if (onStart) {
      await onStart()
    }
  }

  const handlePlay = async () => {
    captureEvent(AnalyticsEvent.MicoVideoPlayed)
  }

  const handlePause = async () => {
    captureEvent(AnalyticsEvent.MicoVideoPaused)
  }

  useEffect(() => {
    setValidSrc(ReactPlayer.canPlay(src))
  }, [src])

  return (
    <div className="relative w-full min-w-full rounded-md pt-[56.25%]">
      {validSrc ? (
        <ReactPlayer
          url={src}
          config={{
            playerVars: {
              showinfo: 1,
              rel: 0,
            },
          }}
          controls={true}
          height={"100%"}
          width={"100%"}
          light={light}
          loop={false}
          muted={false}
          onStart={handleStart}
          onPlay={handlePlay}
          onPause={handlePause}
          onProgress={handleProgress}
          onEnded={handleComplete}
          playbackRate={1}
          playing={false}
          playsinline={true}
          className="absolute top-0 left-0 rounded-md"
          {...props}
        />
      ) : (
        <div className="absolute top-0 left-0 flex items-center justify-center w-full border-2 border-dashed border-neutral-200 text-neutral-400">
          <span>{t(`${MICO.VIEWER}.VIDEO_NOT_FOUND`)}</span>
        </div>
      )}
    </div>
  )
}

export default VideoPlayer
