import { useLazyQuery } from "@apollo/client"
import { useIonRouter } from "@ionic/react"
import * as React from "react"
import { RouteComponentProps } from "react-router-dom"

import SessionPage from "../../components/Movement/SessionPage"
import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"
import {
  GetFollowAlongDocument,
  GetFollowAlongQuery,
  GetFollowAlongQueryVariables,
} from "../../generated/graphql"
import { redirectToAuth } from "../../routers/AppRouter"

const VideoViewerPage: React.FC<RouteComponentProps<{ uuid: string }>> = ({
  match,
}) => {
  const router = useIonRouter()

  const { isSessionActive, isInitialized } = useAuthenticatedClientContext()

  const [getFollowAlong, { data }] = useLazyQuery<
    GetFollowAlongQuery,
    GetFollowAlongQueryVariables
  >(GetFollowAlongDocument, {
    fetchPolicy: "network-only",
  })

  React.useEffect(() => {
    if (!isSessionActive && isInitialized) {
      redirectToAuth(router)
    }
  }, [isSessionActive, isInitialized])

  React.useEffect(() => {
    if (data?.getFollowAlong?.uuid !== match?.params.uuid) {
      getFollowAlong({
        variables: {
          uuid: match?.params.uuid,
        },
      })
    }
  }, [
    data?.getFollowAlong?.uuid,
    match?.params.uuid,
    router.routeInfo.pathname,
  ])

  return (
    <SessionPage
      followAlong={data?.getFollowAlong}
      onCompleted={() => router.goBack()}
    />
  )
}

export default VideoViewerPage
