import { isNil, omit } from "lodash"
import * as React from "react"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"

import {
  AgendaDay,
  useAgendaSchedulingContext,
} from "../../../contexts/AgendaSchedulingContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"
import {
  GetCurrentMovementAgendaDocument,
  GetMovementAgendaItemQuery,
  MovementActivity,
  MovementActivityInput,
  MovementAgendaItemSummaryFragment,
  MovementSession,
  MovementStyle,
  PhaseOfDay,
  SessionExpectedDuration,
  UpdateMovementAgendaItemDescriptionMutation,
  UpdateMovementAgendaPreferencesDocument,
  UpdateMovementAgendaPreferencesMutationVariables,
  Weekday,
} from "../../../generated/graphql"
import { NAME_SPACES, WELCOME } from "../../../locales/constants"
import Modal, { ModalContext, ModalProps } from "../../Core/Modal"
import { GridForm, GridFormSection } from "../../Forms/GridForm"
import { buildSessionLabel } from "../Style"
import { DurationGridFormField } from "../Forms/Fields/DurationSlider"
import { MovementStyleGridFormField } from "../Forms/Fields/MovementStyleSelect"
import { AgendaItemTitleInput } from "../Forms/Fields/MovementTitleInput"
import { PhaseOfDayGridFormField } from "../Forms/Fields/PhaseOfDaySelect"
import {
  MultiBodyPartSelectGridFormField,
  MultiBodyPartSelectState,
} from "../Forms/Fields/MultiBodyPartSelect"
import { useMutation } from "@apollo/client"
import { WeekdayGridFormField } from "../Forms/Fields/WeekdaySelect"

export type MovementActivityFormModal = {
  title?: string | null
  movementStyle?: MovementStyle
  movementTargetBodyParts?: MultiBodyPartSelectState | null
  usualWeeklyFrequency?: number | null
  usualWeekdays?: Weekday[] | null
  usualDuration?: SessionExpectedDuration | null
  usualPhaseOfDay?: PhaseOfDay | null
}

export interface MovementAgendaItemFormProps {
  agendaItem?:
    | MovementAgendaItemSummaryFragment
    | GetMovementAgendaItemQuery["movementAgendaItem"]
  activity?: MovementActivity
  followAlong?: MovementSession["followAlong"]
  day?: AgendaDay
  openWithField?: keyof MovementActivityFormModal
}

const MovementActivityForm: React.FC<MovementAgendaItemFormProps> = ({
  activity,
}) => {
  const { name } = React.useContext(ModalContext)

  const formAction = isNil(activity) ? "add" : "modify"

  const { agenda, agendaItemActionLoading } = useAgendaSchedulingContext()

  const [updateAgendaPreferences] = useMutation<
    UpdateMovementAgendaItemDescriptionMutation,
    UpdateMovementAgendaPreferencesMutationVariables
  >(UpdateMovementAgendaPreferencesDocument, {
    refetchQueries: [GetCurrentMovementAgendaDocument],
  })

  const { closeModal } = useModalOrchestrationContext()

  const { t } = useTranslation(NAME_SPACES.WELCOME)
  const TEXT = t(WELCOME.ACTIVITIES, { returnObjects: true })

  const buildDefaultValues = (
    activity?: MovementActivity
  ): MovementActivityFormModal => {
    if (!isNil(activity)) {
      return {
        title: buildSessionLabel(activity),
        movementTargetBodyParts:
          activity.movementTargetBodyParts as MultiBodyPartSelectState | null,
        ...activity,
      }
    }

    return {}
  }

  const convertActivityToInput = (
    activity: MovementActivity | MovementActivityFormModal
  ) => {
    return {
      ...omit(activity, ["__typename", "movementModality", "uuid"]),
      usualPhaseOfDay: activity.usualPhaseOfDay || undefined,
      usualWeeklyFrequency: activity.usualWeekdays?.length || 0,
      movementTargetBodyParts: activity.movementTargetBodyParts || [],
    } as MovementActivityInput
  }

  const handleAddActivity = useCallback(
    async (data: MovementActivityFormModal) => {
      const newActivity = convertActivityToInput(data)

      const activities = agenda?.favoriteMovementActivities?.map(
        convertActivityToInput
      )

      activities?.push(newActivity)

      if (isNil(activities) || activities.length === 0) return

      console.debug("activities", activities)

      updateAgendaPreferences({
        variables: {
          favoriteMovementActivities: activities,
        },
      })

      closeModal(name, "added", { activity: newActivity })
    },
    [agenda?.favoriteMovementActivities]
  )

  const handleModifyActivity = useCallback(
    async (data: MovementActivityFormModal) => {
      const newActivity = convertActivityToInput(data)

      const activities = agenda?.favoriteMovementActivities?.map((a) =>
        a.uuid === activity?.uuid ? newActivity : convertActivityToInput(a)
      )

      if (isNil(activities) || activities.length === 0) return

      updateAgendaPreferences({
        variables: {
          favoriteMovementActivities: activities,
        },
      })

      closeModal(name, "modified", { activity: newActivity })
    },
    [agenda?.favoriteMovementActivities]
  )

  const handleSubmit = useCallback(
    async (data: MovementActivityFormModal) => {
      if (formAction === "add") {
        await handleAddActivity(data)
      } else {
        await handleModifyActivity(data)
      }
    },
    [formAction, handleAddActivity, handleModifyActivity]
  )

  const submitCTA = isNil(activity)
    ? TEXT.BUTTONS.ADD_ACTIVITY
    : TEXT.BUTTONS.MODIFY_ACTIVITY

  const defaultValues = buildDefaultValues(activity)

  return (
    <GridForm<MovementActivityFormModal>
      onValidSubmit={handleSubmit}
      submitCTA={submitCTA}
      disableSubmit={agendaItemActionLoading}
      defaultValues={defaultValues}
    >
      <div className="col-span-2">
        <AgendaItemTitleInput name="title" className="text-2xl" />
      </div>
      <GridFormSection>
        <MovementStyleGridFormField
          required
          name="movementStyle"
          recommend="favorite"
        />
        <MultiBodyPartSelectGridFormField name="movementTargetBodyParts" />
      </GridFormSection>
      <GridFormSection>
        <WeekdayGridFormField name="usualWeekdays" />
      </GridFormSection>
      <GridFormSection>
        <DurationGridFormField name="usualDuration" type="approximate" />
        <PhaseOfDayGridFormField name="usualPhaseOfDay" />
      </GridFormSection>
    </GridForm>
  )
}

export const MovementActivityFormModal: React.FC<Partial<ModalProps>> = ({
  name = ModalOrchestrationName.MovementActivityForm,
  ...props
}) => {
  return (
    <Modal name={name} isSheet {...props}>
      <Modal.Header />

      <Modal.Body>
        {(props: any) => <MovementActivityForm {...props} />}
      </Modal.Body>
    </Modal>
  )
}

export default MovementActivityForm
