import { useEffect, useState } from "react"
import { useBreakpoint } from "../../utils"
import React from "react"
import {
  DiscreteCarouselPagination,
  NextButtonControl,
  PreviousButtonControl,
} from "./CarouselPagination"
import Carousel, { CarouselProps } from "nuka-carousel"
import clsx from "clsx"

export interface DiscreteCarouselProps extends CarouselProps {
  maxSlidesToShow?: number
}

export const DiscreteCarousel: React.FC<DiscreteCarouselProps> = ({
  maxSlidesToShow = 2,
  children,
  ...props
}) => {
  const breakpoint = useBreakpoint()
  const [nSlides, setNSlides] = useState(1)

  useEffect(() => {
    switch (breakpoint) {
      case "xl":
        setNSlides(Math.min(maxSlidesToShow, 3))
        break

      case "lg":
        setNSlides(Math.min(maxSlidesToShow, 2))
        break

      case "md":
        setNSlides(Math.min(maxSlidesToShow, 1))
        break

      default:
        setNSlides(1)
        break
    }
  }, [breakpoint])

  if (!children) return null

  return (
    <Carousel
      className={clsx(
        "h-full relative",
        React.Children.count(children) > 1 && "pb-8"
      )}
      renderCenterLeftControls={PreviousButtonControl}
      renderCenterRightControls={NextButtonControl}
      renderBottomCenterControls={DiscreteCarouselPagination}
      autoplay={false}
      wrapAround={false}
      slidesToShow={nSlides}
      swiping
      {...props}
    >
      {React.Children.map(children, (child, index) => (
        <div key={index} className="w-full h-full px-4">
          {child}
        </div>
      ))}
    </Carousel>
  )
}
