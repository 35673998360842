import { useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import {
  IdentifyUserWithProviderMutationVariables,
  UpdateUserIdentityDocument,
  UpdateUserIdentityMutation,
  UpdateUserIdentityMutationVariables,
} from "../../../generated/graphql"
import { NAME_SPACES } from "../../../locales/constants"
import { ButtonProps } from "../../Forms/Button"
import useToast from "../../../hooks/useToast"
import { GoogleCalendarIcon } from "./icons"
import { GoogleScope } from "./utils"
import ContinueWithGoogleButton from "./ContinueWithGoogleButton"
import { useAgendaSchedulingContext } from "../../../contexts/AgendaSchedulingContext"
import { useAuthenticatedClientContext } from "../../../contexts/AuthenticatedClientContext"

export const ConnectGoogleCalendarButton: React.FC<
  ButtonProps & {
    clicked?: boolean
    onCompleted?: (data: any) => void
  }
> = ({ onCompleted, ...props }) => {
  const { t } = useTranslation(NAME_SPACES.AUTH)

  const { refetchActiveQueries } = useAuthenticatedClientContext()
  const { agenda } = useAgendaSchedulingContext()

  const isConnected = agenda?.schedulingCalendarConnected

  const { showError } = useToast()

  const [updateUserIdentity] = useMutation<
    UpdateUserIdentityMutation,
    UpdateUserIdentityMutationVariables
  >(UpdateUserIdentityDocument)

  const handleFullfilled = async (
    data: IdentifyUserWithProviderMutationVariables,
    onResolved?: () => void
  ) => {
    await updateUserIdentity({
      variables: data,
      onCompleted: async (data) => {
        onResolved?.()

        onCompleted?.(data)

        refetchActiveQueries()
      },
      onError: async (error) => {
        onResolved?.()

        if (error.message.includes("account_email_mismatch")) {
          showError(t("ERRORS.ACCOUNT_EMAIL_MISMATCH"))
        } else {
          showError(error)
        }
      },
    })
  }

  return (
    <ContinueWithGoogleButton
      scope={GoogleScope.Calendar}
      forcePrompt
      label={
        isConnected
          ? t("RECONNECT", { provider: "Google Calendar" })
          : t("CONNECT", { provider: "Google Calendar" })
      }
      icon={GoogleCalendarIcon}
      onFullfilled={handleFullfilled}
      size="default"
      {...props}
    />
  )
}

export default ConnectGoogleCalendarButton
