export const enum NAME_SPACES {
  AUTH = "AUTH",
  MEMBERSHIP = "MEMBERSHIP",
  COMMON = "COMMON",
  MICO = "MICO",
  MOVEMENT = "MOVEMENT",
  CALENDAR = "CALENDAR",
  NOTIFICATIONS = "NOTIFICATIONS",
  WELCOME = "WELCOME",
  ADVENTURE = "ADVENTURE",
}

export const enum COMMON {
  TABS = "TABS",
  ELEMENTS = "ELEMENTS",
  WEEKDAYS = "WEEKDAYS",
  FORMS = "FORMS",
  LANGUAGES = "LANGUAGES",
}

export const enum CALENDAR {
  WEEKDAYS = "WEEKDAYS",
  RELATIVE = "RELATIVE",
}

export const enum AUTH {
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  LOGIN = "LOGIN",
  ONBOARDING = "ONBOARDING",
  REGISTRATION = "REGISTRATION",
  RESET_PASSWORD = "RESET_PASSWORD",
  WELCOME_SET_PASSWORD = "WELCOME_SET_PASSWORD",
  VALIDATION = "VALIDATION",
  HOME = "HOME",
}

export const enum MEMBERSHIP {
  PROFILE = "PROFILE",
  GUIDANCE = "ASSISTANCE",
  ADVENTURE = "ADVENTURE",
  PROGRESS = "PROGRESS",
  TODAY = "TODAY",
  PREMIUM = "PREMIUM",
  CALENDAR = "CALENDAR",
}

export const enum ADVENTURE {
  DIFFICULTY = "DIFFICULTY",
  GALLERY = "GALLERY",
  TEMPLATE = "TEMPLATE",
  KICK_OFF = "KICK_OFF",
  TYPE = "TYPE",
  CURRENT = "CURRENT",
}

export const enum WELCOME {
  ACTIVITIES = "ACTIVITIES",
  ELEMENTS = "ELEMENTS",
  GET_THE_APP = "GET_THE_APP",
}

export const enum MICO {
  EXPLORER = "EXPLORER",
  VIEWER = "VIEWER",
  FORM = "FORM",
  CARD = "CARD",
  HOME = "HOME",
  GAMEPLAN = "GAMEPLAN",
  FOLLOW_ALONG = "FOLLOW_ALONG",
}

export const enum MOVEMENT {
  AGENDA_HOME = "AGENDA_HOME",
  AGENDA_ITEM = "AGENDA_ITEM",
  CURRENT_MOVEMENT_SESSION = "CURRENT_MOVEMENT_SESSION",
  ITEM_PLANNING = "ITEM_PLANNING",
  SESSION_LOGGING = "SESSION_LOGGING",
  FORM = "FORM",
  POST_SESSION_FEEDBACK = "CELEBRATION_MODAL",
  TARGET_BODY_PARTS = "TARGET_BODY_PARTS",
  STYLE = "MOVEMENT_STYLE",
  AGENDA_SUGGESTION = "AGENDA_SUGGESTION",
  INTENSITY = "INTENSITY",
  PAST_MOVEMENT_SESSION = "PAST_MOVEMENT_SESSION",
  RPE = "RPE",
  EQUIPMENT = "EQUIPMENT",
  DISTANCE = "DISTANCE",
  MODALITY = "MODALITY",
  WIZARD = "RECOMMENDATION",
  LOW_IMPACT = "LOW_IMPACT",
  PHASE_OF_DAY = "PHASE_OF_DAY",
}

export const enum PREMIUM {
  HOME = "HOME",
}
