import clsx from "clsx"
import * as React from "react"
import {
  MovementAgendaItem,
  MovementAgendaItemSummaryFragment,
} from "../../generated/graphql"
import { MovementIcon } from "../Core/Icons"
import { isBodyTreeEmpty } from "../../utils/movementUtils"
import { MovementExtraInformationDetails } from "./MovementInformationDetails"
import { AdventureBanner } from "../Adventure/AdventureBanner"
import { formatStyle } from "../../utils/format"

export interface AgendaItemPageHeaderProps {
  agendaItem: MovementAgendaItem
}

export const AgendaSuggestionHeader: React.FC<AgendaItemPageHeaderProps> = ({
  agendaItem,
}) => {
  const hasTargetBodyParts = !isBodyTreeEmpty(
    agendaItem.movementTargetBodyParts
  )

  return (
    <div className="relative flex flex-col w-full">
      <div className={clsx(`bg-${agendaItem.movementModality}`, "text-white")}>
        <AdventureBanner move={agendaItem} className="px-4 py-3 text-lg" />
        <div className="relative flex flex-col justify-between w-full p-4 mx-auto overflow-hidden gap-y-4">
          <div className="flex flex-col justify-between w-full gap-y-1">
            <div
              className={clsx(
                "flex flex-row items-center justify-between gap-x-2 w-full"
              )}
            >
              <div
                className={clsx(
                  "flex flex-row items-center gap-x-2 w-full truncate"
                )}
              >
                <span
                  className={clsx(
                    "text-2xl font-semibold truncate w-full",
                    `text-white`
                  )}
                >
                  {agendaItem.title || formatStyle(agendaItem.movementStyle)}
                </span>
              </div>

              <MovementIcon
                element={agendaItem}
                className="flex-shrink-0 w-10 h-10 p-1"
              />
            </div>
          </div>
        </div>
        <MovementExtraInformationDetails
          item={agendaItem as MovementAgendaItemSummaryFragment}
          isTargetBodyPartAvailable={hasTargetBodyParts}
        />
      </div>
    </div>
  )
}
