import clsx from "clsx"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { IoCalendarClearOutline } from "react-icons/io5"

import { ModalOrchestrationName } from "../../../../contexts/ModalOrchestrationContext"
import { Weekday } from "../../../../generated/graphql"
import { NAME_SPACES } from "../../../../locales/constants"
import { weekdayOrder } from "../../../../utils"
import { formatWeekday } from "../../../../utils/format"
import {
  GridFormField,
  GridFormFieldProps,
  RequiredFieldModalBodyProps,
} from "../../../Forms/GridFormField"
import { getWeekdayInitial } from "../../../../labels"

export type WeekdaySelectState = Weekday[] | null | undefined

export type WeekdaySelectProps = RequiredFieldModalBodyProps<WeekdaySelectState>

const WeekdaySelect: React.FC<WeekdaySelectProps> = ({ state, setState }) => {
  const { t } = useTranslation(NAME_SPACES.WELCOME)

  const handleSelect = useCallback(
    (weekday: Weekday) => {
      if (!state) {
        setState([weekday])
        return
      }

      if (state?.includes(weekday)) {
        const stateWithoutWeekday = [...state].filter((w) => w !== weekday)
        setState(stateWithoutWeekday)
      } else {
        const stateWithWeekday = [...state, weekday]
        setState(stateWithWeekday)
      }
    },
    [state]
  )

  const isSelected = useCallback(
    (weekday: Weekday) => state?.includes(weekday),
    [state]
  )

  return (
    <div
      className={clsx(
        "flex flex-col items-start justify-center w-full gap-y-2",
        "rounded-b-md bg-opacity-20",
        "p-4"
      )}
    >
      <span className="text-sm">
        {t("ACTIVITIES.HINTS.WEEKDAYS", { weekdays: formatWeekday(state) })}
      </span>
      <div
        className={clsx(
          "flex flex-row items-center justify-around w-full py-3 gap-x-1 max-w-lg mx-auto"
        )}
      >
        {weekdayOrder.map((weekday) => (
          <div
            key={weekday}
            className="flex flex-col items-center justify-center"
          >
            <button
              className={clsx(
                isSelected(weekday) && `bg-primary-500 text-white`,
                `ring-1 ring-primary-500 ring-inset`,
                "-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none aspect-square"
              )}
              onClick={() => handleSelect(weekday)}
            >
              <span className="sr-only">{formatWeekday(weekday)}</span>

              <span
                aria-hidden="true"
                className={clsx(
                  "h-6 w-6 rounded-full flex items-center justify-center font-medium"
                )}
              >
                {getWeekdayInitial(weekday)}
              </span>
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export interface WeekdayGridFormFieldProps
  extends Omit<
    GridFormFieldProps<WeekdaySelectState>,
    "Body" | "label" | "modalName"
  > {
  name: string
}

export const WeekdayGridFormField: React.FC<WeekdayGridFormFieldProps> = ({
  name,
  ...props
}) => {
  return (
    <GridFormField<WeekdaySelectState>
      name={name}
      modalName={ModalOrchestrationName.FormFieldWeekdaySelect}
      label={"Days"}
      Body={WeekdaySelect}
      Icon={IoCalendarClearOutline}
      formatValue={formatWeekday}
      showDismiss
      {...props}
    />
  )
}

export default WeekdaySelect
