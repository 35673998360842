import { useContext } from "react"
import { NotificationContext } from "./NotificationProvider"

export const useNotificationContext = () => {
  const context = useContext(NotificationContext)

  if (context === undefined) {
    throw new Error(
      "useNotificationContext must be used within a NotificationContext provider"
    )
  }

  return context
}
