import { AUTH } from "../constants"

export default {
  INCORRECT_CREDENTIALS: "Identifiants incorrects",
  AN_ERROR_HAS_OCCURED: "Une erreur est survenue",
  EMAIL_LABEL: "Email",
  PASSWORD_LABEL: "Mot de passe",
  LOGOUT: "Déconnexion",
  CONTINUE_WITH_PROVIDER: "Continuer avec {{provider}}",
  CONNECT: "Connecter {{provider}}",
  RECONNECT: "Reconnecter {{provider}}",
  ERRORS: {
    ACCOUNT_EMAIL_MISMATCH:
      "Votre email doit correspondre à celui de votre compte",
  },
  [AUTH.FORGOT_PASSWORD]: {
    SUBMIT: "Recevoir les instructions",
    TITLE: "Mot de passe oublié ?",
    RESET_MAIL_SENT_MESSAGE:
      "Si tu es inscrit dans la plateforme, le email a été envoyé !",

    PASSWOD_FOUND: "C'est bon, je l'ai trouvé !",
    EMAIL_INPUT_LABEL:
      "Saisis ton adresse e-mail ci-dessous et nous t'enverrons les instructions de réinitialisation du mot de passe.",
    HINT: "Si tu ne vois pas ton e-mail de réinitialisation, vérifie si ton filtre anti-spam n'a pas reçu d'e-mail de support@behale.io",
  },
  [AUTH.HOME]: {
    WELCOME_MESSAGE: "Adaptons le sport à nos vies",
  },
  [AUTH.LOGIN]: {
    SUBMIT: "Se connecter",
    SUCCESS_MESSAGE: "Connecté !",
    FORGOT_PASSWORD: "Mot de passe oublié ?",
    SIGN_UP: "Pas encore inscrit ?",
    TITLE: "Connexion",
  },
  [AUTH.ONBOARDING]: {
    ALREADY_REGISTRED: "Tu as déjà complété l'onboarding.",
    TITLE: "Gère ton fitness - behale",
    DESCRIPTION:
      "Avec l'agenda de mouvement, Behale te propose un programme d'entrainement adapté, personnalisé et dynamique.",
    FORM_ID: "myY6pjhI",
  },
  [AUTH.REGISTRATION]: {
    SUCCESS_MESSAGE: "Bien enregistré.e !",
    ALREADY_REGISTRED: "Déjà inscrit ?",
    EMAIL_TAKEN: "Cette adresse email est déjà utilisée",
    TITLE: "Inscris-toi à Behale",
    SUBMIT: "S'inscrire",
    FITST_NAME: "Prénom",
    LAST_NAME: "Nom",
    ACCEPTS_TERMS:
      "J'accepte les <0>{{termsOfService}}</0> et les <1>{{privacyPolicy}}</1>.",
  },
  [AUTH.VALIDATION]: {
    PASSWORD_MIN_DIGIT_LENGTH:
      "Le mot de passe doit contenir au moins un chiffre",
    PASSWORD_MIN_LENGTH: "Le mot de passe doit contenir au moins 8 caractères",
    PASSWORD_MAX_LENGTH: "Le mot de passe doit contenir au plus 100 caractères",
    FIRST_NAME_REQUIRED: "Prénom requis",
    SHORT: "Trop court",
    LAST_NAME_REQUIRED: "Nom requis",
    INVALID_EMAIL: "Email n'est pas valide",
    EMAIL_REQUIRED: "Email requis",
    PASSWOD_REQUIRED: "Le mot de passe est requis",
    UNMATCHED_PASSWORDS: "Les mots de passe ne correspondent pas",
  },

  [AUTH.RESET_PASSWORD]: {
    TITLE: "Réinitialise ton mot de passe",
    SUBMIT: "Réinitialiser mot de passe",
    SUCCESS_MESSAGE: "Ton mot de passe a bien été réinitialisé.",
    INVALID_RESET_TOKEN: "Le token de réinitialisation n'est pas valide.",
    ERROR_MESSAGE:
      "Impossible de réinitialiser le mot de passe. Essaye de nouveau.",
    NEW_PASSWORD: "Nouveau mot de passe",
    CONFIRM_NEW_PASSWORD: "Confirme ton nouveau mot de passe",
  },
  [AUTH.WELCOME_SET_PASSWORD]: {
    TITLE: "Bienvenue chez Behale !",
    SUBTILE: "Finalise ton inscription en choisissant un mot de passe.",
    SUBMIT: "Créer mon compte",
    SUCCESS_MESSAGE: "Ton compte a bien était créé.",
  },
}
