import {
  InternalRefetchQueryDescriptor,
  useMutation,
  useQuery,
} from "@apollo/client"
import { isSameDay, startOfWeek } from "date-fns"
import isAfter from "date-fns/fp/isAfter"
import isBefore from "date-fns/isBefore"
import isNil from "lodash/isNil"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react"
import { useTranslation } from "react-i18next"

import {
  AddMovementAgendaItemDocument,
  AddMovementAgendaItemMutation,
  AddMovementAgendaItemMutationVariables,
  CompleteMovementSessionDocument,
  CompleteMovementSessionMutation,
  CompleteMovementSessionMutationVariables,
  DeleteMovementSessionDocument,
  DeleteMovementSessionMutation,
  DeleteMovementSessionMutationVariables,
  EditMovementSessionReportedDataDocument,
  EditMovementSessionReportedDataMutation,
  EditMovementSessionReportedDataMutationVariables,
  GetCurrentMovementAgendaDocument,
  GetCurrentMovementAgendaQuery,
  GetMovementAgendaItemDocument,
  GetMovementSessionDocument,
  GetMovementWeekByStartDateDocument,
  GetMovementWeekByStartDateQuery,
  GetMovementWeekByStartDateQueryVariables,
  ListMemberAdventuresDocument,
  ListSuggestionInboxDocument,
  ListSuggestionInboxQuery,
  LogMovementSessionDocument,
  LogMovementSessionMutation,
  LogMovementSessionMutationVariables,
  Maybe,
  ModifyMovementAgendaItemDocument,
  ModifyMovementAgendaItemMutation,
  ModifyMovementAgendaItemMutationVariables,
  RemoveMovementAgendaItemDocument,
  RemoveMovementAgendaItemMutation,
  RemoveMovementAgendaItemMutationVariables,
  SetTimeZoneDocument,
  SetTimeZoneMutation,
  SetTimeZoneMutationVariables,
  Weekday,
} from "../../generated/graphql"
import useToast from "../../hooks/useToast"
import { MOVEMENT, NAME_SPACES } from "../../locales/constants"
import { getWeekdayDate, weekdayIndex } from "../../utils"
import { useAuthenticatedClientContext } from "../AuthenticatedClientContext"
import { useNotificationContext } from "../NotificationContext"
import { buildVirtualWeekSchedule, buildToday, addActivityToDay } from "./day"
import { agendaSchedulingReducer } from "./agendaReducer"
import { getTimeZoneValue } from "./agendaTimeUtils"
import {
  AgendaDay,
  AgendaSchedulingContextActions,
  AgendaSchedulingContextState,
  AgendaSchedulingReducerAction,
  AgendaSchedulingReducerActionType as AgendaReducerActionType,
  AgendaWeekSchedule,
} from "./agendaTypes"
import { useLocaleContext } from "../LocaleContext"
import { useSubscriptionContext } from "../SubscriptionContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../ModalOrchestrationContext"
import { useAnalyticsContext } from "../AnalyticsContext"
import { useAdventureContext } from "../AdventureContext"
import { get } from "lodash"

export const AgendaSchedulingContext = createContext<
  (AgendaSchedulingContextState & AgendaSchedulingContextActions) | undefined
>(undefined)

export const useAgendaSchedulingContext = () => {
  const context = useContext(AgendaSchedulingContext)

  if (context === undefined) {
    throw new Error(
      "useAgendaSchedulingContext must be used within a AgendaSchedulingContext"
    )
  }

  return context
}

const today = buildToday()

const startOfWeekDate = startOfWeek(today.date, { weekStartsOn: 1 })

const initialState = {
  today,
  selectedDay: today,
  timezone: "Europe/Paris",
  isReady: false,
  isEnabled: false,
  agenda: null,
  recurringItemsPerWeekday: {},
  suggestionInboxItems: [],
  // current week
  currentWeek: null,
  currentWeekUuid: undefined,
  currentWeekSchedule: buildVirtualWeekSchedule({ startDate: startOfWeekDate }),
  currentWeekStartDate: startOfWeekDate,
  isSelectedWeekCurrent: true,
  canAccessSelectedWeek: true,
  // selected week
  selectedWeek: null,
  selectedWeekUuid: undefined,
  selectedWeekStartDate: startOfWeekDate,
  selectedWeekday: today.weekday,
  selectedWeekSchedule: buildVirtualWeekSchedule({
    startDate: startOfWeekDate,
  }),
  // loading
  isSelectedWeekStale: true,
  agendaItemActionLoading: false,
  sessionActionLoading: false,
} as AgendaSchedulingContextState

export const AgendaSchedulingProvider: React.FC = ({ children }) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.ITEM_PLANNING, { returnObjects: true })

  const [state, dispatch] = useReducer(
    agendaSchedulingReducer,
    initialState
  ) as [
    AgendaSchedulingContextState,
    React.Dispatch<AgendaSchedulingReducerAction>
  ]

  const {
    isSessionActive,
    isLoadingUser: isLoading,
    isClientAuthenticated,
  } = useAuthenticatedClientContext()
  const { isCurrentPhaseActivity } = useAdventureContext()
  const { formatDate } = useLocaleContext()
  const { badgeCount, setAppBadge, clearAppBadge } = useNotificationContext()
  const { openModal, modals } = useModalOrchestrationContext()
  const { isPremium } = useSubscriptionContext()
  const { setPersonProperties } = useAnalyticsContext()

  const { showError, showInfo, showSuccess } = useToast()

  const { refetch: getCurrentAgenda } = useQuery<GetCurrentMovementAgendaQuery>(
    GetCurrentMovementAgendaDocument,
    {
      skip: !isSessionActive || isLoading || !isClientAuthenticated,
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        dispatch({
          type: AgendaReducerActionType.AgendaLoaded,
          payload: data.currentMovementAgenda,
        })
      },
      onError: (error) => {
        if (error.graphQLErrors.at(0)?.message === "not_found") {
          setTimeout(() => {
            getCurrentAgenda()
          }, 1_000)
        }
      },
    }
  )

  useQuery<
    GetMovementWeekByStartDateQuery,
    GetMovementWeekByStartDateQueryVariables
  >(GetMovementWeekByStartDateDocument, {
    fetchPolicy: "cache-and-network",
    skip: !state.isReady || isNil(state.selectedWeekStartDate),
    variables: {
      startDate: formatDate(state.selectedWeekStartDate, "yyyy-MM-dd"),
    },
    onCompleted: (data) => {
      const newSelectedWeek = data.movementWeekByStartDate

      dispatch({
        type: AgendaReducerActionType.SelectedWeekLoaded,
        payload: newSelectedWeek,
      })
    },
    onError: (error) => {
      console.warn(
        "[AgendaSchedulingContext] getSelectedWeekByStartDate.onError: ",
        error
      )
    },
  })

  const { data: suggestionInboxItemsData, refetch: refetchSuggestions } =
    useQuery<ListSuggestionInboxQuery>(ListSuggestionInboxDocument, {
      skip: !state.isReady,
    })

  const [setTimeZone] = useMutation<
    SetTimeZoneMutation,
    SetTimeZoneMutationVariables
  >(SetTimeZoneDocument)

  const [addItem, { loading: addingItem }] = useMutation<
    AddMovementAgendaItemMutation,
    AddMovementAgendaItemMutationVariables
  >(AddMovementAgendaItemDocument)

  const [modifyItem, { loading: modifyingItem }] = useMutation<
    ModifyMovementAgendaItemMutation,
    ModifyMovementAgendaItemMutationVariables
  >(ModifyMovementAgendaItemDocument)

  const [removeItem, { loading: removingItem }] = useMutation<
    RemoveMovementAgendaItemMutation,
    RemoveMovementAgendaItemMutationVariables
  >(RemoveMovementAgendaItemDocument)

  const [
    editSessionReportedDataMutation,
    { loading: editingSessionReportedData },
  ] = useMutation<
    EditMovementSessionReportedDataMutation,
    EditMovementSessionReportedDataMutationVariables
  >(EditMovementSessionReportedDataDocument)

  const [logSessionMutation, { loading: loggingSession }] = useMutation<
    LogMovementSessionMutation,
    LogMovementSessionMutationVariables
  >(LogMovementSessionDocument)

  const [deleteSessionMutation, { loading: deletingSession }] = useMutation<
    DeleteMovementSessionMutation,
    DeleteMovementSessionMutationVariables
  >(DeleteMovementSessionDocument)

  const [completeSessionMutation, { loading: completingSession }] = useMutation<
    CompleteMovementSessionMutation,
    CompleteMovementSessionMutationVariables
  >(CompleteMovementSessionDocument)

  const setSelectedWeekSchedule = (newWeekSchedule: AgendaWeekSchedule) => {
    dispatch({
      type: AgendaReducerActionType.SelectedWeekScheduleUpdated,
      payload: newWeekSchedule,
    })
  }

  const getDay = useCallback(
    (weekday?: Maybe<Weekday>) => {
      // returns today if weekday is nil
      if (isNil(weekday)) {
        return state.today
      }

      const date = getWeekdayDate(state.selectedWeekStartDate, weekday)

      return { weekday, date }
    },
    [state.selectedWeekStartDate]
  )

  const selectedDay = useMemo(() => {
    const weekdaySchedule = get(
      state.selectedWeekSchedule,
      weekdayIndex[state.selectedWeekday || state.today.weekday]
    )

    return addActivityToDay(getDay(state.selectedWeekday), weekdaySchedule)
  }, [state.selectedWeekday, state.selectedWeekSchedule])

  const getDate = (weekday?: Maybe<Weekday>) => {
    return getDay(weekday).date
  }

  const selectWeekday = (weekday?: Weekday) => {
    dispatch({
      type: AgendaReducerActionType.WeekdaySelected,
      payload: weekday,
    })
  }

  const selectPreviousWeek = () => {
    if (state.sessionActionLoading || state.agendaItemActionLoading) {
      return
    }

    dispatch({
      type: AgendaReducerActionType.PreviousWeekSelected,
      payload: {
        selectedWeekUuid: state.selectedWeek?.previousMovementWeekUuid,
      },
    })
  }

  const selectNextWeek = () => {
    if (state.sessionActionLoading || state.agendaItemActionLoading) {
      return
    }

    dispatch({
      type: AgendaReducerActionType.NextWeekSelected,
      payload: {
        selectedWeekUuid: state.selectedWeek?.nextMovementWeekUuid,
      },
    })
  }

  const canAccessSelectedWeek = useMemo(() => {
    return true

    // TODO: re-enable this logic

    // if (
    //   ["development", "preview", "staging"].includes(getCurrentEnvironment())
    // ) {
    //   return true
    // }

    // // premium users can access any week
    // if (isPremium) {
    //   return true
    // }

    // if (isBefore(state.selectedWeekStartDate, state.currentWeekStartDate)) {
    //   return true
    // }

    // const isLessThanAWeekInFuture =
    //   differenceInCalendarWeeks(
    //     state.selectedWeekStartDate,
    //     state.currentWeekStartDate
    //   ) < 1

    // // if it's the weekend and the selected week is the next week, allow access
    // if ([Weekday.Saturday, Weekday.Sunday].includes(state.today.weekday)) {
    //   return (
    //     differenceInCalendarWeeks(
    //       state.selectedWeekStartDate,
    //       state.currentWeekStartDate
    //     ) <= 1
    //   )
    // }

    // return isLessThanAWeekInFuture
  }, [
    state.today,
    state.currentWeekStartDate,
    state.selectedWeekStartDate,
    isPremium,
  ])

  const selectWeekDate = (startDate: Date) => {
    const startOfWeekDate = startOfWeek(startDate, {
      weekStartsOn: 1,
    })

    if (isSameDay(startOfWeekDate, state.selectedWeekStartDate)) {
      return
    }

    dispatch({
      type: AgendaReducerActionType.WeekSelectedByStartDate,
      payload: {
        startDate: startOfWeekDate,
      },
    })
  }

  const selectToday = async () => {
    selectWeekDate(state.currentWeekStartDate)

    selectWeekday(state.today.weekday)
  }

  const isToday = useCallback(
    (day: AgendaDay) => {
      return isSameDay(day.date, state.today.date)
    },
    [state.today]
  )

  const isPast = useCallback(
    (day: AgendaDay) => {
      return isBefore(day.date, state.today.date)
    },
    [state.today]
  )

  const isFuture = useCallback(
    (day: AgendaDay) => {
      return isAfter(state.today.date, day.date)
    },
    [state.today]
  )

  const refetchAgenda = async (onCompleted?: () => void) => {
    if (!state.isReady) {
      return
    }

    await getCurrentAgenda()

    await refetchSuggestions()

    onCompleted?.()
  }

  const refetchQueries = useMemo(() => {
    const queries = [
      GetCurrentMovementAgendaDocument,
    ] as InternalRefetchQueryDescriptor[]

    if (!isNil(state.selectedWeekStartDate)) {
      const startDate = formatDate(state.selectedWeekStartDate, "yyyy-MM-dd")

      queries.push({
        query: GetMovementWeekByStartDateDocument,
        variables: { startDate },
      })
    }

    return queries
  }, [state.selectedWeekStartDate, state.selectedWeekUuid])

  const addAgendaItem: AgendaSchedulingContextActions["addAgendaItem"] =
    useCallback(
      async (variables, showSuccessMessage = true, onSuccess) => {
        await addItem({
          variables,
          onCompleted: async (result) => {
            onSuccess?.(result)

            if (showSuccessMessage) {
              showSuccess(TEXT.SESSION_ADDED_SUCCESS_MESSAGE)
            }

            refetchAgenda()
          },
          onError: async (error: any) => {
            await showError(error)
          },
          refetchQueries,
        })
      },
      [refetchQueries, refetchAgenda]
    )

  const modifyAgendaItem = useCallback(
    async (
      variables: ModifyMovementAgendaItemMutationVariables,
      refreshSchedule = true,
      onSuccess?: (data: ModifyMovementAgendaItemMutation) => void
    ) => {
      await modifyItem({
        variables,
        onCompleted: async (result) => {
          onSuccess?.(result)

          if (refreshSchedule) {
            showSuccess(TEXT.SESSION_MODIFIED_SUCCESS_MESSAGE)

            refetchAgenda()
          }
        },
        onError: async (error) => {
          await showError(error)
        },
        refetchQueries: () => [
          ...refetchQueries,
          {
            query: GetMovementAgendaItemDocument,
            variables: {
              uuid: variables.uuid,
            },
          },
          ListSuggestionInboxDocument,
        ],
      })
    },
    [refetchQueries, refetchAgenda]
  )

  const removeAgendaItem = useCallback(
    async (
      variables: RemoveMovementAgendaItemMutationVariables,
      onSuccess?: () => void
    ) => {
      await removeItem({
        variables,
        onCompleted: async () => {
          onSuccess?.()

          await showInfo(TEXT.SESSION_DELETED_SUCCESS_MESSAGE)

          refetchAgenda()
        },
        onError: async (error) => {
          await showError(error)
        },
        refetchQueries,
      })
    },
    [refetchQueries]
  )

  const editSessionReportedData = useCallback(
    async (
      variables: EditMovementSessionReportedDataMutationVariables,
      onSuccess?: (data: EditMovementSessionReportedDataMutation) => void
    ) => {
      await editSessionReportedDataMutation({
        variables,
        onCompleted: async (result) => {
          onSuccess?.(result)
        },
        onError: async (error) => await showError(error),
        refetchQueries: [
          {
            query: GetMovementSessionDocument,
            variables: { uuid: variables.uuid },
          },
          ...refetchQueries,
        ],
      })
    },
    [refetchQueries]
  )

  const logSession = useCallback(
    async (
      variables: LogMovementSessionMutationVariables,
      showFeedbackModal = false,
      onSuccess?: (data: LogMovementSessionMutation) => void
    ) => {
      await logSessionMutation({
        variables,
        onCompleted: async (result) => {
          onSuccess?.(result)

          refetchAgenda()

          setPersonProperties({
            lastMovementDatetime: new Date().toISOString(),
          })

          if (showFeedbackModal) {
            openModal(ModalOrchestrationName.PostSessionFeedback, {
              ...result.logMovementSession,
              sessionDuration: result.logMovementSession.reportedDuration,
            })
          }
        },
        onError: async (error) => await showError(error),
        refetchQueries: [ListMemberAdventuresDocument, ...refetchQueries],
      })
    },
    [refetchQueries, modals]
  )

  const deleteSession = useCallback(
    async (
      variables: DeleteMovementSessionMutationVariables,
      onSuccess?: (data: DeleteMovementSessionMutation) => void
    ) => {
      await deleteSessionMutation({
        variables,
        onCompleted: async (result) => {
          await refetchAgenda()

          onSuccess?.(result)
        },
        onError: async (error) => await showError(error),
        refetchQueries,
      })
    },
    [refetchQueries]
  )

  const completeSession = useCallback(
    async (
      variables: CompleteMovementSessionMutationVariables,
      onSuccess?: (data: CompleteMovementSessionMutation) => void
    ) => {
      await completeSessionMutation({
        variables,
        onCompleted: async (result) => {
          await refetchAgenda()

          openModal(ModalOrchestrationName.PostSessionFeedback, {
            ...result.completeMovementSession,
            sessionDuration:
              result.completeMovementSession.sessionActualDuration,
          })

          setPersonProperties({
            lastMovementDatetime: new Date().toISOString(),
          })

          onSuccess?.(result)
        },
        onError: async (error) => await showError(error),
        refetchQueries: [ListMemberAdventuresDocument, ...refetchQueries],
      })
    },
    [refetchQueries, modals]
  )

  const suggestionInboxItems = useMemo(() => {
    return (
      suggestionInboxItemsData?.listSuggestionInbox.filter((item) => {
        if (isNil(item.membershipAdventureUuid)) return true

        return isCurrentPhaseActivity(
          item.membershipAdventureUuid,
          item.squaloAdventureActivityId
        )
      }) || []
    )
  }, [suggestionInboxItemsData, isCurrentPhaseActivity])

  // ----------------------------- EFFECTS ----------------------------- //

  useEffect(() => {
    if (!state.isReady) return

    if (isNil(state.agenda)) return

    setPersonProperties({
      nCompletedSessions: state.agenda.nCompletedSessions,
      weeklyGoal: state.agenda.regularityIdealWeeklySessionGoal,
      nRecurringItems: state.agenda.recurringItems?.length || 0,
    })
  }, [state.isReady, state.agenda])

  useEffect(() => {
    if (!state.isReady) return

    const timezone = getTimeZoneValue()

    if (timezone === state.timezone) return

    dispatch({
      type: AgendaReducerActionType.TimezoneChanged,
      payload: { timezone },
    })

    if (timezone !== state.agenda?.schedulingCalendarTimezone) {
      setTimeZone({ variables: { timezone } })
    }
  }, [state.isReady, state.agenda, state.timezone])

  // effect - set badge count
  useEffect(() => {
    if (!state.isEnabled) return

    const todaysIndex = weekdayIndex[state.today.weekday]
    const todaysSchedule = state.currentWeekSchedule[todaysIndex]

    if (badgeCount === todaysSchedule.items.length) {
      return
    } else if (todaysSchedule.items.length > 0) {
      setAppBadge(todaysSchedule.items.length)
    } else {
      clearAppBadge()
    }
  }, [state.isEnabled, state.today, state.currentWeekSchedule])

  useEffect(() => {
    if (isLoading) return

    if (state.isEnabled) return

    if (isSessionActive) {
      dispatch({ type: AgendaReducerActionType.ContextEnabled })
    }
  }, [isSessionActive, isLoading, state.isEnabled])

  const value = {
    ...state,
    selectedDay,
    isToday,
    isPast,
    isFuture,
    setSelectedWeekSchedule,
    getDay,
    getDate,
    selectToday,
    selectWeekday,
    selectPreviousWeek,
    canAccessSelectedWeek,
    selectNextWeek,
    selectWeekDate,
    addAgendaItem,
    modifyAgendaItem,
    removeAgendaItem,
    agendaItemActionLoading: addingItem || modifyingItem || removingItem,
    completeSession,
    logSession,
    deleteSession,
    editSessionReportedData,
    sessionActionLoading:
      deletingSession ||
      loggingSession ||
      editingSessionReportedData ||
      completingSession,
    refetchAgenda,
    isSelectedWeekCurrent: state.selectedWeekUuid === state.currentWeekUuid,
    refetchQueries,
    suggestionInboxItems,
  }

  return (
    <AgendaSchedulingContext.Provider value={value}>
      {children}
    </AgendaSchedulingContext.Provider>
  )
}
