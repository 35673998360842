import { Redirect, Route, Switch } from "react-router-dom"

import AgendaItemSessionPage from "../pages/Agenda/AgendaItemSessionPage"
import HubRouter from "./HubRouter"
import { useIonRouter, UseIonRouterResult } from "@ionic/react"
import useRouterQueryParams from "../hooks/useRouterQueryParams"
import { useAuthenticatedClientContext } from "../contexts/AuthenticatedClientContext"
import { useRedirectionState } from "./AuthRouter"
import { useEffect } from "react"
import { isInProtectedRoute } from "../utils/routes"
import { FeedbackListener } from "../listeners/FeedbackListener"
import MicoRouter from "./MicoRouter"
import WelcomeRouter from "./WelcomeRouter"
import FavoriteActivitySelectionPage from "../pages/Welcome/FavoriteActivitySelectionPage"
import ElementSelectionPage from "../pages/Welcome/ElementSelectionPage"
import AdventureKickOffPage from "../pages/Adventure/AdventureKickOffPage"

export const buildAuthRedirect = (
  router: UseIonRouterResult,
  query?: URLSearchParams,
  pathname?: string
) => {
  query = query || new URLSearchParams()

  pathname = pathname || router.routeInfo.pathname

  query.append("redirectTo", encodeURIComponent(pathname))

  return `/auth/home?${query.toString()}`
}

export const redirectToAuth = (
  router: UseIonRouterResult,
  query?: any,
  to?: string
) => {
  const pathname = buildAuthRedirect(router, query, to)

  router.push(pathname, "root", "replace")
}

export const redirectToMico = (router: UseIonRouterResult) => {
  const pathname = router.routeInfo.pathname.replace(
    "/app/hub/discover",
    "/app/mico"
  )

  router.push(pathname, "root", "replace")
}

const AppRouter: React.FC = () => {
  const router = useIonRouter()
  const query = useRouterQueryParams()

  const { isSessionActive, isInitialized } = useAuthenticatedClientContext()

  const [redirectionState, setRedirectionState] = useRedirectionState({})

  useEffect(() => {
    if (!isInitialized) return
    if (!isInProtectedRoute(router)) return

    if (!isSessionActive) {
      if (router.routeInfo.pathname.includes("/app/hub/discover")) {
        redirectToMico(router)
      } else {
        redirectToAuth(router, query)
      }
    }
  }, [router.routeInfo.pathname, query, isInitialized, isSessionActive])

  useEffect(() => {
    if (!isInitialized) return
    if (!isInProtectedRoute(router)) return

    if (redirectionState.shouldRedirect) {
      setRedirectionState({
        shouldRedirect: false,
        to: undefined,
        search: undefined,
      })
    }
  }, [redirectionState, router.routeInfo.pathname])

  return (
    <>
      <Switch>
        <Route
          exact
          path="/app/agenda/items/:uuid/session"
          component={AgendaItemSessionPage}
        />

        <Route
          exact
          path="/app/adventure/kickoff"
          component={AdventureKickOffPage}
        />

        <Route
          exact
          path="/app/profile/activities"
          component={FavoriteActivitySelectionPage}
        />

        <Route
          exact
          path="/app/profile/elements"
          component={ElementSelectionPage}
        />

        <Route path="/app/welcome">
          <WelcomeRouter />
        </Route>

        <Route path="/app/hub">
          <HubRouter />
        </Route>

        <Route path="/app/mico">
          <MicoRouter />
        </Route>

        <Route>
          <Redirect to="/app/hub/home" />
        </Route>
      </Switch>

      <FeedbackListener />
    </>
  )
}

export default AppRouter
