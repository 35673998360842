import { SqualoAdventureElement } from "../../generated/graphql"
import { WELCOME } from "../constants"

export default {
  [WELCOME.ELEMENTS]: {
    TITLE: "Quels éléments correspondent le mieux à tes objectifs ?",
    DESCRIPTION:
      "Choisis l'élément qui te correspond le mieux à ton contexte et tes objectifs.",
    CONTINUE: "Continuer",
    SKIP: "Passer pour l'instant",
    ELEMENTS: {
      [SqualoAdventureElement.Fire]: {
        TITLE: "Cardio, énergie et puissance",
        DESCRIPTION:
          "Il est idéal pour les sports de combat, les sports de force et les sports de vitesse.",
      },
      [SqualoAdventureElement.Water]: {
        TITLE: "Souplesse et gênes physiques",
        DESCRIPTION:
          "Il est idéal pour ceux qui veulent améliorer leur posture, leur équilibre ou des gênes physiques spécifiques.",
      },
      [SqualoAdventureElement.Earth]: {
        TITLE: "Perte de graisse et tonification",
        DESCRIPTION:
          "Il est idéal pour ceux qui veulent perdre de la graisse, tonifier leur corps ou se sentir mieux dans leur peau.",
      },
      [SqualoAdventureElement.Air]: {
        TITLE: "Régularité et bien-être mental",
        DESCRIPTION:
          "Il est idéal pour ceux qui veulent se concentrer sur la création d'habitudes durables pour mieux se sentir dans leur tête et leur esprit.",
      },
    },
  },
  [WELCOME.ACTIVITIES]: {
    TITLE: "Quels sont tes activités préférées ?",
    DESCRIPTION:
      "Choisis les activités que tu pratiques régulièrement (au moins une fois par semaine).",
    LATEST: {
      TITLE: "As-tu fait une séance récemment ?",
      CTA: {
        LOG: "Inscrire une séance",
        SKIP: "Pas de séances récentes",
      },
    },
    NEXT: {
      TITLE: "Quelle est ta prochaine séance ?",
      CTA: {
        SKIP: "Pas de séances prévues",
        RECOMMEND: "Demander à MoveAI",
        PLAN: "Planifier une séance",
      },
    },
    BUTTONS: {
      ADD_ACTIVITY: "Ajouter une activité",
      MODIFY_ACTIVITY: "Modifier l'activité",
      CLEAR: {
        CONFIRMATION: "Es-tu sûr de vouloir supprimer cette activité ?",
      },
    },
    HINTS: {
      FREQUENCY: "par semaine",
      WEEKDAYS: "D'habitude, le {{ weekdays }}",
    },
  },
  [WELCOME.GET_THE_APP]: {
    TITLE: "Télécharge l'application",
    DESCRIPTION:
      "📱Si tu veux bénéficier d'une meilleure expérience, télécharge l'application mobile.<br />  💻  Tu pourras toujours accéder à ton compte sur ton ordinateur via le site web app.behale.io.",
    QR_CODE: "Scan ce QR Code pour télécharger l’appli",
    AGENDA_BUTTON: "Commencer mon parcours sans l'appli",
    COMING_SOON: "Bientôt disponible",
  },
}
