import { isNil } from "lodash"
import { parseISOTime, useLocaleContext } from "../../contexts/LocaleContext"
import {
  MovementAgendaItemSummaryFragment,
  SessionExpectedDuration,
  Weekday,
} from "../../generated/graphql"
import {
  formatBodyTree,
  formatSessionDuration,
  formatWeekday,
} from "../../utils/format"
import {
  convertExpectedDurationToExactDuration,
  dateFromTime,
  timeFromDate,
} from "../../contexts/AgendaSchedulingContext/agendaTimeUtils"
import { addHours, addMinutes } from "date-fns"
import clsx from "clsx"
import { DurationIcon, TargetBodyPartIcon } from "../Core/Icons"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"

export const MovementExtraInformationDetails: React.FC<{
  item: MovementAgendaItemSummaryFragment
  isTargetBodyPartAvailable?: boolean
  className?: string
}> = ({ item, isTargetBodyPartAvailable, className }) => {
  return (
    <div
      className={clsx(
        "flex flex-row items-center justify-start w-full border-white/50 border-t-[0.55px] px-4 py-2 gap-x-4",
        className
      )}
    >
      <MovementInformationDurationSpan duration={item.expectedDuration} />
      <MovementInformationTargetBodyPartsSpan
        item={item}
        isTargetBodyPartAvailable={isTargetBodyPartAvailable}
      />
    </div>
  )
}

export const MovementInformationDateSpan: React.FC<{
  item: {
    instanceDate?: string | null
    weekday?: Weekday | null
  }
  className?: string
}> = ({ item, className }) => {
  const { today } = useAgendaSchedulingContext()
  const { formatRelativeDate, parseISODate } = useLocaleContext()

  let label = ""

  if (isNil(item.instanceDate)) {
    label = formatWeekday(item.weekday)
  } else {
    const date = parseISODate(item.instanceDate)

    if (!isNil(date)) {
      label = formatRelativeDate(date, today.date)
    }
  }

  return <span className={clsx("font-semibold", className)}>{label}</span>
}

export const MovementInformationTimingSpan: React.FC<{
  item: {
    plannedStartTime?: string | null
    expectedDuration?: SessionExpectedDuration
    duration?: number | null
  }
  className?: string
}> = ({ item, className }) => {
  const { formatTime } = useLocaleContext()

  const duration = item.duration || item.expectedDuration

  const getSessionTiming = (item: MovementAgendaItemSummaryFragment) => {
    const startTime = parseISOTime(item.plannedStartTime)

    if (isNil(startTime)) {
      return formatSessionDuration(duration)
    }

    const startDate = dateFromTime(startTime)

    const exactDuration = convertExpectedDurationToExactDuration(
      item.expectedDuration
    )

    const endDate = addHours(
      addMinutes(startDate, exactDuration.minutes),
      exactDuration.hours
    )

    const endTime = timeFromDate(endDate)

    return `${formatTime(startTime, "HH:mm")} - ${formatTime(endTime, "HH:mm")}`
  }

  const timingLabel = getSessionTiming(
    item as MovementAgendaItemSummaryFragment
  )

  return <span className={clsx("font-semibold", className)}>{timingLabel}</span>
}

export const MovementInformationTargetBodyPartsSpan: React.FC<{
  item: Pick<MovementAgendaItemSummaryFragment, "movementTargetBodyParts">
  isTargetBodyPartAvailable?: boolean
  className?: string
}> = ({ item, isTargetBodyPartAvailable = false, className }) => {
  const hasTargetBodyParts =
    !isNil(item.movementTargetBodyParts) &&
    (item.movementTargetBodyParts.isFullBody ||
      item.movementTargetBodyParts.regions.length > 0)

  if (!isTargetBodyPartAvailable || !hasTargetBodyParts) {
    return <></>
  }

  return (
    <div
      className={clsx(
        "flex flex-row items-center gap-x-1 font-semibold flex-shrink-0 truncate",
        className
      )}
    >
      <TargetBodyPartIcon />
      <span className="w-full truncate">
        {formatBodyTree(item.movementTargetBodyParts)}
      </span>
    </div>
  )
}

export const MovementInformationDurationSpan: React.FC<{
  duration: number | SessionExpectedDuration | null | undefined
  className?: string
}> = ({ duration, className }) => {
  if (isNil(duration)) {
    return <></>
  }

  return (
    <div
      className={clsx(
        "flex flex-row items-center gap-x-1 font-semibold flex-shrink-0",
        className
      )}
    >
      <DurationIcon />
      <span>{formatSessionDuration(duration)}</span>
    </div>
  )
}
