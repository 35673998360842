import classNames from "clsx"
import { useContext, useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"
import isNil from "lodash/isNil"

import Button from "../Forms/Button"
import StorySetIcon, { StorySetIconType } from "../StorySetIcon"
import ConnectGoogleCalendarButton from "../Auth/Google/ConnectGoogleCalendarButton"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { AccountsIdentityProvider } from "../../generated/graphql"
import { MEMBERSHIP, NAME_SPACES } from "../../locales/constants"
import Content from "../Core/Content"
import useToast from "../../hooks/useToast"
import Modal, { ModalContext } from "../Core/Modal"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import usePersistedFlag from "../../hooks/usePersistedFlag"

const ConnectCalendarModalBody: React.FC = () => {
  const { name } = useContext(ModalContext)

  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)
  const CALENDAR = t(MEMBERSHIP.CALENDAR, { returnObjects: true })

  const { showSuccess } = useToast()

  const { agenda } = useAgendaSchedulingContext()
  const { captureEvent } = useAnalyticsContext()
  const { closeModal } = useModalOrchestrationContext()

  const handleCompleted = () => {
    captureEvent(AnalyticsEvent.UserConnectedExternalCalendar, {
      provider: AccountsIdentityProvider.Google,
    })

    showSuccess(CALENDAR.SUCCESS_MESSAGE)

    closeModal(name)
  }

  const handleSkip = () => {
    captureEvent(AnalyticsEvent.UserSkippedExternalCalendarConnection)

    closeModal(name)
  }

  // skip if user already has connected to calendar
  useEffect(() => {
    if (isNil(agenda)) {
      return
    }

    if (agenda.schedulingCalendarConnected) {
      closeModal(name)
    }
  }, [agenda])

  return (
    <Content
      color="primary"
      className="relative flex flex-col items-center justify-center h-full"
      scrollY={false}
    >
      <div
        className={classNames(
          "flex flex-col items-center justify-between max-w-3xl h-full mx-auto gap-y-4 py-4 px-4"
        )}
      >
        <div className="flex flex-col items-center justify-between h-full gap-y-4">
          <div className="flex flex-col my-4 gap-y-2">
            <span className="w-full text-3xl font-semibold text-center">
              {CALENDAR.TITLE}
            </span>

            <span className="w-full font-medium text-center w sm:text-lg">
              <Trans i18nKey={"CALENDAR.DESCRIPTION"} t={t} />
            </span>
          </div>

          <div className="flex flex-col items-center justify-center flex-grow w-full max-w-xl mx-auto -mt-4 md:p-4">
            <StorySetIcon type={StorySetIconType.Schedule} />
          </div>

          <div className="flex flex-col items-center justify-center w-full pb-safe">
            <Button
              fill="outline"
              className="w-full opacity-75"
              expand="block"
              color="white"
              size="large"
              iconSlot="start"
              onClick={handleSkip}
              label={CALENDAR.SKIP}
            />

            <ConnectGoogleCalendarButton
              onCompleted={handleCompleted}
              className="w-full"
              size="large"
            />
          </div>
        </div>
      </div>
    </Content>
  )
}

export const ConnectCalendarModal: React.FC = () => {
  const { dirty: markCalendarConnectionModalAsShown } = usePersistedFlag(
    "hasShownCalendarConnectionModal"
  )

  return (
    <Modal
      name={ModalOrchestrationName.CalendarConnection}
      isSheet
      background="primary-500"
      onDidPresent={() => {
        markCalendarConnectionModalAsShown()
      }}
    >
      <Modal.Body>
        {(props: any) => <ConnectCalendarModalBody {...props} />}
      </Modal.Body>
    </Modal>
  )
}

export default ConnectCalendarModalBody
