import { useQuery } from "@apollo/client"
import { get, isNil } from "lodash"
import { useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ModalOrchestrationName } from "../../../../contexts/ModalOrchestrationContext"

import {
  AssessmentRatingOfPerceivedExertion,
  GetStyleDocument,
  GetStyleQuery,
  MovementModality,
} from "../../../../generated/graphql"
import { MOVEMENT, NAME_SPACES } from "../../../../locales/constants"
import { rpeOrder } from "../../../../utils"
import { formatRelativeRPE } from "../../../../utils/format"
import {
  FieldModalBodyProps,
  GridFormField,
  GridFormFieldProps,
} from "../../../Forms/GridFormField"
import { Slider, SliderProps } from "../../../Forms/Slider"
import { IntensityIcon } from "../../../Core/Icons"
import { useMemo } from "react"

export type RPESliderState =
  | AssessmentRatingOfPerceivedExertion
  | null
  | undefined

export type RPESliderProps = SliderProps<RPESliderState> &
  FieldModalBodyProps<RPESliderState>

export const RPESlider: React.FC<RPESliderProps> = ({ state, setState }) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)

  const TEXT = t(MOVEMENT.RPE, { returnObjects: true })

  const getEmoji = (rpe: RPESliderState) => {
    return get(TEXT, `${rpe}.EMOJI`, "")
  }

  const getLabel = (rpe: RPESliderState) => {
    return get(TEXT, `${rpe}.LABEL`, "")
  }

  const getDescription = (rpe: RPESliderState) => {
    return get(TEXT, `${rpe}.DESCRIPTION`, "")
  }

  const handleChange = (rpe: RPESliderState) => {
    if (isNil(rpe) || rpe.length === 0) {
      setState(undefined)
    }

    setState(rpe)
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <Slider<RPESliderState>
        onChange={handleChange}
        label={getLabel(state)}
        description={getDescription(state)}
        values={rpeOrder}
        formatValue={getEmoji}
        initialValue={state || AssessmentRatingOfPerceivedExertion.SomewhatEasy}
      />
    </div>
  )
}

export interface RPEGridFormFieldProps
  extends Omit<
    GridFormFieldProps<RPESliderState>,
    "Body" | "label" | "modalName"
  > {
  name: string
}

export const isRPEAvailable = (modality: MovementModality | undefined) => {
  switch (modality) {
    case MovementModality.Cardio:
    case MovementModality.Sport:
    case MovementModality.Strength:
      return true

    default:
      return false
  }
}

export const RPEGridFormField: React.FC<RPEGridFormFieldProps> = ({
  name,
  ...props
}) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)

  const { RPE }: any = t(MOVEMENT.FORM, {
    returnObjects: true,
  })

  const movementStyle = useWatch({ name: "movementStyle" })

  const { data } = useQuery<GetStyleQuery>(GetStyleDocument, {
    variables: { style: movementStyle },
    skip: isNil(movementStyle),
  })

  const isAvailable = useMemo(() => {
    return isRPEAvailable(get(data, "activityStyle.modality"))
  }, [movementStyle, data])

  const Body = RPESlider

  return (
    <GridFormField<RPESliderState>
      name={name}
      modalName={ModalOrchestrationName.FormFieldRPESlider}
      label={RPE.LABEL}
      title={RPE.TITLE}
      Body={Body}
      Icon={IntensityIcon}
      formatValue={formatRelativeRPE}
      hidden={!isAvailable}
      showDismiss
      {...props}
    />
  )
}
