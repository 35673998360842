import classNames from "clsx"
import { arrowDownOutline, arrowUpOutline } from "ionicons/icons"
import { isNil, take } from "lodash"
import React, { useEffect, useRef } from "react"
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableStateSnapshot,
} from "react-beautiful-dnd"

import {
  AgendaDay,
  AgendaDaySchedule,
  useAgendaSchedulingContext,
} from "../../../contexts/AgendaSchedulingContext"
import {
  MovementAgendaItemSummaryFragment,
  Weekday,
} from "../../../generated/graphql"
import Button from "../../Forms/Button"
import { AgendaTimelineDayHeader } from "./AgendaTimelineDayHeader"
import { AgendaTimelineDayItem } from "./AgendaTimelineDayItem"
import { AgendaTimelineDaySession } from "./AgendaTimelineDaySession"
import { AgendaTimelineRestDaySlot } from "./AgendaTimelineRestDaySlot"
import { BaseWeekdayComponent } from "./BaseWeekdayComponent"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"

type AgendaScheduleWeekdayProps = {
  weekday: Weekday
  weekdaySchedule: AgendaDaySchedule
  handleAddClick: (day: AgendaDay) => void
  snapshot?: DroppableStateSnapshot
  placeholder?: any
}

export const AgendaTimelineDay: React.FC<AgendaScheduleWeekdayProps> = ({
  weekday,
  weekdaySchedule,
  handleAddClick,
}) => {
  const {
    isToday,
    selectedWeekday,
    isSelectedWeekStale: selectedWeekLoading,
    selectedWeekUuid,
    getDay,
    isPast,
    isFuture,
  } = useAgendaSchedulingContext()

  const { openModal } = useModalOrchestrationContext()

  const day = getDay(weekday)

  const latestSessionCutoff = 3
  const [showAllSessions, setShowAllSessions] = React.useState(false)

  const maybeHandleSuggestionClick = (
    item: MovementAgendaItemSummaryFragment
  ) => {
    openModal(ModalOrchestrationName.AgendaSuggestionModal, {
      agendaItemFragment: item,
    })
  }

  // if week is in the past, don't show any items
  const nItems = weekdaySchedule.items.length

  const sessions = showAllSessions
    ? weekdaySchedule.sessions
    : take(weekdaySchedule.sessions, latestSessionCutoff)

  const nSessions = weekdaySchedule.sessions.length

  const baseIndex = weekdaySchedule.weekScheduleOffset

  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isNil(scrollRef.current)) return

    if (selectedWeekLoading) return

    if (selectedWeekday === weekday) {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({
          block: "center",
          // behavior: "smooth",
        })
      }, 50)
    }
  }, [selectedWeekLoading, selectedWeekday, scrollRef, selectedWeekUuid])

  return (
    <div
      className={classNames(
        "flex flex-col w-full select-none",
        isPast(day) && !isToday(day) && "bg-neutral-200/75",
        (isFuture(day) || isToday(day)) && "bg-neutral-100"
      )}
      ref={scrollRef}
    >
      <div
        className={classNames(
          "flex flex-col w-full h-full p-2",
          selectedWeekday === weekday &&
            "border-y-4 border-primary-500 bg-primary/5"
        )}
      >
        <Draggable
          draggableId={"header:" + weekday}
          index={baseIndex}
          isDragDisabled
        >
          {(provided) => (
            <AgendaTimelineDayHeader
              ref={provided.innerRef as any}
              weekday={weekday}
              nSessionsCompleted={nSessions}
              handleAddClick={handleAddClick}
            />
          )}
        </Draggable>

        <div className={classNames("flex flex-col px-2 py-2 w-full gap-y-2")}>
          <div className="flex flex-col divide-y divide-neutral/20">
            {nItems > 0 && (
              <div
                className="flex flex-col justify-center pb-2 gap-y-2"
                style={{ minHeight: nItems * 47 + "px" }}
              >
                {weekdaySchedule.items.map((item, index) => (
                  <Draggable
                    draggableId={item.uuid}
                    index={baseIndex + 1 + index}
                    key={item.uuid}
                  >
                    {(
                      provided: DraggableProvided,
                      snapshot: DraggableStateSnapshot
                    ) => (
                      <AgendaTimelineDayItem
                        provided={provided}
                        snapshot={snapshot}
                        item={item}
                        onClick={() => maybeHandleSuggestionClick(item)}
                      />
                    )}
                  </Draggable>
                ))}
              </div>
            )}

            {nSessions > 0 && (
              <div className="flex flex-col pt-2 gap-y-2">
                {sessions.map((session, index) => (
                  <Draggable
                    draggableId={session.uuid}
                    index={baseIndex + 1 + nItems + index}
                    key={session.uuid}
                    isDragDisabled
                  >
                    {(
                      provided: DraggableProvided,
                      snapshot: DraggableStateSnapshot
                    ) => (
                      <AgendaTimelineDaySession
                        provided={provided}
                        snapshot={snapshot}
                        session={session}
                      />
                    )}
                  </Draggable>
                ))}
                {nSessions > latestSessionCutoff && (
                  <BaseWeekdayComponent
                    className="flex flex-row items-center justify-center bg-white shadow-sm cursor-pointer opacity-70"
                    onClick={() => setShowAllSessions(!showAllSessions)}
                  >
                    <Button
                      // label={
                      //   showAllSessions
                      //     ? "Voir moins de séances complétées"
                      //     : "Voir toutes les séances complétées"
                      // }
                      iconSlot="end"
                      fill="clear"
                      icon={showAllSessions ? arrowUpOutline : arrowDownOutline}
                    />
                  </BaseWeekdayComponent>
                )}
              </div>
            )}
          </div>

          {nSessions == 0 && nItems == 0 && (
            <AgendaTimelineRestDaySlot
              day={weekdaySchedule.day}
              baseIndex={baseIndex}
              handleAddClick={handleAddClick}
              isPast={isPast(day) && !isToday(day)}
            />
          )}

          <Draggable
            draggableId={"placeholder:" + weekday}
            index={baseIndex + Math.max(nItems + nSessions, 1) + 1}
            isDragDisabled
          >
            {(provided) => <div className="h-2" ref={provided.innerRef}></div>}
          </Draggable>
        </div>
      </div>
    </div>
  )
}
