import { useLazyQuery } from "@apollo/client"
import { useIonRouter } from "@ionic/react"
import isNil from "lodash/isNil"
import { useCallback, useEffect } from "react"
import { RouteComponentProps } from "react-router"
import { AdventureTemplateContent } from "../../components/Adventure/AdventureTemplateContent"
import AdventurePreparationModal from "../../components/Adventure/Modals/AdventurePreparationModal"
import Content from "../../components/Core/Content"
import Page from "../../components/Core/Page"
import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"
import { useLocaleContext } from "../../contexts/LocaleContext"
import { useModalOrchestrationContext } from "../../contexts/ModalOrchestrationContext"
import {
  GetAdventureTemplateDocument,
  GetAdventureTemplateQuery,
  GetAdventureTemplateQueryVariables,
  Language,
} from "../../generated/graphql"
import useToast from "../../hooks/useToast"
import { buildURL } from "../../utils"
import { AdventureActivityModal } from "../../components/Adventure/Modals/AdventureActivityModal"
import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { NAME_SPACES } from "../../locales/constants"

export type AdventureTemplatePageProps = RouteComponentProps<
  { uuid: string },
  any,
  any
>

const AdventureTemplatePage: React.FC<AdventureTemplatePageProps> = ({
  match,
}) => {
  const router = useIonRouter()

  const { t } = useTranslation(NAME_SPACES.ADVENTURE)

  const { showError } = useToast()
  const { isInitialized } = useAuthenticatedClientContext()
  const { toggleLoading } = useModalOrchestrationContext()
  const { language, isInitialized: isLanguageReady } = useLocaleContext()

  const [getAdventureTemplate, { called, loading, data }] = useLazyQuery<
    GetAdventureTemplateQuery,
    GetAdventureTemplateQueryVariables
  >(GetAdventureTemplateDocument, {
    fetchPolicy: "cache-first",
  })

  useEffect(() => {
    if (!isInitialized || !isLanguageReady) return

    if (isNil(match.params.uuid)) {
      router.goBack()
    }

    if (called) return

    getAdventureTemplate({
      variables: {
        locale: language as unknown as Language,
        id: match.params.uuid,
      },
      onCompleted: (data) => {
        if (isNil(data.getAdventureTemplate)) {
          router.goBack()
        }
      },
      onError: (error) => {
        showError(error.message)

        router.goBack()
      },
    })
  }, [match, called, isInitialized, isLanguageReady, language])

  const handlePreparationClose = useCallback(
    (reason?: string) => {
      if (reason === "started") {
        setTimeout(() => {
          router.push("/app/adventure/kickoff")
        }, 1000)
      }
    },
    [router]
  )

  useEffect(() => {
    toggleLoading(loading, {
      background: "primary",
    })
  }, [loading])

  const adventureTemplate = data?.getAdventureTemplate

  const sharePayload = {
    title: adventureTemplate?.name,
    text: adventureTemplate?.description,
    url: buildURL(`/app/mico/adventures/${adventureTemplate?.id}`),
  }

  return (
    <Page>
      <Page.Header
        color={adventureTemplate?.element || "primary"}
        showBackButton
        showShareButton
        sharePayload={sharePayload}
        backButtonRoot="/app/mico/adventures"
      ></Page.Header>

      <Helmet>
        <title>
          {t("TEMPLATE.SEO.TITLE", { name: adventureTemplate?.name })}
        </title>
        <meta name="description" content={adventureTemplate?.description} />

        <meta
          property="og:title"
          content={t("TEMPLATE.SEO.TITLE", { name: adventureTemplate?.name })}
        />

        <meta
          property="og:description"
          content={adventureTemplate?.description}
        />

        {!isNil(adventureTemplate?.imageUrl) && (
          <meta property="og:image" content={adventureTemplate?.imageUrl} />
        )}
      </Helmet>

      <Content color={adventureTemplate?.element || "primary"}>
        {!isNil(adventureTemplate) && (
          <AdventureTemplateContent adventureTemplate={adventureTemplate} />
        )}

        <AdventurePreparationModal onClose={handlePreparationClose} />
        <AdventureActivityModal />
      </Content>
    </Page>
  )
}

export default AdventureTemplatePage
