import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react"
import clsx from "clsx"
import { chevronBackOutline } from "ionicons/icons"
import isNil from "lodash/isNil"
import React, { forwardRef, useCallback } from "react"
import Button, { ButtonProps } from "../Forms/Button"
import ShareButton, { ShareButtonProps } from "./ShareButton"

export interface PageModal {
  key: string
  Component: React.FC<any>
  onClose?: () => void
  props?: any
}

export interface PageProps extends React.ComponentProps<typeof IonPage> {
  showBackButton?: boolean
  backButtonRoot?: string
  backButtonOverride?: string
  backButtonMethod?: "pop" | "replace"
}

const Page = ({
  children,
  showBackButton = false,
  backButtonRoot = "/",
  backButtonOverride,
  backButtonMethod = "pop",
  ...props
}: PageProps) => {
  const router = useIonRouter()

  const handleBackButton = useCallback(() => {
    if (backButtonOverride) {
      router.push(backButtonOverride, "back", backButtonMethod)

      return
    }

    if (router.canGoBack()) {
      router.goBack()
    } else {
      router.push(backButtonRoot, "root", backButtonMethod)
    }
  }, [backButtonOverride, backButtonRoot])

  return (
    <IonPage {...props}>
      {showBackButton ? (
        <IonHeader>
          <IonToolbar color={"primary"}>
            <IonButtons slot="start" color="white">
              <IonButton onClick={handleBackButton} color="white">
                <IonIcon slot="icon-only" icon={chevronBackOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      ) : null}
      <>{children}</>
    </IonPage>
  )
}

export interface PageHeaderProps
  extends React.ComponentProps<typeof IonHeader> {
  title?: string
  subtitle?: string
  className?: string
  showBackButton?: boolean
  backButtonRoot?: string
  backButtonOverride?: string
  sharePayload?: ShareButtonProps
  showShareButton?: boolean
  actions?: ButtonProps[]
  toolbarButtons?: ButtonProps[]
}

Page.Header = forwardRef(function PageHeader(
  {
    title,
    subtitle,
    color,
    className,
    showBackButton,
    sharePayload,
    showShareButton,
    backButtonRoot = "/",
    backButtonOverride,
    toolbarButtons,
    children,
    ...props
  }: PageHeaderProps,
  ref
) {
  const router = useIonRouter()

  const handleBackButton = () => {
    if (backButtonOverride) {
      router.push(backButtonOverride, "back")
      return
    }

    if (router.canGoBack()) {
      router.goBack()
    } else {
      router.push(backButtonRoot, "root", "pop")
    }
  }

  const showTitle = !isNil(title)

  const startButtons = toolbarButtons?.filter((b) => b.slot === "start") || []
  const endButtons =
    toolbarButtons?.filter((b) => b.slot === "end" || !b.slot) || []

  const Title = () => (
    <IonTitle className="px-2">
      <div
        className={clsx(
          "flex flex-row items-baseline justify-center gap-x-1",
          "text-left"
        )}
      >
        <h1 className="text-xl font-medium tracking-tight">{title}</h1>
        {subtitle && (
          <h2 className="text-xl tracking-tight font-semilight opacity-80">
            {subtitle}
          </h2>
        )}
      </div>
    </IonTitle>
  )

  return (
    <IonHeader
      ref={ref as any}
      className={clsx("w-full", `bg-${color}`, className)}
      color={color}
      {...props}
    >
      <IonToolbar
        className={clsx("items-center justify-between select-none mx-auto")}
        color={color}
      >
        {showTitle && <Title />}
        <IonButtons slot="start">
          {showBackButton && (
            <IonButton onClick={handleBackButton}>
              <IonIcon slot="icon-only" icon={chevronBackOutline} />
            </IonButton>
          )}

          {startButtons.map((button, index) => (
            <Button key={index} iconSlot="icon-only" {...button} />
          ))}
        </IonButtons>
        <IonButtons slot="end">
          {endButtons?.map((button, index) => (
            <Button key={index} iconSlot="icon-only" {...button} />
          ))}
          {showShareButton && sharePayload && <ShareButton {...sharePayload} />}
        </IonButtons>
      </IonToolbar>

      {children}
    </IonHeader>
  )
})

export default Page
