import clsx from "clsx"
import React from "react"
import { useTranslation } from "react-i18next"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import { NAME_SPACES } from "../../locales/constants"
import Button from "../Forms/Button"
import { RiArrowLeftSLine, RiEyeFill, RiLockUnlockLine } from "react-icons/ri"
import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"

export const AgendaHomeConversionHook: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)

  const { openModal } = useModalOrchestrationContext()

  const { currentMember } = useAuthenticatedClientContext()
  const { selectPreviousWeek } = useAgendaSchedulingContext()

  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-center w-full h-full text-center text-neutral-700",
        "p-4",
        "absolute inset-0",
        "backdrop backdrop-blur z-[10000]"
      )}
    >
      <Button
        className="absolute left-0 top-2 text-neutral"
        iconClassName="h-10 w-10"
        fill="clear"
        icon={RiArrowLeftSLine}
        onClick={() => selectPreviousWeek()}
      />
      <RiEyeFill className="w-28 h-28 text-neutral-500" />
      <span className="mt-4 text-2xl font-semibold">
        {t("AGENDA_HOME.CONVERSION_HOOK.TITLE", {
          firstName: currentMember?.firstName || "Behaler",
        })}
      </span>

      <span className="mt-2 font-medium">
        {t("AGENDA_HOME.CONVERSION_HOOK.DESCRIPTION")}
      </span>

      <Button
        className="mt-4"
        onClick={() => openModal(ModalOrchestrationName.GetPremium)}
        icon={RiLockUnlockLine}
        color="CARDIO"
        iconSlot="start"
        iconClassName="mr-1"
        label={t("AGENDA_HOME.CONVERSION_HOOK.CTA")}
      />
    </div>
  )
}
