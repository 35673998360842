import React, { useCallback, useEffect, useMemo } from "react"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../../contexts/AnalyticsContext"
import { useAgendaSchedulingContext } from "../../../contexts/AgendaSchedulingContext"
import { clsx } from "clsx"
import { formatSessionDuration, formatStyle } from "../../../utils/format"
import { IonCheckbox } from "@ionic/react"
import { useLocaleContext } from "../../../contexts/LocaleContext"
import {
  useModalOrchestrationContext,
  ModalOrchestrationName,
} from "../../../contexts/ModalOrchestrationContext"
import {
  LogMovementSessionMutationVariables,
  MovementAgendaItemSummaryFragment,
  MovementSessionSummaryFragment,
  MovementActivitySummaryFragment,
  BodyTree,
} from "../../../generated/graphql"
import {
  getModalityTextColor,
  isBodyTreeEmpty,
} from "../../../utils/movementUtils"
import { flattenBodyTree } from "../../../utils/body"
import { isNil } from "lodash"
import { TargetBodyPartIcon } from "../../Core/Icons"
import useToast from "../../../hooks/useToast"
import { convertApproximateDurationToSeconds } from "../../../utils/duration"

export interface CoreHabitCardProps {
  index?: number
  activity:
    | MovementActivitySummaryFragment
    | MovementAgendaItemSummaryFragment
    | MovementSessionSummaryFragment
}

export const TodayActivityCard: React.FC<CoreHabitCardProps> = ({
  activity,
}) => {
  const { formatDate } = useLocaleContext()
  const { captureEvent } = useAnalyticsContext()
  const { openModal } = useModalOrchestrationContext()
  const { logSession, deleteSession, selectedDay, isFuture } =
    useAgendaSchedulingContext()

  const { showWarning } = useToast()

  const title = activity.title || formatStyle(activity.movementStyle)

  const isSession = activity.__typename === "MovementSession"
  const isAgendaItem = activity.__typename === "MovementAgendaItem"
  const isActivity = activity.__typename === "MovementActivity"

  const [checked, setChecked] = React.useState(isSession || false)

  const duration = React.useMemo(
    () =>
      (activity as MovementActivitySummaryFragment).usualDuration ||
      (activity as any).expectedDuration ||
      (activity as any).reportedDuration,
    [activity]
  )

  const hasTargetBodyParts = useMemo(() => {
    if (isActivity) {
      return (
        Array.isArray(activity.movementTargetBodyParts) &&
        activity.movementTargetBodyParts.length > 0
      )
    } else {
      return !isBodyTreeEmpty(activity.movementTargetBodyParts as BodyTree)
    }
  }, [activity])

  const routerLink = useMemo(() => {
    if (isSession) {
      return `/app/hub/agenda/sessions/${activity.uuid}`
    }

    if (isAgendaItem) {
      return `/app/hub/agenda/items/${activity.uuid}`
    }

    return "/app/profile/activities"
  }, [activity])

  const handleCardPressed = useCallback(async () => {
    captureEvent(AnalyticsEvent.TodayActivityPressed, {
      uuid: activity.uuid,
    })

    openModal(ModalOrchestrationName.TodayActivityPreview, {
      activity,
    })
  }, [isSession, routerLink, openModal])

  const handleUnchecked = async () => {
    await deleteSession({ uuid: activity.uuid })
  }

  const handleChecked = useCallback(async () => {
    const reportedDate = formatDate(selectedDay.date, "yyyy-MM-dd")

    const movementTargetBodyParts = Array.isArray(
      activity.movementTargetBodyParts
    )
      ? activity.movementTargetBodyParts
      : flattenBodyTree(activity.movementTargetBodyParts as BodyTree)

    const reportedDuration = convertApproximateDurationToSeconds(
      (activity as MovementActivitySummaryFragment).usualDuration ||
        (activity as MovementAgendaItemSummaryFragment).expectedDuration
    )

    const reportedPhaseOfDay =
      (activity as MovementAgendaItemSummaryFragment).plannedPhaseOfDay ||
      (activity as MovementActivitySummaryFragment).usualPhaseOfDay

    const movementAgendaItemUuid = isAgendaItem ? activity.uuid : undefined

    const variables = {
      movementAgendaItemUuid,
      reportedDate,
      reportedDuration,
      reportedPhaseOfDay,
      title: activity.title,
      movementTargetBodyParts,
      movementStyle: activity.movementStyle,
    } as LogMovementSessionMutationVariables

    await logSession(variables)
  }, [activity, selectedDay])

  const onCheckboxClicked = useCallback(async () => {
    if (isFuture(selectedDay)) {
      showWarning("You can't check future sessions")

      return
    }

    setChecked(!checked)
  }, [selectedDay])

  useEffect(() => {
    // if it's a session and it's already checked, don't do anything
    // if it's not a session and it's not checked, don't do anything
    if (isSession === checked) {
      return
    }

    if (checked) {
      captureEvent(AnalyticsEvent.TodayActivityChecked, {
        uuid: activity.uuid,
      })

      handleChecked()
    } else {
      captureEvent(AnalyticsEvent.TodayActivityUnchecked, {
        uuid: activity.uuid,
      })

      handleUnchecked()
    }
  }, [checked])

  useEffect(() => {
    setChecked(isSession)
  }, [isSession])

  return (
    <div
      className={clsx(
        "flex flex-row items-center justify-center w-full gap-y-1",
        "rounded-md",
        `bg-${activity.movementModality} border-${activity.movementModality}-700 border`,
        isAgendaItem && "shadow-xl",
        isActivity &&
          `border-dashed border-${activity.movementModality}-400 border-opacity-85 border-2 shadow-inner`,
        isSession && "shadow-inner opacity-75",
        getModalityTextColor(activity.movementModality)
      )}
    >
      <div className="flex flex-row items-center justify-between w-full h-full gap-2 px-3 py-4">
        <IonCheckbox
          mode="ios"
          slot="start"
          color={activity.movementModality}
          className="flex-shrink-0"
          onIonChange={onCheckboxClicked}
          checked={checked}
          disabled={isFuture(selectedDay)}
        />
        <div
          className="flex flex-row items-center justify-between w-full h-full gap-2 truncate cursor-pointer"
          onClick={handleCardPressed}
        >
          <div className="flex flex-row items-center justify-start w-full h-full gap-2 truncate">
            <span className="text-xl font-medium truncate select-auto">
              {title}
            </span>

            {hasTargetBodyParts && <TargetBodyPartIcon />}
          </div>

          {!isNil(duration) && (
            <span className="text-base font-medium truncate text-opacity-70 shrink-0">
              {formatSessionDuration(duration)}
            </span>
          )}
        </div>

        {/* {hasFollowAlong && (
          <Button
            icon={RiPlayLine}
            fill="clear"
            color={getModalityContrastColor(activity.movementModality)}
            size="small"
            paddingWidth="thin"
            onClick={handleStartSession}
          />
        )} */}

        {/* <div className="flex flex-col items-end gap-2">
              <Button
                icon={activity.isPrimary ? RiStarFill : RiStarLine}
                fill="clear"
                color={getModalityContrastColor(activityModality)}
                className={clsx("w-10 h-10 -px-1 -py-1", "hidden")}
                onClick={handleSetPrimary}
                paddingWidth="thin"
                iconSlot="icon-only"
              />
            </div> */}
      </div>
    </div>
  )
}
