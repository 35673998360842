import clsx from "clsx"
import { omit, get, isNil } from "lodash"
import { useState, useMemo, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { RiAddLine } from "react-icons/ri"
import { CardSelectOption } from "../../components/Forms/CardSelect"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import {
  MovementStyle,
  MovementActivityInput,
  Weekday,
  PhaseOfDay,
} from "../../generated/graphql"
import { NAME_SPACES, WELCOME } from "../../locales/constants"
import { formatModality } from "../../utils/format"
import { MovementStyleSelectModal } from "../../components/Movement/Forms/Fields/MovementStyleSelect"
import { FavoriteActivityCard } from "./FavoriteActivityCard"
import { weekdayOrder } from "../../utils"

export const FavoriteActivitySelect: React.FC<{
  primaryStyle: MovementStyle | null
  setPrimaryStyle: (primaryStyle: MovementStyle | null) => void
  favoriteActivities: MovementActivityInput[]
  setFavoriteActivities: (favoriteActivities: MovementActivityInput[]) => void
}> = ({
  favoriteActivities,
  setFavoriteActivities,
  primaryStyle,
  setPrimaryStyle,
}) => {
  const { t, i18n } = useTranslation(NAME_SPACES.WELCOME)
  const TEXT = t(WELCOME.ACTIVITIES, { returnObjects: true })

  const [state, setState] = useState<{
    [key in MovementStyle]?: MovementActivityInput
  }>({})

  const { captureEvent } = useAnalyticsContext()
  const { openModal } = useModalOrchestrationContext()

  const filterBy = (option: CardSelectOption<MovementStyle>) => {
    if (option.value === MovementStyle.Other) {
      return false
    }

    return true
  }

  const updateState = (
    style: MovementStyle,
    activity: Partial<MovementActivityInput>
  ) => {
    setState((state) => ({
      ...state,
      [style]: {
        ...get(state, style, {}),
        ...activity,
      },
    }))
  }

  const handleClearStyle = (style: MovementStyle) => {
    setState((state) => omit(state, style))
  }

  const handleSetPhaseOfDay = (
    style: MovementStyle,
    phaseOfDay: PhaseOfDay | undefined
  ) => {
    updateState(style, { usualPhaseOfDay: phaseOfDay })
  }

  const handleSetStyleFrequency = (style: MovementStyle, frequency: number) => {
    updateState(style, { usualWeeklyFrequency: frequency })
  }

  const handleSetActivityWeekdays = (
    style: MovementStyle,
    weekdays: Weekday[]
  ) => {
    const orderedWeekdays = weekdayOrder.filter((weekday) =>
      weekdays.includes(weekday)
    )

    setState((state) => ({
      ...state,
      [style]: {
        ...get(state, style, {}),
        usualWeekdays: orderedWeekdays,
      } as MovementActivityInput,
    }))
  }

  const handleAddActivity = () => {
    captureEvent(AnalyticsEvent.WelcomeFavoriteActivitiesAddActivityClicked)

    openModal(ModalOrchestrationName.FormFieldMovementStyleSelect, {
      state: Object.keys(state),
    })
  }

  const handleModalClose = (role?: string, data?: any) => {
    if (role === "submit") {
      if (!isNil(state[data as MovementStyle])) {
        return
      }

      const selectedStyles = data as MovementStyle[]

      setState((state) => ({
        ...state,
        ...selectedStyles.reduce(
          (acc, style) => ({
            ...acc,
            [style]: {
              usualWeeklyFrequency: get(
                state,
                [style, "usualWeeklyFrequency"],
                0
              ),
              usualWeekdays: get(state, [style, "usualWeekdays"], []),
            } as MovementActivityInput,
          }),
          {}
        ),
      }))
    }
  }

  // get list of activities from state ordered by frequency
  const orderedState = useMemo(() => {
    return Object.entries(state)
  }, [state])

  // update favorite activities when state changes
  useEffect(() => {
    setFavoriteActivities(
      orderedState.map(([style, config]) => ({
        ...config,
        movementStyle: style as MovementStyle,
      }))
    )
  }, [orderedState])

  // update state when favorite activities get loaded
  useEffect(() => {
    if (favoriteActivities.length > 0 && Object.keys(state).length === 0) {
      setState(
        favoriteActivities.reduce(
          (acc, activity) => ({
            ...acc,
            [activity.movementStyle]: activity,
          }),
          {}
        )
      )
    }
  }, [favoriteActivities])

  useEffect(() => {
    for (const [style, config] of orderedState) {
      if (config.usualWeeklyFrequency < (config.usualWeekdays?.length || 0)) {
        handleSetStyleFrequency(
          style as MovementStyle,
          Math.max(
            config.usualWeekdays?.length || 0,
            config.usualWeeklyFrequency
          )
        )
      }
    }
  }, [orderedState])

  return (
    <div className="flex flex-col w-full h-full p-4 overflow-y-auto gap-y-4">
      {orderedState.map(([style, config], index) => (
        <FavoriteActivityCard
          key={index}
          style={style as MovementStyle}
          config={config}
          isPrimary={primaryStyle === style}
          setPrimary={setPrimaryStyle}
          setFrequency={handleSetStyleFrequency}
          onClear={handleClearStyle}
          setWeekdays={handleSetActivityWeekdays}
          setPhaseOfDay={handleSetPhaseOfDay}
        />
      ))}

      <button
        className={clsx(
          "flex flex-row items-center justify-between w-full h-18 p-4",
          "rounded-md shadow-md bg-white/20"
        )}
        onClick={handleAddActivity}
      >
        <span className="text-xl font-medium">{TEXT.BUTTONS.ADD_ACTIVITY}</span>

        <RiAddLine className="text-3xl" />
      </button>

      <MovementStyleSelectModal
        onClose={handleModalClose}
        initialProps={{
          state: Object.keys(state),
          multi: true,
          showElements: true,
          recommend: "popular",
          recommendedLabel: i18n.t("COMMON:POPULAR"),
          filterBy,
          formatGroupLabel: formatModality,
        }}
      />
    </div>
  )
}
