export enum StaminaLevel {
  Inactive = "Inactive",
  Okay = "Okay",
  Good = "Good",
  Great = "Great",
  Excellent = "Excellent",
}

export const staminaLevelOrder = [
  StaminaLevel.Inactive,
  StaminaLevel.Okay,
  StaminaLevel.Good,
  StaminaLevel.Great,
]
