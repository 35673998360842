import { isNil } from "lodash"

export const formatDuration = (seconds: number | undefined) => {
  if (isNil(seconds)) return "0 seconds"

  if (seconds < 60) {
    return `${seconds} seconds`
  } else {
    const minutes = Math.floor(seconds / 60)
    return `${minutes} minutes`
  }
}

export const formatCountdown = (
  seconds: number | undefined,
  roundDown = false
) => {
  if (isNil(seconds)) return ""

  seconds = roundDown ? Math.floor(seconds) : Math.ceil(seconds)

  const minutes = Math.floor(seconds / 60)

  if (minutes > 0) {
    seconds = seconds % 60

    return `${minutes.toPrecision().padStart(2, "00")}:${seconds
      .toString()
      .padStart(2, "00")}`
  }

  return `${seconds}`
}
