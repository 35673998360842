import { useMutation } from "@apollo/client"
import { Capacitor } from "@capacitor/core"
import {
  ActionPerformed,
  LocalNotifications,
  LocalNotificationSchema,
  PermissionStatus,
  Schedule,
} from "@capacitor/local-notifications"
import { Badge } from "@capawesome/capacitor-badge"
import {
  PushNotifications,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications"
import isNil from "lodash/isNil"
import {
  createContext,
  Dispatch,
  useCallback,
  useEffect,
  useReducer,
} from "react"
import {
  RegisterPushNotificationDeviceDocument,
  RegisterPushNotificationDeviceMutation,
  RegisterPushNotificationDeviceMutationVariables,
} from "../../generated/graphql"
import { toIsoWeekday } from "../../utils"
import { AnalyticsEvent, useAnalyticsContext } from "../AnalyticsContext"
import { useAuthenticatedClientContext } from "../AuthenticatedClientContext"
import {
  ReminderProps,
  ReminderTemplate,
  NotificationContextState,
  NotificationContextActions,
  NotificationReducerActionType,
  ReminderState,
} from "./notificationTypes"
import { notificationReducer } from "./notificationReducer"
import { addBreadcrumb, captureMessage } from "@sentry/capacitor"
import {
  findRemindersByTemplate,
  loadPushNotificationToken,
  loadTemplatesAllowed,
  storePushNotificationToken,
  storeTemplatesAllowed,
} from "./notificationUtils"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../ModalOrchestrationContext"
import { isPast } from "date-fns"
import { useLocaleContext } from "../LocaleContext"

export const initialNotificationState = {
  maxNativeId: 0,
  activeNativeIds: new Set(),
  isEnabled: false,
  isInitialized: false,
  isPushNotificationRegistered: false,
  devicePushNotificationToken: null,
  isNativePermissionGranted: false,
  isNativePermissionRequested: false,
  templatesAllowed: [
    ReminderTemplate.WeekAheadPreview,
    ReminderTemplate.MorningReminder,
  ],
  hasShownNotificationPermissionModal: false,
  reminderKeyMap: {},
  reminders: {},
  badgeCount: 0,
} as NotificationContextState

export const NotificationContext = createContext<
  (NotificationContextState & NotificationContextActions) | undefined
>(undefined)

export const NotificationProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    notificationReducer,
    initialNotificationState
  ) as [
    NotificationContextState,
    Dispatch<{ type: NotificationReducerActionType; payload?: any }>
  ]

  const { captureEvent } = useAnalyticsContext()
  const { contact } = useLocaleContext()
  const { platform, currentUser } = useAuthenticatedClientContext()
  const { openModal } = useModalOrchestrationContext()

  const [
    mutateRegisterPushNotificationDevice,
    { called: registeredWithHiveCalled },
  ] = useMutation<
    RegisterPushNotificationDeviceMutation,
    RegisterPushNotificationDeviceMutationVariables
  >(RegisterPushNotificationDeviceDocument)

  const enableNotifications = () => {
    if (!state.isNativePermissionGranted) {
      openModal(ModalOrchestrationName.NotificationPermission, {
        onClose: (reason?: string) => {
          if (reason === "granted") {
            dispatchPermissionGranted()

            captureEvent(AnalyticsEvent.AllLocalNotificationsEnabled)

            dispatch({
              type: NotificationReducerActionType.LocalNotificationsEnabled,
            })
          }
        },
      })
    } else {
      captureEvent(AnalyticsEvent.AllLocalNotificationsEnabled)

      dispatch({
        type: NotificationReducerActionType.LocalNotificationsEnabled,
      })
    }
  }

  const disableNotifications = () => {
    captureEvent(AnalyticsEvent.AllLocalNotificationsDisabled)

    dispatch({
      type: NotificationReducerActionType.LocalNotificationsDisabled,
    })

    // disable all templates
    for (const template of Object.values(ReminderTemplate)) {
      disableTemplate(template)
    }
  }

  const enableTemplate = async (
    template: ReminderTemplate,
    onSuccess?: () => void
  ) => {
    // analytics
    captureEvent(AnalyticsEvent.LocalNotificationEnabled, {
      template,
    })

    dispatch({
      type: NotificationReducerActionType.TemplateEnabled,
      payload: { template },
    })

    onSuccess?.()
  }

  const isTemplateEnabled = useCallback(
    (template: ReminderTemplate) => {
      return state.templatesAllowed.includes(template)
    },
    [state.templatesAllowed]
  )

  const disableTemplate = async (
    template: ReminderTemplate,
    onSuccess?: () => void
  ) => {
    // analytics
    captureEvent(AnalyticsEvent.LocalNotificationDisabled, {
      template,
    })

    dispatch({
      type: NotificationReducerActionType.TemplateDisabled,
      payload: { template },
    })

    // cancel all reminders for this template
    const reminders = findRemindersByTemplate(state.reminders, template)

    for (const reminder of reminders) {
      cancelReminder(reminder.key)
    }

    onSuccess?.()
  }

  const isReminderDelivered = useCallback(
    (key: string) => {
      if (!state.isEnabled) return false

      const id = state.reminderKeyMap[key]

      if (isNil(id)) return false

      return state.reminders[id]?.status === "delivered"
    },
    [state.isEnabled, state.reminders]
  )

  const isReminderScheduled = useCallback(
    (key: string) => {
      if (!state.isEnabled) return false

      const id = state.reminderKeyMap[key]

      if (isNil(id)) return false

      return state.reminders[id]?.status === "scheduled"
    },
    [state.isEnabled, state.reminders]
  )

  const buildNativeSchedule = (schedule: ReminderProps["schedule"]) => {
    let nativeSchedule: Schedule

    if (isNil(schedule.date)) {
      if (isNil(schedule.weekday)) {
        throw new Error("scheduleReminder: weekday is required for recurring")
      }

      nativeSchedule = {
        on: {
          weekday: toIsoWeekday(schedule.weekday),
          hour: schedule.time?.hours,
          minute: schedule.time?.minutes,
          second: schedule.time?.seconds,
        },
      }
    } else {
      nativeSchedule = {
        at: schedule.date,
      }
    }

    return nativeSchedule
  }

  const getNextId = useCallback(() => {
    let result = state.maxNativeId + 1

    while (state.activeNativeIds.has(result)) {
      result++
    }

    if (result === Number.MAX_SAFE_INTEGER) {
      result = 0
    }

    dispatch({
      type: NotificationReducerActionType.NativeIdActivated,
      payload: { id: result },
    })

    return result
  }, [state.maxNativeId, state.activeNativeIds, state.reminders])

  const scheduleReminder = useCallback(
    async (key: string, props: ReminderProps, shouldUpdate = false) => {
      // check if enabled
      if (!state.isEnabled) {
        return false
      }

      const { template, title, body, schedule, payload } = props

      let id = state.reminderKeyMap[key]

      if (!isNil(id)) {
        const existingReminder = state.reminders[id]

        if (isNil(existingReminder)) {
          throw new Error(
            `scheduleReminder: id ${id} is mapped to key ${key} but no reminder exists`
          )
        }

        if (existingReminder.status === "scheduled" && !shouldUpdate) {
          return false
        }

        // cancel existing reminder
        await cancelReminderById(id)
      } else {
        id = getNextId()
      }

      const nativeSchedule = buildNativeSchedule(schedule)

      // check if reminder is in the past
      if (!isNil(nativeSchedule.at) && isPast(nativeSchedule.at)) {
        console.debug(
          "[NotificationContext] scheduleReminder: reminder is in the past"
        )

        return false
      }

      const { notifications } = await LocalNotifications.schedule({
        notifications: [
          {
            id,
            title,
            body,
            schedule: nativeSchedule,
            actionTypeId: template,
            extra: {
              key,
              template,
              payload,
            },
          },
        ],
      })

      if (notifications.length === 0) {
        captureMessage(
          "[NotificationContext] scheduleReminder: no notifications returned",
          "warning"
        )

        return false
      }

      dispatch({
        type: NotificationReducerActionType.ReminderScheduled,
        payload: {
          id,
          title,
          body,
          schedule,
          template,
          key,
          extra: payload,
        },
      })

      return true
    },
    [
      state.isEnabled,
      state.reminders,
      state.templatesAllowed,
      state.maxNativeId,
      state.activeNativeIds,
    ]
  )

  const getReminder = useCallback(
    (key: string) => {
      const id = state.reminderKeyMap[key]

      if (isNil(id)) {
        return undefined
      }

      return state.reminders[id]
    },
    [state.reminders, state.reminderKeyMap]
  )

  const cancelReminder = useCallback(
    async (key: string) => {
      const reminderId = state.reminderKeyMap[key]

      if (isNil(reminderId)) {
        console.debug(
          "[NotificationContext] cancelReminder: reminder key not found"
        )

        return false
      }

      return await cancelReminderById(reminderId)
    },
    [state.reminderKeyMap]
  )

  const cancelReminderById = useCallback(
    async (id: number) => {
      const reminder = state.reminders[id]

      if (isNil(reminder)) {
        return false
      }

      if (reminder.status === "delivered") {
        await LocalNotifications.removeDeliveredNotifications({
          notifications: [{ ...reminder }],
        })
      } else if (reminder.status === "scheduled") {
        await LocalNotifications.cancel({
          notifications: [{ id: reminder.id }],
        })
      }

      dispatch({
        type: NotificationReducerActionType.ReminderCancelled,
        payload: { id },
      })

      return true
    },
    [state.reminders]
  )

  const loadTemplatePermissions = async () => {
    const templatesAllowed = await loadTemplatesAllowed()

    console.debug("[NotificationContext] all loadTemplatePermissions", {
      templatesAllowed,
    })

    dispatch({
      type: NotificationReducerActionType.TemplatePermissionsLoaded,
      payload: { templatesAllowed },
    })
  }

  const setAppBadge = useCallback(
    async (count: number) => {
      if (!state.isEnabled) return

      if (count < 0) {
        clearAppBadge()
      } else {
        await Badge.set({ count })

        dispatch({
          type: NotificationReducerActionType.BadgeCountUpdated,
          payload: { count },
        })
      }
    },
    [state.isEnabled]
  )

  const clearAppBadge = useCallback(async () => {
    if (!state.isEnabled) return

    await Badge.clear()

    dispatch({
      type: NotificationReducerActionType.BadgeCountUpdated,
      payload: { count: 0 },
    })
  }, [state.isEnabled])

  const dispatchPermissionGranted = () => {
    dispatch({
      type: NotificationReducerActionType.NotificationPermissionGranted,
    })
  }

  const dispatchPermissionRejected = () => {
    dispatch({
      type: NotificationReducerActionType.NotificationPermissionRejected,
    })
  }

  const checkNativePermissions = async () => {
    const { display } = await LocalNotifications.checkPermissions()

    if (display === "granted") {
      dispatchPermissionGranted()

      return true
    } else if (display === "denied") {
      dispatchPermissionRejected()

      return false
    } else {
      dispatch({
        type: NotificationReducerActionType.NotificationPermissionNotPrompted,
      })

      return false
    }
  }

  const requestPermissionIfMissing = useCallback(
    async (
      onGranted: (alreadyGranted?: boolean) => void,
      onRejected?: () => void
    ) => {
      if (state.isNativePermissionGranted) {
        return onGranted(true)
      }

      let promptResult: PermissionStatus

      const { display } = await LocalNotifications.checkPermissions()

      switch (display) {
        case "prompt":
        case "prompt-with-rationale":
          promptResult = await LocalNotifications.requestPermissions()

          if (promptResult.display === "granted") {
            dispatchPermissionGranted()

            return onGranted(false)
          } else {
            dispatchPermissionRejected()

            return onRejected?.()
          }

        case "granted":
          dispatchPermissionGranted()

          return onGranted(true)

        case "denied":
          dispatchPermissionRejected()

          return onRejected?.()

        default:
          return
      }
    },
    [state.isEnabled, state.isNativePermissionGranted]
  )

  const maybeLoadPushNotificationToken = async () => {
    console.debug("[NotificationContext] loading push notification token")

    const token = await loadPushNotificationToken()

    if (token) {
      console.debug(
        "[NotificationContext] loaded push notification token",
        token
      )

      dispatch({
        type: NotificationReducerActionType.PushNotificationTokenLoaded,
        payload: token,
      })
    } else {
      console.debug("[NotificationContext] no push notification token found")
    }
  }

  const handlePushRegistration = useCallback(
    async ({ value }: Token) => {
      if (isNil(value)) throw new Error("handlePushRegistration: no token")

      const existingToken = await loadPushNotificationToken()

      // only save token if the token has changed
      if (isNil(existingToken) && existingToken !== value) {
        await storePushNotificationToken(value)
      }

      if (contact?.deviceToken === value) {
        console.debug(
          "[NotificationContext] handlePushRegistration: same token already registered"
        )
      }

      // register token with backend
      await mutateRegisterPushNotificationDevice({
        variables: { deviceToken: value, devicePlatform: platform },
        onCompleted: (registered) => {
          if (registered) {
            dispatch({
              type: NotificationReducerActionType.PushNotificationTokenLoaded,
              payload: value,
            })
          }
        },
        onError: (error) => {
          addBreadcrumb({
            category: "pushNotification",
            message: "push notification registration failed",
            type: "error",
            data: {
              error,
            },
          })
        },
      })
    },
    [platform, contact]
  )

  const handlePushRegistrationError = useCallback(async (error: any) => {
    if (
      error.error === "remote notifications are not supported in the simulator"
    ) {
      return
    }

    addBreadcrumb({
      category: "pushNotification",
      message: "push notification registration failed",
      type: "error",
      data: {
        error,
      },
    })

    captureMessage("[PushNotification] failed to register", "warning")
  }, [])

  const handlePushNotificationReceived = useCallback(
    async (notification: PushNotificationSchema) => {
      if (!state.isEnabled) return

      captureEvent(AnalyticsEvent.PushNotificationReceived, {
        notification,
      })
    },
    [state.isEnabled, captureEvent]
  )

  const handlePushNotificationActionPerformed = useCallback(
    async (props: any) => {
      if (!state.isEnabled) return

      const { notification, actionId, inputValue } = props

      captureEvent(AnalyticsEvent.PushNotificationActionPerformed, {
        notification,
        actionId,
        inputValue,
      })

      PushNotifications.removeAllDeliveredNotifications()
    },
    [state.isEnabled, captureEvent]
  )

  const handleLocalNotificationReceived = useCallback(
    async (notification: LocalNotificationSchema) => {
      console.debug("[NotificationContext] local notification received", {
        notification: JSON.stringify(notification),
      })

      captureEvent(AnalyticsEvent.LocalNotificationReceived, {
        template: notification.extra.template,
        title: notification.title,
        body: notification.body,
      })

      dispatch({
        type: NotificationReducerActionType.ReminderDelivered,
        payload: {
          ...notification,
        },
      })
    },
    [state.isEnabled, state.reminders]
  )

  const handleLocalNotificationActionPerformed = useCallback(
    async ({ notification, actionId }: ActionPerformed) => {
      console.debug(
        "[NotificationContext] local notification action performed",
        {
          notification: JSON.stringify(notification),
          actionId,
        }
      )

      captureEvent(AnalyticsEvent.LocalNotificationActionPerformed, {
        template: notification.extra.template,
        actionId,
      })

      cancelReminderById(notification.id)
    },
    [state.isEnabled]
  )

  const addNotificationListeners = async () => {
    // Local Notifications
    await LocalNotifications.addListener(
      "localNotificationReceived",
      handleLocalNotificationReceived
    )

    await LocalNotifications.addListener(
      "localNotificationActionPerformed",
      handleLocalNotificationActionPerformed
    )

    await PushNotifications.addListener("registration", handlePushRegistration)

    await PushNotifications.addListener(
      "registrationError",
      handlePushRegistrationError
    )

    await PushNotifications.addListener(
      "pushNotificationReceived",
      handlePushNotificationReceived
    )

    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      handlePushNotificationActionPerformed
    )
  }

  const removeNotificationListeners = async () => {
    // Local Notifications
    await LocalNotifications.removeAllListeners()

    // Push Notifications
    await PushNotifications.removeAllListeners()
  }

  const refreshLocalNotficiationStatus = useCallback(async () => {
    if (!state.isInitialized) return

    if (!state.isEnabled) {
      await checkNativePermissions()
    }

    const reminders = {} as Record<number, ReminderState>
    const reminderKeyMap = {} as Record<string, number>
    const activeNativeIds = [] as number[]

    const { notifications: deliveredNotifications } =
      await LocalNotifications.getDeliveredNotifications()

    deliveredNotifications.forEach((notification) => {
      const reminderKey = notification.extra?.key

      reminders[notification.id] = {
        ...notification,
        schedule: notification.schedule,
        key: notification.extra?.key,
        template: notification.extra?.template,
        status: "delivered",
      }

      reminderKeyMap[reminderKey] = notification.id

      activeNativeIds.push(notification.id)
    })

    const { notifications: scheduledNotifications } =
      await LocalNotifications.getPending()

    scheduledNotifications.forEach(async (notification) => {
      const reminderKey = notification.extra?.key

      // cancel duplicate reminders
      if (isNil(reminderKey) || !isNil(reminderKeyMap[reminderKey])) {
        await LocalNotifications.cancel({
          notifications: [{ id: notification.id }],
        })

        return
      }

      reminderKeyMap[reminderKey] = notification.id

      reminders[notification.id] = {
        ...notification,
        schedule: notification.schedule,
        key: notification.extra?.key,
        template: notification.extra?.template,
        status: "scheduled",
      }

      activeNativeIds.push(notification.id)
    })

    dispatch({
      type: NotificationReducerActionType.LocalNotificationsImported,
      payload: {
        reminders,
        reminderKeyMap,
        activeNativeIds,
      },
    })
  }, [state.isInitialized, state.isEnabled, state.reminders])

  useEffect(() => {
    if (!state.isInitialized) return

    console.debug("[NotificationContext] state", state)
  }, [state])

  // effect - if preferences are loaded, but dirty, save them
  useEffect(() => {
    if (!state.isInitialized) return

    if (isNil(state.templatesAllowed)) return

    storeTemplatesAllowed(state.templatesAllowed)
  }, [state.templatesAllowed])

  // effect - if device token is loaded, but device is not registered, register it
  useEffect(() => {
    if (isNil(currentUser)) return
    if (isNil(state.devicePushNotificationToken)) return

    if (!registeredWithHiveCalled) {
      handlePushRegistration({ value: state.devicePushNotificationToken })
    }
  }, [state.devicePushNotificationToken, currentUser, registeredWithHiveCalled])

  useEffect(() => {
    if (!state.isInitialized) return

    maybeLoadPushNotificationToken()

    loadTemplatePermissions()
    checkNativePermissions()

    const interval = setInterval(() => {
      refreshLocalNotficiationStatus()
    }, 15_000)

    return () => {
      clearInterval(interval)
    }
  }, [state.isInitialized])

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      checkNativePermissions()

      addNotificationListeners()

      return () => {
        removeNotificationListeners()
      }
    }
  }, [])

  const value = {
    ...state,
    enableNotifications,
    disableNotifications,
    enableTemplate,
    disableTemplate,
    requestPermissionIfMissing,
    setAppBadge,
    clearAppBadge,
    isReminderScheduled,
    isReminderDelivered,
    scheduleReminder,
    cancelReminder,
    checkNativePermissions,
    isTemplateEnabled,
    refreshLocalNotficiationStatus,
    getReminder,
  }

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  )
}
