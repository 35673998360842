import { checkmarkDoneOutline, playOutline } from "ionicons/icons"
import isNil from "lodash/isNil"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { MovementAgendaItem } from "../../../generated/graphql"
import { MOVEMENT, NAME_SPACES } from "../../../locales/constants"
import Button from "../../Forms/Button"
import useActivityStyle from "../../../hooks/useActivityStyle"

export const AgendaItemButtons: React.FC<{
  agendaItem: MovementAgendaItem
  disabled?: boolean
  isLogEnabled?: boolean
  handleStart: () => void
  handleLogSession: () => void
}> = ({
  agendaItem,
  handleStart,
  handleLogSession,
  isLogEnabled,
  disabled = false,
}) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.AGENDA_ITEM, {
    returnObjects: true,
  })

  const activityStyle = useActivityStyle(agendaItem.movementStyle)

  const isFollowAlongAvailable =
    !isNil(agendaItem.followAlongCustomUrl) ||
    activityStyle?.isFollowAlongVideoAvailable ||
    !isNil(agendaItem.followAlong)

  return (
    <div className="flex flex-col border-none gap-y-2">
      <Button
        onClick={handleStart}
        routerDirection="forward"
        color={agendaItem.movementModality || "primary"}
        label={TEXT.CTA.START_SESSION}
        iconClassName="absolute left-0"
        className="shadow-xl"
        fill={"solid"}
        icon={playOutline}
        disabled={disabled}
        hidden={!isFollowAlongAvailable}
      />

      <Button
        onClick={handleLogSession}
        color={
          isFollowAlongAvailable
            ? "white"
            : agendaItem.movementModality || "primary"
        }
        label={TEXT.CTA.LOG_SESSION}
        fill={"solid"}
        icon={checkmarkDoneOutline}
        iconClassName="absolute left-0"
        className="shadow-lg"
        iconSlot="start"
        disabled={disabled}
        hidden={!isNil(agendaItem.followAlong) || !isLogEnabled}
      />
    </div>
  )
}
