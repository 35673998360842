import { useTranslation } from "react-i18next"
import { IoMailOpenOutline } from "react-icons/io5"

import BaseAuthPage, {
  BaseAuthPageLinkProps,
} from "../../components/Auth/BaseAuthPage"
import { AuthenticateWithGoogleButton } from "../../components/Auth/Google/AuthenticateWithGoogleButton"
import Button from "../../components/Forms/Button"
import { AUTH, NAME_SPACES } from "../../locales/constants"
import { UserSession } from "../../generated/graphql"
import ContinueWithAppleButton from "../../components/Auth/Apple/ContinueWithAppleButton"

interface AuthHomePageProps {
  onCompleted: (user: UserSession) => void
}

const AuthHomePage: React.FC<AuthHomePageProps> = ({ onCompleted }) => {
  const { t } = useTranslation(NAME_SPACES.AUTH)
  const TEXT = t(AUTH.REGISTRATION, {
    returnObjects: true,
  })

  const links = [
    {
      href: `/auth/login`,
      routerDirection: "forward",
      label: TEXT.ALREADY_REGISTRED,
    },
  ] as BaseAuthPageLinkProps[]

  return (
    <BaseAuthPage
      links={links as BaseAuthPageLinkProps[]}
      title={t("HOME.WELCOME_MESSAGE")}
    >
      <div className="flex flex-col md:py-10 gap-y-4">
        <ContinueWithAppleButton onCompleted={onCompleted} />
        <AuthenticateWithGoogleButton onCompleted={onCompleted} />
        <Button
          routerLink={`/auth/register`}
          routerDirection="none"
          fill="outline"
          color="white"
          icon={IoMailOpenOutline}
          size="large"
          label={t("CONTINUE_WITH_PROVIDER", { provider: "Email" })}
          textColor="white"
        />
      </div>
    </BaseAuthPage>
  )
}

export default AuthHomePage
