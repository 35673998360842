import { useIonRouter } from "@ionic/react"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import Content from "../../components/Core/Content"
import Page from "../../components/Core/Page"
import Button from "../../components/Forms/Button"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { NAME_SPACES, WELCOME } from "../../locales/constants"
import { useCallback, useEffect } from "react"
import { RiAddLine, RiRestTimeLine } from "react-icons/ri"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import MovementAgendaItemFormModal from "../../components/Movement/Modals/MovementAgendaItemFormModal"
import { YinBehaleIcon } from "../../components/BehaleIcon"
import ResourceExplorerModal from "../../components/Mico/Modals/ResourceExplorerModal"
import StorySetIcon, { StorySetIconType } from "../../components/StorySetIcon"
import { useQuery } from "@apollo/client"
import {
  RecommendedSqualoResourcesDocument,
  RecommendedSqualoResourcesQuery,
} from "../../generated/graphql"
import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"
import isNil from "lodash/isNil"
import { isAdventurePremium } from "../../components/Adventure/AdventurePreviewCard"

const NextSessionPage: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.WELCOME)
  const TEXT = t(WELCOME.ACTIVITIES, { returnObjects: true })

  const router = useIonRouter()

  const nextPath = "/app/welcome/end"

  const { captureEvent } = useAnalyticsContext()

  const { isSessionActive, currentMember } = useAuthenticatedClientContext()
  const { openModal, closeModal, updateModalState, toggleLoading, isOpen } =
    useModalOrchestrationContext()

  const { data, loading, refetch } = useQuery<RecommendedSqualoResourcesQuery>(
    RecommendedSqualoResourcesDocument,
    {
      skip: !isSessionActive,
      fetchPolicy: "network-only",
    }
  )

  const handleContinue = () => {
    router.push(nextPath, "forward")
  }

  const handleSkip = () => {
    captureEvent(AnalyticsEvent.WelcomeNextActivitySkipped)
  }

  const handlePlanItem = () => {
    captureEvent(AnalyticsEvent.WelcomeNextActivityPlanClicked)

    openModal(ModalOrchestrationName.MovementAgendaItemForm, {
      onClose: handleItemModalClosed,
    })
  }

  const handleRecommendItem = () => {
    captureEvent(AnalyticsEvent.WelcomeNextActivityRecommendClicked)

    openModal(ModalOrchestrationName.VideoExplorer, {
      videos: data?.recommendedResources?.followAlongs || [],
      adventures: data?.recommendedResources?.adventures || [],
    })
  }

  const handleItemModalClosed = useCallback(
    (reason?: string, data?: any) => {
      if (reason === "added") {
        captureEvent(AnalyticsEvent.WelcomeNextActivitySessionPlanned, {
          style: data.movementStyle,
        })

        setTimeout(() => handleContinue(), 500)
      }
    },
    [captureEvent, closeModal]
  )

  const handleCloseExplorer = (reason?: string, data?: any) => {
    if (reason === "started") {
      captureEvent(AnalyticsEvent.WelcomeNextActivityRecommendationStarted, {
        style: data.movementStyle,
      })

      const videoPath = `/app/hub/discover/videos/${data.uuid}`

      router.push(videoPath, "forward")
    } else if (reason === "scheduled") {
      captureEvent(AnalyticsEvent.WelcomeNextActivityRecommendationScheduled, {
        style: data.movementStyle,
      })

      setTimeout(() => handleContinue(), 500)
    } else if (reason === "adventure-chosen") {
      captureEvent(
        AnalyticsEvent.WelcomeNextActivityRecommendationAdventureChosen,
        {
          isPremium: isAdventurePremium(data),
          element: data.element,
          adventureUuid: data.id,
        }
      )
    }
  }

  useEffect(() => {
    if (!isOpen(ModalOrchestrationName.VideoExplorer)) return

    toggleLoading(loading)

    updateModalState(ModalOrchestrationName.VideoExplorer, {
      props: {
        videos: data?.recommendedResources?.followAlongs || [],
        adventures: data?.recommendedResources?.adventures || [],
      },
    })
  }, [data, loading])

  useEffect(() => {
    if (!isNil(currentMember?.elements) && !isNil(data)) {
      refetch()
    }
  }, [currentMember?.elements, data])

  return (
    <Page className="bg-primary" showBackButton backButtonRoot="/app/welcome">
      <Content
        color="primary"
        scrollY={false}
        className="relative flex flex-col items-center justify-center h-full"
      >
        <div
          className={clsx(
            "flex flex-col items-center justify-between max-w-3xl h-full mx-auto gap-y-4 py-4"
          )}
        >
          <div className="flex flex-col w-full px-4">
            <div className="flex flex-row items-center justify-start w-full my-4 gap-x-2">
              <span className="w-full text-3xl font-semibold text-center">
                {TEXT.NEXT.TITLE}
              </span>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center flex-grow w-full max-w-xl p-4 mx-auto aspect-w-4 aspect-h-3">
            <StorySetIcon type={StorySetIconType.Workout} />
          </div>

          <div className="flex flex-col items-center justify-end flex-shrink-0 w-full max-w-xl px-4 mx-auto pb-safe gap-y-2">
            <Button
              fill="outline"
              className="w-full opacity-75"
              expand="block"
              color="white"
              size="large"
              icon={RiRestTimeLine}
              iconSlot="start"
              routerLink={nextPath}
              routerDirection="forward"
              onClick={handleSkip}
              label={TEXT.NEXT.CTA.SKIP}
            />

            <Button
              fill="solid"
              className="w-full"
              expand="block"
              textColor="navy"
              color="white"
              size="large"
              icon={RiAddLine}
              iconSlot="start"
              onClick={handlePlanItem}
              label={TEXT.NEXT.CTA.PLAN}
            />

            <Button
              fill="solid"
              className="w-full"
              expand="block"
              textColor="white"
              color="tertiary"
              size="large"
              icon={YinBehaleIcon}
              iconSlot="start"
              onClick={handleRecommendItem}
              label={TEXT.NEXT.CTA.RECOMMEND}
            />
          </div>
        </div>

        <MovementAgendaItemFormModal onClose={handleItemModalClosed} />
        <ResourceExplorerModal onClose={handleCloseExplorer} />
      </Content>
    </Page>
  )
}

export default NextSessionPage
