import { Share } from "@capacitor/share"
import { shareSocialOutline } from "ionicons/icons"
import { useEffect, useState } from "react"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import Button, { ButtonProps } from "../Forms/Button"

export interface ShareButtonProps extends ButtonProps {
  url: string
  title?: string
  text?: string
}

const ShareButton: React.FC<ShareButtonProps> = ({
  url,
  title,
  text,
  ...props
}) => {
  const { captureEvent } = useAnalyticsContext()
  const [canShare, setCanShare] = useState(false)

  useEffect(() => {
    Share.canShare()
      .then(({ value }) => {
        setCanShare(value)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const handleClick = async () => {
    captureEvent(AnalyticsEvent.ShareButtonClicked, {
      url,
      title,
    })

    await Share.share({
      dialogTitle: title,
      title,
      text,
      url,
    })
  }

  if (!canShare) {
    return null
  }

  return (
    <Button
      onClick={handleClick}
      icon={shareSocialOutline}
      fill="clear"
      iconSlot="icon-only"
      {...props}
    />
  )
}

export default ShareButton
