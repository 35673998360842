import { useMutation } from "@apollo/client"
import { isNil } from "lodash"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Redirect } from "react-router"

import BaseAuthPage from "../../components/Auth/BaseAuthPage"
import { ResetPasswordForm } from "../../components/Auth/ResetPasswordForm"
import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"
import {
  ResetPasswordDocument,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from "../../generated/graphql"
import useRouterQueryParams from "../../hooks/useRouterQueryParams"
import useToast from "../../hooks/useToast"
import { AUTH, NAME_SPACES } from "../../locales/constants"

interface ResetPasswordPageProps {
  title?: string
  subtitle?: string
  CTA?: string
  passwordResetToken?: string
  successMessage?: string
  redirectTo?: string
  onCompleted?: () => void
}

const ResetPasswordPage: React.FC<ResetPasswordPageProps> = (props) => {
  const { showError, showSuccess, showInfo } = useToast()

  const { t } = useTranslation(NAME_SPACES.AUTH)
  const TEXT = t(AUTH.RESET_PASSWORD, {
    returnObjects: true,
  })

  const {
    title = TEXT.TITLE,
    subtitle,
    CTA = TEXT.SUBMIT,
    successMessage = TEXT.SUCCESS_MESSAGE,
    redirectTo = "/",
    passwordResetToken,
    onCompleted,
  } = props

  const { login } = useAuthenticatedClientContext()

  const query = useRouterQueryParams()

  const [token, setToken] = useState<string>()
  const [isTokenValid, setIsTokenValid] = useState<boolean>(true)

  const [mutate] = useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument)

  const resetPassword = async ({ password }: { password: string }) => {
    if (isNil(token)) {
      showError(TEXT.INVALID_RESET_TOKEN)

      setIsTokenValid(false)

      return
    }

    await mutate({
      variables: { token, password },
      onCompleted: async (data) => {
        showSuccess(successMessage)

        login(data.resetPassword.token)

        onCompleted?.()
      },
      onError: async (error) => {
        console.warn(error.graphQLErrors)

        if (error.message === "invalid_token") {
          setIsTokenValid(false)

          showInfo(TEXT.ERROR_MESSAGE)
        } else {
          showError(error.message)
        }
      },
      refetchQueries: [],
    })
  }

  // set the token state from query parameter
  useEffect(() => {
    if (query && query.has("token")) {
      setToken(query.get("token") || undefined)
    }
  }, [query])

  // set the token state from props if present
  useEffect(() => {
    if (passwordResetToken) {
      setToken(passwordResetToken)
    }
  }, [passwordResetToken])

  // if the password reset token is invalid or the user is already authenticated,
  // then we redirect to the login page
  if (!isTokenValid) {
    return <Redirect to={redirectTo} />
  }

  return (
    <BaseAuthPage>
      <span className="text-2xl font-semibold text-center">{title}</span>

      {subtitle && <span>{subtitle}</span>}

      <ResetPasswordForm resetPassword={resetPassword} CTA={CTA} />
    </BaseAuthPage>
  )
}

export default ResetPasswordPage
