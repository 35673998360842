import { DailyMoveCollectionType } from "../../components/Today/DailyMove/DailyMoveCollectionType"
import { StaminaLevel } from "../../components/Today/StaminaMeter/StaminaLevel"
import { MEMBERSHIP } from "../constants"

export default {
  [MEMBERSHIP.PREMIUM]: {
    CTA: "Upgrade",
    FREE_TRIALS: {
      ONE_WEEK: "7 day free trial",
    },
    PERIODS: {
      MONTH: "month",
      PER_MONTH: "/ mo",
      PER_YEAR: "/ year",
    },
    TIER_1: {
      NOT_AVAILABLE_ON_WEB: "N/A on web",
      CTA: "Try for free",
      NO_THANKS: "No thanks",
      TITLE:
        "Behale <0>Heros</0> are 3.2x more likely to complete their weekly sessions!",
      FEATURES: [
        {
          emoji: "🏔",
          key: "adventures",
          title: "Unleash Your Inner Adventurer",
          description:
            "Unlock all of our exhilarating adventures and start the one you love right away.",
        },
        {
          emoji: "👊",
          key: "community",
          title: "Join the Dream Team",
          description:
            "Become part of our exclusive community to get support from our coaches, specialists and other heros.",
        },
        // {
        //   emoji: "🧬",
        //   key: "personalized",
        //   title: "Movement Made Just for You",
        //   description:
        //     "Get personalized suggestions based on your activity, injuries, and discomforts.",
        // },
        {
          emoji: "🔮",
          key: "future",
          title: "See Your Future",
          description:
            "Unlock the full power of the agenda: see the future phases of any adventure and plan ahead.",
        },

        {
          emoji: "🗓️",
          key: "calendar",
          title: "Never Miss a Session",
          description:
            "Stay on top of your movement anywhere with Google Calendar integrations.",
        },
      ],
    },
    CANCELLATION_POLICY: {
      TITLE: "Cancel anytime",
      SUBTITLE:
        "Cancel your subscription anytime - no penalties, fees or questions asked.",
    },
    LEGAL_DISCLAIMER: {
      IOS: "A {{purchaseAmountAndPeriod}} purchase will be applied to your iTunes account upon confirmation. Subscriptions will automatically renew unless canceled within 24-hours before the end of the current period. You can cancel anytime with your iTunes account settings. Any unused portion of a free trial will be forfeited if you purchase a subscription. For more information, see our <0>{{termsOfService}}</0> and <1>{{privacyPolicy}}</1>.",
    },
  },
  [MEMBERSHIP.GUIDANCE]: {
    CHAT: {
      TITLE: "Speak to your coach",
      SUBTITLE: "Ask them anything in the chat window below 😊",
      NEW_MESSAGE_PLACEHOLDER: "Type your message here",
      AGENT_AVAILABLE_TEXT: "I'm all ears",
      AGENT_UNAVAILABLE_TEXT:
        "I'm unavailable right now, but I'll be back soon!",
      INITIAL_MESSAGE:
        "Welcome to Behale 😁 Let me know if you have any questions so that I can help you move.",
      SENDING: "Sending...",
      SENT_AT: "Sent at {{time}}",
      NO_MESSAGES_SENT: "No messages sent",
    },
  },
  [MEMBERSHIP.PROFILE]: {
    SYNCRO_PERMISSION: "Sync with local calendar",
    TITLE: "Profile",
    SETTINGS: {
      TITLE: "Settings",
      FIELDS: {
        ACCOUNT: {
          LABEL: "Account",
          TITLE: "Account Settings",
          FIELDS: {
            FIRST_NAME: {
              LABEL: "First Name",
              PLACEHOLDER: "First Name",
            },
            LAST_NAME: {
              LABEL: "Last Name",
              PLACEHOLDER: "Last Name",
            },
          },
          DELETION: {
            CTA: "Delete account",
            CONFIRMATION: {
              HEADER: "Account deletion",
              SUBHEADER:
                "Are you sure you want to delete your account? This action is irreversible.",
            },
            STATUS: {
              SUCCESS: "Account deleted",
              ERROR: "An error occured while deleting your account",
            },
          },
        },
        NOTIFICATIONS: {
          TITLE: "Notification Settings",
          FIELD: {
            LABEL: "Notifications",
            VALUES: {
              ALL_ENABLED: "Enabled",
              ALL_DISABLED: "Disabled",
            },
            OPTIONS: {
              ALL_NOTIFICATIONS: "Enable notifications",
            },
          },
          REQUEST_PERMISSION: {
            TITLE: "Help us, help you",
            DESCRIPTION:
              "💬 Turn on notifications to get messages from our coaches and stay motivated. We won't spam you, we promise!",
            GRANT: "Keep me motivated",
            DENY: "No, I'm taking the risk",
          },
        },
      },
      ADMIN: {
        TITLE: "Admin Settings",
        FIELDS: {},
      },
      PRO: {
        TITLE: "Pro Settings",
        CALENDAR: {
          TITLE: "Calendar Integration",
          FIELD: {
            LABEL: "Calendar",
            INTEGRATIONS: {
              GOOGLE: "Google Calendar",
            },
            VALUES: {
              CONNECTED: "Connected",
              CONNECTED_TO: "Connected to {{calendarName}}",
              NOT_CONNECTED: "Not connected",
            },
          },
        },
        WHATSAPP: {
          TITLE: "WhatsApp Community",
          FIELD: {
            LABEL: "WhatsApp",
            VALUES: {
              CTA: "Join Now",
            },
          },
        },
      },
    },
    TABS: {
      PROGRESS: {
        TITLE: "Progress",
      },
      ADVENTURE: {
        TITLE: "Adventure",
      },
    },
    CURRENT_ADVENTURES: {
      TITLE: "Current Adventures",
      SUBTITLE: "Keep track of your progress and stay motivated.",
    },
    PUBLIC_PROFILE: {
      JOINED_AT: "Member since {{date}}",
      NO_ELEMENTS: "Set elements",
    },
    BUDDIES: {
      TITLE: "Buddies",
      SUBTITLE:
        "Connect with friends to share your activity and stay motivated.",
      EMPTY_STATE: {
        TITLE: "No buddies yet",
      },
      SHARE_LINK: {
        CTA: "Invite a buddy",
        TITLE: "Invite a Behale Buddy",
        TEXT: "Hey, want to be my Behale buddy? It's the best way for us to keep each other moving for free.",
        SUCCESS: "Link copied to clipboard",
      },
      CARD: {
        PREVIEW: {
          ITEM: "Next",
          SESSION: "Latest",
          PLACEHOLDER: "No activity",
        },
      },
      FEEDBACK: {
        ALREADY_BUDDIES: "You're already buddies!",
        SUCCESS: "Buddy added!",
        ERROR: "Something went wrong while adding a buddy.",
      },
    },
  },
  [MEMBERSHIP.TODAY]: {
    STAMINA_METER: {
      LABEL: "Stamina Meter",
      LEVELS: {
        [StaminaLevel.Inactive]: "Inactive",
        [StaminaLevel.Okay]: "Okay",
        [StaminaLevel.Good]: "Good",
        [StaminaLevel.Great]: "Great",
        [StaminaLevel.Excellent]: "Amazing",
      },
      LINK: "https://behale.notion.site/What-is-the-Stamina-Meter-13cfdabedc02482e98900e66f5463e27",
    },
    DAILY_MOVE: {
      TITLE: "Today's Move",
      COMPLETED: {
        TITLE: "Bravo 👏 ",
        SUBTITLE: "You've completed your session for today!",
      },
      EMPTY_STATE: {
        [DailyMoveCollectionType.Favorite]: "Manage favorite activities",
      },
      TYPES: {
        [DailyMoveCollectionType.Planned]: "Planned",
        [DailyMoveCollectionType.Adventure]: "Adventure",
        [DailyMoveCollectionType.Suggestion]: "Suggestions",
        [DailyMoveCollectionType.Favorite]: "Favorites",
      },
      CTAS: {
        START: "Let's go!",
        LOG: "I've done it!",
        OPEN: "Open link",
        VIEW: "See info",
        PLAN: "Add to agenda",
      },
      PLANNED: {
        INDICATION: {
          PLANNED: "Planned",
          ADVENTURE: "Next up",
          SUGGESTED: "Suggested",
        },
        CTA: "Let's move!",
        MODAL: {
          TITLE: "Adapt your session",
          SUBTITLE:
            "Life is unpredictable, adapt your session to your schedule.",
          AVAILABLE_TIME: "How much time do you have?",
        },
      },
    },
    CURRENT_ADVENTURES: {
      TITLE: "Current Adventures",
    },
    RECOMMENDED_ADVENTURES: {
      TITLE: "Recommended Adventures",
      SUBTITLE: "Discover new ways to move",
      VIEW_ALL: "See more adventures",
    },
  },
  [MEMBERSHIP.CALENDAR]: {
    TITLE: "Never miss a session again",
    DESCRIPTION:
      "Members who connect their calendar are <strong>3x more likely</strong> to complete their sessions.",
    SKIP: "I'll do it tomorrow (or never)",
    SUCCESS_MESSAGE: "Calendar connected!",
  },
  [MEMBERSHIP.PROGRESS]: {
    STATISTICS: "Statistics",
    WEEKLY_STREAK: "active week streak",
    TOTAL_ACTIVE_WEEKS: "total active weeks",
    TOTAL_SESSIONS: "total sessions",
    TOTAL_TIME: "total time",
    AVERAGE_SESSIONS_PER_WEEK: "sessions / week",
    ACTIVITY_LOG: "Activity History",
    SESSIONS_COMPLETED: "sessions",
    COMPLETE_YOUR_FIRST_SESSION: "Get your move on!",
    NO_SESSIONS_COMPLETED_WEEK: "No activity this week.",
  },
}
