import { IonBadge, IonIcon } from "@ionic/react"
import classNames, { clsx } from "clsx"
import { addOutline, checkmarkDoneOutline } from "ionicons/icons"
import React from "react"

import {
  AgendaDay,
  useAgendaSchedulingContext,
} from "../../../contexts/AgendaSchedulingContext"
import { useLocaleContext } from "../../../contexts/LocaleContext"
import { Weekday } from "../../../generated/graphql"
import Button from "../../Forms/Button"
import { formatWeekday } from "../../../utils/format"

export type ScheduleWeekdayHeaderProps = {
  selected?: boolean
  weekday: Weekday
  nSessionsCompleted: number
  handleAddClick: (day: AgendaDay) => void
}

export const AgendaTimelineDayHeader = React.forwardRef(
  (props: ScheduleWeekdayHeaderProps, ref) => {
    const { weekday, nSessionsCompleted, handleAddClick } = props

    const { formatDate } = useLocaleContext()

    const { getDay, isToday } = useAgendaSchedulingContext()

    const day = getDay(weekday)

    return (
      <div
        className={clsx("flex flex-row items-center justify-between px-2")}
        ref={ref as any}
      >
        <div className="flex flex-row items-center justify-start gap-x-2">
          <div
            className={classNames(
              "flex flex-row items-baseline justify-start gap-x-1",
              isToday(day) ? "text-primary-600" : "text-neutral-600"
            )}
          >
            <span
              className={classNames(
                "text-lg font-medium",
                isToday(day) && "font-bold"
              )}
            >
              {formatWeekday(weekday)}
            </span>

            <span className={classNames("text-opacity-80")}>
              {formatDate(day.date, "d MMMM")}
            </span>
          </div>
        </div>

        <div className="flex flex-row items-center">
          {nSessionsCompleted > 1 && (
            <IonBadge
              color="success"
              className="flex flex-row items-center select-none gap-x-1"
            >
              {nSessionsCompleted}
              <IonIcon icon={checkmarkDoneOutline} />
            </IonBadge>
          )}
          <Button
            icon={addOutline}
            onClick={() => handleAddClick(getDay(weekday))}
            iconSlot="icon-only"
            color="primary"
            fill="clear"
            size="small"
            className="-mr-3"
          />
        </div>
      </div>
    )
  }
)
