import { AuthenticateWithGoogleButton } from "../../components/Auth/Google/AuthenticateWithGoogleButton"

import Page from "../../components/Core/Page"
import Loading from "../../components/Loading"
import { UserSession } from "../../generated/graphql"

interface OAuthCallbackPageProps {
  onCompleted: (user: UserSession) => void
}

const OAuthCallbackPage: React.FC<OAuthCallbackPageProps> = ({
  onCompleted,
}) => {
  const isInSafari = /Safari/.test(navigator.userAgent)

  return (
    <Page>
      <Loading />
      {isInSafari && (
        <AuthenticateWithGoogleButton
          onCompleted={onCompleted}
          className="hidden"
        />
      )}
    </Page>
  )
}

export default OAuthCallbackPage
