import { yupResolver } from "@hookform/resolvers/yup"
import classNames from "clsx"
import isNil from "lodash/isNil"
import React, { useEffect } from "react"
import {
  DeepPartial,
  FieldValues,
  Path,
  SubmitHandler,
  UseFormProps,
  useForm,
} from "react-hook-form"
import * as Yup from "yup"

import Button, { ButtonProps } from "./Button"
import { buildFieldProps } from "./Field"

export interface FormProps<FormData extends FieldValues>
  extends UseFormProps<FormData> {
  schema?: Yup.AnyObjectSchema
  onSubmit: SubmitHandler<FormData>
  onValid?: (data: FormData) => void
  className?: string
  floatingSubmit?: boolean
  submitButtonProps?: Partial<ButtonProps>
  hiddenValues?: {
    [key: string]: any
  }
  onChange?: (data: DeepPartial<FormData>, changes: any) => void
  watchFields?: any[]
}

export const Form = <FormData extends FieldValues>({
  schema,
  defaultValues,
  children,
  className,
  onSubmit,
  onValid,
  submitButtonProps,
  hiddenValues,
  onChange,
  floatingSubmit = false,
  watchFields = [],
  ...props
}: React.PropsWithChildren<FormProps<FormData>>) => {
  const methods = useForm<FormData>({
    defaultValues,
    resolver: schema ? yupResolver(schema) : undefined,
    criteriaMode: "all",
    reValidateMode: "onBlur",
    ...props,
  })

  const { handleSubmit, setValue, watch } = methods

  const [isValid] = React.useState(true)

  const buildChidlrenProps = (child: React.ReactElement) => {
    return {
      ...child.props,
      ...buildFieldProps(child.props.name, methods),
    }
  }

  const createField = (child: any): any => {
    if (isNil(child)) {
      return null
    }

    if (child.props.name) {
      return React.createElement(child.type, {
        ...buildChidlrenProps(child),
      })
    } else if (child.props && child.props.children) {
      const grandChildren = React.Children.map(
        child.props.children,
        createField
      )

      return React.createElement(child.type, {
        ...{
          ...child.props,
          children: grandChildren,
        },
      })
    }

    return child
  }

  useEffect(() => {
    if (isValid && onValid) {
      onValid(methods.getValues())
    }
  }, [isValid])

  // effect - if hidden values are provided, set them
  useEffect(() => {
    if (hiddenValues && Object.keys(hiddenValues).length > 0) {
      Object.entries(hiddenValues).forEach(([key, value]) => {
        setValue(key as Path<FormData>, value)
      })
    }
  }, [hiddenValues])

  useEffect(() => {
    if (onChange && watchFields && watchFields.length > 0) {
      const subscription = watch(async (value, changes) => {
        if (changes.name) {
          if (watchFields.some((field) => field === changes.name)) {
            onChange(value, changes)
          }
        }
      })

      return () => subscription.unsubscribe()
    }
  }, [watch, watchFields, onChange])

  const onError = (error: any) => {
    console.error(error)
  }

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        await onSubmit(data)
      }, onError)}
      className={classNames(
        "flex flex-col h-full max-h-full bg-transparent justify-between gap-y-6"
      )}
    >
      <div
        className={classNames(
          "flex flex-col flex-grow",
          floatingSubmit && "pb-32",
          className
        )}
      >
        {React.Children.map(children, createField)?.flat()}
      </div>

      <div
        className={classNames(
          floatingSubmit
            ? "absolute left-0 z-50 bottom-0 right-0 px-4 pb-4 bg-gradient-to-b from-white/20 to-white/70"
            : "flex"
        )}
      >
        <Button
          fill="solid"
          type="submit"
          expand="block"
          color="primary"
          {...submitButtonProps}
          disabled={!isValid || submitButtonProps?.disabled}
          className={classNames(
            "w-full m-0 rounded-lg",
            submitButtonProps?.className || ""
          )}
        />
      </div>
    </form>
  )
}
