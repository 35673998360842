import get from "lodash/get"
import {
  FieldError,
  RegisterOptions,
  SetValueConfig,
  UseFormRegister,
  UseFormReturn,
} from "react-hook-form"

export interface FormFieldProps<T = any> {
  name: string
  label?: string
  register?: UseFormRegister<any>
  error?: any
  value?: T
  setValue?: (data: T, config?: SetValueConfig) => void
  getState?: () => {
    invalid: boolean
    isDirty: boolean
    isTouched: boolean
    error?: FieldError
  }
  readonly?: boolean
  reset?: () => void
  registerOptions?: RegisterOptions
  hideLabel?: boolean
  hidden?: boolean
  className?: string
}

export const buildFieldProps = (name: string, methods: UseFormReturn<any>) => {
  return {
    name,
    register: methods.register,
    value: get(methods.getValues(), name),
    setValue: (val: any, opts: any) => {
      methods.setValue(name, val, {
        ...opts,
        shouldDirty: true,
        shouldTouch: true,
      })
    },
    getState: () => methods.getFieldState(name),
    error: get(methods.formState.errors, name),
    reset: (opts: any = {}) => {
      methods.resetField(name, opts)
    },
  }
}
