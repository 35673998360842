import * as React from "react"
import { IconBaseProps, IconType } from "react-icons/lib"

export interface IBehaleIconProps extends IconBaseProps {
  variant?: "yin" | "yang" | "taoline" | "yinFlower"
}

export const YinBehaleIcon: IconType = ({ fill, className, ...props }) => {
  return (
    <div className={className}>
      <svg
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        viewBox="0 0 57 78"
        {...props}
      >
        <path
          stroke="none"
          fillRule="evenodd"
          clipRule="evenodd"
          // className={`fill-current text-${fill}`}
          d="M36.4403 77.2603C36.5665 76.8484 27.2053 76.0686 21.8631 68.8467C20.1727 66.5361 19.0187 63.8775 18.4849 61.0644C17.9511 58.2514 18.0511 55.3548 18.7775 52.5853C20.0648 48.2682 22.7738 44.5134 26.4641 41.931C27.8697 40.9809 31.1585 38.8171 35.2488 38.7732C36.8057 38.8551 38.3669 38.7852 39.9102 38.5645C41.4813 38.315 43.0167 37.878 44.4838 37.2629C47.0474 36.1601 49.344 34.5188 51.2178 32.4503C53.0915 30.3818 54.4987 27.9344 55.3439 25.2741C56.8106 20.686 56.4284 15.7065 54.2788 11.3961C52.631 8.11575 50.0753 5.37829 46.916 3.50976C44.4315 2.10432 41.6912 1.20991 38.8561 0.879143C28.4242 -0.504813 19.8756 5.031 16.8119 7.01357C13.0918 9.3885 9.82041 12.4023 7.14875 15.9159C4.63468 19.3882 2.75877 23.2804 1.60887 27.4104C0.781853 30.4577 0.312014 33.5908 0.208814 36.7467C0.0823489 38.8334 0.120914 40.9268 0.324126 43.0074C0.712699 46.6666 1.65885 50.2448 3.12974 53.6177C5.90596 59.9861 10.2254 65.5619 15.6974 69.8408C25.4813 77.1505 36.303 77.6942 36.4403 77.2603Z"
        />
      </svg>
    </div>
  )
}

export const YangBehaleIcon: IconType = ({ fill, className, ...props }) => {
  return (
    <div className={className}>
      <svg
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        viewBox="0 0 56 57"
        {...props}
      >
        <path
          stroke="none"
          fillRule="evenodd"
          clipRule="evenodd"
          // className={`fill-current text-${fill}`}
          d="M56 28.0074C56 43.4754 43.464 56.0147 28 56.0147C12.536 56.0147 0 43.4754 0 28.0074C0 12.5393 12.536 0 28 0C43.464 0 56 12.5393 56 28.0074ZM28.7167 48.3974C29.1052 48.4967 29.3287 48.5538 29.314 48.6017C29.2401 48.8353 23.4131 48.5425 18.1448 44.6065C15.1983 42.3025 12.8725 39.3002 11.3776 35.8711C10.5856 34.0549 10.0761 32.1282 9.86687 30.1578C9.75744 29.0375 9.73668 27.9102 9.80477 26.7866C9.86034 25.0873 10.1133 23.4003 10.5587 21.7594C11.1778 19.5356 12.1879 17.4397 13.5417 15.5701C14.9802 13.6781 16.7417 12.0553 18.7449 10.7765L18.7472 10.775C20.3989 9.7062 25.0003 6.7285 30.6148 7.47336C32.1414 7.65146 33.617 8.13307 34.9548 8.88984C36.6559 9.89598 38.0321 11.37 38.9194 13.1363C40.0769 15.4573 40.2827 18.1386 39.4929 20.6091C39.0378 22.0416 38.2801 23.3594 37.2711 24.4732C36.2622 25.587 35.0256 26.4708 33.6451 27.0646C32.8552 27.3958 32.0284 27.6311 31.1825 27.7655C30.3514 27.8843 29.5108 27.922 28.6725 27.8778C26.47 27.9015 24.6991 29.0666 23.9423 29.5782C21.9551 30.9687 20.4965 32.9905 19.8033 35.3151C19.4121 36.8064 19.3583 38.3661 19.6457 39.8808C19.9332 41.3955 20.5546 42.8271 21.4648 44.0713C23.7202 47.1203 27.3061 48.0368 28.7167 48.3974Z"
        />
      </svg>
    </div>
  )
}

export const YinFlowerBehaleIcon: React.FC<IconBaseProps> = ({
  className,
  ...props
}) => {
  return (
    <div className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 108 111"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#filter0_d_1732_18016)">
          <path
            d="M56.858 49.6643C57.0754 49.7656 58.3935 44.4742 62.9186 41.9345C64.3646 41.1327 65.9537 40.6619 67.5703 40.5563C69.1868 40.4507 70.79 40.7129 72.2633 41.324C74.5453 42.365 76.3799 44.1782 77.4692 46.4689C77.8655 47.3389 78.7596 49.3706 78.3978 51.7099C78.2052 52.5933 78.0967 53.4899 78.0739 54.387C78.0644 55.302 78.1627 56.2099 78.3667 57.0914C78.7386 58.6337 79.4355 60.0616 80.4102 61.2784C81.3849 62.4951 82.6146 63.4722 84.0159 64.1433C86.4318 65.306 89.2402 65.4404 91.8429 64.5179C93.8248 63.8092 95.5902 62.5434 96.9288 60.8713C97.9458 59.5519 98.7025 58.0501 99.1544 56.4542C100.91 50.5942 98.6351 45.3195 97.8206 43.4293C96.8496 41.1363 95.4806 39.0544 93.7767 37.2796C92.0809 35.5978 90.0911 34.2506 87.9003 33.3013C86.2819 32.6132 84.5819 32.1229 82.8346 31.8404C81.6848 31.6204 80.5157 31.4941 79.3381 31.4628C77.2642 31.4256 75.1827 31.7125 73.166 32.3137C69.3584 33.4482 65.8463 35.5203 62.9473 38.3426C57.6256 43.5235 56.851 48.9768 56.858 49.6643Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter1_d_1732_18016)">
          <path
            d="M58.1424 55.3492C58.0411 55.5666 63.3325 56.8847 65.8721 61.4098C66.6739 62.8558 67.1448 64.445 67.2504 66.0615C67.356 67.678 67.0937 69.2813 66.4826 70.7546C65.4416 73.0365 63.6284 74.8711 61.3378 75.9604C60.4677 76.3567 58.4361 77.2508 56.0967 76.889C55.2133 76.6964 54.3167 76.5879 53.4196 76.5651C52.5047 76.5556 51.5967 76.6539 50.7153 76.8579C49.1729 77.2298 47.745 77.9267 46.5283 78.9014C45.3116 79.8761 44.3345 81.1058 43.6633 82.5071C42.5007 84.923 42.3662 87.7314 43.2887 90.3341C43.9975 92.316 45.2633 94.0814 46.9353 95.42C48.2548 96.437 49.7566 97.1937 51.3524 97.6456C57.2125 99.4011 62.4871 97.1263 64.3774 96.3118C66.6704 95.3408 68.7522 93.9718 70.527 92.2679C72.2089 90.5721 73.556 88.5823 74.5053 86.3915C75.1935 84.7731 75.6838 83.0731 75.9662 81.3259C76.1863 80.1761 76.3125 79.0069 76.3438 77.8294C76.3811 75.7554 76.0941 73.674 75.493 71.6572C74.3584 67.8496 72.2863 64.3375 69.4641 61.4385C64.2832 56.1168 58.8299 55.3422 58.1424 55.3492Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter2_d_1732_18016)">
          <path
            d="M51.142 56.9432C50.9246 56.8419 49.6066 62.1332 45.0814 64.6729C43.6354 65.4747 42.0463 65.9455 40.4297 66.0511C38.8132 66.1568 37.21 65.8945 35.7367 65.2834C33.4547 64.2424 31.6201 62.4292 30.5308 60.1386C30.1345 59.2685 29.2404 57.2369 29.6022 54.8975C29.7948 54.0141 29.9033 53.1175 29.9261 52.2204C29.9356 51.3054 29.8373 50.3975 29.6333 49.516C29.2614 47.9737 28.5645 46.5458 27.5898 45.3291C26.6151 44.1123 25.3854 43.1353 23.9841 42.4641C21.5682 41.3014 18.7598 41.167 16.1571 42.0895C14.1752 42.7983 12.4098 44.0641 11.0712 45.7361C10.0542 47.0556 9.29747 48.5574 8.84562 50.1532C7.09011 56.0133 9.36494 61.2879 10.1794 63.1782C11.1504 65.4711 12.5194 67.553 14.2233 69.3278C15.9191 71.0097 17.9089 72.3568 20.0997 73.3061C21.7181 73.9943 23.4181 74.4845 25.1654 74.767C26.3152 74.987 27.4843 75.1133 28.6619 75.1446C30.7358 75.1819 32.8173 74.8949 34.834 74.2937C38.6416 73.1592 42.1537 71.0871 45.0527 68.2649C50.3744 63.084 51.149 57.6307 51.142 56.9432Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter3_d_1732_18016)">
          <path
            d="M49.7844 49.142C49.8857 48.9246 44.5943 47.6065 42.0546 43.0814C41.2528 41.6354 40.782 40.0463 40.6764 38.4297C40.5708 36.8132 40.833 35.21 41.4441 33.7367C42.4852 31.4547 44.2983 29.6201 46.589 28.5308C47.459 28.1345 49.4907 27.2404 51.83 27.6022C52.7134 27.7948 53.61 27.9033 54.5071 27.9261C55.4221 27.9356 56.33 27.8373 57.2115 27.6333C58.7538 27.2614 60.1817 26.5645 61.3985 25.5898C62.6152 24.6151 63.5923 23.3854 64.2634 21.9841C65.4261 19.5682 65.5605 16.7598 64.6381 14.1571C63.9293 12.1752 62.6635 10.4098 60.9915 9.07124C59.672 8.05417 58.1702 7.29747 56.5744 6.84562C50.7143 5.09011 45.4396 7.36494 43.5494 8.17944C41.2564 9.15039 39.1745 10.5194 37.3997 12.2233C35.7179 13.9191 34.3708 15.9089 33.4215 18.0997C32.7333 19.7181 32.243 21.4181 31.9605 23.1654C31.7405 24.3152 31.6142 25.4843 31.5829 26.6619C31.5457 28.7358 31.8326 30.8173 32.4338 32.834C33.5684 36.6416 35.6404 40.1537 38.4627 43.0527C43.6436 48.3744 49.0969 49.149 49.7844 49.142Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1732_18016"
            x="48.8579"
            y="27.4595"
            width="58.9453"
            height="49.6646"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1732_18016"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1732_18016"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_1732_18016"
            x="34.6826"
            y="51.3491"
            width="49.6646"
            height="58.9453"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1732_18016"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1732_18016"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_1732_18016"
            x="0.196777"
            y="37.4834"
            width="58.9453"
            height="49.6646"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1732_18016"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1732_18016"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_1732_18016"
            x="23.5796"
            y="2.19678"
            width="49.6646"
            height="58.9453"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1732_18016"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1732_18016"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  )
}

export const BehaleIcon: React.FC<IBehaleIconProps> = ({
  variant = "yin",
  fill = "",
  ...props
}) => {
  switch (variant) {
    case "yin":
      return <YinBehaleIcon fill={fill} {...props} />
    case "yinFlower":
      return <YinFlowerBehaleIcon fill={fill} {...props} />
    default:
    case "yang":
      return <YangBehaleIcon fill={fill} {...props} />
  }
}
