import isNil from "lodash/isNil"

import {
  Language,
  MovementModality,
  SessionExpectedDuration,
  Weekday,
} from "./generated/graphql"
import { MOVEMENT, NAME_SPACES } from "./locales/constants"
import i18n from "./locales/i18n"
import { formatWeekday } from "./utils/format"

export const getWeekdayInitial = (weekday?: Weekday) => {
  if (isNil(weekday)) {
    return "-"
  }

  return formatWeekday(weekday).charAt(0).toUpperCase()
}

export const modalityLabels: Record<MovementModality, string> = i18n.t(
  `${NAME_SPACES.MOVEMENT}:${MOVEMENT.MODALITY}`,
  {
    returnObjects: true,
  }
)

export const expectedDurationLabels = {
  [SessionExpectedDuration.Nano]: "~2 min",
  [SessionExpectedDuration.Micro]: "~5 min",
  [SessionExpectedDuration.Mini]: "~10 min",
  [SessionExpectedDuration.Short]: "~20 min",
  [SessionExpectedDuration.Medium]: "~30 min",
  [SessionExpectedDuration.Long]: "~45 min",
  [SessionExpectedDuration.VeryLong]: "~1 h",
  [SessionExpectedDuration.HourAndHalf]: "~1 h 30 min",
  [SessionExpectedDuration.TwoHours]: "~2 h",
  [SessionExpectedDuration.ThreeHours]: "~3 h",
  [SessionExpectedDuration.FourHours]: "~4 h",
}

export const languageLabels = {
  [Language.Fr]: "🇫🇷 Français",
  [Language.En]: "🇬🇧 English",
}
