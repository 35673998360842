import clsx from "clsx"
import { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { RiCheckLine, RiLoader4Line } from "react-icons/ri"
import { useDebounce } from "use-debounce"

export interface MovementDescriptionTextareaProps {
  initialValue?: string | null
  onChange: (value: string | null | undefined) => void
  disabled?: boolean
  placeholder?: string
  loading?: boolean
  saved?: boolean
  className?: string
}

export const MovementDescriptionTextareaFormField: React.FC<
  Exclude<
    Partial<MovementDescriptionTextareaProps>,
    "initialValue" | "onChange"
  >
> = ({ ...props }) => {
  const { setValue, register } = useFormContext()

  const handleChange = (value: string | null | undefined) => {
    setValue("description", value)
  }

  useEffect(() => {
    register("description")
  }, [])

  return <MovementDescriptionTextarea {...props} onChange={handleChange} />
}

export const MovementDescriptionTextarea: React.FC<
  MovementDescriptionTextareaProps
> = ({
  initialValue,
  onChange,
  disabled = false,
  placeholder,
  loading = false,
  saved = false,
  className,
}) => {
  const [state, setState] = useState(initialValue)

  const [debouncedState, { cancel }] = useDebounce(state, 300)

  useEffect(() => {
    if (debouncedState !== initialValue) {
      onChange(debouncedState)
    }
  }, [debouncedState])

  useEffect(() => {
    return () => {
      cancel()
    }
  }, [])

  const classNames = clsx(
    "relative h-48 p-4 w-full rounded-md shadow-inner",
    "overflow-y-auto resize-none",
    "focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-primary-500",
    "whitespace-pre-wrap",
    className
  )

  if (disabled) {
    return <p className={classNames}>{state}</p>
  }

  return (
    <div className="relative w-full h-48">
      <textarea
        className={classNames}
        onChange={(event) => {
          setState(event.target.value)
        }}
        disabled={disabled}
        placeholder={placeholder}
        value={state || ""}
      ></textarea>
      <div className="absolute flex items-center justify-center bottom-2 right-2 text-neutral-700/80">
        {loading && <RiLoader4Line className="w-6 h-6 animate-spin" />}
        {saved && <RiCheckLine className="w-6 h-6" />}
      </div>
    </div>
  )
}
