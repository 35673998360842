import { filter, max, omit } from "lodash"
import {
  NotificationContextState,
  NotificationReducerActionType,
} from "./notificationTypes"

export const notificationReducer = (
  state: NotificationContextState,
  action: { type: NotificationReducerActionType; payload: any }
): NotificationContextState => {
  const { type, payload } = action

  switch (type) {
    case NotificationReducerActionType.PushNotificationTokenLoaded:
      return {
        ...state,
        isPushNotificationRegistered: true,
        devicePushNotificationToken: payload,
      }

    case NotificationReducerActionType.NotificationPermissionGranted:
      return {
        ...state,
        isInitialized: true,
        isEnabled: true,
        isNativePermissionGranted: true,
        isNativePermissionRequested: true,
      }

    case NotificationReducerActionType.NotificationPermissionRejected:
      return {
        ...state,
        isInitialized: true,
        isEnabled: false,
        isNativePermissionGranted: false,
        isNativePermissionRequested: true,
      }

    case NotificationReducerActionType.NotificationPermissionNotPrompted:
      return {
        ...state,
        isInitialized: true,
        isEnabled: false,
        isNativePermissionRequested: false,
        isNativePermissionGranted: false,
      }

    case NotificationReducerActionType.LocalNotificationsEnabled:
      return {
        ...state,
        isEnabled: true,
      }

    case NotificationReducerActionType.LocalNotificationsDisabled:
      return {
        ...state,
        isEnabled: false,
      }

    case NotificationReducerActionType.TemplatePermissionsLoaded:
      return {
        ...state,
        templatesAllowed: payload.templatesAllowed || state.templatesAllowed,
      }

    case NotificationReducerActionType.TemplateEnabled:
      return {
        ...state,
        isEnabled: true,
        templatesAllowed: [...state.templatesAllowed, payload.template],
      }

    case NotificationReducerActionType.TemplateDisabled:
      return {
        ...state,
        templatesAllowed: filter(
          state.templatesAllowed,
          (template) => template !== payload.template
        ),
      }

    case NotificationReducerActionType.NativeIdActivated:
      return {
        ...state,
        activeNativeIds: state.activeNativeIds.add(payload.id),
        maxNativeId: Math.max(state.maxNativeId, payload.id),
      }

    case NotificationReducerActionType.ReminderScheduled:
      return {
        ...state,
        reminderKeyMap: {
          ...state.reminderKeyMap,
          [payload.key]: payload.id,
        },
        reminders: {
          ...state.reminders,
          [payload.id]: {
            ...payload,
            status: "scheduled",
          },
        },
      }

    case NotificationReducerActionType.LocalNotificationsImported:
      return {
        ...state,
        maxNativeId: max(payload.activeNativeIds) || state.maxNativeId,
        activeNativeIds: new Set(payload.activeNativeIds),
        reminderKeyMap: payload.reminderKeyMap,
        reminders: payload.reminders,
      }

    case NotificationReducerActionType.ReminderDelivered:
      return {
        ...state,
        reminders: {
          ...state.reminders,
          [payload.id]: {
            ...state.reminders[payload.id],
            status: "delivered",
          },
        },
      }

    case NotificationReducerActionType.ReminderCancelled:
      return {
        ...state,
        activeNativeIds: new Set(
          filter(Array.from(state.activeNativeIds), (id) => id !== payload.id)
        ),
        reminderKeyMap: omit(state.reminderKeyMap, payload.key),
        reminders: omit(state.reminders, payload.uid),
      }

    case NotificationReducerActionType.BadgeCountUpdated:
      return {
        ...state,
        badgeCount: payload.count,
      }

    default:
      return state
  }
}
