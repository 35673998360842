import { ApolloError } from "@apollo/client"
import { Haptics, NotificationType } from "@capacitor/haptics"
import { ToastOptions, useIonToast } from "@ionic/react"
import { HookOverlayOptions } from "@ionic/react/dist/types/hooks/HookOverlayOptions"
import * as Sentry from "@sentry/capacitor"
import {
  alertCircleOutline,
  checkmarkCircleOutline,
  closeCircleOutline,
  closeOutline,
  informationCircleOutline,
} from "ionicons/icons"
import i18n from "../locales/i18n"

interface ToastProps extends ToastOptions, HookOverlayOptions {
  status: "success" | "danger" | "info" | "warning"
  onClose?: () => void
}

type ToastResult = {
  present: (props: ToastProps) => Promise<void>
  dismiss: () => Promise<void>
  showSuccess: (message: string, onClose?: () => void) => Promise<void>
  showError: (message: string | Error, onClose?: () => void) => Promise<void>
  showInfo: (message: string, onClose?: () => void) => Promise<void>
  showWarning: (message: string, onClose?: () => void) => Promise<void>
}

const useToast = (): ToastResult => {
  const [ionPresent, ionDismiss] = useIonToast()

  const getIcon = (status: ToastProps["status"]) => {
    switch (status) {
      case "success":
        return checkmarkCircleOutline
      case "danger":
        return closeCircleOutline
      case "warning":
        return alertCircleOutline
      case "info":
        return informationCircleOutline
      default:
        return
    }
  }

  const getColor = (status: ToastProps["status"]) => {
    if (status === "info") {
      return "white"
    }

    return status
  }

  const present = async ({
    message,
    status,
    icon,
    onClose,
    duration,
    ...props
  }: ToastProps) => {
    await ionPresent({
      message,
      color: getColor(status),
      animated: true,
      position: "top",
      buttons: [
        {
          icon: closeOutline,
          role: "cancel",
        },
      ],
      onWillDismiss: onClose,
      icon: icon || getIcon(status),
      duration: duration || 3000,
      ...props,
    })
  }

  const dismiss = async () => {
    await ionDismiss()
  }

  const showSuccess = async (message: string, onClose?: () => void) => {
    await Haptics.notification({
      type: NotificationType.Success,
    })

    await present({
      message,
      status: "success",
      onClose,
    })
  }

  const showInfo = async (message: string, onClose?: () => void) => {
    await present({
      message,
      status: "info",
      cssClass: "text-neutral",
      onClose,
    })
  }

  const showError = async (message: string | Error, onClose?: () => void) => {
    await Haptics.notification({
      type: NotificationType.Error,
    })

    Sentry.captureException(message)

    if (message instanceof Error) {
      // message = message.message
      const error = message

      if (error instanceof ApolloError) {
        console.warn(error.message, error.graphQLErrors)
      } else {
        console.warn(error.message)
      }

      await present({
        message: i18n.t("AUTH:AN_ERROR_HAS_OCCURED"),
        status: "danger",
        onClose,
      })
    } else {
      await present({
        message,
        status: "danger",
        onClose,
      })
    }
  }

  const showWarning = async (message: string, onClose?: () => void) => {
    await Haptics.notification({
      type: NotificationType.Warning,
    })

    await present({
      message,
      status: "warning",
      cssClass: "text-white font-semibold",
      onClose,
    })
  }

  return { present, dismiss, showSuccess, showWarning, showError, showInfo }
}

export default useToast
