import classNames, { clsx } from "clsx"
import { isNil, orderBy } from "lodash"
import React, { useEffect, useMemo } from "react"
import { IconType } from "react-icons/lib"
import { NAME_SPACES } from "../../locales/constants"
import { useTranslation } from "react-i18next"

export interface CardSelectOption<T> {
  value: T
  label: string
  Icon?: IconType
  information?: string
  recommended?: boolean
  color?: string
  disabled?: boolean
  extra?: any
}

export interface CardSelectOptionProps<T> {
  value: T
  label: string
  Icon?: IconType
  information?: string
  selected?: T | T[]
  handleSelect: (value: T) => void
  isSelected: (value: T) => boolean
  disabled?: boolean
  className?: string
}

const CardSelectOption = <T,>({
  value,
  label,
  Icon,
  information,
  children,
  handleSelect,
  isSelected,
  disabled,
  className,
}: React.PropsWithChildren<CardSelectOptionProps<T>>) => {
  return (
    <div className="flex flex-col w-full gap-y-3">
      <button
        type="button"
        disabled={disabled}
        className={classNames(
          "inline-flex items-center w-full px-4 py-3 my-1 text-base font-medium rounded-md shadow-sm gap-x-2 justify-between",
          "bg-white text-neutral-700 hover:ring-primary-500/40",
          disabled &&
            "disabled:opacity-50 disabled:saturate-50 disabled:select-none",
          isSelected(value) && `outline-none ring-4 ring-primary-500/60`,
          className
        )}
        onClick={disabled ? undefined : () => handleSelect(value)}
      >
        <div className="flex flex-row gap-x-2">
          {Icon && <Icon className="w-6 h-6" />}
          <span className="flex-grow truncate">{label}</span>
        </div>
        {information && (
          <span className="text-sm truncate text-neutral-400">
            {information}
          </span>
        )}
      </button>

      {children && (
        <div
          className={classNames(
            "rounded-md shadow-inner bg-neutral-200/20",
            isSelected(value) ? "visble" : "hidden"
          )}
        >
          {children}
        </div>
      )}
    </div>
  )
}

export interface CardSelectOptionGroup<T> {
  key: string
  label?: string

  className?: string

  options: CardSelectOption<T>[]
}

export interface CardSelectProps<T> {
  multi?: boolean
  options: CardSelectOption<T>[]
  onSelect: (value: any) => void
  selected?: T | T[]
  sort?: boolean
  filterBy?: (options: CardSelectOption<T>) => boolean
  groupBy?: (options: CardSelectOption<T>) => string
  groupOrder?: string[]
  groupLabels?: Record<string, string>
  recommendedLabel?: string
  className?: string
}

const CardSelect = <T,>({
  options,
  selected,
  onSelect,
  multi = false,
  sort = true,
  filterBy,
  groupBy,
  groupLabels,
  recommendedLabel,
  className = "p-4",
  children,
}: React.PropsWithChildren<CardSelectProps<T>>) => {
  const { t } = useTranslation(NAME_SPACES.COMMON)

  const [selectedOptions, setSelectedOptions] = React.useState<
    T | T[] | undefined
  >(selected)

  const [groupedOptions, setGroupedOptions] = React.useState<
    CardSelectOptionGroup<T>[]
  >([])

  const recommendedOptions = useMemo(() => {
    return options.filter((option) => option.recommended)
  }, [options])

  useEffect(() => {
    let newOptions = options.filter((option) => !option.recommended)

    if (!isNil(filterBy)) {
      newOptions = newOptions.filter(filterBy)
    }

    if (sort) {
      newOptions = orderBy(newOptions, "label")
    }

    if (isNil(groupBy)) {
      return setGroupedOptions([
        {
          key: "default",
          options: newOptions,
        },
      ])
    } else {
      const grouped = newOptions.reduce((acc, option) => {
        const key = groupBy(option)

        if (isNil(acc[key])) {
          acc[key] = []
        }

        acc[key].push(option)

        return acc
      }, {} as Record<string, CardSelectOption<T>[]>)

      return setGroupedOptions(
        Object.keys(grouped).map((key) => ({
          key,
          label: groupLabels?.[key] ?? key,
          options: grouped[key],
        }))
      )
    }
  }, [options, filterBy, sort, groupBy])

  const handleSelect = (value: T) => {
    if (multi && Array.isArray(selectedOptions)) {
      if (selectedOptions?.includes(value)) {
        return setSelectedOptions((s) => (s as T[]).filter((v) => v !== value))
      } else {
        return setSelectedOptions((s) => [...(s as T[]), value] as T[])
      }
    } else if (selectedOptions === value) {
      return setSelectedOptions(undefined)
    } else {
      return setSelectedOptions(multi ? [value] : value)
    }
  }

  const isSelected = (value: T) => {
    if (multi) {
      return (selectedOptions as T[])?.includes(value)
    } else {
      return selectedOptions === value
    }
  }

  useEffect(() => {
    onSelect(selectedOptions)
  }, [selectedOptions])

  useEffect(() => {
    if (selected !== selectedOptions) {
      setSelectedOptions(selected)
    }
  }, [selected])

  return (
    <div className={classNames("flex flex-col w-full gap-y-6", className)}>
      {recommendedOptions.length > 0 && (
        <div
          className={clsx(
            "flex flex-col w-full gap-y-3 p-4",
            "rounded-lg shadow-inner",
            "bg-gradient-to-br from-primary-400/80 to-primary-600/80 backdrop-blur"
          )}
        >
          <span className="font-semibold text-white">
            {recommendedLabel || t("RECOMMENDED")}
          </span>
          <div className="grid w-full grid-cols-1 gap-3">
            {recommendedOptions.map((option, index) => (
              <CardSelectOption
                key={index}
                selected={selected}
                handleSelect={handleSelect}
                isSelected={isSelected}
                className={clsx(
                  `bg-white opacity-80`,
                  isSelected(option.value) &&
                    `ring-4 ring-primary-500/60 opacity-100`
                )}
                {...option}
              >
                {children}
              </CardSelectOption>
            ))}
          </div>
        </div>
      )}

      {groupedOptions.map((group, index) => (
        <div key={index} className="flex flex-col w-full gap-y-3">
          {group.label && <span className="font-medium">{group.label}</span>}
          <div className="grid w-full grid-cols-1 gap-3">
            {group.options.map((option, index) => (
              <CardSelectOption
                key={index}
                selected={selected}
                handleSelect={handleSelect}
                isSelected={isSelected}
                {...option}
              >
                {children}
              </CardSelectOption>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default CardSelect
