/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react"
import { Subs } from "react-sub-unsub"
import { Timer, useTimer, TimerOptions } from "react-use-precision-timer"

export type UseMoveTimerOptions = TimerOptions & {
  repetitionDuration?: number
  repetitionCount?: number
}

export type MoveTimer = Timer & {
  secondsPassed: number
  secondsRemaining: number
  repetitionsCompleted?: number
  repetitionsRemaining?: number
}

const useMoveTimer = (options: UseMoveTimerOptions, callback: any = null) => {
  const timer = useTimer({ ...options }, callback)

  const [timePassed, setTimePassed] = useState(0)
  const [timeRemaining, setTimeRemaining] = useState(0)

  const [frameRate, setFrameRate] = useState(100)

  const [repetitionsCompleted, setRepetitionsCompleted] = useState(0)

  const [status, setStatus] = useState(
    "stopped" as "stopped" | "running" | "paused"
  )

  const [checkpoints, setCheckpoints] = useState<number[]>([])

  const updateTime = () => {
    setTimePassed(timer.getElapsedRunningTime())
    setTimeRemaining(timer.getRemainingTime())
  }

  const addCheckpoint = () => {
    const durationMS = timer.getElapsedRunningTime()

    setCheckpoints((prevCheckpoints) => [...prevCheckpoints, durationMS])
  }

  useEffect(() => {
    const subs = new Subs()

    subs.setInterval(() => {
      updateTime()
    }, frameRate)

    return subs.createCleanup()
  }, [])

  return {
    repetitionsCompleted,
    repetitionsRemaining: options.repetitionCount
      ? options.repetitionCount - repetitionsCompleted
      : undefined,
    secondsPassed: timePassed / 1000,
    secondsRemaining: timeRemaining / 1000,
    status,
    addCheckpoint,
    ...timer,
  }
}

export default useMoveTimer
