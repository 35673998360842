import { IonImg } from "@ionic/react"
import classNames, { clsx } from "clsx"
import { Trans, useTranslation } from "react-i18next"

import { BehaleIcon } from "../../components/BehaleIcon"
import Page from "../../components/Core/Page"
import Button from "../../components/Forms/Button"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { useLocaleContext } from "../../contexts/LocaleContext"
import { NAME_SPACES, WELCOME } from "../../locales/constants"

export const AppStoreLink: React.FC<React.HTMLAttributes<HTMLOrSVGElement>> = ({
  ...props
}) => {
  const { locale } = useLocaleContext()

  const AppStoreImage = ({ ...props }) =>
    locale === "fr" ? (
      <img src="/assets/images/apple-store-badge-fr.svg" {...props} />
    ) : (
      <img src="/assets/images/apple-store-badge.svg" {...props} />
    )

  return (
    <a
      href="https://apps.apple.com/fr/app/behale/id1627733355?itsct=apps_box_badge&amp;itscg=30200"
      target="_blank"
      rel="noopener noreferrer"
      className="rounded-xl"
    >
      <AppStoreImage {...props} />
    </a>
  )
}

export const PlayStoreLink: React.FC<
  React.HTMLAttributes<HTMLOrSVGElement>
> = ({ className, ...props }) => {
  const { locale } = useLocaleContext()

  const PlayStoreImage = ({ ...props }) =>
    locale === "fr" ? (
      <img src="/assets/images/google-play-badge-fr.png" {...props} />
    ) : (
      <img src="/assets/images/google-play-badge.png" {...props} />
    )

  return (
    // <a
    //   href={"https://play.google.com/store/apps/details?id=io.behale.crush"}
    //   target="_blank"
    //   rel="noopener noreferrer"
    //   download
    // >
    <PlayStoreImage className={clsx(className)} {...props} />
    // </a>
  )
}

const AppDownloadPage: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.WELCOME)
  const TEXT = t(WELCOME.GET_THE_APP, { returnObjects: true })

  const { captureEvent } = useAnalyticsContext()

  const Header = ({ label }: { label: any }) => {
    return (
      <div className="flex flex-col items-center w-full gap-y-4">
        <BehaleIcon fill="white" variant="yang" />
        <span className="text-3xl font-semibold tracking-tight text-white">
          {label}
        </span>
      </div>
    )
  }

  const title = `${TEXT.TITLE}`

  return (
    <Page className="bg-primary">
      <div
        className={classNames(
          "text-white text-center",
          "h-full flex flex-col justify-between p-8 gap-y-8 w-full max-w-3xl mx-auto"
        )}
      >
        <Header label={title} />

        <span className="text-lg tracking-tight text-white">
          <Trans t={t} i18nKey={`${WELCOME.GET_THE_APP}.DESCRIPTION`}></Trans>
        </span>

        <div className="flex-col items-center justify-center flex-grow hidden w-full h-full md:flex gap-y-4">
          <IonImg
            src="/assets/images/download_app_qr_code.svg"
            className="w-48 max-w-sm p-1 border-8 rounded-md xl:w-64 border-white/20"
          />
          <span className="font-medium">{TEXT.QR_CODE}</span>
        </div>

        <div className="flex flex-col items-center justify-around w-full divide-y-2 md:divide-y-0 md:flex-row divide-white/20">
          <div className="flex flex-col items-center justify-center w-full py-2">
            <AppStoreLink
              className="w-40 h-16 opacity-60"
              onClick={() => {
                captureEvent(AnalyticsEvent.DownloadAppLinkClicked, {
                  platform: "ios",
                })
              }}
            />
          </div>
          <div className="flex flex-col items-center justify-center w-full py-2">
            <PlayStoreLink
              className="w-40 h-16 py-[0.3rem]"
              aria-disabled
              onClick={() => {
                captureEvent(AnalyticsEvent.DownloadAppLinkClicked, {
                  platform: "android",
                })
              }}
            />
          </div>
        </div>

        <div className="flex flex-col gap-y-1">
          <Button
            label={TEXT.AGENDA_BUTTON}
            color="white"
            fill="clear"
            labelClassName="text-white"
            routerLink="/app/hub/home"
            routerDirection="forward"
          />
        </div>
      </div>
    </Page>
  )
}

export default AppDownloadPage
