import { cloneDeep } from "lodash"
import {
  MovementSessionSummaryFragment,
  MovementWeekScheduleFragment,
  Weekday,
} from "../../generated/graphql"
import {
  getWeekdayDate,
  getWeekdaySchedule,
  toWeekday,
  weekdayOrder,
} from "../../utils"
import { convertToLocalTime } from "./agendaTimeUtils"
import {
  AgendaDay,
  AgendaDaySchedule,
  AgendaSchedulingContextState,
  AgendaWeekSchedule,
} from "./agendaTypes"

export const buildToday = (timezone?: string): AgendaDay => {
  const date = convertToLocalTime(new Date(), timezone)

  const weekday = toWeekday(date.getDay())

  return {
    date,
    weekday,
  }
}

export const addActivityToDay = (
  day: AgendaDay,
  schedule: AgendaDaySchedule
) => {
  const clonedDay = cloneDeep(day)

  const nextItem = cloneDeep(schedule.items?.[0])

  const hasSession = schedule.sessions?.length > 0

  return {
    ...clonedDay,
    sessions: schedule.sessions,
    items: schedule.items,
    hasSession,
    nextItem,
  }
}

export const buildWeekScheduleOffsets = (schedule: AgendaWeekSchedule) => {
  const result = cloneDeep(schedule)

  let offset = 0
  for (const day of result) {
    day.weekScheduleOffset = offset

    offset += Math.max(day.items.length + day.sessions.length + 2, 3)
  }

  return result
}

export const buildVirtualWeekSchedule = ({
  startDate,
  itemsPerWeekday = {},
}: {
  startDate: Date
  itemsPerWeekday?: AgendaSchedulingContextState["recurringItemsPerWeekday"]
}): AgendaWeekSchedule => {
  const clonedItemsPerWeekday = cloneDeep(itemsPerWeekday)

  return buildWeekScheduleOffsets(
    weekdayOrder.map((weekday, index) => ({
      index,
      day: {
        date: getWeekdayDate(startDate, weekday),
        weekday,
      },
      items: clonedItemsPerWeekday[weekday] || [],
      sessions: [] as MovementSessionSummaryFragment[],
    })) as AgendaWeekSchedule
  )
}

export const buildWeekSchedule = (
  weekSchedule: MovementWeekScheduleFragment["schedule"],
  weekStartDate: Date
) => {
  const clonedSchedule = cloneDeep(weekSchedule)

  return buildWeekScheduleOffsets(
    weekdayOrder.map((weekday: Weekday, index: number) => {
      const dailySchedule = getWeekdaySchedule(clonedSchedule, weekday)

      return {
        index,
        day: {
          date: getWeekdayDate(weekStartDate, weekday),
          weekday,
        },
        items: dailySchedule.items,
        sessions: dailySchedule.sessions,
      }
    }) as AgendaWeekSchedule
  )
}
