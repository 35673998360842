import { mailOpenOutline } from "ionicons/icons"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

import { AUTH, NAME_SPACES } from "../../locales/constants"
import { Form } from "../Forms/Form"
import Input from "../Forms/Input"

export const ForgotPasswordForm: React.FC<{
  requestPasswordReset: (data: any) => Promise<void>
}> = ({ requestPasswordReset }) => {
  const { t } = useTranslation(NAME_SPACES.AUTH)
  const TEXT: any = t(AUTH.FORGOT_PASSWORD, {
    returnObjects: true,
  })
  const formSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  })

  return (
    <Form
      schema={formSchema}
      onSubmit={requestPasswordReset}
      submitButtonProps={{
        label: TEXT.SUBMIT,
        color: "secondary",
        icon: mailOpenOutline,
        disabled: false,
      }}
    >
      <Input
        name="email"
        label={t("EMAIL_LABEL")}
        type="email"
        color="white"
        inputmode="email"
        required
      />
    </Form>
  )
}
