import clsx from "clsx"
import * as React from "react"
import { MovementIcon } from "../../Core/Icons"
import { GetMovementAgendaItemQuery } from "../../../generated/graphql"
import { formatStyle, formatWeekday } from "../../../utils/format"
import isNil from "lodash/isNil"
import { useLocaleContext } from "../../../contexts/LocaleContext"
import { useAgendaSchedulingContext } from "../../../contexts/AgendaSchedulingContext"
import {
  getModalityTextColor,
  isBodyTreeEmpty,
} from "../../../utils/movementUtils"
import useActivityStyle from "../../../hooks/useActivityStyle"
import {
  MovementInformationTargetBodyPartsSpan,
  MovementInformationTimingSpan,
} from "../MovementInformationDetails"

export interface AgendaItemPageHeaderProps {
  agendaItem: Exclude<
    GetMovementAgendaItemQuery["movementAgendaItem"],
    null | undefined
  >
  canEdit: boolean
  handleOpenForm?: () => void
  isCoachSuggestion: boolean
}

export const AgendaItemPageHeader: React.FC<AgendaItemPageHeaderProps> = ({
  agendaItem,
  canEdit,
  handleOpenForm,
}) => {
  const { formatRelativeDate, parseISODate } = useLocaleContext()
  const { today } = useAgendaSchedulingContext()

  const activityStyle = useActivityStyle(agendaItem.movementStyle)

  const hasTargetBodyParts = !isBodyTreeEmpty(
    agendaItem.movementTargetBodyParts
  )

  const isExtraInfoAvailable =
    activityStyle?.isTargetBodyPartAvailable && hasTargetBodyParts

  const date = parseISODate(agendaItem.instanceDate)

  const instanceLabel = isNil(date)
    ? formatWeekday(agendaItem.weekday, agendaItem.isRecurring)
    : formatRelativeDate(date, today.date)

  const textColor = getModalityTextColor(agendaItem.movementModality)

  return (
    <div className={clsx(`bg-${agendaItem.movementModality}`, textColor)}>
      <div className="w-full max-w-3xl mx-auto">
        <div className="relative flex flex-col justify-between w-full p-4 mx-auto overflow-hidden gap-y-8">
          <div className="flex flex-row items-start justify-between w-full">
            <div className="flex flex-row items-center text-sm font-semibold tracking-tighter text-opacity-90 gap-x-1">
              <span className="font-bold uppercase">{instanceLabel}</span>
            </div>
          </div>

          <div className="flex flex-col justify-between w-full gap-y-1">
            <MovementInformationTimingSpan
              item={agendaItem}
              className="-mb-1"
            />
            <div
              className={clsx(
                "flex flex-row items-end justify-between gap-x-2 w-full",
                canEdit && "cursor-pointer"
              )}
              onClick={canEdit ? () => handleOpenForm?.() : undefined}
            >
              <div
                className={clsx("flex flex-row items-baseline gap-x-2 w-full")}
              >
                <span className={clsx("text-2xl font-semibold w-full")}>
                  {agendaItem.title || formatStyle(agendaItem.movementStyle)}
                </span>
              </div>

              <MovementIcon
                element={agendaItem}
                className="flex-shrink-0 w-12 h-12 p-1"
              />
            </div>
          </div>
        </div>
        {isExtraInfoAvailable && (
          <div
            className={clsx(
              "flex flex-row items-center justify-between w-full border-opacity-50 border-t-[0.55px] px-4 py-2"
            )}
          >
            <MovementInformationTargetBodyPartsSpan
              className="text-lg text-opacity-90"
              isTargetBodyPartAvailable={
                activityStyle?.isTargetBodyPartAvailable
              }
              item={agendaItem}
            />
          </div>
        )}
      </div>
    </div>
  )
}
