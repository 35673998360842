import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react"
import {
  CapacitorPurchases,
  CustomerInfo,
  EntitlementInfo,
  Offering,
  Package,
} from "@capgo/capacitor-purchases"
import { useAuthenticatedClientContext } from "./AuthenticatedClientContext"
import { DevicePlatform, MemberSubscriptionPlan } from "../generated/graphql"
import { Capacitor } from "@capacitor/core"

import {
  addBreadcrumb,
  captureException,
  captureMessage,
} from "@sentry/capacitor"
import isNil from "lodash/isNil"
import { useModalOrchestrationContext } from "./ModalOrchestrationContext"
import { getCurrentEnvironment } from "../utils"
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin"

export interface SubscriptionContextState {
  isInitialized: boolean
  isIdentified: boolean

  isFirstTimeUser?: boolean
  customerInfo?: CustomerInfo

  activeSubscriptionIds: string[]

  isPremium: boolean
  activeEntitlementLevel: "free" | "tier_1" | "tier_2" | "tier_3"
  activeEntitlements?: {
    [key: string]: EntitlementInfo
  }

  isOfferingLoading: boolean
  currentOffering?: Offering
  availablePackages: Package[]
}

export interface SubscriptionContextActions {
  identify: (id: string) => Promise<void>

  purchasePackage: (
    packageId: string,
    onSuccess?: (purchaserInfo: CustomerInfo) => void,
    onError?: (error: any) => void
  ) => Promise<void>

  restorePurchases: () => Promise<void>
}

export const PurchaseContext = createContext<
  (SubscriptionContextState & SubscriptionContextActions) | undefined
>(undefined)

export enum SubscriptionReducerActionType {
  Initialize = "Initialize",
  SetCurrentOffering = "SetCurrentOffering",
  SetCustomerInfo = "SetCustomerInfo",
  SetActiveEntitlementLevel = "SetActiveEntitlementLevel",
  RestoreInformation = "RestoreInformation",
}

const getActiveEntitlementLevel = (entitlements: {
  [key: string]: EntitlementInfo
}) => {
  if (entitlements["tier_3"]?.isActive) {
    return "tier_3"
  } else if (entitlements["tier_2"]?.isActive) {
    return "tier_2"
  } else if (entitlements["tier_1"]?.isActive) {
    return "tier_1"
  }

  return "free"
}

export enum PurchaseContextErrors {
  PurchaseCancelled = "PurchaseCancelled",
  UnknownError = "UnknownError",
}

const purchaseReducer = (
  state: SubscriptionContextState,
  action: {
    type: SubscriptionReducerActionType
    payload?: any
  }
): SubscriptionContextState => {
  const { type, payload } = action

  let activeEntitlementLevel: SubscriptionContextState["activeEntitlementLevel"]

  addBreadcrumb({
    message: `PurchaseReducerAction.${type}`,
    data: payload,
    type: "debug",
  })

  switch (type) {
    case SubscriptionReducerActionType.Initialize:
      return {
        ...state,
        isInitialized: true,
      }

    case SubscriptionReducerActionType.SetCurrentOffering:
      return {
        ...state,
        isOfferingLoading: false,
        currentOffering: payload.currentOffering as Offering,
        availablePackages: payload.currentOffering?.availablePackages || [],
      }

    case SubscriptionReducerActionType.SetCustomerInfo:
      if (isNil(payload?.customerInfo)) {
        return state
      }

      activeEntitlementLevel = getActiveEntitlementLevel(
        payload.customerInfo.entitlements.active
      )

      return {
        ...state,
        isIdentified: true,
        customerInfo: payload.customerInfo,
        isFirstTimeUser: payload.isFirstTimeUser || state.isFirstTimeUser,
        isPremium: activeEntitlementLevel !== "free",
        activeEntitlementLevel,
        activeEntitlements: payload.customerInfo.entitlements.active,
      }

    case SubscriptionReducerActionType.SetActiveEntitlementLevel:
      activeEntitlementLevel = payload.activeEntitlementLevel

      if (isNil(activeEntitlementLevel)) {
        return state
      }

      return {
        ...state,
        isPremium: activeEntitlementLevel !== "free",
        activeEntitlementLevel: activeEntitlementLevel,
      }

    default:
      return state
  }
}

export const useSubscriptionContext = () => {
  const context = useContext(PurchaseContext)

  if (context === undefined) {
    throw new Error(
      "useSubscriptionContextContext must be used within a PurchaseContext provider"
    )
  }

  return context
}

const initialPurchaseState: SubscriptionContextState = {
  isInitialized: false,
  isIdentified: false,
  isPremium: false,
  isFirstTimeUser: true,
  activeEntitlementLevel: "free",
  activeSubscriptionIds: [],
  isOfferingLoading: true,
  availablePackages: [],
}

export const storeActiveEntitlementLevel = async (level: string) => {
  if (!Capacitor.isNativePlatform()) return

  await SecureStoragePlugin.set({
    key: "purchasesActiveEntitlementLevel",
    value: level,
  })
}

export const loadActiveEntitlementLevelFromStorage = async () => {
  if (!Capacitor.isNativePlatform()) return

  return await SecureStoragePlugin.get({
    key: "purchasesActiveEntitlementLevel",
  })
    .then(({ value }) => {
      return value
    })
    .catch(() => {
      return null
    })
}

export const PurchaseProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    purchaseReducer,
    initialPurchaseState
  ) as [SubscriptionContextState, Dispatch<{ type: string; payload?: any }>]

  const { toggleLoading } = useModalOrchestrationContext()
  const {
    currentUser,
    isPlatformInitialized,
    platform,
    isSessionActive,
    currentMember,
    updateMemberProfile,
  } = useAuthenticatedClientContext()

  const loadOfferings = useCallback(
    async (nRetries = 0) => {
      if (!isNil(state.currentOffering)) {
        console.debug("[PurchaseContext] offerings already loaded")

        return
      }

      if (nRetries >= 5) {
        captureMessage(
          "[PurchaseContext] failed to load offerings - too many retries",
          "warning"
        )

        return
      }

      addBreadcrumb({
        category: "purchases",
        message: "Loading offerings",
        data: state,
      })

      try {
        const { offerings } = await CapacitorPurchases.getOfferings()

        console.debug("[PurchaseContext] loaded current offerings", offerings)

        addBreadcrumb({
          category: "purchases",
          message: "Loaded current offerings",
          data: offerings,
        })

        const currentOffering =
          offerings.current || Object.values(offerings.all)[0]

        dispatch({
          type: SubscriptionReducerActionType.SetCurrentOffering,
          payload: {
            currentOffering,
          },
        })
      } catch (error) {
        console.error("[PurchaseContext] failed to load offerings", error)

        addBreadcrumb({
          category: "purchases",
          message: "Failed to load offerings - retrying",
          type: "warning",
          data: {
            nRetries,
            error,
          },
        })

        setTimeout(() => {
          loadOfferings(nRetries + 1)
        }, 5000)
      }
    },
    [state]
  )

  const getApiKey = useCallback(() => {
    switch (platform) {
      case DevicePlatform.Ios:
        return process.env.REACT_APP_REVENUE_CAT_IOS_API_KEY as string
      case DevicePlatform.Android:
        return process.env.REACT_APP_REVENUE_CAT_ANDROID_API_KEY as string
      default:
        return process.env.REACT_APP_REVENUE_CAT_STRIPE_API_KEY as string
    }
  }, [platform])

  const handlePurchasesUpdate = async ({
    purchases,
    purchaserInfo,
  }: {
    purchases: Package
    purchaserInfo: CustomerInfo
  }) => {
    if (isNil(purchaserInfo)) {
      return
    }

    addBreadcrumb({
      category: "purchases",
      message: "Purchases update",
      type: "debug",
      data: {
        purchases,
        purchaserInfo,
      },
    })

    dispatch({
      type: SubscriptionReducerActionType.SetCustomerInfo,
      payload: {
        customerInfo: purchaserInfo,
      },
    })
  }

  const initializeContext = async () => {
    await CapacitorPurchases.setDebugLogsEnabled({
      enabled: getCurrentEnvironment() === "development",
    })

    try {
      await CapacitorPurchases.setup({
        apiKey: getApiKey(),
        appUserID: currentUser?.userUuid,
      })

      console.debug("[PurchaseContext] setup successful")

      addBreadcrumb({
        category: "purchases",
        message: "Setup successful",
        type: "debug",
      })

      dispatch({ type: SubscriptionReducerActionType.Initialize })
    } catch (error) {
      console.error("[PurchaseContext] setup failed", error)

      addBreadcrumb({
        category: "purchases",
        message: "Setup failed",
        type: "error",
      })
    }
  }

  const restorePurchases = useCallback(async () => {
    if (!state.isInitialized || !state.isIdentified) return

    const { purchaserInfo } = await CapacitorPurchases.restorePurchases()

    dispatch({
      type: SubscriptionReducerActionType.SetCustomerInfo,
      payload: { customerInfo: purchaserInfo },
    })

    if (!isNil(purchaserInfo)) {
      const activeEntitlementLevel = getActiveEntitlementLevel(
        purchaserInfo.entitlements.active
      )

      if (
        activeEntitlementLevel === "tier_1" &&
        currentMember?.subscriptionPlan !== MemberSubscriptionPlan.Hero
      ) {
        updateMemberProfile({
          subscriptionPlan: MemberSubscriptionPlan.Hero,
        })
      }
    }
  }, [state.isInitialized, state.isIdentified, currentMember])

  const handlePurchase = async (
    packageId: string,
    onSuccess?: (purchaserInfo: CustomerInfo) => void,
    onError?: (error: any) => void
  ) => {
    if (!state.isInitialized) {
      throw new Error("Not initialized")
    }

    if (!state.currentOffering) {
      throw new Error("No current offering")
    }

    toggleLoading(true, {
      background: "primary",
    })

    try {
      const { purchaserInfo } = await CapacitorPurchases.purchasePackage({
        identifier: packageId,
        offeringIdentifier: state.currentOffering?.identifier,
      })

      if (purchaserInfo.activeSubscriptions.includes(packageId)) {
        onSuccess?.(purchaserInfo)
      }
    } catch (error: any) {
      if (error?.message === "Purchase was cancelled.") {
        onError?.(PurchaseContextErrors.PurchaseCancelled)
      } else {
        console.error("[PurchaseContext] purchase error", error)

        onError?.(PurchaseContextErrors.UnknownError)
      }
    } finally {
      toggleLoading(false)
    }
  }

  const handleIdentify = useCallback(
    async (id: string) => {
      if (!state.isInitialized) return

      try {
        const { purchaserInfo: customerInfo, created } =
          await CapacitorPurchases.logIn({ appUserID: id })

        addBreadcrumb({
          category: "purchases",
          message: "Identified user",
          type: "debug",
          data: {
            id,
            created,
          },
        })

        dispatch({
          type: SubscriptionReducerActionType.SetCustomerInfo,
          payload: { customerInfo, isFirstTimeUser: created },
        })

        await loadOfferings()
      } catch (error) {
        console.error("[PurchaseContext] identify", error)

        captureException(error, {
          extra: {
            message: "Failed to identify user",
          },
        })
      }
    },
    [state.isInitialized]
  )

  // effect - identify user
  useEffect(() => {
    if (!state.isInitialized) return

    if (isNil(currentUser) || isNil(currentUser.userUuid)) return

    handleIdentify(currentUser.userUuid)
  }, [currentUser, state.isInitialized])

  // effect - initialize context
  useEffect(() => {
    if (!isPlatformInitialized) return
    if (!isSessionActive) return

    if (Capacitor.isNativePlatform()) {
      initializeContext()
    }
  }, [isPlatformInitialized, isSessionActive])

  useEffect(() => {
    if (isNil(currentMember)) return

    if (currentMember.subscriptionPlan === MemberSubscriptionPlan.Hero) {
      dispatch({
        type: SubscriptionReducerActionType.SetActiveEntitlementLevel,
        payload: { activeEntitlementLevel: "tier_1" },
      })
    }
  }, [currentMember])

  useEffect(() => {
    CapacitorPurchases.addListener(
      "purchasesUpdate",
      handlePurchasesUpdate as any
    )
  }, [])

  const value = {
    ...state,
    identify: handleIdentify,
    purchasePackage: handlePurchase,
    restorePurchases,
  }

  return (
    <PurchaseContext.Provider value={value}>
      {children}
    </PurchaseContext.Provider>
  )
}
