import isNil from "lodash/isNil"
import * as React from "react"
import { useTranslation } from "react-i18next"
import { MovementModality } from "../../generated/graphql"
import { MOVEMENT, NAME_SPACES } from "../../locales/constants"
import { ThumbnailButton } from "../Core/ThumbnailButton"
import { IoLinkOutline } from "react-icons/io5"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import clsx from "clsx"
import { Browser } from "@capacitor/browser"

export const SuggestedExternalLinkPreview: React.FC<{
  suggestedExternalLink?: string | null
  movementModality: MovementModality
  className?: string
}> = ({ suggestedExternalLink, movementModality, className }) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.AGENDA_ITEM, {
    returnObjects: true,
  })

  const cleanLink = suggestedExternalLink?.trim()

  const { captureEvent } = useAnalyticsContext()

  const onClick = async () => {
    if (!cleanLink) {
      return
    }

    captureEvent(AnalyticsEvent.SuggestedExternalLinkClicked, {
      externalLink: cleanLink,
    })

    Browser.open({
      url: cleanLink,
      presentationStyle: "popover",
      windowName: "_blank",
    })
  }

  if (isNil(movementModality) || isNil(suggestedExternalLink)) {
    return null
  }

  return (
    <ThumbnailButton
      background={movementModality}
      onClick={onClick}
      overlayOpacity={50}
      className={clsx("text-white rounded-lg", className)}
    >
      <div className="flex flex-col justify-between w-full h-full p-4">
        <div className="flex flex-col-reverse items-start w-full h-full">
          <div className="flex flex-row items-center justify-start w-full gap-x-2">
            <IoLinkOutline className="w-6 h-6" />
            <span className="w-full text-xl font-bold text-left">
              {TEXT.SUGGESTED_EXTERNAL_LINK}
            </span>
          </div>
        </div>
      </div>
    </ThumbnailButton>
  )
}
