import { range } from "lodash"
import { useTranslation } from "react-i18next"
import { ModalOrchestrationName } from "../../../../contexts/ModalOrchestrationContext"

import { SessionExpectedDuration } from "../../../../generated/graphql"
import { MOVEMENT, NAME_SPACES } from "../../../../locales/constants"
import { expectedDurationOrder } from "../../../../utils"
import { formatSessionDuration } from "../../../../utils/format"
import {
  GridFormField,
  GridFormFieldProps,
  RequiredFieldModalBodyProps,
} from "../../../Forms/GridFormField"
import { Slider, SliderProps } from "../../../Forms/Slider"
import { DurationIcon } from "../../../Core/Icons"

export type DurationSliderState = number | SessionExpectedDuration

export type DurationSliderProps = SliderProps<DurationSliderState> &
  RequiredFieldModalBodyProps<DurationSliderState>

export const ExactDurationSlider: React.FC<DurationSliderProps> = ({
  state,
  setState,
}) => {
  const maxDuration = 60 * 4 // 4 hours

  const durationOptions = range(1, maxDuration, 1).map(
    (duration) => duration * 60
  )

  const initialValue = Number(state) || durationOptions[0]

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <Slider<number>
        onChange={setState}
        values={durationOptions}
        formatValue={formatSessionDuration}
        initialValue={initialValue}
      />
    </div>
  )
}

export const ApproximateDurationSlider: React.FC<DurationSliderProps> = ({
  state,
  setState,
}) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <Slider<DurationSliderState>
        onChange={setState}
        values={expectedDurationOrder}
        formatValue={formatSessionDuration}
        initialValue={state}
      />
    </div>
  )
}

export interface DurationGridFormFieldProps
  extends Omit<
    GridFormFieldProps<DurationSliderState>,
    "Body" | "label" | "modalName"
  > {
  name: string
  type?: "exact" | "approximate"
}

export const DurationGridFormField: React.FC<DurationGridFormFieldProps> = ({
  name,
  type = "exact",
  ...props
}) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)

  const { DURATION }: any = t(MOVEMENT.FORM, {
    returnObjects: true,
  })

  const Body =
    type === "exact" ? ExactDurationSlider : ApproximateDurationSlider

  return (
    <GridFormField<DurationSliderState>
      name={name}
      modalName={ModalOrchestrationName.FormFieldDurationSlider}
      label={DURATION.LABEL}
      title={DURATION.TITLE}
      Body={Body}
      Icon={DurationIcon}
      formatValue={formatSessionDuration}
      showDismiss
      {...props}
    />
  )
}
