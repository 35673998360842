import { useTranslation } from "react-i18next"

import { MEMBERSHIP, NAME_SPACES } from "../../locales/constants"

import { useRouteMatch } from "react-router"
import { useMutation, useQuery } from "@apollo/client"

import isNil from "lodash/isNil"
import { useEffect } from "react"
import {
  AddBuddyDocument,
  AddBuddyMutation,
  GetMemberWithBuddiesDocument,
  GetMemberWithBuddiesQuery,
} from "../../generated/graphql"
import useToast from "../../hooks/useToast"
import { useIonRouter } from "@ionic/react"
import Page from "../../components/Core/Page"
import Loading from "../../components/Loading"
import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"

const MemberPublicProfilePage: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)
  const TEXT = t(MEMBERSHIP.PROFILE, { returnObjects: true })

  const router = useIonRouter()

  const { showInfo, showSuccess } = useToast()

  const match = useRouteMatch<{ uuid: string }>()

  const { currentMember } = useAuthenticatedClientContext()

  const { data, loading } = useQuery<GetMemberWithBuddiesQuery>(
    GetMemberWithBuddiesDocument,
    {
      skip: isNil(match.params.uuid),
      variables: {
        memberUuid: match.params.uuid,
      },
    }
  )

  const [addBuddy] = useMutation<AddBuddyMutation>(AddBuddyDocument, {
    variables: {
      buddyUuid: match.params.uuid,
    },
    onCompleted: () => {
      showSuccess(TEXT.BUDDIES.FEEDBACK.SUCCESS)

      router.push("/app/hub/profile", "forward", "replace")
    },
  })

  useEffect(() => {
    if (loading) return

    if (isNil(currentMember)) return

    const alreadyBuddies = data?.getMemberPublicProfile?.buddies?.find(
      (b) => b.memberUuid === currentMember?.memberUuid
    )

    if (alreadyBuddies) {
      showInfo(TEXT.BUDDIES.FEEDBACK.ALREADY_BUDDIES)

      router.push("/app/hub/profile", "forward", "replace")
    } else {
      addBuddy()
    }
  }, [loading, data, currentMember])

  return (
    <Page>
      <Loading />
    </Page>
  )
}

export default MemberPublicProfilePage
