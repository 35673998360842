import { useContext } from "react"
import { useTranslation } from "react-i18next"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"

import {
  Language,
  MovementStyle,
  SearchFollowAlongsQueryVariables,
  SessionExpectedDuration,
} from "../../generated/graphql"
import { MICO, NAME_SPACES } from "../../locales/constants"
import Modal, { ModalContext, ModalProps, ModalSize } from "../Core/Modal"
import { GridForm } from "../Forms/GridForm"
import {
  BodyPartGridFormField,
  BodyPartSelectState,
} from "../Movement/Forms/Fields/BodyPartSelect"
import { DurationGridFormField } from "../Movement/Forms/Fields/DurationSlider"
import { MovementStyleGridFormField } from "../Movement/Forms/Fields/MovementStyleSelect"
import { extractMovementTargetBodyParts } from "../Movement/Modals/MovementSessionFormModal"
import { LanguageSelectFormField } from "./Forms/LanguageSelect"

interface VideoExplorerFilterFormProps {
  filters: SearchFollowAlongsQueryVariables
  setFilters: (filters: SearchFollowAlongsQueryVariables) => void
}

interface VideoExplorerFilterFormData {
  expectedDuration?: SessionExpectedDuration | null
  language?: Language | null
  styles?: MovementStyle[]
  bodyPartSelectState?: BodyPartSelectState
}

export const VideoExplorerFilterForm: React.FC<
  VideoExplorerFilterFormProps
> = ({ filters }) => {
  const { t } = useTranslation(NAME_SPACES.MICO)
  const TEXT = t(MICO.FORM, { returnObjects: true })

  const { name } = useContext(ModalContext)
  const { closeModal } = useModalOrchestrationContext()

  const handleSearch = (data: VideoExplorerFilterFormData) => {
    const newFilters = {
      ...filters,
      styles: data.styles || [],
      expectedDuration: data.expectedDuration || null,
      language: data.language || null,
      bodyPartSelectState: data.bodyPartSelectState,
      targetBodyPart: extractMovementTargetBodyParts(data.bodyPartSelectState),
    } as SearchFollowAlongsQueryVariables

    closeModal(name, "search", newFilters)
  }

  const handleClear = () => {
    closeModal(name, "clear")
  }

  const defaultValues = {
    ...filters,
    styles: filters.styles as MovementStyle[],
  }

  return (
    <GridForm<VideoExplorerFilterFormData>
      onValidSubmit={handleSearch}
      defaultValues={defaultValues}
      submitCTA={TEXT.SEARCH}
      onClear={handleClear}
    >
      <MovementStyleGridFormField
        name="styles"
        filterBy={(style) => style.extra.isFollowAlongVideoAvailable}
        multi
        showClearButton
        recommend="none"
      />
      <BodyPartGridFormField name="bodyPartSelectState" alwaysShow />
      <LanguageSelectFormField name="language" />
      <DurationGridFormField
        type="approximate"
        name="expectedDuration"
        showClearButton
      />
    </GridForm>
  )
}

export const VideoExplorerFilterModal: React.FC<Partial<ModalProps>> = ({
  name = ModalOrchestrationName.VideoExplorerFilters,
  ...props
}) => {
  const { t } = useTranslation(NAME_SPACES.MICO)
  const TEXT = t(MICO.EXPLORER, { returnObjects: true })

  return (
    <Modal name={name} isSheet initialSize={ModalSize.Full} {...props}>
      <Modal.Header title={TEXT.FILTERS.TITLE} />

      <Modal.Body>
        {(props: any) => <VideoExplorerFilterForm {...props} />}
      </Modal.Body>
    </Modal>
  )
}

export default VideoExplorerFilterForm
