import React, { useCallback, useMemo, useState } from "react"

import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../../contexts/AnalyticsContext"
import { useAgendaSchedulingContext } from "../../../contexts/AgendaSchedulingContext"
import { clsx } from "clsx"

import { formatPhaseOfDay } from "../../../utils/format"
import { RiAddLine, RiCheckLine } from "react-icons/ri"
import {
  useModalOrchestrationContext,
  ModalOrchestrationName,
} from "../../../contexts/ModalOrchestrationContext"
import {
  MovementAgendaItemSummaryFragment,
  MovementActivitySummaryFragment,
  Weekday,
  PhaseOfDay,
} from "../../../generated/graphql"
import { differenceBy, filter, groupBy } from "lodash"
import Button from "../../Forms/Button"
import { PhaseOfDayIcon } from "../../Core/Icons"
import { phaseOfDayOrder } from "../../../utils"
import { TodayActivityCard } from "./CoreHabitCard"
import { CoreHabitPreviewModal } from "./CoreHabitPreviewModal"
import Toggle from "../../Forms/Toggle"

export const CoreHabitsSection: React.FC = () => {
  return (
    <div className="flex flex-col w-full h-full mx-auto gap-y-4">
      <CoreHabits />

      <CoreHabitPreviewModal />
    </div>
  )
}

// const phaseOfDayHourCutoffs = [
//   12, // Morning
//   18, // Afternoon
//   3, // Evening
// ]

export const CoreHabits: React.FC = () => {
  const {
    agenda,
    selectedDay,
    selectedWeekday,
    selectedWeekSchedule,
    agendaItemActionLoading,
    sessionActionLoading,
    isPast,
    isToday,
  } = useAgendaSchedulingContext()
  const { openModal } = useModalOrchestrationContext()
  const { captureEvent } = useAnalyticsContext()

  const [includeUsualActivities, setIncludeUsualActivities] = useState(false)

  const itemsPlannedForToday = useMemo(
    () => selectedDay.items || [],
    [agenda, selectedDay, selectedWeekSchedule, agendaItemActionLoading]
  )
  const sessionsDoneToday = useMemo(
    () => selectedDay.sessions || [],
    [agenda, selectedDay, selectedWeekSchedule, sessionActionLoading]
  )

  const usualActivitiesRemainingToday = useMemo(() => {
    const activities: MovementActivitySummaryFragment[] =
      agenda?.favoriteMovementActivities || []

    const activitiesUsuallyDoneToday = activities.filter((activity) =>
      activity.usualWeekdays?.includes(selectedWeekday as Weekday)
    )

    const activitiesAlreadyPlannedForPhase = filter(
      itemsPlannedForToday,
      (activity: MovementAgendaItemSummaryFragment) =>
        itemsPlannedForToday.find(
          (item) =>
            item.movementModality === activity.movementModality &&
            item.plannedPhaseOfDay === activity.plannedPhaseOfDay
        )
    )

    const activitiesAlreadyDoneInPhase = filter(
      activities,
      (activity: MovementActivitySummaryFragment) =>
        sessionsDoneToday.find(
          (session) =>
            session.movementModality === activity.movementModality &&
            session.reportedPhaseOfDay === activity.usualPhaseOfDay
        )
    )

    const result = differenceBy(
      activitiesUsuallyDoneToday,
      activitiesAlreadyPlannedForPhase,
      "uuid"
    )

    return differenceBy(result, activitiesAlreadyDoneInPhase, "uuid")
  }, [agenda, selectedWeekday, selectedWeekSchedule, sessionActionLoading])

  const remainingHabitsPerPhase = useMemo(() => {
    const remainingHabits = includeUsualActivities
      ? [...itemsPlannedForToday, ...usualActivitiesRemainingToday]
      : itemsPlannedForToday

    return groupBy(
      remainingHabits,
      (activity) =>
        (activity as MovementActivitySummaryFragment).usualPhaseOfDay ||
        (activity as MovementAgendaItemSummaryFragment).plannedPhaseOfDay ||
        "any"
    )
  }, [
    itemsPlannedForToday,
    usualActivitiesRemainingToday,
    includeUsualActivities,
  ])

  const completedHabitsPerPhase = useMemo(
    () =>
      groupBy(
        sessionsDoneToday,
        (activity) => activity.reportedPhaseOfDay || "any"
      ),
    [sessionsDoneToday]
  )

  const handleAddActivity = useCallback(
    (section: string, phaseOfDay?: PhaseOfDay) => {
      captureEvent(AnalyticsEvent.TodayActivityAddClicked, {
        section,
      })
      switch (section) {
        case "planned":
          openModal(ModalOrchestrationName.MovementAgendaItemForm, {
            day: selectedDay,
            phaseOfDay,
          })
          break
        case "done":
          openModal(ModalOrchestrationName.MovementSessionForm, {
            day: selectedDay,
            phaseOfDay,
          })
          break
        case "usual":
          openModal(ModalOrchestrationName.MovementActivityForm, {
            day: selectedDay,
          })
          break
      }
    },
    [openModal]
  )

  const HeaderButton = ({ phase }: { phase: PhaseOfDay }) => {
    const shouldLog = isPast(selectedDay) && !isToday(selectedDay)

    return (
      <Button
        icon={shouldLog ? RiCheckLine : RiAddLine}
        fill="clear"
        color="neutral"
        slot="icon-only"
        onClick={() => handleAddActivity(shouldLog ? "done" : "planned", phase)}
        paddingWidth="thin"
        size="small"
      />
    )
  }

  const EmptyButton = () => {
    const shouldLog = isPast(selectedDay) && !isToday(selectedDay)

    return (
      <div
        className={clsx(
          "flex flex-row items-center justify-start w-full gap-2",
          "py-4 px-4",
          "rounded-md shadow-inner border border-dashed border-slate-300"
        )}
      >
        <span className="text-left text-base font-medium text-slate-600">
          {shouldLog ? "No activity logged" : "No activity planned"}
        </span>
      </div>
    )
  }

  const remainingElements = useMemo(
    () =>
      phaseOfDayOrder.map((phase) => [
        ...(remainingHabitsPerPhase[phase] || []),
      ]),
    [remainingHabitsPerPhase]
  )

  const completedElements = useMemo(
    () =>
      phaseOfDayOrder.map((phase) => [
        ...(completedHabitsPerPhase[phase] || []),
      ]),
    [completedHabitsPerPhase]
  )

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col h-full w-full items-center">
        <div className="flex flex-col w-full items-center px-4 py-4 bg-primary-50 gap-4">
          <Toggle
            label="Include usual activities"
            className="w-full max-w-lg"
            labelClassName="italic"
            onToggle={setIncludeUsualActivities}
            isEnabled={includeUsualActivities}
          />
          {(remainingHabitsPerPhase.any || completedHabitsPerPhase.any) && (
            <div className="flex flex-col max-w-lg gap-y-4 w-full">
              {remainingHabitsPerPhase.any?.map((activity) => (
                <TodayActivityCard activity={activity} key={activity.uuid} />
              ))}
              {completedHabitsPerPhase.any?.map((activity) => (
                <TodayActivityCard activity={activity} key={activity.uuid} />
              ))}
            </div>
          )}
        </div>
        <div className="flex flex-col h-full w-full items-center">
          {phaseOfDayOrder.map((phase, index) => (
            <div
              className={clsx(
                "flex flex-col w-full items-center py-6 px-4 h-full",
                phase === PhaseOfDay.Morning &&
                  "border-t border-slate-200 bg-slate-50",
                phase === PhaseOfDay.Afternoon &&
                  "border-t border-slate-300 bg-slate-100",
                phase === PhaseOfDay.Evening &&
                  "border-t border-slate-400 bg-slate-200 pb-16"
              )}
              key={phase}
            >
              <div
                className="flex flex-col w-full gap-y-4 max-w-lg h-full"
                key={phase}
              >
                <div className="flex flex-row items-center justify-between w-full gap-2">
                  <h2 className="text-xl font-medium text-slate-600 inline-flex gap-x-2 items-center">
                    <PhaseOfDayIcon phase={phase} className="w-6 h-6" />
                    {formatPhaseOfDay(phase)}
                  </h2>
                  <HeaderButton phase={phase} />
                </div>
                {remainingElements[index].length === 0 &&
                completedElements[index].length === 0 ? (
                  <EmptyButton />
                ) : (
                  <div className="flex flex-col w-full divide-y divide-slate-300">
                    {remainingElements[index].length > 0 && (
                      <div
                        className={clsx(
                          "flex flex-col w-full h-full gap-2",
                          completedElements[index].length > 0 && "pb-4"
                        )}
                      >
                        {remainingElements[index].map((activity) => (
                          <TodayActivityCard
                            activity={activity}
                            key={activity.uuid}
                          />
                        ))}
                      </div>
                    )}
                    {completedElements[index].length > 0 && (
                      <div
                        className={clsx(
                          "flex flex-col w-full h-full gap-2",
                          remainingElements[index].length > 0 && "pt-4"
                        )}
                      >
                        {completedElements[index].map((activity) => (
                          <TodayActivityCard
                            activity={activity}
                            key={activity.uuid}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
