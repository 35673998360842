import { isNil, range } from "lodash"
import { useTranslation } from "react-i18next"
import { Time } from "../../../../contexts/AgendaSchedulingContext/agendaTimeUtils"
import { formatTime } from "../../../../contexts/LocaleContext"
import { ModalOrchestrationName } from "../../../../contexts/ModalOrchestrationContext"

import { MOVEMENT, NAME_SPACES } from "../../../../locales/constants"
import {
  FieldModalBodyProps,
  GridFormField,
  GridFormFieldProps,
} from "../../../Forms/GridFormField"
import { Slider, SliderProps } from "../../../Forms/Slider"
import { TimeIcon } from "../../../Core/Icons"
import i18n from "../../../../locales/i18n"

export type TimeSliderState = Time

export type TimeSliderProps = SliderProps<TimeSliderState> &
  FieldModalBodyProps<TimeSliderState>

const formatTimeState = (time?: TimeSliderState) => {
  if (isNil(time) || time.toString().length === 0) {
    return i18n.t(
      `${NAME_SPACES.MOVEMENT}:${MOVEMENT.FORM}.START_TIME.PLACEHOLDER`
    )
  }

  return formatTime(time, "HH:mm")
}

export const TimeSlider: React.FC<TimeSliderProps> = ({ state, setState }) => {
  const timeOptions = range(0, 24, 0.25).map((hours) => ({
    hours: Math.floor(hours),
    minutes: Math.round((hours % 1) * 60),
    seconds: 0,
  }))

  const defaultTime = {
    hours: 9,
    minutes: 0,
    seconds: 0,
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <Slider<TimeSliderState>
        onChange={setState}
        values={timeOptions}
        formatValue={formatTimeState}
        initialValue={state || defaultTime}
      />
    </div>
  )
}

export interface TimeGridFormFieldProps
  extends Omit<
    GridFormFieldProps<TimeSliderState>,
    "Body" | "label" | "modalName"
  > {
  name: string
  window: "past" | "future"
}

export const TimeGridFormField: React.FC<TimeGridFormFieldProps> = ({
  name,
  window = "past",
  ...props
}) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)

  const { START_TIME } = t(MOVEMENT.FORM, {
    returnObjects: true,
  })

  const Body = TimeSlider

  const { TITLE } = window === "past" ? START_TIME.PAST : START_TIME.FUTURE

  return (
    <GridFormField<TimeSliderState>
      name={name}
      modalName={ModalOrchestrationName.FormFieldTimePicker}
      label={START_TIME.LABEL}
      title={TITLE}
      // subtitle={SUBTITLE}
      Body={Body}
      Icon={TimeIcon}
      formatValue={formatTimeState}
      showDismiss
      showClearButton
      {...props}
    />
  )
}
