import { useIonRouter } from "@ionic/react"
import { useEffect } from "react"
import Page from "../components/Core/Page"
import Loading from "../components/Loading"
import { useAuthenticatedClientContext } from "../contexts/AuthenticatedClientContext"

const BaseLoadingPage: React.FC = () => {
  const router = useIonRouter()

  const { isInitialized, isClientAuthenticated } =
    useAuthenticatedClientContext()

  useEffect(() => {
    if (isInitialized) {
      if (isClientAuthenticated) {
        router.push("/app", "root", "replace")
      } else {
        router.push("/auth", "root", "replace")
      }
    }
  }, [isInitialized, isClientAuthenticated])

  return (
    <Page>
      <Loading />
    </Page>
  )
}

export default BaseLoadingPage
