import { UseIonRouterResult } from "@ionic/react"

export const pathnameIncludes = (
  router: UseIonRouterResult,
  pathnames: string | string[]
) => {
  if (typeof pathnames === "string") {
    return router.routeInfo.pathname.includes(pathnames)
  } else {
    return pathnames.some((pathname) =>
      router.routeInfo.pathname.includes(pathname)
    )
  }
}

export const isInProtectedRoute = (router: UseIonRouterResult) => {
  return pathnameIncludes(router, "/app") && !pathnameIncludes(router, "/mico")
}

export const isInAuthRoute = (router: UseIonRouterResult) => {
  return pathnameIncludes(router, ["/auth"])
}

export const wasInAuthRoute = (router: UseIonRouterResult) => {
  return router.routeInfo.lastPathname?.includes("/auth")
}
