export enum MovementWizardQuestion {
  LatestMovement = "LATEST_MOVEMENT",
  CurrentMovementVibe = "CURRENT_MOVEMENT_VIBE",
  PhyiscalDiscomforts = "PHYSICAL_DISCOMFORTS",
}

export const QUESTION_ORDER = [
  MovementWizardQuestion.LatestMovement,
  MovementWizardQuestion.CurrentMovementVibe,
  MovementWizardQuestion.PhyiscalDiscomforts,
]
