import { useQuery } from "@apollo/client"
import { IonInfiniteScrollCustomEvent } from "@ionic/core"
import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  useIonRouter,
} from "@ionic/react"
import { isNil, uniqBy } from "lodash"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { IoOptionsOutline, IoSearchCircle } from "react-icons/io5"
import { useDebounce } from "use-debounce"
import Content from "../../components/Core/Content"

import { VideoExplorerFilterModal } from "../../components/Mico/VideoExplorerFilterModal"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { useLocaleContext } from "../../contexts/LocaleContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import {
  Language,
  SearchFollowAlongsDocument,
  SearchFollowAlongsQuery,
  SearchFollowAlongsQueryVariables,
  SqualoFollowAlongVideo,
} from "../../generated/graphql"
import { MICO, NAME_SPACES } from "../../locales/constants"
import { pathnameIncludes } from "../../utils/routes"
import Button from "../Forms/Button"
import Loading from "../Loading"
import FollowAlongPreviewModal from "./Modals/FollowAlongPreviewModal"
import { MicoResourceCard } from "./MicoResourceCard"
import clsx from "clsx"

export const resetMicoFilters = (
  props: Partial<SearchFollowAlongsQueryVariables> = {}
): SearchFollowAlongsQueryVariables =>
  ({
    search: null,
    styles: [],
    language: null,
    expectedDuration: null,
    targetBodyPart: null,
    bodyPartSelectState: null,
    limit: 10,
    offset: 0,
    ...props,
  } as SearchFollowAlongsQueryVariables)

export interface VideoExplorerPageProps {
  className?: string
  onSelect?: (video: SearchFollowAlongsQuery["searchFollowAlongs"][0]) => void
}

export interface MicoVideoGalleryContentProps {
  className?: string
  loadMore?: (event: IonInfiniteScrollCustomEvent<void>) => void
  searching?: boolean
  videos?: SearchFollowAlongsQuery["searchFollowAlongs"]
  onSelect?: (video: SqualoFollowAlongVideo, index?: number) => void
  filters?: SearchFollowAlongsQueryVariables
  setFilters?: (filters: SearchFollowAlongsQueryVariables) => void
  handleOpenFilters?: () => void
}

export const MicoVideoGalleryContent: React.FC<
  MicoVideoGalleryContentProps
> = ({
  onSelect,
  filters,
  setFilters,
  handleOpenFilters,
  videos,
  searching,
  loadMore,
  className,
}) => {
  const { t } = useTranslation(NAME_SPACES.MICO)
  const TEXT = t(MICO.EXPLORER, {
    returnObjects: true,
  })

  const handleSelectResource = (
    video: SqualoFollowAlongVideo,
    index?: number
  ) => {
    onSelect?.(video, index)
  }

  return (
    <Content scrollY className={"py-8 bg-neutral-100"}>
      {!isNil(filters) && !isNil(setFilters) && (
        <div className="w-full bg-primary">
          <div className="flex flex-row items-center justify-center w-full max-w-3xl px-2 py-2 mx-auto">
            <IonSearchbar
              className="flex-grow p-0 pl-2 bg-primary"
              color="white"
              placeholder={TEXT.PLACEHOLDER_SEARCH}
              showClearButton="focus"
              onIonChange={(event) => {
                setFilters?.({ ...filters, search: event.detail.value || null })
              }}
            />

            <Button
              className="flex-shrink-0"
              iconClassName="text-white h-9 w-9"
              icon={IoOptionsOutline}
              onClick={handleOpenFilters}
              paddingWidth={"thin"}
            />
          </div>
        </div>
      )}

      {searching && <Loading overlay background="neutral-100" />}

      <div
        className={clsx(
          "relative grid h-full max-w-3xl grid-cols-1 gap-4 px-4 py-4 mx-auto overflow-y-overflow sm:grid-cols-2",
          className
        )}
      >
        {videos &&
          videos.map((video) => (
            <MicoResourceCard
              key={video?.uuid}
              video={video as SqualoFollowAlongVideo}
              onSelect={handleSelectResource}
            />
          ))}

        {!searching && videos && videos.length === 0 && (
          <div className="absolute inset-0 flex flex-col items-center justify-center p-8 gap-y-6 text-neutral">
            <IoSearchCircle className="w-20 h-20" />
            <span className="text-lg font-semibold text-center">
              {TEXT.EMPTY_MESSAGE}
            </span>
          </div>
        )}
      </div>

      {!isNil(loadMore) && (
        <IonInfiniteScroll
          className="my-16 text-neutral"
          onIonInfinite={(event) => loadMore?.(event)}
          disabled={videos?.length === 0}
        >
          <IonInfiniteScrollContent
            loadingSpinner="circles"
            loadingText="Loading..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      )}
    </Content>
  )
}

const MicoVideoGalleryTab: React.FC = () => {
  const { language, isInitialized } = useLocaleContext()
  const { captureEvent } = useAnalyticsContext()
  const { openModal } = useModalOrchestrationContext()

  const router = useIonRouter()
  const isInPage = pathnameIncludes(router, "/app/hub/discover")

  const [videos, setVideos] = useState<
    SearchFollowAlongsQuery["searchFollowAlongs"]
  >([])

  const [filters, setFilters] = useState<SearchFollowAlongsQueryVariables>(
    resetMicoFilters()
  )

  const [isSearchStale, setIsSearchStale] = useState(false)

  const [debouncedSearchQuery, { cancel: cancelSearchDebounce }] = useDebounce(
    filters.search,
    100
  )

  const { loading: searching, fetchMore } = useQuery<
    SearchFollowAlongsQuery,
    SearchFollowAlongsQueryVariables
  >(SearchFollowAlongsDocument, {
    skip: !isSearchStale,
    variables: filters,
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-and-network",
    onCompleted: async (data) => {
      if (data && data.searchFollowAlongs) {
        setVideos(data.searchFollowAlongs)

        setIsSearchStale(false)
      }
    },
  })

  const handleCloseFilters = (reason?: string, data?: any) => {
    if (reason === "back") {
      return
    }

    if (!isNil(data) && reason === "search") {
      setFilters(data)
    } else if (reason === "clear") {
      setFilters(resetMicoFilters())
    }

    setIsSearchStale(true)
  }

  const handleOpenFilters = () => {
    captureEvent(AnalyticsEvent.MicoExplorerFiltersOpened, {
      filters,
    })

    openModal(ModalOrchestrationName.VideoExplorerFilters, {
      filters,
      setFilters,
    })
  }

  const loadMore = async (event: IonInfiniteScrollCustomEvent<void>) => {
    const { data: moreVideos } = await fetchMore({
      variables: {
        ...filters,
        offset: videos.length,
      },
    })

    if (
      moreVideos &&
      moreVideos.searchFollowAlongs &&
      moreVideos.searchFollowAlongs.length > 0
    ) {
      setVideos(
        uniqBy([...videos, ...moreVideos.searchFollowAlongs], (v) => v.uuid)
      )
    }

    event.target.complete()
  }

  const handleSelectResource = (
    followAlong: SearchFollowAlongsQuery["searchFollowAlongs"][0]
  ) => {
    openModal(ModalOrchestrationName.FollowAlongPreview, {
      followAlong,
    })
  }

  const handleClosePreview = (reason?: string, data?: any) => {
    if (reason === "started") {
      const videoPath = `/app/hub/discover/videos/${data.uuid}`

      router.push(videoPath, "forward")
    } else if (reason === "scheduled") {
      const itemPath = `/app/hub/agenda/items/${data.uuid}`

      router.push(itemPath, "forward")
    }
  }

  useEffect(() => {
    const trueLanguage = language as unknown as Language

    if (isInPage && isInitialized && filters.language != trueLanguage) {
      setFilters({ ...filters, language: trueLanguage })
      setIsSearchStale(true)
    }
  }, [isInitialized, language])

  useEffect(() => {
    setFilters({ ...filters, search: debouncedSearchQuery })
    setIsSearchStale(true)
  }, [debouncedSearchQuery])

  useEffect(() => {
    return cancelSearchDebounce
  }, [])

  return (
    <>
      <MicoVideoGalleryContent
        onSelect={handleSelectResource}
        filters={filters}
        setFilters={setFilters}
        handleOpenFilters={handleOpenFilters}
        videos={videos}
        searching={searching}
        loadMore={loadMore}
      />

      <VideoExplorerFilterModal onClose={handleCloseFilters} />
      <FollowAlongPreviewModal onClose={handleClosePreview} />
    </>
  )
}

export default MicoVideoGalleryTab
