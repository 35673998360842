import { useMemo } from "react"
import Button from "../../Forms/Button"
import clsx from "clsx"
import { getModalityContrastColor } from "../../../utils/movementUtils"
import { MoveSnackTemplate } from "./type"
import { formatDuration } from "./util"
import { getIcon } from "../../Core/Icons"

export const MoveSnackNotStarted = ({
  template,
  startSession,
}: {
  template: MoveSnackTemplate
  startSession: () => void
}) => {
  const color = useMemo(() => {
    return template.movementModality
  }, [template.movementModality])

  const contrastColor = useMemo(() => {
    return getModalityContrastColor(template.movementModality)
  }, [template.movementModality])

  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-around w-full h-full p-8"
      )}
    >
      <div className="flex flex-col items-start justify-center gap-y-4 flex-grow w-full">
        <span className="text-xl font-semibold tracking-tight">
          {formatDuration(template.duration)}
        </span>
        <span className="text-5xl font-bold tracking-tight text-left">
          {template.name}
        </span>
      </div>

      <div className="flex flex-col items-center justify-center py-8">
        <Button
          icon={getIcon(template)}
          fill="clear"
          color={color}
          iconSlot="icon-only"
          paddingWidth="thin"
          className={clsx(
            "rounded-full aspect-1",
            "shadow-xl",
            "h-20 w-20",
            `bg-${contrastColor}`
          )}
          iconClassName={clsx("text-5xl md:text-6xl", color)}
          onClick={startSession}
        />
      </div>
    </div>
  )
}
