import { Route, Switch } from "react-router"
import ElementSelectionPage from "../pages/Welcome/ElementSelectionPage"
import AppDownloadPage from "../pages/Welcome/AppDownloadPage"

const PublicRouter: React.FC = () => {
  return (
    <Switch>
      <Route path="/public/download" exact component={AppDownloadPage} />

      <Route path="/public/elements" exact component={ElementSelectionPage} />
    </Switch>
  )
}

export default PublicRouter
