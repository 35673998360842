import { SessionExpectedDuration } from "../generated/graphql"

export const convertApproximateDurationToSeconds = (
  duration?: SessionExpectedDuration | null
): number | null => {
  switch (duration) {
    case SessionExpectedDuration.Nano:
      return 60
    case SessionExpectedDuration.Micro:
      return 300
    case SessionExpectedDuration.Mini:
      return 600
    case SessionExpectedDuration.Short:
      return 1200
    case SessionExpectedDuration.Medium:
      return 1800
    case SessionExpectedDuration.Long:
      return 3600
    case SessionExpectedDuration.VeryLong:
      return 4500
    case SessionExpectedDuration.HourAndHalf:
      return 5400
    case SessionExpectedDuration.TwoHours:
      return 7200
    case SessionExpectedDuration.ThreeHours:
      return 10800
    case SessionExpectedDuration.FourHours:
      return 14400
    default:
      return null
  }
}
