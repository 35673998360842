import { IonIcon } from "@ionic/react"
import { ModalOrchestrationName } from "../../contexts/ModalOrchestrationContext"
import {
  MemberPublicProfile,
  MovementSessionSummaryFragment,
} from "../../generated/graphql"
import { ProfileHeader } from "../../pages/Profile/ProfileHomePage"
import Modal, { ModalSize } from "../Core/Modal"
import { MovementStyleSpan } from "../Movement/Style"
import { checkmarkDoneOutline } from "ionicons/icons"
import { useEffect } from "react"

export const BuddyProfileModalBody: React.FC<{
  buddy: MemberPublicProfile
}> = ({ buddy }) => {
  useEffect(() => {
    console.debug("Buddy profile modal body", buddy)
  }, [buddy])
  return (
    <div className="flex flex-col">
      <ProfileHeader profile={buddy} />

      <div className="flex flex-col gap-y-4">
        {buddy.recentMovementSessions?.map((session, index) => (
          <div className="flex-grow max-w-full p-2 overflow-hidden" key={index}>
            <div className="flex flex-row items-center text-sm gap-x-1 text-neutral">
              <MovementStyleSpan
                element={session as MovementSessionSummaryFragment}
                className="truncate"
              />
              <div className="flex items-center justify-center px-2 opacity-80 gap-x-2">
                <IonIcon
                  icon={checkmarkDoneOutline}
                  className="w-6 h-6"
                  color="success"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const BuddyProfileModal: React.FC = () => {
  return (
    <Modal
      name={ModalOrchestrationName.BuddyProfile}
      isSheet
      initialSize={ModalSize.Most}
    >
      <Modal.Body>
        {(props: any) => <BuddyProfileModalBody {...props} />}
      </Modal.Body>
    </Modal>
  )
}
