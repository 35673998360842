import React, { useContext, useState } from "react"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"

import {
  ListAdventureTemplatesQuery,
  SearchFollowAlongsQueryVariables,
  SqualoAdventure,
  SqualoFollowAlongVideo,
} from "../../../generated/graphql"
import Modal, { ModalContext, ModalProps } from "../../Core/Modal"
import { MicoVideoGalleryContent } from "../MicoVideoGalleryTab"
import FollowAlongPreviewModal from "./FollowAlongPreviewModal"
import isNil from "lodash/isNil"
import TabBar, { TabBarElement } from "../../Core/TabBar"
import { useTranslation } from "react-i18next"
import { MICO, NAME_SPACES } from "../../../locales/constants"
import { MicoAdventureGalleryContent } from "../MicoAdventureGalleryTab"
import clsx from "clsx"
import { getCurrentEnvironment } from "../../../utils"

export interface ResourceExplorerModalBodyProps {
  videos: SqualoFollowAlongVideo[]
  adventures?: SqualoAdventure[]
  movementModality?: string
  filters?: SearchFollowAlongsQueryVariables
}

export const ResourceExplorerModalBody: React.FC<
  ResourceExplorerModalBodyProps
> = ({ videos, adventures }) => {
  const { t } = useTranslation(NAME_SPACES.MICO)
  const TEXT = t(MICO.HOME, { returnObjects: true })

  const { name } = useContext(ModalContext)

  const { closeModal, openModal } = useModalOrchestrationContext()

  const tabs = [
    {
      name: "ADVENTURES",
      label: TEXT.TABS.ADVENTURES,
      // icon: IoCompassOutline,
    },
    {
      name: "VIDEOS",
      label: TEXT.TABS.VIDEOS,
      // icon: IoVideocam,
    },
    {
      name: "ACTIVITIES",
      label: TEXT.TABS.ACTIVITIES,
      hidden: getCurrentEnvironment() === "production",
      // icon: TbRun,
    },
    {
      name: "COACHES",
      label: TEXT.TABS.COACHES,
      hidden: getCurrentEnvironment() === "production",
      // icon: RiUserSearchLine,
    },
  ] as TabBarElement[]

  const [activeTab, setActiveTab] =
    useState<TabBarElement["name"]>("ADVENTURES")

  const handleSelect = (followAlong: SqualoFollowAlongVideo) => {
    openModal(
      ModalOrchestrationName.FollowAlongPreview,
      {
        followAlong,
      },
      {
        onClose: handlePreviewClose,
      }
    )
  }

  const handlePreviewClose = (reason?: string, data?: any) => {
    if (reason === "started" || reason === "scheduled") {
      closeModal(name, reason, data)
    }
  }

  const handleAdventureClick = (adventure: SqualoAdventure) => {
    closeModal(name, "adventure-chosen", adventure)
  }

  const hasAdventures = !isNil(adventures) && adventures.length > 0

  return (
    <div className="h-full pb-8-safe">
      <TabBar
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        color="white"
        className={clsx(
          "shadow-lg bg-tertiary overflow-x-scroll overflow-y-clip",
          !hasAdventures ? "hidden" : ""
        )}
      />

      {activeTab === "ADVENTURES" && hasAdventures && (
        <MicoAdventureGalleryContent
          adventureTemplates={
            adventures as ListAdventureTemplatesQuery["listAdventures"]
          }
          onAdventureClick={handleAdventureClick}
          className="pb-24"
        />
      )}

      {activeTab === "ACTIVITIES" && (
        <iframe
          title="decathlon-activities"
          src={"https://activites.decathlon.fr/fr-FR/c/fitness-gym-yoga"}
          width="100%"
          height={"100%"}
        />
      )}

      {activeTab === "COACHES" && (
        <iframe
          title="decathlon-coaches"
          src={"https://trainme.co/coach-sportif-43"}
          width="100%"
          height={"100%"}
        />
      )}

      {(activeTab === "VIDEOS" || !hasAdventures) && (
        <MicoVideoGalleryContent
          videos={videos}
          onSelect={handleSelect}
          className="pb-24"
        />
      )}

      <FollowAlongPreviewModal />
    </div>
  )
}

export type ResourceExplorerModalProps = Partial<ModalProps>

export const ResourceExplorerModal: React.FC<ResourceExplorerModalProps> = ({
  name = ModalOrchestrationName.VideoExplorer,
  ...props
}) => {
  return (
    <Modal name={name} fullScreen {...props}>
      <Modal.Header showCloseButton background="tertiary" />

      <Modal.Body scrollY={false}>
        {(props: any) => <ResourceExplorerModalBody {...props} />}
      </Modal.Body>
    </Modal>
  )
}

export default ResourceExplorerModal
