import { Capacitor } from "@capacitor/core"
import { useIonRouter } from "@ionic/react"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import Content from "../../components/Core/Content"
import Page from "../../components/Core/Page"
import { useModalOrchestrationContext } from "../../contexts/ModalOrchestrationContext"
import useToast from "../../hooks/useToast"
import { ADVENTURE, NAME_SPACES } from "../../locales/constants"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import { useAdventureContext } from "../../contexts/AdventureContext"

const AdventureKickOffPage: React.FC = () => {
  const router = useIonRouter()

  const { t } = useTranslation(NAME_SPACES.ADVENTURE)
  const TEXT = t(ADVENTURE.KICK_OFF, { returnObjects: true })

  const { refetchAgenda } = useAgendaSchedulingContext()
  const { refetchAdventures } = useAdventureContext()
  const { toggleLoading } = useModalOrchestrationContext()
  const { present } = useToast()

  // todo - add a message that the program is ready
  // todo - opportunity to add a loading bar here

  // todo - opportunity for behavior contract / 1st session commitment

  const LoadingChildren = () => (
    <div className="flex flex-col items-center justify-center w-full px-4 mt-8 text-white gap-y-4">
      <span className="text-2xl font-semibold">{TEXT.TITLE}</span>
      <span className="text-lg font-medium text-center">{TEXT.SUBTITLE}</span>
    </div>
  )

  useEffect(() => {
    toggleLoading(true, {
      background: "bg-primary",
      children: <LoadingChildren />,
    })

    refetchAgenda()
    refetchAdventures()

    setTimeout(() => {
      toggleLoading(false)

      present({
        message: TEXT.TOAST.SUCCESS,
        status: "success",
        duration: 3_000,
      })

      router.push(
        Capacitor.isNativePlatform()
          ? "/app/hub/home"
          : "/app/welcome/download",
        "root",
        "replace"
      )
    }, 1_500)
  }, [])

  return (
    <Page className="bg-primary">
      <Content color="primary" fullscreen scrollY={false}></Content>
    </Page>
  )
}

export default AdventureKickOffPage
