import { useTranslation } from "react-i18next"

import Content from "../../components/Core/Content"

import Page from "../../components/Core/Page"
import { COMMON, NAME_SPACES } from "../../locales/constants"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import { ButtonProps } from "../../components/Forms/Button"
import clsx from "clsx"
import { useLocaleContext } from "../../contexts/LocaleContext"

import { useIonRouter } from "@ionic/react"
import { useEffect, useMemo } from "react"
import { useAdventureContext } from "../../contexts/AdventureContext"
import { AgendaTimelineIndex } from "../../components/Movement/AgendaManagement/AgendaTimelineIndex"
import MovementAgendaItemFormModal from "../../components/Movement/Modals/MovementAgendaItemFormModal"
import { CoreHabitsSection } from "../../components/Today/Habits/CoreHabits"
import MovementSessionFormModal from "../../components/Movement/Modals/MovementSessionFormModal"
import { MovementActivityFormModal } from "../../components/Movement/Modals/MovementActivityFormModal"
import { RiSettings2Line } from "react-icons/ri"

const TodayHomePage: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.COMMON)

  const router = useIonRouter()

  const {
    refetchAgenda,
    selectedWeek,
    selectedWeekday,
    getDate,
    today,
    selectedDay,
    isToday,
    selectToday,
  } = useAgendaSchedulingContext()
  const { refetchAdventures } = useAdventureContext()
  const { formatDate } = useLocaleContext()

  const isTodaySelected = useMemo(() => {
    return isToday(selectedDay)
  }, [selectedDay, isToday])

  const selectedDate = useMemo(() => {
    return getDate(selectedWeekday)
  }, [selectedWeekday, selectedWeek])

  const title = useMemo(
    () => t(`${COMMON.WEEKDAYS}.${selectedWeekday || today.weekday}`),
    [selectedWeekday, today, selectedWeek]
  )

  const subtitle = useMemo(
    () => formatDate(selectedDate, "dd MMMM"),
    [selectedDate]
  )

  const toolbarButtons = useMemo(() => {
    const buttons = [
      {
        icon: RiSettings2Line,
        fill: "clear",
        onClick: () => router.push("/app/profile/activities"),
      },
    ] as ButtonProps[]

    if (!isTodaySelected) {
      buttons.push({
        label: t("TODAY"),
        onClick: () => selectToday(),
        slot: "start",
      })
    }

    return buttons as ButtonProps[]
  }, [isTodaySelected])

  useEffect(() => {
    if (router.routeInfo.pathname?.includes("/app/hub/home")) {
      refetchAdventures()
      refetchAgenda()
    }
  }, [router.routeInfo.pathname])

  return (
    <Page>
      <Page.Header
        title={title}
        subtitle={subtitle}
        toolbarButtons={toolbarButtons}
        color="primary"
      >
        <AgendaTimelineIndex />
      </Page.Header>

      <Content slot="fixed" scrollY={true} scrollX={false}>
        <div className={clsx("flex flex-col h-full gap-y-4", "relative")}>
          <CoreHabitsSection />
        </div>
      </Content>

      <MovementAgendaItemFormModal />
      <MovementSessionFormModal />
      <MovementActivityFormModal />
    </Page>
  )
}

export default TodayHomePage
