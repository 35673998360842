import { useTranslation } from "react-i18next"
import { RiNotificationLine, RiNotificationOffLine } from "react-icons/ri"

import { ReminderTemplate } from "../../contexts/NotificationContext/notificationTypes"
import { useNotificationContext } from "../../contexts/NotificationContext"
import { MEMBERSHIP, NAME_SPACES } from "../../locales/constants"
import { GridFormField } from "../Forms/GridFormField"
import Toggle from "../Forms/Toggle"
import { ModalOrchestrationName } from "../../contexts/ModalOrchestrationContext"

export const TemplateToggle: React.FC<{
  template: ReminderTemplate
  onEnable?: () => void
}> = ({ template, onEnable }) => {
  const { t } = useTranslation(NAME_SPACES.NOTIFICATIONS)

  const { isTemplateEnabled, enableTemplate, disableTemplate } =
    useNotificationContext()

  const toggleNotificationTemplate = (value: boolean) => {
    if (value) {
      // Enable all notifications
      enableTemplate(template, onEnable)
    } else {
      // Disable all notifications
      disableTemplate(template)
    }
  }

  return (
    <Toggle
      label={t(`LOCAL.${template}.label`)}
      onToggle={(value) => toggleNotificationTemplate(value)}
      isEnabled={isTemplateEnabled(template)}
    />
  )
}

const NotificationSettingsBody = () => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)
  const {
    SETTINGS: {
      FIELDS: { NOTIFICATIONS },
    },
  } = t(MEMBERSHIP.PROFILE, { returnObjects: true })

  const {
    enableNotifications,
    disableNotifications,
    isEnabled,
    isNativePermissionGranted,
  } = useNotificationContext()

  return (
    <div className="flex flex-col w-full h-full p-4 divide-y gap-y-4 divide-neutral-300">
      <Toggle
        label={NOTIFICATIONS.FIELD.OPTIONS.ALL_NOTIFICATIONS}
        onToggle={(value) => {
          if (value) {
            enableNotifications()
          } else {
            disableNotifications()
          }
        }}
        locked={!isNativePermissionGranted}
        isEnabled={isEnabled}
      />

      <div className="flex flex-col py-4 gap-y-4">
        <TemplateToggle template={ReminderTemplate.WeekAheadPreview} />
        <TemplateToggle template={ReminderTemplate.MorningReminder} />
      </div>
    </div>
  )
}

export const NotificationSettingsField: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)
  const {
    SETTINGS: {
      FIELDS: { NOTIFICATIONS },
    },
  } = t(MEMBERSHIP.PROFILE, { returnObjects: true })

  const { isInitialized, isEnabled } = useNotificationContext()

  const formatNotificationSettings = (_val: any) => {
    if (isEnabled) {
      return NOTIFICATIONS.FIELD.VALUES.ALL_ENABLED
    }

    return NOTIFICATIONS.FIELD.VALUES.ALL_DISABLED
  }

  return (
    <GridFormField
      name={"notifications"}
      modalName={ModalOrchestrationName.NotificationSettings}
      label={NOTIFICATIONS.FIELD.LABEL}
      Body={NotificationSettingsBody}
      Icon={isEnabled ? RiNotificationLine : RiNotificationOffLine}
      title={NOTIFICATIONS.TITLE}
      formatValue={formatNotificationSettings}
      showDismiss
      showSubmitButton={false}
      hidden={!isInitialized}
      required
    />
  )
}
