import pkceChallenge from "pkce-challenge"

import {
  AccountsIdentityProvider,
  IdentifyUserWithProviderMutationVariables,
} from "../../../generated/graphql"
import Button, { ButtonProps } from "../../Forms/Button"
import { useEffect } from "react"
import { getAppLaunchUrlQuery } from "../../../listeners/AppStateListener"
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin"
import { App } from "@capacitor/app"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../../contexts/AnalyticsContext"
import { useAuthenticatedClientContext } from "../../../contexts/AuthenticatedClientContext"
import { GoogleScope, authenticateWithGoogle } from "./utils"
import { useModalOrchestrationContext } from "../../../contexts/ModalOrchestrationContext"
import { useIonRouter } from "@ionic/react"

export interface ContinueWithGoogleButtonProps extends ButtonProps {
  query?: URLSearchParams
  scope: GoogleScope
  forcePrompt: boolean
  withLoading?: boolean
  onFullfilled: (
    data: IdentifyUserWithProviderMutationVariables,
    onCompleted?: () => void
  ) => Promise<void>
}

const ContinueWithGoogleButton: React.FC<ContinueWithGoogleButtonProps> = ({
  query,
  scope,
  forcePrompt,
  onFullfilled,
  ...props
}) => {
  const router = useIonRouter()

  const { platform } = useAuthenticatedClientContext()
  const { captureEvent } = useAnalyticsContext()

  const { toggleLoading } = useModalOrchestrationContext()

  const handleAuthCodeResponse = async (query: URLSearchParams) => {
    const authCode = query.get("code")

    const { value: storedCodeVerifier } = await SecureStoragePlugin.get({
      key: "googleCodeVerifier",
    })

    if (authCode && storedCodeVerifier) {
      await onFullfilled(
        {
          platform,
          provider: AccountsIdentityProvider.Google,
          authCode,
          codeVerifier: storedCodeVerifier,
        },
        () => toggleLoading(false)
      )
    }
  }

  const handleFulfilled = async (codeVerifier: string, response: any) => {
    const { authorization_response } = response

    if (authorization_response.code) {
      await onFullfilled(
        {
          platform,
          provider: AccountsIdentityProvider.Google,
          authCode: authorization_response.code,
          codeVerifier,
        },
        () => toggleLoading(false)
      )
    }
  }

  const handleRejected = async (error: any) => {
    toggleLoading(false)

    switch (error.message) {
      case "ERR_NO_AUTHORIZATION_CODE":
        // this is normal in mobile
        break

      case "access_denied":
        captureEvent(AnalyticsEvent.ContinueWithGoogleAccessDenied)

        router.push("/auth/home", "root", "replace")

        break

      case "USER_CANCELLED":
        captureEvent(AnalyticsEvent.ContinueWithGoogleCancelled)
        break

      default:
        console.warn("GoogleSignInButton:handleError:default", error)
        break
    }
  }

  const storeCodeVerifier = async (codeVerifier: string) => {
    await SecureStoragePlugin.set({
      key: "googleCodeVerifier",
      value: codeVerifier,
    })
  }

  const handleClick = async () => {
    captureEvent(AnalyticsEvent.ContinueWithGoogleClicked)

    toggleLoading(true, { background: "primary" })

    const { code_challenge, code_verifier } = pkceChallenge(128)

    await storeCodeVerifier(code_verifier)

    await authenticateWithGoogle({
      scope,
      forcePrompt,
      codeVerifier: code_verifier,
      codeChallenge: code_challenge,
      handleFulfilled,
      handleRejected,
    })
  }

  useEffect(() => {
    if (query && query.has("code")) {
      handleAuthCodeResponse(query)
    } else if (query && query.has("error")) {
      handleRejected({ message: query.get("error") })
    }
  }, [query])

  useEffect(() => {
    App.addListener("appUrlOpen", async (data) => {
      getAppLaunchUrlQuery(data).then((params) => {
        if (params.has("code")) {
          handleAuthCodeResponse(params)
        }
      })
    })
  }, [platform])

  return (
    <Button
      color="white"
      onClick={handleClick}
      labelClassName="text-neutral-700"
      size="large"
      iconSlot="start"
      {...props}
    />
  )
}

export default ContinueWithGoogleButton
