import * as React from "react"
import { useImage } from "react-image"
import clsx from "clsx"
import isNil from "lodash/isNil"
import Loading from "../Loading"
import { captureMessage } from "@sentry/capacitor"

interface ThumbnailImageProps extends React.HTMLAttributes<HTMLImageElement> {
  url?: string | null
  title?: string
}

export const ThumbnailImage: React.FC<ThumbnailImageProps> = ({
  url,
  title = "thumbnail",
  className,
  ...props
}) => {
  try {
    const { src, isLoading } = useImage({
      srcList: url || [],
      useSuspense: false,
    })

    if (isLoading) {
      return <Loading background="transparent" overlay size="small" />
    }

    if (isNil(src)) {
      return <div className={clsx("w-full bg-center bg-cover", className)} />
    }

    const classNames = clsx("h-full w-full", className)

    return <img alt={title} src={src} className={classNames} {...props} />
  } catch (error) {
    captureMessage("Error loading image", {
      level: "warning",
      extra: {
        url,
        error,
      },
    })

    console.warn("No image found for url", url)
  }

  return <div className={clsx("w-full bg-center bg-cover", className)} />
}

export interface ThumbnailButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  url?: string | null
  background?: string
  overlayOpacity?: number
  backdropBlur?: "none" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl"
  backdropOpacity?: number
  containerClassName?: string
}

export const ThumbnailFallback: React.FC<any> = ({
  backgroundClass,
  className,
}) => (
  <div
    className={clsx(
      "w-full min-w-full bg-center bg-cover",
      backgroundClass,
      className
    )}
  />
)

export const ThumbnailButton = ({
  url,
  background = "neutral-300",
  overlayOpacity = 25,
  backdropBlur = "lg",
  backdropOpacity = 80,
  containerClassName = "rounded-lg",
  children,
  className,
  ...props
}: React.PropsWithChildren<ThumbnailButtonProps>) => {
  const backgroundClass = `bg-${background}`

  const overlayClass = `bg-opacity-${overlayOpacity}`

  const backdropClass = `backdrop-blur-${backdropBlur} backdrop-filter backdrop-opacity-${backdropOpacity}`

  return (
    <button
      type="button"
      {...props}
      className={clsx("relative shadow-lg w-full", backgroundClass, className)}
    >
      <React.Suspense fallback={<ThumbnailFallback />}>
        {!isNil(url) && <ThumbnailImage url={url} className={className} />}
      </React.Suspense>

      {children && (
        <div
          className={clsx(
            "h-full w-full",
            "absolute inset-0",
            backdropClass,
            backgroundClass,
            overlayClass,
            "cursor-pointer",
            containerClassName
          )}
        >
          {children}
        </div>
      )}
    </button>
  )
}
