import { Share, ShareResult } from "@capacitor/share"
import { useEffect, useState } from "react"

export const useShare = () => {
  const [isSupported, setIsSupported] = useState(false)
  const [isShared, setIsShared] = useState(false)

  const share = async ({
    title,
    text,
    url,
  }: {
    title?: string
    text?: string
    url: string
  }) => {
    if (!isSupported) {
      console.warn("Sharing is not supported")

      return
    }

    return await Share.share({
      title,
      text,
      url,
    }).then(({ activityType }: ShareResult) => {
      setIsShared(true)

      return activityType
    })
  }

  useEffect(() => {
    Share.canShare().then((result) => {
      setIsSupported(result.value)
    })
  }, [])

  return { isSupported, isShared, share }
}
