import pkceChallenge from "pkce-challenge"

import {
  AccountsIdentityProvider,
  DevicePlatform,
  IdentifyUserWithProviderDocument,
  IdentifyUserWithProviderMutation,
  IdentifyUserWithProviderMutationVariables,
  UserSession,
} from "../../../generated/graphql"
import Button, { ButtonProps } from "../../Forms/Button"
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../../contexts/AnalyticsContext"
import { useAuthenticatedClientContext } from "../../../contexts/AuthenticatedClientContext"
import { authenticateWithApple } from "./utils"
import { AppleIcon } from "./icons"
import { useLocaleContext } from "../../../contexts/LocaleContext"
import { useMutation } from "@apollo/client"
import useToast from "../../../hooks/useToast"
import { useTranslation } from "react-i18next"
import { NAME_SPACES } from "../../../locales/constants"
import { getReferralCode } from "../../../routers/AuthRouter"

export interface ContinueWithAppleButtonProps extends ButtonProps {
  onCompleted: (data: UserSession) => void
}

const ContinueWithAppleButton: React.FC<ContinueWithAppleButtonProps> = ({
  onCompleted,
  ...props
}) => {
  const { t } = useTranslation(NAME_SPACES.AUTH)

  const { platform } = useAuthenticatedClientContext()
  const { captureEvent } = useAnalyticsContext()

  const { locale } = useLocaleContext()

  const { showError } = useToast()

  const [identifyWithProvider] = useMutation<
    IdentifyUserWithProviderMutation,
    IdentifyUserWithProviderMutationVariables
  >(IdentifyUserWithProviderDocument)

  const onFullfilled = async (
    data: IdentifyUserWithProviderMutationVariables,
    onResolved?: () => void
  ) => {
    const variables = {
      ...data,
      referralCode: await getReferralCode(),
    }

    await identifyWithProvider({
      variables,
      onCompleted: async (completedData) => {
        onResolved?.()

        const resp = completedData.identifyUserWithProvider

        captureEvent(AnalyticsEvent.UserIdentifiedWithProvider, {
          provider: AccountsIdentityProvider.Apple,
          isFirstSignIn: resp.isFirstSession,
        })

        onCompleted(resp as UserSession)
      },
      onError: async (error) => {
        onResolved?.()

        let text

        switch (error.message) {
          case "Authentication failed":
            text = t("INCORRECT_CREDENTIALS")
            break

          default:
            text = `${t("AN_ERROR_HAS_OCCURED")}: ` + error.message
            break
        }

        showError(text)
      },
    })
  }

  const handleFulfilled = async (codeVerifier: string, response: any) => {
    await onFullfilled({
      platform,
      provider: AccountsIdentityProvider.Apple,
      authCode: response.code,
      codeVerifier,
      id: response.id,
      idToken: response.id_token,
      email: response.email,
      givenName: response.given_name,
      familyName: response.family_name,
      locale,
    })
  }

  const handleRejected = async (error: any) => {
    switch (error.message) {
      case "ERR_NO_AUTHORIZATION_CODE":
        // this is normal in iOS
        break

      case "USER_CANCELLED":
        captureEvent(AnalyticsEvent.ContinueWithAppleCancelled)
        break

      default:
        console.warn("AppleSignInButton:handleError:default", error)
        break
    }
  }

  const storeCodeVerifier = async (codeVerifier: string) => {
    await SecureStoragePlugin.set({
      key: "appleCodeVerifier",
      value: codeVerifier,
    })
  }

  const handleClick = async () => {
    captureEvent(AnalyticsEvent.ContinueWithAppleClicked)

    const { code_challenge, code_verifier } = pkceChallenge(128)

    await storeCodeVerifier(code_verifier)

    await authenticateWithApple({
      codeVerifier: code_verifier,
      codeChallenge: code_challenge,
      handleFulfilled,
      handleRejected,
    })
  }

  if (platform !== DevicePlatform.Ios) {
    return null
  }

  return (
    <Button
      color="dark"
      onClick={handleClick}
      icon={AppleIcon}
      textColor="white"
      size="large"
      iconSlot="start"
      label={t("CONTINUE_WITH_PROVIDER", { provider: "Apple" })}
      {...props}
    />
  )
}

export default ContinueWithAppleButton
