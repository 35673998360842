import isNil from "lodash/isNil"
import { getCurrentEnvironment } from "."

export const buildFormURL = (
  formPath: string,
  fields?: Record<string, any>
) => {
  const url = new URL(formPath, process.env.REACT_APP_BASE_TALLY_URL as string)

  if (!isNil(fields)) {
    Object.entries(fields).forEach(([key, value]) => {
      url.searchParams.append(key, value)
    })
  }

  url.searchParams.append("environment", getCurrentEnvironment())

  return url.toString()
}
