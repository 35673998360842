import { useLazyQuery } from "@apollo/client"
import { useIonRouter } from "@ionic/react"
import { parseISO } from "date-fns"
import format from "date-fns/format"
import { isEqual } from "lodash"
import isNil from "lodash/isNil"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  AgendaDaySchedule,
  useAgendaSchedulingContext,
} from "../contexts/AgendaSchedulingContext"
import { buildWeekSchedule } from "../contexts/AgendaSchedulingContext/day"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../contexts/ModalOrchestrationContext"
import { useNotificationContext } from "../contexts/NotificationContext"
import { ReminderTemplate } from "../contexts/NotificationContext/notificationTypes"
import {
  GetMovementWeekDocument,
  GetMovementWeekQuery,
  GetMovementWeekQueryVariables,
  Weekday,
} from "../generated/graphql"
import usePersistedFlag from "../hooks/usePersistedFlag"
import { NAME_SPACES } from "../locales/constants"
import { NotificationPermissionModal } from "../components/Notification/NotificationPermissionModal"
import { weekdayIndex } from "../utils"
import { isInProtectedRoute, pathnameIncludes } from "../utils/routes"
import { dateFromTime } from "../contexts/AgendaSchedulingContext/agendaTimeUtils"
import toDate from "date-fns-tz/toDate"

export const NotificationListener = () => {
  const { t } = useTranslation(NAME_SPACES.NOTIFICATIONS)

  const router = useIonRouter()

  const { isDirty: hasShownNotificationPermissionModal } = usePersistedFlag(
    "hasShownNotificationPermissionModal"
  )

  const {
    scheduleReminder,
    cancelReminder,
    isInitialized,
    isEnabled: isNotificationEnabled,
    isNativePermissionRequested,
    templatesAllowed,
    isTemplateEnabled,
    isReminderScheduled,
    getReminder,
    reminders,
  } = useNotificationContext()

  const {
    currentWeek,
    currentWeekSchedule,
    today,
    timezone,
    isEnabled: isAgendaEnabled,
    selectedWeek,
  } = useAgendaSchedulingContext()

  const { openModal, modals } = useModalOrchestrationContext()

  const isAnyModalOpen = useMemo(
    () => Object.values(modals).some((modal) => modal.isOpen),
    [modals]
  )

  const [nextWeekSchedule, setNextWeekSchedule] = useState<AgendaDaySchedule[]>(
    []
  )

  const [getNextWeek, { called, loading }] = useLazyQuery<
    GetMovementWeekQuery,
    GetMovementWeekQueryVariables
  >(GetMovementWeekDocument, {
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      if (
        isNil(data) ||
        isNil(data?.movementWeek) ||
        isNil(data?.movementWeek?.schedule)
      ) {
        return
      }

      setNextWeekSchedule(
        buildWeekSchedule(
          data.movementWeek.schedule,
          parseISO(data?.movementWeek.startDate as string)
        )
      )
    },
  })

  const scheduleMorningReminder = useCallback(
    async (dailySchedule: AgendaDaySchedule) => {
      const formattedDate = format(dailySchedule.day.date, "yyyy-MM-dd")
      const reminderKey = `morning-reminder-${formattedDate}`

      const hasItems = dailySchedule.items.length > 0

      if (!hasItems) {
        if (isReminderScheduled(reminderKey)) {
          await cancelReminder(reminderKey)
        }
      } else {
        const payload = {
          sessionTitle: dailySchedule.items[0].title,
          nItems: dailySchedule.items.length,
        }

        const currentReminder = getReminder(reminderKey)

        if (isEqual(currentReminder?.extra?.payload, payload)) {
          return
        }

        const title = t("LOCAL.MorningReminder.title")

        const body = t("LOCAL.MorningReminder.body", {
          sessionTitle: payload.sessionTitle,
        })

        const date = dateFromTime(
          { hours: 8, minutes: 0 },
          toDate(dailySchedule.day.date, { timeZone: timezone })
        )

        scheduleReminder(
          reminderKey,
          {
            template: ReminderTemplate.MorningReminder,
            title,
            body,
            schedule: {
              date,
            },
            payload,
          },
          true
        )
      }
    },
    [getReminder, isReminderScheduled, scheduleReminder, reminders, timezone]
  )

  const updateAllMorningReminders = useCallback(async () => {
    console.debug("[NotificationListener] updateAllMorningReminders")

    for (const dailySchedule of currentWeekSchedule) {
      if (dailySchedule.index < weekdayIndex[today.weekday]) {
        continue
      }

      await scheduleMorningReminder(dailySchedule)
    }

    for (const dailySchedule of nextWeekSchedule) {
      await scheduleMorningReminder(dailySchedule)
    }
  }, [currentWeekSchedule, scheduleMorningReminder, nextWeekSchedule, today])

  const scheduleWeeklyCommitmentReminder = useCallback(async () => {
    await scheduleReminder(
      ReminderTemplate.WeekAheadPreview,
      {
        template: ReminderTemplate.WeekAheadPreview,
        title: t(`LOCAL.${ReminderTemplate.WeekAheadPreview}.title`),
        body: t(`LOCAL.${ReminderTemplate.WeekAheadPreview}.body`),
        schedule: {
          weekday: Weekday.Sunday,
          time: {
            hours: 18,
            minutes: 0,
          },
        },
      },
      false
    )
  }, [templatesAllowed, scheduleReminder])

  // effect - get next week if it's not loaded or if it's selected
  useEffect(() => {
    if (!isAgendaEnabled) return

    if (isNil(currentWeek) || isNil(currentWeek?.nextMovementWeekUuid)) return

    if (loading) return

    if (!called || currentWeek.nextMovementWeekUuid === selectedWeek?.uuid) {
      getNextWeek({ variables: { uuid: currentWeek.nextMovementWeekUuid } })
    }
  }, [currentWeek, selectedWeek, isAgendaEnabled, called, loading])

  // effect - schedule weekly commitment reminder
  useEffect(() => {
    if (!isNotificationEnabled) return
    if (!isTemplateEnabled(ReminderTemplate.WeekAheadPreview)) return

    scheduleWeeklyCommitmentReminder()
  }, [templatesAllowed, isNotificationEnabled])

  // effect - schedule morning reminders for current week
  useEffect(() => {
    if (!isAgendaEnabled) return
    if (!isNotificationEnabled) return
    if (!isTemplateEnabled(ReminderTemplate.MorningReminder)) return

    updateAllMorningReminders()
  }, [
    currentWeek,
    currentWeekSchedule,
    nextWeekSchedule,
    templatesAllowed,
    isNotificationEnabled,
    isAgendaEnabled,
  ])

  // effect - show notification permission modal if has not been shown
  useEffect(() => {
    if (!isAgendaEnabled || !isInitialized) return

    if (!isInProtectedRoute(router)) return

    if (isNativePermissionRequested) return

    if (hasShownNotificationPermissionModal) return

    if (
      pathnameIncludes(router, "welcome") ||
      pathnameIncludes(router, "adventures")
    )
      return

    if (isAnyModalOpen) return

    openModal(ModalOrchestrationName.NotificationPermission)
  }, [
    isAgendaEnabled,
    isInitialized,
    isNativePermissionRequested,
    router.routeInfo.pathname,
    isAnyModalOpen,
  ])

  if (!isNativePermissionRequested) {
    return <NotificationPermissionModal />
  }

  return <></>
}

export default NotificationListener
