import clsx from "clsx"
import { useMemo } from "react"
import { RiCheckDoubleFill } from "react-icons/ri"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { SqualoAdventureActivity } from "../../generated/graphql"
import { getModalityContrastColor } from "../../utils/movementUtils"
import isNil from "lodash/isNil"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import { useAdventureContext } from "../../contexts/AdventureContext"
import { MovementIcon } from "../Core/Icons"
import { formatSessionDuration, formatStyle } from "../../utils/format"

export const AdventureActivityCard: React.FC<{
  templateId: string
  activity: SqualoAdventureActivity
  isPremiumAdventure?: boolean
}> = ({ templateId, activity, isPremiumAdventure }) => {
  const { currentAdventures, isCurrentPhaseActivity } = useAdventureContext()
  const { captureEvent } = useAnalyticsContext()
  const { openModal } = useModalOrchestrationContext()

  const adventure = useMemo(() => {
    return currentAdventures?.find(
      (adventure) => adventure.squaloAdventure.id === templateId
    )
  }, [currentAdventures, templateId])

  const isActivityCompleted = useMemo(() => {
    return adventure?.allCompletedActivities?.includes(activity.id)
  }, [adventure, activity.id])

  const isActivityLocked = useMemo(() => {
    if (isNil(adventure)) {
      return isPremiumAdventure
    } else {
      return (
        !isActivityCompleted &&
        !isCurrentPhaseActivity(adventure?.adventureUuid, activity.id)
      )
    }
  }, [adventure, activity, isActivityCompleted, isCurrentPhaseActivity])

  const movementModality = activity.movementModality

  const handleClick = () => {
    captureEvent(AnalyticsEvent.CurrentAdventureActivityCardClick, {
      activityId: activity.id,
      activityTitle: activity.title,
      isActivityLocked,
    })

    openModal(ModalOrchestrationName.AdventureActivity, {
      activity,
      isLocked: isActivityLocked,
    })
  }

  return (
    <div className="w-full">
      <button
        type="button"
        className={clsx(
          "flex flex-col items-center justify-between w-full p-4 gap-y-4",
          `bg-${movementModality} rounded-lg`,
          "h-full min-h-24 md:min-h-32",
          `text-${getModalityContrastColor(movementModality)}`,
          isActivityCompleted ? "saturate-50" : "shadow-lg"
        )}
        onClick={handleClick}
      >
        <div className="flex flex-row items-center justify-between w-full gap-x-2 opacity-80">
          <div className="flex flex-row items-center justify-start w-full truncate gap-x-2">
            <MovementIcon
              width={16}
              element={activity}
              className="flex-shrink-0 w-4 h-4"
            />
            {!isNil(activity.movementStyle) && (
              <span className="font-medium truncate">
                {formatStyle(activity.movementStyle)}
              </span>
            )}
          </div>

          {!isNil(activity.expectedDuration) && (
            <span className="flex-shrink-0 text-sm font-semibold">
              {formatSessionDuration(activity.expectedDuration)}
            </span>
          )}
        </div>

        <div className="flex flex-row items-center justify-between w-full gap-x-2">
          <div className="flex flex-col items-start justify-center flex-grow w-full gap-y-1">
            <span className="text-xl font-semibold tracking-tighter text-left">
              {activity?.title}
            </span>

            <p className="text-sm font-semibold tracking-tighter text-left whitespace-pre-wrap line-clamp-2">
              {activity?.description}
            </p>
          </div>

          {isActivityCompleted && <RiCheckDoubleFill className="text-3xl" />}
        </div>
      </button>
    </div>
  )
}
