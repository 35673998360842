import { IconBaseProps } from "react-icons/lib"

import { ReactComponent as ScheduleIcon } from "../assets/illustrations/storyset/schedule.svg"
import { ReactComponent as NotificationIcon } from "../assets/illustrations/storyset/notifications.svg"
import { ReactComponent as StretchIcon } from "../assets/illustrations/storyset/stretch.svg"
import { ReactComponent as WorkoutIcon } from "../assets/illustrations/storyset/workout.svg"
import clsx from "clsx"
import isNil from "lodash/isNil"
import { IonImg } from "@ionic/react"

export enum StorySetIconType {
  Schedule = "schedule",
  Notification = "notification",
  Stretch = "Stretch",
  Workout = "Workout",
}

interface StorySetIconProps extends IconBaseProps {
  url?: string | null
  type?: StorySetIconType
}

const StorySetIcon: React.FC<StorySetIconProps> = ({ type, ...props }) => {
  switch (type) {
    case StorySetIconType.Schedule:
      return <ScheduleIcon {...props} />
    case StorySetIconType.Notification:
      return <NotificationIcon {...props} />
    case StorySetIconType.Stretch:
      return <StretchIcon {...props} />
    case StorySetIconType.Workout:
      return <WorkoutIcon {...props} />
    default:
      return null
  }
}

const StorySetIconWithAttribution: React.FC<StorySetIconProps> = ({
  url,
  type,
  className,
  ...props
}) => {
  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-center w-full h-full gap-y-1",
        className
      )}
    >
      {isNil(url) ? (
        isNil(type) ? (
          <></>
        ) : (
          <StorySetIcon type={type} {...props} />
        )
      ) : (
        <IonImg src={url} className="w-full h-full" />
      )}
      <div className="w-full">
        <a
          href="https://storyset.com"
          className="float-right text-xs underline text-neutral-200 font-semilight"
        >
          Illustration by Storyset
        </a>
      </div>
    </div>
  )
}

export default StorySetIconWithAttribution
