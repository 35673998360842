import { useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ModalOrchestrationName } from "../../../../contexts/ModalOrchestrationContext"

import { PhaseOfDay } from "../../../../generated/graphql"
import { MOVEMENT, NAME_SPACES } from "../../../../locales/constants"
import { formatPhaseOfDay } from "../../../../utils/format"
import CardSelect, { CardSelectProps } from "../../../Forms/CardSelect"
import {
  GridFormField,
  GridFormFieldProps,
  RequiredFieldModalBodyProps,
} from "../../../Forms/GridFormField"
import { getPhaseOfDayIcon } from "../../../Core/Icons"
import isNil from "lodash/isNil"

export type PhaseOfDaySelectState = PhaseOfDay

export type PhaseOfDaySelectProps = CardSelectProps<PhaseOfDaySelectState> &
  RequiredFieldModalBodyProps<PhaseOfDaySelectState>

const PhaseOfDaySelect: React.FC<PhaseOfDaySelectProps> = ({
  state,
  setState,
}) => {
  const options = [
    PhaseOfDay.Morning,
    PhaseOfDay.Afternoon,
    PhaseOfDay.Evening,
  ].map((phase) => ({
    label: formatPhaseOfDay(phase),
    value: phase,
    Icon: getPhaseOfDayIcon(phase),
  }))

  return (
    <div className="flex flex-col w-full h-full">
      <CardSelect<PhaseOfDay>
        options={options}
        onSelect={setState}
        selected={state}
        sort={false}
      ></CardSelect>
    </div>
  )
}

export interface PhaseOfDayGridFormFieldProps
  extends Omit<
    GridFormFieldProps<PhaseOfDaySelectState>,
    "Body" | "label" | "formatValue" | "modalName"
  > {
  name: string
}

export const PhaseOfDayGridFormField: React.FC<
  PhaseOfDayGridFormFieldProps
> = ({ name, ...props }) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)

  const { PHASE_OF_DAY } = t(MOVEMENT.FORM, { returnObjects: true })

  const phaseOfDay = useWatch({ name })

  const Body = (props: any) => <PhaseOfDaySelect {...props} />

  const formatValue = (value?: PhaseOfDay) => {
    if (isNil(value)) return PHASE_OF_DAY.PLACEHOLDER

    return formatPhaseOfDay(value)
  }

  return (
    <GridFormField<PhaseOfDaySelectState>
      name={name}
      modalName={ModalOrchestrationName.FormFieldPhaseOfDaySelect}
      label={PHASE_OF_DAY.LABEL}
      title={PHASE_OF_DAY.TITLE}
      Body={Body}
      Icon={getPhaseOfDayIcon(phaseOfDay)}
      formatValue={formatValue}
      showDismiss
      showClearButton
      {...props}
    />
  )
}

export default PhaseOfDaySelect
