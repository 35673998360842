import { useQuery } from "@apollo/client"
import { useIonRouter } from "@ionic/react"
import { isNil } from "lodash"
import * as React from "react"
import { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router-dom"

import SessionPage from "../../components/Movement/SessionPage"
import {
  GetMovementAgendaItemDocument,
  GetMovementAgendaItemQuery,
  GetMovementAgendaItemQueryVariables,
  SessionExpectedDuration,
} from "../../generated/graphql"

export type AgendaItemSessionPageProps = RouteComponentProps<
  { uuid: string },
  any,
  { timeAvailable?: SessionExpectedDuration }
>

const AgendaItemSessionPage: React.FC<AgendaItemSessionPageProps> = ({
  match,
  location,
}) => {
  const router = useIonRouter()

  const [agendaItem, setAgendaItem] =
    useState<GetMovementAgendaItemQuery["movementAgendaItem"]>()

  const { data, loading } = useQuery<
    GetMovementAgendaItemQuery,
    GetMovementAgendaItemQueryVariables
  >(GetMovementAgendaItemDocument, {
    variables: { uuid: match.params.uuid },
    fetchPolicy: "cache-and-network",
  })

  // effect - fetch agenda item
  useEffect(() => {
    if (!loading && data && isNil(agendaItem)) {
      if (data.movementAgendaItem) {
        setAgendaItem(data.movementAgendaItem)
      } else {
        router.goBack()
      }
    }
  }, [match, loading, data])

  return (
    <SessionPage
      agendaItem={agendaItem}
      reportedTimeAvailable={location?.state?.timeAvailable}
      onCompleted={() => router.goBack()}
    />
  )
}

export default AgendaItemSessionPage
