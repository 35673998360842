import { IonRouterOutlet, useIonRouter } from "@ionic/react"
import { useEffect } from "react"
import { Redirect, Route } from "react-router-dom"
import { useAuthenticatedClientContext } from "../contexts/AuthenticatedClientContext"
import AdventureTemplatePage from "../pages/Adventure/AdventureTemplatePage"
import MicoHomePage from "../pages/Mico/MicoHomePage"

import VideoViewerPage from "../pages/Mico/VideoViewerPage"

const MicoRouter: React.FC = () => {
  const router = useIonRouter()
  const { isSessionActive, isInitialized } = useAuthenticatedClientContext()

  useEffect(() => {
    if (!isInitialized) return

    if (isSessionActive && router.routeInfo.pathname.includes("mico")) {
      const pathname = router.routeInfo.pathname.replace(
        "/app/mico",
        "/app/hub/discover"
      )

      router.push(pathname, "root", "replace")
    }
  }, [router.routeInfo.pathname])

  return (
    <IonRouterOutlet>
      <Route exact path="/app/mico">
        {!isSessionActive ? (
          <MicoHomePage />
        ) : (
          <Redirect to="/app/hub/discover" />
        )}
      </Route>

      <Route exact path="/app/mico/adventures">
        <Redirect to="/app/mico?tab=ADVENTURES" />
      </Route>

      <Route exact path="/app/mico/videos">
        <Redirect to="/app/mico?tab=VIDEOS" />
      </Route>

      <Route
        exact
        path="/app/mico/adventures/:uuid"
        component={AdventureTemplatePage}
      />

      <Route exact path="/app/mico/videos/:uuid" component={VideoViewerPage} />
    </IonRouterOutlet>
  )
}

export default MicoRouter
