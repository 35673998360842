import { useMutation } from "@apollo/client"
import clsx from "clsx"
import isNil from "lodash/isNil"
import { useTranslation } from "react-i18next"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { useLocaleContext } from "../../contexts/LocaleContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import {
  ChooseAdventureDocument,
  ChooseAdventureMutation,
  ChooseAdventureMutationVariables,
  GetAdventureTemplateQuery,
  ListMemberAdventuresDocument,
  SqualoAdventure,
  SqualoAdventureStatus,
  SqualoAdventureType,
} from "../../generated/graphql"
import { ADVENTURE, NAME_SPACES } from "../../locales/constants"
import {
  getElementContrastColor,
  getElementTextColor,
} from "../../utils/elementUtils"
import { ThumbnailButton, ThumbnailImage } from "../Core/ThumbnailButton"
import Button from "../Forms/Button"
import { useSubscriptionContext } from "../../contexts/SubscriptionContext"
import {
  RiCompassDiscoverLine,
  RiExternalLinkLine,
  RiLockUnlockLine,
} from "react-icons/ri"
import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"
import { useIonActionSheet, useIonRouter } from "@ionic/react"
import { redirectToAuth } from "../../routers/AppRouter"
import useRouterQueryParams from "../../hooks/useRouterQueryParams"
import { AdventurePhaseGrid } from "./AdventureTemplatePhase"
import { isAdventurePremium } from "./AdventurePreviewCard"
import { openBrowser } from "../../hooks/useInlineBrowser"
import { AdventureTemplateProductCarousel } from "./AdventureTemplateProductCard"
import { useAdventureContext } from "../../contexts/AdventureContext"
import { useEffect } from "react"

interface AdventureTemplateContentProps {
  activeTab?: string
  adventureTemplate: Exclude<
    GetAdventureTemplateQuery["getAdventureTemplate"],
    null | undefined
  >
}

export const AdventureTemplatePartnershipButton: React.FC<{
  element: SqualoAdventure["element"]
  partnership?: Exclude<
    AdventureTemplateContentProps["adventureTemplate"]["partnerships"],
    null | undefined
  >[0]
}> = ({ element, partnership }) => {
  const { t } = useTranslation(NAME_SPACES.AUTH)

  if (isNil(partnership)) {
    partnership = {
      id: "behale",
      name: "behale",
      description: t("HOME.WELCOME_MESSAGE"),
      logoUrl: "https://app.behale.io/assets/icon/appicon-60.png",
      url: null,
    }
  }

  const { captureEvent } = useAnalyticsContext()

  const handleClick = () => {
    if (isNil(partnership)) return

    captureEvent(AnalyticsEvent.AdventureTemplatePartnershipClicked, {
      partnershipName: partnership.name,
    })

    if (isNil(partnership.url)) return

    openBrowser(partnership.url)
  }

  return (
    <button
      className={clsx(
        "flex flex-row items-center justify-start gap-x-3 w-full",
        "text-base py-3 px-4 bg-opacity-10 lg:rounded-lg shadow-inner",
        `bg-${getElementContrastColor(element)}`
      )}
      onClick={handleClick}
    >
      {!isNil(partnership.logoUrl) && (
        <div className="flex-shrink-0 w-10 h-10 bg-white rounded-full bg-opacity-20">
          <ThumbnailImage
            url={partnership.logoUrl}
            className="flex items-center justify-center w-10 h-10 rounded-full bg-white/20"
          />
        </div>
      )}

      <div
        className={clsx(
          "flex flex-col items-start justify-center flex-grow truncate"
        )}
      >
        <span className="text-lg font-semibold">{partnership.name}</span>
        <span className="text-sm font-medium line-clamp-2">
          {partnership.description}
        </span>
      </div>

      {!isNil(partnership.url) && (
        <RiExternalLinkLine className="text-2xl opacity-70" />
      )}
    </button>
  )
}

export const AdventureTemplateContent: React.FC<
  AdventureTemplateContentProps
> = ({ adventureTemplate }) => {
  const query = useRouterQueryParams()
  const [present] = useIonActionSheet()

  const router = useIonRouter()

  const { t, i18n } = useTranslation(NAME_SPACES.ADVENTURE)
  const TEXT = t(ADVENTURE.TEMPLATE, { returnObjects: true })

  const { isPremium } = useSubscriptionContext()
  const { captureEvent } = useAnalyticsContext()
  const { isSessionActive } = useAuthenticatedClientContext()
  const { openModal, toggleLoading } = useModalOrchestrationContext()
  const { language, isInitialized } = useLocaleContext()
  const { currentAdventures, pauseAdventure } = useAdventureContext()

  const currentAdventure = currentAdventures.find(
    (adventure) => adventure.squaloAdventure.id === adventureTemplate.id
  )

  const isAdventureTemplateLocked =
    !isPremium && isAdventurePremium(adventureTemplate)

  const [chooseAdventure] = useMutation<
    ChooseAdventureMutation,
    ChooseAdventureMutationVariables
  >(ChooseAdventureDocument, {
    onCompleted: (data) => {
      if (isNil(adventureTemplate.onboardingUrl)) {
        router.push("/app/adventure/kickoff")
      } else {
        openModal(ModalOrchestrationName.TallyAdventurePreparation, {
          adventureUuid: data.chooseAdventure.adventureUuid,
          url: adventureTemplate.onboardingUrl,
        })
      }
    },
  })

  const handleChooseAdventure = async () => {
    captureEvent(AnalyticsEvent.AdventureTemplateChooseClicked, {
      adventureElement: adventureTemplate.element,
      adventureUuid: adventureTemplate.id,
    })

    if (adventureTemplate.status !== SqualoAdventureStatus.EarlyBird) {
      toggleLoading(true, { background: "primary" })

      await chooseAdventure({
        variables: {
          squaloAdventureId: adventureTemplate.id,
          squaloAdventureName: adventureTemplate.name,
          squaloAdventureElement: adventureTemplate.element,
          squaloAdventureDuration: adventureTemplate.phaseCount || 0,
          squaloAdventureLanguage: language.toLowerCase(),
          squaloAdventureOnboardingUrl: adventureTemplate.onboardingUrl,
        },
        refetchQueries: [ListMemberAdventuresDocument],
      })
    }
  }

  const handleUnlockAdventure = () => {
    captureEvent(AnalyticsEvent.AdventureTemplateUnlockClicked, {
      adventureElement: adventureTemplate.element,
      adventureUuid: adventureTemplate.id,
    })

    openModal(ModalOrchestrationName.GetPremium)
  }

  const handleCTAClick = async () => {
    if (isAdventureTemplateLocked) {
      handleUnlockAdventure()
    } else if (!isSessionActive) {
      query.append("startAdventure", "true")

      redirectToAuth(router, query)
    } else {
      handleChooseAdventure()
    }
  }

  const handlePauseAdventure = () => {
    if (isNil(currentAdventure)) return

    captureEvent(AnalyticsEvent.CurrentAdventurePaused, {
      adventureUuid: currentAdventure.squaloAdventure.id,
      adventureElement: currentAdventure.squaloAdventure.element,
      nActivitiesCompleted: currentAdventure.allCompletedActivities.length,
    })

    present({
      header: TEXT.ACTIVE.STOP.CONFIRMATION.TITLE,
      buttons: [
        {
          text: TEXT.ACTIVE.STOP.CONFIRMATION.CTA,
          handler: () => {
            pauseAdventure(currentAdventure.adventureUuid)
          },
        },
        {
          text: i18n.t("COMMON:FORMS.CANCEL"),
          role: "cancel",
        },
      ],
    })
  }

  useEffect(() => {
    if (!isInitialized) return
    if (!isSessionActive) return

    if (query.has("startAdventure") && query.get("startAdventure") === "true") {
      if (isAdventureTemplateLocked) {
        handleUnlockAdventure()
      } else {
        handleChooseAdventure()
      }
    }
  }, [query, isSessionActive, isInitialized])

  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-start h-full w-full",
        `bg-${adventureTemplate.element}-500`,
        getElementTextColor(adventureTemplate.element)
      )}
    >
      <div className="flex flex-col items-center justify-between w-full h-full">
        <div
          className={clsx(
            "h-full w-full",
            `bg-${adventureTemplate.element}-600`,
            "shadow-inner overflow-y-scroll",
            getElementTextColor(adventureTemplate.element)
          )}
        >
          <div
            className={clsx(
              "flex flex-col w-full max-w-3xl mx-auto gap-y-4 lg:py-4"
            )}
          >
            {!isNil(adventureTemplate.imageUrl) && (
              <div className="w-full h-full lg:px-4">
                <ThumbnailButton
                  url={adventureTemplate.imageUrl}
                  className="w-full h-48 lg:h-80 md:h-60 lg:rounded-lg"
                />
              </div>
            )}

            <div className="flex flex-col w-full px-4 text-left gap-y-1">
              <span className="text-3xl font-semibold">
                {adventureTemplate.name}
              </span>

              <span className="text-lg font-medium">
                {adventureTemplate.goal}
              </span>
            </div>

            <div className="flex flex-col w-full gap-y-2 lg:px-4">
              {adventureTemplate.partnerships?.map((partnership, index) => (
                <AdventureTemplatePartnershipButton
                  key={index}
                  element={adventureTemplate.element}
                  partnership={partnership}
                />
              )) || (
                <AdventureTemplatePartnershipButton
                  element={adventureTemplate.element}
                  partnership={undefined}
                />
              )}
            </div>

            <Button
              fill="solid"
              color={"neutral"}
              textColor={"white"}
              className="w-full max-w-3xl mx-auto text-lg rounded-lg"
              label={TEXT.ACTIVE.STOP.CTA}
              hidden={isNil(currentAdventure)}
              onClick={handlePauseAdventure}
            />

            <div className="flex flex-col w-full px-4 gap-y-4">
              <span className="w-full font-medium text-justify whitespace-pre-wrap">
                {adventureTemplate.description}
              </span>

              {/* <div
                className={clsx(
                  "grid grid-cols-2 w-full items-center justify-around py-4 divide-x divide-neutral-100/20",
                  "rounded-lg bg-white bg-opacity-10",
                  "font-semibold text-left"
                )}
              >
                <div className="flex flex-row items-center justify-center gap-x-2">
                  <MdStairs className="text-2xl" />
                  <span>{formatAdventureDifficulty(adventure.difficulty)}</span>
                </div>

                <div className="flex flex-row items-center justify-center gap-x-2">
                  <IoCalendar className="text-2xl" />
                  <span>
                    {formatAdventureDuration(adventure.phaseCount || 3)}
                  </span>
                </div>
              </div> */}
            </div>

            {/* Products */}

            {!isNil(adventureTemplate.products) &&
              adventureTemplate.products.length > 0 && (
                <div className="flex flex-col w-full py-4 lg:px-4 gap-y-2">
                  <span className="px-4 text-lg font-semibold lg:px-0">
                    {t("TEMPLATE.PRODUCTS.TITLE")}
                  </span>

                  <div
                    className={clsx(
                      "flex flex-col w-full relative",
                      "gap-4 py-4",
                      "lg:rounded-lg shadow-inner",
                      `bg-${getElementContrastColor(
                        adventureTemplate.element
                      )}`,
                      "bg-opacity-20"
                    )}
                  >
                    <AdventureTemplateProductCarousel
                      products={adventureTemplate.products}
                    />
                  </div>
                </div>
              )}

            {/* Locked */}
            {isAdventureTemplateLocked && (
              <div
                className={clsx(
                  "flex flex-col items-center justify-start w-full h-full px-4 lg:px-0"
                )}
              >
                <div
                  className={clsx(
                    getElementTextColor(adventureTemplate.element),
                    "flex flex-col items-start justify-center gap-y-2",
                    "border-neutral-100/20 border bg-neutral-100/20",
                    "w-full",
                    "rounded-lg p-4"
                  )}
                >
                  <span className="text-xl font-semibold">
                    {t("TEMPLATE.LOCKED.CARD.TITLE")}
                  </span>
                  <span className="">{t("TEMPLATE.LOCKED.CARD.SUBTITLE")}</span>
                </div>
              </div>
            )}

            {/* Phases */}
            <div
              className={clsx(
                "flex flex-col items-start justify-center gap-y-2 py-4 h-full px-4"
              )}
            >
              <span className="text-lg font-semibold">
                {t("TEMPLATE.PHASES.TITLE")}
              </span>

              {adventureTemplate.phases?.map((phase, index) => (
                <AdventurePhaseGrid
                  key={index}
                  templateId={adventureTemplate.id}
                  element={adventureTemplate.element}
                  index={index}
                  phase={phase}
                  isOnlyPhase={adventureTemplate.phases?.length === 1}
                  isPremiumAdventure={
                    adventureTemplate.type !== SqualoAdventureType.Basic
                  }
                />
              ))}
            </div>

            <div className="w-full h-24" />
          </div>
        </div>

        <div
          className={clsx(
            "absolute bottom-0 w-full p-4 flex flex-col items-center justify-center"
          )}
        >
          <Button
            fill="solid"
            color={getElementContrastColor(adventureTemplate.element)}
            textColor={`${adventureTemplate.element}-500`}
            className="w-full max-w-3xl mx-auto text-xl rounded-lg"
            label={
              isAdventureTemplateLocked ? TEXT.LOCKED.CTA : TEXT.UNLOCKED.CTA
            }
            icon={
              isAdventureTemplateLocked
                ? RiLockUnlockLine
                : RiCompassDiscoverLine
            }
            iconClassName={clsx(
              "absolute left-0",
              `text-${adventureTemplate.element}-500`
            )}
            hidden={!isNil(currentAdventure)}
            onClick={handleCTAClick}
          />
        </div>
      </div>
    </div>
  )
}
