import { IonIcon } from "@ionic/react"
import classNames from "clsx"
import { checkmarkDoneOutline } from "ionicons/icons"
import isNil from "lodash/isNil"
import React from "react"
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd"

import { MovementSessionSummaryFragment } from "../../../generated/graphql"
import { MovementStyleSpan } from "../Style"
import { BaseWeekdayComponent } from "./BaseWeekdayComponent"
import {
  MovementInformationDurationSpan,
  MovementInformationTargetBodyPartsSpan,
} from "../MovementInformationDetails"
import { useAdventureContext } from "../../../contexts/AdventureContext"

export const AgendaTimelineDaySession: React.FC<{
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  session: MovementSessionSummaryFragment
}> = ({ session, provided }) => {
  const { getAdventure } = useAdventureContext()

  const routerLink = `/app/hub/agenda/sessions/${session.uuid}`

  const sessionAdventure = getAdventure(session.membershipAdventureUuid)

  return (
    <div
      className={classNames("flex flex-row items-center h-10", "bg-white z-10")}
      ref={provided.innerRef}
    >
      <BaseWeekdayComponent
        className={classNames(
          "flex flex-row bg-white shadow-sm opacity-80",
          "flex flex-row items-center w-full",
          "z-10 bg-white rounded-sm",
          !isNil(sessionAdventure) &&
            `border-l-8 border-${sessionAdventure.squaloAdventure.element}-500`
        )}
        routerLink={routerLink}
        routerDirection="forward"
      >
        <div className="flex-grow max-w-full p-2 overflow-hidden">
          <div className="flex flex-row items-center justify-between w-full text-sm gap-x-1 text-neutral">
            <div className="flex flex-row items-center text-sm truncate gap-x-1 text-neutral">
              <MovementStyleSpan element={session} className="truncate" />

              <MovementInformationTargetBodyPartsSpan
                item={session}
                isTargetBodyPartAvailable={
                  !isNil(session.movementTargetBodyParts)
                }
                className="flex-shrink text-sm truncate gap-x-1 text-neutral-500/80"
              />
            </div>
            <div className="flex items-center justify-center px-2 opacity-80 gap-x-2">
              <MovementInformationDurationSpan duration={session.duration} />
              <IonIcon
                icon={checkmarkDoneOutline}
                className="w-6 h-6"
                color="success"
              />
            </div>
          </div>
        </div>
      </BaseWeekdayComponent>
    </div>
  )
}
