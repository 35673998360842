import { Schedule } from "@capacitor/local-notifications"
import { Weekday } from "../../generated/graphql"
import { Time } from "../AgendaSchedulingContext/agendaTimeUtils"

export interface NotificationContextState {
  // native permissions
  maxNativeId: number
  activeNativeIds: Set<number>

  isInitialized: boolean
  isEnabled: boolean

  isPushNotificationRegistered: boolean
  devicePushNotificationToken: string | null

  isNativePermissionRequested: boolean
  isNativePermissionGranted: boolean

  templatesAllowed: ReminderTemplate[]

  reminderKeyMap: {
    [key: string]: number
  }

  reminders: {
    [nativeId: number]: ReminderState
  }

  badgeCount: number
}

export interface NotificationContextActions {
  requestPermissionIfMissing: (
    onGranted: (alreadyGranted?: boolean) => void,
    onRejected?: () => void
  ) => void

  enableNotifications: () => void
  disableNotifications: () => void

  enableTemplate: (template: ReminderTemplate, onSuccess?: () => void) => void
  disableTemplate: (template: ReminderTemplate, onSuccess?: () => void) => void

  isTemplateEnabled: (template: ReminderTemplate) => boolean

  isReminderScheduled: (key: string) => boolean
  isReminderDelivered: (key: string) => boolean

  checkNativePermissions: () => Promise<boolean>

  refreshLocalNotficiationStatus: () => Promise<void>

  getReminder: (key: string) => ReminderState | undefined

  scheduleReminder: (
    key: string,
    props: ReminderProps,
    shouldUpdate?: boolean
  ) => Promise<boolean>

  cancelReminder: (key: string) => Promise<boolean>

  setAppBadge: (badgeCount: number) => Promise<void>
  clearAppBadge: () => Promise<void>
}

export const enum NotificationReducerActionType {
  NotificationPermissionNotPrompted,
  PushNotificationTokenLoaded,
  NotificationPermissionRejected,
  NotificationPermissionGranted,
  TemplatePermissionsLoaded,
  TemplateEnabled,
  TemplateDisabled,
  ReminderCancelled,
  LocalNotificationsImported,
  ReminderDelivered,
  LocalNotificationsDisabled,
  LocalNotificationsEnabled,
  ReminderScheduled,
  NativeIdActivated,
  BadgeCountUpdated,
}

export interface LocalNotificationAction {
  id: LocalNotificationActionId
  role?: "button" | "input" | "destructive"
  onAction?: () => void
}

export interface ReminderProps {
  template: ReminderTemplate

  title: string
  body: string

  largerBody?: string

  payload?: any

  actions?: LocalNotificationAction[]

  schedule: {
    date?: Date

    weekday?: Weekday

    time?: Time
  }
}

export type ReminderStatus = "scheduled" | "delivered" | "cancelled"

export interface ReminderState {
  key: string

  id: number
  groupId?: string

  title: string
  body: string

  status: ReminderStatus
  template: ReminderTemplate

  schedule?: Schedule

  extra?: any
}

export interface LocalNotificationPreferences {
  isEnabled: boolean
  templatePermissions: Record<ReminderTemplate, boolean>
}

export const enum LocalNotificationActionId {
  View = "View",
  Dismiss = "Dismiss",
}

export enum ReminderTemplate {
  WeekAheadPreview = "WeeklyCommitmentReminder",
  MorningReminder = "MorningReminder",
  SessionReminder = "SessionReminder",
}

export const LOCAL_NOTIFICATION_TEMPLATE_ACTIONS: Record<
  ReminderTemplate,
  LocalNotificationAction[]
> = {
  [ReminderTemplate.WeekAheadPreview]: [],
  [ReminderTemplate.MorningReminder]: [],
  [ReminderTemplate.SessionReminder]: [],
}
