import { MovementModality, MovementStyle } from "../../../generated/graphql"

export enum MoveSnackFrameStatus {
  NotStarted = "not_started",
  Ongoing = "ongoing",
  Completed = "completed",
}

export enum MoveSnackFrameTimerType {
  Countdown = "countdown",
  Repetitions = "repetitions",
  Stopwatch = "stopwatch",
}

export type MoveSnackFrame = {
  name: string
  title?: string
  description?: string

  status: MoveSnackFrameStatus

  autoStart?: boolean

  type: MoveSnackFrameTimerType
  duration?: number
  repetitionCount?: number
  repetitionDuration?: number
}

export interface MoveSnackTemplate {
  name: string

  title?: string
  description?: string
  movementModality: MovementModality
  movementStyle: MovementStyle

  duration: number

  autoStart: boolean

  frames: MoveSnackFrame[]
}
