import { SqualoAdventureElement } from "../../generated/graphql"
import { WELCOME } from "../constants"

export default {
  [WELCOME.ELEMENTS]: {
    TITLE: "What do you want to focus on?",
    DESCRIPTION: "",
    // "Choose the element that best represents you and your goals. You can change it later if you want.",
    CONTINUE: "Continue",
    SKIP: "Skip for now",
    ELEMENTS: {
      [SqualoAdventureElement.Fire]: {
        TITLE: "Cardio",
        DESCRIPTION:
          "It's ideal for those who want to improve their endurance, speed or explosive power.",
      },
      [SqualoAdventureElement.Water]: {
        TITLE: "Flexibility",
        DESCRIPTION:
          "It's ideal for those who want to improve their posture, balance or specific physical traits.",
      },
      [SqualoAdventureElement.Earth]: {
        TITLE: "Fat loss",
        DESCRIPTION:
          "It's ideal for those who want to lose fat, tone their body or feel better in their skin.",
      },
      [SqualoAdventureElement.Air]: {
        TITLE: "Minfulness",
        DESCRIPTION:
          "It's ideal for those who want to focus on creating sustainable habits to feel better in their head and spirit.",
      },
    },
  },
  [WELCOME.ACTIVITIES]: {
    TITLE: "What are your usual activities?",
    LATEST: {
      TITLE: "What was your latest session?",
      CTA: {
        LOG: "Log completed session",
        SKIP: "No sessions recently",
      },
    },
    NEXT: {
      TITLE: "What is your next session?",
      CTA: {
        SKIP: "No sessions planned",
        PLAN: "Plan a session",
        RECOMMEND: "Ask MoveAI",
      },
    },
    DESCRIPTION: "",
    BUTTONS: {
      ADD_ACTIVITY: "Add activity",
      MODIFY_ACTIVITY: "Modify activity",
      CLEAR: {
        CONFIRMATION: "Are you sure you want to delete this activity ?",
      },
    },
    HINTS: {
      FREQUENCY: "per week",
      WEEKDAYS: "Usually on {{ weekdays }}",
    },
  },
  [WELCOME.GET_THE_APP]: {
    TITLE: "Continue on the app",
    DESCRIPTION:
      "If you want a better experience, <strong>download our mobile app 📱</strong>. You can always access your account with your computer 💻 on <strong>app.behale.io</strong>",
    QR_CODE: "Scan this QR Code to download the app",
    AGENDA_BUTTON: "Begin my journey without the app",
    COMING_SOON: "Coming soon",
  },
}
