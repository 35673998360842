import { Preferences } from "@capacitor/preferences"
import { useEffect, useState } from "react"
import { isInMetaAppBrowser, postRequest } from "../utils"
import { buildPravoURL } from "../utils/pravo"
import useRouterQueryParams from "./useRouterQueryParams"

const useMarketingOptimizer = () => {
  const query = useRouterQueryParams()

  const [isActive, setIsActive] = useState(false)

  const [metaClickId, setMetaClickId] = useState<string | null>(null)

  const storeMarketingId = async (key: string, value: string) => {
    await Preferences.set({
      key: `marketing.${key}`,
      value,
    })
  }

  const loadMarketingId = async (key: string) => {
    const { value } = await Preferences.get({
      key: `marketing.${key}`,
    })

    return value
  }

  const reportMarketingEvent = async ({
    name,
    externalId,
    userData,
    force = false,
  }: {
    name: string
    externalId?: string
    userData?: {
      email?: string
      firstName?: string | null
      lastName?: string | null
    }
    force?: boolean
  }) => {
    // Don't report events if not in meta app browser
    if (!isActive) {
      // unless force is true
      if (!force) {
        return
      }
    }

    const eventTimestamp = Math.floor(new Date().getTime() / 1000)

    const url = buildPravoURL("/api/marketing/meta")

    await postRequest(url, {
      eventName: name,
      user: userData,
      eventTimestamp,
      externalId,
      fbc: metaClickId,
    })
  }

  useEffect(() => {
    if (query && query.has("fbclid")) {
      setIsActive(true)

      const fbclid = query.get("fbclid") as string

      setMetaClickId(fbclid)
      storeMarketingId("metaClickId", fbclid)
    }
  }, [query])

  useEffect(() => {
    loadMarketingId("metaClickId").then((value) => {
      if (value) {
        setIsActive(true)
        setMetaClickId(value)
      }
    })

    if (navigator && isInMetaAppBrowser(navigator)) {
      setIsActive(true)
    }
  }, [])

  return {
    isActive,
    reportMarketingEvent,
  }
}

export default useMarketingOptimizer
