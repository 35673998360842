import { isSameDay, parseISO } from "date-fns/esm"
import {
  buildToday,
  buildWeekSchedule,
  buildWeekScheduleOffsets,
  buildVirtualWeekSchedule,
  addActivityToDay,
} from "./day"
import {
  AgendaSchedulingContextState,
  AgendaSchedulingReducerAction,
  AgendaSchedulingReducerActionType,
} from "./agendaTypes"
import addWeeks from "date-fns/addWeeks"
import { cloneDeep, groupBy, isNil } from "lodash"
import { weekdayIndex } from "../../utils"
import { Weekday } from "../../generated/graphql"

export const agendaSchedulingReducer = (
  state: AgendaSchedulingContextState,
  action: AgendaSchedulingReducerAction
): AgendaSchedulingContextState => {
  const { type, payload } = action

  let startDate, schedule, todaySchedule, isSelectedWeekCurrent

  const today = buildToday(payload?.timezone || state.timezone)

  switch (type) {
    case AgendaSchedulingReducerActionType.ContextEnabled:
      return {
        ...state,
        isEnabled: true,
      }

    case AgendaSchedulingReducerActionType.ContextDisabled:
      return {
        ...state,
        isEnabled: false,
      }

    case AgendaSchedulingReducerActionType.AgendaLoaded:
      startDate = parseISO(payload.currentWeek.startDate as string)

      isSelectedWeekCurrent = isSameDay(state.selectedWeekStartDate, startDate)

      schedule = buildWeekSchedule(
        payload.currentWeek.schedule,
        startDate
      ) as AgendaSchedulingContextState["currentWeekSchedule"]

      todaySchedule = schedule[weekdayIndex[today.weekday]]

      return {
        ...state,
        isReady: true,
        agenda: payload,
        recurringItemsPerWeekday: groupBy(
          payload.recurringItems,
          "weekday"
        ) as AgendaSchedulingContextState["recurringItemsPerWeekday"],
        currentWeek: payload.currentWeek,
        currentWeekUuid: payload.currentWeek.uuid,
        currentWeekStartDate: startDate,
        currentWeekSchedule: schedule,
        today: addActivityToDay(today, todaySchedule),
        isSelectedWeekCurrent,
        ...(isSelectedWeekCurrent
          ? {
              selectedWeek: cloneDeep(payload.currentWeek),
              selectedWeekUuid: payload.currentWeek.uuid,
              selectedWeekStartDate: startDate,
              selectedWeekSchedule: cloneDeep(schedule),
              isSelectedWeekStale: false,
            }
          : {}),
      }

    case AgendaSchedulingReducerActionType.SelectedWeekLoaded:
      startDate = isNil(payload)
        ? new Date(state.selectedWeekStartDate)
        : parseISO(payload.startDate as string)

      schedule = isNil(payload)
        ? buildVirtualWeekSchedule({
            startDate,
            itemsPerWeekday: state.recurringItemsPerWeekday,
          })
        : buildWeekSchedule(payload.schedule, startDate)

      isSelectedWeekCurrent = isSameDay(startDate, state.currentWeekStartDate)

      return {
        ...state,
        isSelectedWeekCurrent,
        selectedWeek: payload,
        selectedWeekUuid: payload?.uuid,
        selectedWeekStartDate: startDate,
        selectedWeekSchedule: schedule,
        isSelectedWeekStale: false,
      }

    case AgendaSchedulingReducerActionType.SelectedWeekScheduleUpdated:
      schedule = buildWeekScheduleOffsets(payload)

      isSelectedWeekCurrent = isSameDay(
        state.selectedWeekStartDate,
        state.currentWeekStartDate
      )

      return {
        ...state,
        isSelectedWeekCurrent,
        today: isSelectedWeekCurrent
          ? addActivityToDay(today, schedule[weekdayIndex[today.weekday]])
          : today,
        selectedWeekSchedule: schedule,
        currentWeekSchedule: isSelectedWeekCurrent
          ? cloneDeep(schedule)
          : state.currentWeekSchedule,
      }

    case AgendaSchedulingReducerActionType.WeekSelectedByStartDate:
      startDate = new Date(payload.startDate)

      isSelectedWeekCurrent = isSameDay(startDate, state.currentWeekStartDate)

      return {
        ...state,
        isSelectedWeekCurrent,
        selectedWeekStartDate: startDate,
        ...(isSelectedWeekCurrent
          ? {
              selectedWeek: cloneDeep(state.currentWeek),
              selectedWeekUuid: state.currentWeekUuid,
              selectedWeekSchedule: cloneDeep(state.currentWeekSchedule),
              isSelectedWeekStale: false,
            }
          : {
              selectedWeek: undefined,
              selectedWeekUuid: undefined,
              isSelectedWeekStale: true,
            }),
      }

    case AgendaSchedulingReducerActionType.NextWeekSelected:
      startDate = addWeeks(new Date(state.selectedWeekStartDate), 1)

      return {
        ...state,
        selectedWeekUuid: payload.selectedWeekUuid,
        selectedWeekday: Weekday.Monday,
        selectedWeek: undefined,
        selectedWeekStartDate: startDate,
        selectedWeekSchedule: buildVirtualWeekSchedule({
          startDate,
          itemsPerWeekday: cloneDeep(state.recurringItemsPerWeekday),
        }),
        isSelectedWeekStale: true,
      }

    case AgendaSchedulingReducerActionType.PreviousWeekSelected:
      startDate = addWeeks(new Date(state.selectedWeekStartDate), -1)

      return {
        ...state,
        selectedWeekUuid: payload.selectedWeekUuid,
        selectedWeekday: Weekday.Monday,
        selectedWeek: undefined,
        selectedWeekStartDate: startDate,
        selectedWeekSchedule: buildVirtualWeekSchedule({
          startDate,
          itemsPerWeekday: cloneDeep(state.recurringItemsPerWeekday),
        }),
        isSelectedWeekStale: true,
      }

    case AgendaSchedulingReducerActionType.TimezoneChanged:
      todaySchedule = state.currentWeekSchedule[weekdayIndex[today.weekday]]

      return {
        ...state,
        today: addActivityToDay(today, todaySchedule),
        timezone: payload.timezone,
      }

    case AgendaSchedulingReducerActionType.WeekdaySelected:
      return {
        ...state,
        selectedWeekday: payload,
      }

    default:
      return state
  }
}
