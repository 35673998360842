import { range } from "lodash"
import { useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ModalOrchestrationName } from "../../../../contexts/ModalOrchestrationContext"

import { MOVEMENT, NAME_SPACES } from "../../../../locales/constants"
import { formatDistance } from "../../../../utils/format"
import {
  GridFormField,
  GridFormFieldProps,
  RequiredFieldModalBodyProps,
} from "../../../Forms/GridFormField"
import { Slider, SliderProps } from "../../../Forms/Slider"
import { DistanceIcon } from "../../../Core/Icons"
import useActivityStyle from "../../../../hooks/useActivityStyle"

export type DistanceSliderState = number

export type DistanceSliderProps = SliderProps<DistanceSliderState> &
  RequiredFieldModalBodyProps<DistanceSliderState>

export const ExactDistanceSlider: React.FC<DistanceSliderProps> = ({
  state,
  setState,
}) => {
  const maxDistance = 50000 // 50 km

  const distanceOptions = range(0, maxDistance, 100)

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <Slider<number>
        onChange={setState}
        values={distanceOptions}
        formatValue={formatDistance}
        initialValue={state || 1000}
      />
    </div>
  )
}

export interface DistanceGridFormFieldProps
  extends Omit<
    GridFormFieldProps<DistanceSliderState>,
    "Body" | "label" | "modalName"
  > {
  name: string
}

export const DistanceGridFormField: React.FC<DistanceGridFormFieldProps> = ({
  name,
  ...props
}) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)

  const { DISTANCE }: any = t(MOVEMENT.FORM, {
    returnObjects: true,
  })

  const movementStyle = useWatch({ name: "movementStyle" })

  const activityStyle = useActivityStyle(movementStyle)

  const isAvailable = activityStyle?.isDistanceAvailable

  const Body = ExactDistanceSlider

  return (
    <GridFormField<DistanceSliderState>
      name={name}
      modalName={ModalOrchestrationName.FormFieldDistanceSlider}
      label={DISTANCE.LABEL}
      title={DISTANCE.TITLE}
      Body={Body}
      Icon={DistanceIcon}
      formatValue={formatDistance}
      hidden={!isAvailable}
      showDismiss
      {...props}
    />
  )
}
