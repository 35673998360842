import classNames, { clsx } from "clsx"
import React, { useCallback, useMemo } from "react"
import Carousel from "nuka-carousel"

import { useAgendaSchedulingContext } from "../../../contexts/AgendaSchedulingContext"
import { useLocaleContext } from "../../../contexts/LocaleContext"
import { Weekday } from "../../../generated/graphql"
import { getWeekdayInitial } from "../../../labels"
import { getWeekdayDate, weekdayOrder } from "../../../utils"
import { formatWeekday } from "../../../utils/format"
import isSameDay from "date-fns/isSameDay"
import addWeeks from "date-fns/addWeeks"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../../contexts/AnalyticsContext"

export const AgendaTimelineIndex: React.FC = () => {
  const { formatDate } = useLocaleContext()

  const {
    selectedWeekday,
    selectedWeekSchedule,
    selectedWeekStartDate,
    isSelectedWeekStale: isFetchingSelectedWeek,
    selectWeekday,
    today,
    selectPreviousWeek,
    selectNextWeek,
  } = useAgendaSchedulingContext()
  const { captureEvent } = useAnalyticsContext()

  const previousWeekStartDate = useMemo(
    () => addWeeks(selectedWeekStartDate, -1),
    [selectedWeekStartDate]
  )

  const nextWeekStartDate = useMemo(
    () => addWeeks(selectedWeekStartDate, 1),
    [selectedWeekStartDate]
  )

  const buildWeekdayOption = useCallback(
    (baseDate: Date, weekday: Weekday, index: number) => {
      const weekdayDate = getWeekdayDate(baseDate, weekday)

      const isToday = isSameDay(weekdayDate, today.date)

      const sessions = selectedWeekSchedule[index].sessions.slice(0, 6) || []

      const isSelected = weekday === selectedWeekday

      const textColor = isToday ? "text-primary" : `text-white`

      const handleWeekdayClick = () => {
        captureEvent(AnalyticsEvent.AgendaHomeTimelineIndexWeekdayClicked, {
          weekday,
        })

        if (weekday === selectedWeekday) {
          // if the selected weekday is the same as the current one,
          // we want to unselect it
          selectWeekday(undefined)
          // and select it again shortly after to trigger a scroll
          setTimeout(() => {
            selectWeekday(weekday)
          }, 200)
        } else {
          selectWeekday(weekday)
        }
      }

      return (
        <div
          key={weekday}
          onClick={handleWeekdayClick}
          className={classNames(
            "relative",
            "flex flex-col select-none justify-between",
            "w-full h-full p-1 gap-y-0.5 rounded-md",
            isToday && "bg-white/20",
            isSelected && "border-white/20 border-2 shadow-lg"
          )}
        >
          <>
            <div className="h-4 w-full z-20 flex flex-row items-center justify-center">
              {sessions.map((session, index) => (
                <div
                  key={index}
                  className={clsx(
                    index > 0 && "-ml-1",
                    "flex items-center justify-center",
                    `w-2 h-2 sm:w-3 sm:h-3 rounded-full`,
                    "shadow-sm",
                    `bg-${session.movementModality}`
                  )}
                />
              ))}
            </div>

            <div
              className={classNames(
                "aspect-1",
                textColor,
                isToday ? "bg-white" : "bg-primary",
                "relative rounded-md flex flex-col items-center justify-center cursor-pointer focus:outline-none aspect-square",
                "overflow-hidden"
              )}
            >
              <span className="sr-only">{formatWeekday(weekday)}</span>

              <span
                aria-hidden="true"
                className={classNames(
                  "h-6 w-6 rounded-full flex items-center justify-center text-lg font-semibold z-10"
                )}
              >
                {getWeekdayInitial(weekday)}
              </span>

              {weekdayDate && (
                <span
                  className={classNames(
                    textColor,
                    "text-xs tracking-tighter text-center"
                  )}
                >
                  {formatDate(weekdayDate, "d")}
                </span>
              )}
            </div>
          </>
        </div>
      )
    },
    [
      selectedWeekStartDate,
      selectedWeekday,
      today,
      formatDate,
      selectedWeekSchedule,
    ]
  )

  const slides = useMemo(
    () => [previousWeekStartDate, selectedWeekStartDate, nextWeekStartDate],
    [selectedWeekStartDate]
  )

  const IndexContent = ({ date, ...props }: { date: Date }) => (
    <div
      {...props}
      className="relative flex flex-row items-center w-full py-1 space-x-1 sm:space-x-3 justify-evenly"
    >
      {weekdayOrder.map((weekday, index) =>
        buildWeekdayOption(date, weekday, index)
      )}
    </div>
  )

  if (isFetchingSelectedWeek) {
    return (
      <div className="flex flex-col w-full max-w-lg px-2 mx-auto">
        <IndexContent date={selectedWeekStartDate} />
      </div>
    )
  }

  return (
    <div className="relative flex flex-col w-full max-w-lg mx-auto px-2">
      <Carousel
        slideIndex={1}
        beforeSlide={(currIndex, endIndex) => {
          captureEvent(AnalyticsEvent.AgendaHomeTimelineIndexSwiped, {
            direction: currIndex < endIndex ? "next" : "previous",
          })

          if (currIndex < endIndex) {
            selectNextWeek()
          } else {
            selectPreviousWeek()
          }
        }}
        swiping={!isFetchingSelectedWeek}
        withoutControls
      >
        {slides.map((slide, index) => (
          <IndexContent key={index} date={slide} />
        ))}
      </Carousel>
    </div>
  )
}
