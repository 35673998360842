import { IonCheckbox, IonLabel } from "@ionic/react"
import { clsx } from "clsx"
import isNil from "lodash/isNil"
import React from "react"

import { FormFieldProps } from "./Field"

interface CheckboxProps
  extends Omit<React.ComponentProps<typeof IonCheckbox>, "name" | "value">,
    FormFieldProps {
  label: any
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  label,
  color,
  className,
  register,
  ...rest
}) => {
  if (isNil(register)) {
    return <></>
  }

  const { ref, onBlur, onChange } = register(name)

  const handleChange = (e: any) => {
    onChange(e.detail.checked)
  }

  return (
    <div
      className={clsx("flex flex-row items-center gap-x-2", className)}
      ref={ref}
    >
      <IonCheckbox
        slot="start"
        color={color}
        className="flex-shrink-0"
        onIonBlur={onBlur}
        onIonChange={handleChange}
        {...rest}
      />
      <IonLabel color={color}>{label}</IonLabel>
    </div>
  )
}

export default Checkbox
