import { isNil } from "lodash"
import {
  Maybe,
  MovementModality,
  SqualoAdventureElement,
} from "../generated/graphql"
import clsx from "clsx"

export const getCombinedElementStyle = (
  elements?: SqualoAdventureElement[] | null
) => {
  if (isNil(elements) || elements.length === 0) {
    return "bg-neutral-200 text-neutral-800"
  } else if (elements.length === 1) {
    return clsx(
      `bg-gradient-to-br from-${elements[0]}-400 to-${elements[0]}-600`,
      `text-${getElementContrastColor(elements[0])}`
    )
  } else if (elements.length === 2) {
    return `bg-gradient-to-br from-${elements[0]}-500 to-${elements[1]}-500 text-white`
  } else {
    return `bg-gradient-to-br from-${elements[0]}-500 via-${elements[1]}-500 to-${elements[2]}-500 text-white`
  }
}

export const getElementContrastColor = (
  element?: Maybe<SqualoAdventureElement>
) => {
  if (element == SqualoAdventureElement.Air) {
    return "navy"
  } else {
    return "white"
  }
}

export const getElementTextColor = (
  element?: Maybe<SqualoAdventureElement>
) => {
  return `text-${getElementContrastColor(element)}`
}

export const elementOrder = [
  SqualoAdventureElement.Earth,
  SqualoAdventureElement.Fire,
  SqualoAdventureElement.Water,
  SqualoAdventureElement.Air,
]

export const modalityElement: Record<MovementModality, SqualoAdventureElement> =
  {
    [MovementModality.Breathing]: SqualoAdventureElement.Air,
    [MovementModality.Knowledge]: SqualoAdventureElement.Air,
    [MovementModality.Cardio]: SqualoAdventureElement.Fire,
    [MovementModality.Sport]: SqualoAdventureElement.Fire,
    [MovementModality.Mobility]: SqualoAdventureElement.Water,
    [MovementModality.Strength]: SqualoAdventureElement.Earth,
    [MovementModality.Other]: SqualoAdventureElement.Air,
  }

export const elementModalities: Record<
  SqualoAdventureElement,
  MovementModality[]
> = {
  [SqualoAdventureElement.Air]: [
    MovementModality.Breathing,
    MovementModality.Knowledge,
    MovementModality.Other,
  ],
  [SqualoAdventureElement.Fire]: [
    MovementModality.Cardio,
    MovementModality.Sport,
  ],
  [SqualoAdventureElement.Water]: [MovementModality.Mobility],
  [SqualoAdventureElement.Earth]: [MovementModality.Strength],
}
