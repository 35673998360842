import { clsx } from "clsx"
import React from "react"
import {
  MovementAgendaItemSummaryFragment,
  MovementSessionSummaryFragment,
} from "../../generated/graphql"
import { useAdventureContext } from "../../contexts/AdventureContext"
import { getElementTextColor } from "../../utils/elementUtils"
import { formatAdventurePhaseTitle } from "../../utils/format"
import { IoOpenOutline } from "react-icons/io5"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { useIonRouter } from "@ionic/react"

export const AdventureBanner: React.FC<{
  move: Pick<
    MovementAgendaItemSummaryFragment | MovementSessionSummaryFragment,
    "membershipAdventureUuid"
  >
  className?: string
}> = ({ move, className }) => {
  const router = useIonRouter()
  const { captureEvent } = useAnalyticsContext()
  const { getAdventure } = useAdventureContext()

  const adventure = getAdventure(move.membershipAdventureUuid)

  const currentPhase =
    adventure?.squaloAdventure.phases?.[adventure.currentPhaseIndex]

  const handleClick = () => {
    if (!adventure) return

    captureEvent(AnalyticsEvent.AdventureBannerClicked, {
      adventureUuid: adventure?.squaloAdventure.id,
      adventureElement: adventure?.squaloAdventure.element,
    })

    router.push(`/app/hub/discover/adventures/${adventure.squaloAdventure.id}`)
  }

  if (!adventure) return null

  return (
    <>
      <button
        className={clsx(
          "flex flex-row items-center justify-between px-2 py-1 w-full",
          `bg-${adventure.squaloAdventure.element}-500`,
          getElementTextColor(adventure.squaloAdventure.element),
          className
        )}
        onClick={handleClick}
      >
        <div className="flex flex-row items-baseline flex-grow truncate gap-x-1">
          <span className="font-bold truncate">
            {adventure.squaloAdventure.name}
          </span>
          {(adventure.squaloAdventure.phases?.length || 0) > 1 && (
            <span className="font-medium opacity-80">
              {formatAdventurePhaseTitle(
                currentPhase?.title,
                adventure.currentPhaseIndex
              )}
            </span>
          )}
        </div>

        <div className="flex flex-row items-center justify-end flex-shrink-0 gap-x-1">
          <IoOpenOutline className="text-sm" />
        </div>
      </button>
    </>
  )
}
