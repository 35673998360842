import clsx from "clsx"
import { useMemo } from "react"
import { RiCheckDoubleLine } from "react-icons/ri"
import {
  AdventureTemplatePhaseFragment,
  SqualoAdventureActivity,
  SqualoAdventureElement,
} from "../../generated/graphql"
import isNil from "lodash/isNil"
import { useAdventureContext } from "../../contexts/AdventureContext"
import { getElementContrastColor } from "../../utils/elementUtils"
import { useTranslation } from "react-i18next"
import { NAME_SPACES } from "../../locales/constants"
import { AdventureActivityCard } from "./AdventureTemplateActivityCard"

export const AdventurePhaseGrid: React.FC<{
  templateId: string
  element: SqualoAdventureElement
  index: number
  phase: AdventureTemplatePhaseFragment
  isOnlyPhase?: boolean
  isPremiumAdventure?: boolean
}> = ({
  element,
  index,
  phase,
  isOnlyPhase = true,
  templateId,
  isPremiumAdventure,
}) => {
  const { t } = useTranslation(NAME_SPACES.ADVENTURE)

  const { currentAdventures } = useAdventureContext()

  const adventure = useMemo(() => {
    return currentAdventures?.find(
      (adventure) => adventure.squaloAdventure.id === templateId
    )
  }, [currentAdventures, templateId])

  const isPhaseCompleted = useMemo(() => {
    if (isNil(adventure)) {
      return false
    }

    return adventure.currentPhaseIndex > index
  }, [adventure, index])

  if (isNil(phase.activities) || phase.activities.length === 0) {
    return null
  }

  return (
    <div
      className={clsx(
        "flex flex-col gap-y-4 w-full",
        isPhaseCompleted && "opacity-80",
        !isOnlyPhase && "border-b-1 border-neutral-200 border-opacity-50 py-2"
      )}
    >
      {!isOnlyPhase && (
        <div className="flex flex-col items-start w-full gap-y-1">
          <span className="text-xs font-medium uppercase">
            {t("TEMPLATE.TABS.PHASES.PHASE_N", { index: index + 1 })}
          </span>

          <div className="flex flex-row items-center justify-between w-full gap-x-2">
            <span className="text-xl font-semibold text-justify">
              {phase?.title}
            </span>

            {isPhaseCompleted ? (
              <RiCheckDoubleLine className="text-3xl" />
            ) : (
              <></>
            )}
          </div>

          <span className="font-medium text-justify">{phase?.description}</span>
        </div>
      )}

      <div
        className={clsx(
          "grid grid-cols-1 md:grid-cols-2 w-full relative",
          "p-4 gap-4",
          "rounded-lg shadow-inner",
          `bg-${getElementContrastColor(element)} bg-opacity-40`
        )}
      >
        {phase.activities?.map((activity, index) =>
          isNil(activity) ? (
            <></>
          ) : (
            <AdventureActivityCard
              key={index}
              templateId={templateId}
              activity={activity as SqualoAdventureActivity}
              isPremiumAdventure={isPremiumAdventure}
            />
          )
        )}
      </div>
    </div>
  )
}
