import classNames from "clsx"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { IoMegaphoneOutline } from "react-icons/io5"
import Content from "../Core/Content"
import Modal from "../Core/Modal"

import Button from "../Forms/Button"
import StorySetIcon, { StorySetIconType } from "../StorySetIcon"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import { useNotificationContext } from "../../contexts/NotificationContext"
import usePersistedFlag from "../../hooks/usePersistedFlag"
import { MEMBERSHIP, NAME_SPACES } from "../../locales/constants"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"

const NotificationPermissionBody: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)

  const {
    SETTINGS: {
      FIELDS: { NOTIFICATIONS },
    },
  } = t(MEMBERSHIP.PROFILE, { returnObjects: true })

  const { captureEvent } = useAnalyticsContext()
  const { closeModal } = useModalOrchestrationContext()
  const { requestPermissionIfMissing, isNativePermissionGranted } =
    useNotificationContext()

  const handleOK = () => {
    requestPermissionIfMissing(
      (alreadyGranted) => {
        if (!alreadyGranted) {
          captureEvent(AnalyticsEvent.NotificationPermissionGranted)
        }

        closeModal(ModalOrchestrationName.NotificationPermission, "granted")
      },
      () => {
        captureEvent(AnalyticsEvent.NotificationPermissionRejected)

        closeModal(ModalOrchestrationName.NotificationPermission, "denied")
      }
    )
  }

  const handleSkip = () => {
    captureEvent(AnalyticsEvent.NotificationPermissionRejected)

    closeModal(ModalOrchestrationName.NotificationPermission, "skip")
  }

  useEffect(() => {
    if (isNativePermissionGranted) {
      closeModal(ModalOrchestrationName.NotificationPermission)
    }
  }, [isNativePermissionGranted])

  return (
    <Content
      color="primary"
      className="relative flex flex-col items-center justify-center h-full"
      scrollY={false}
    >
      <div
        className={classNames(
          "flex flex-col items-center justify-between max-w-3xl h-full mx-auto gap-y-4 py-4 px-4"
        )}
      >
        <div className="flex flex-col items-center justify-center h-full gap-y-2">
          <span className="w-full my-4 text-3xl font-semibold text-center">
            {NOTIFICATIONS.REQUEST_PERMISSION.TITLE}
          </span>

          <span className="flex-grow w-full text-lg font-medium tracking-tight text-center">
            {NOTIFICATIONS.REQUEST_PERMISSION.DESCRIPTION}
          </span>

          <div className="flex flex-col items-center justify-center flex-grow w-full mx-auto md:p-4">
            <StorySetIcon
              type={StorySetIconType.Notification}
              className="items-start"
            />
          </div>

          <div className="flex flex-col items-center justify-center w-full gap-y-1 pb-safe">
            <Button
              fill="outline"
              className="w-full opacity-75"
              expand="block"
              color="white"
              size="large"
              iconSlot="start"
              onClick={handleSkip}
              label={NOTIFICATIONS.REQUEST_PERMISSION.DENY}
            />

            <Button
              color="white"
              onClick={handleOK}
              size="large"
              icon={IoMegaphoneOutline}
              className="w-full"
              labelClassName="text-neutral-700"
              iconClassName="text-neutral-700"
              label={NOTIFICATIONS.REQUEST_PERMISSION.GRANT}
            />
          </div>
        </div>
      </div>
    </Content>
  )
}

export const NotificationPermissionModal = () => {
  const { dirty: showNotificationPermissionModal } = usePersistedFlag(
    "hasShownNotificationPermissionModal"
  )

  return (
    <Modal
      name={ModalOrchestrationName.NotificationPermission}
      isSheet
      onDidPresent={() => {
        showNotificationPermissionModal()
      }}
      background="primary-500"
    >
      <Modal.Body>
        {(props: any) => <NotificationPermissionBody {...props} />}
      </Modal.Body>
    </Modal>
  )
}

export default NotificationPermissionModal
