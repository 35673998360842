import { IonItem } from "@ionic/react"
import classNames from "clsx"
import React from "react"

export type BaseWeekdayComponentProps = React.ComponentProps<typeof IonItem>

export const BaseWeekdayComponent: React.FC<BaseWeekdayComponentProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <IonItem
      mode="ios"
      detail={false}
      routerDirection="forward"
      lines="none"
      className="w-full p-0"
      {...props}
    >
      <div
        className={classNames(
          "flex flex-row items-center h-full w-full",
          className
        )}
      >
        {children}
      </div>
    </IonItem>
  )
}
