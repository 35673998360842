import { BodyTree, BodyPartEnum } from "../generated/graphql"
import { BodyPart } from "./types"

export const flattenBodyTree = (bodyTree: BodyTree): BodyPart[] => {
  const bodyParts: BodyPartEnum[] = []

  if (bodyTree?.isFullBody) {
    bodyParts.push(BodyPartEnum.FullBody)
  } else if (bodyTree && bodyTree.regions.length > 0) {
    bodyTree.regions.forEach((region) => {
      if (region.muscleGroups.length > 0) {
        region.muscleGroups.forEach((muscleGroup) => {
          if (muscleGroup.muscles.length > 0) {
            muscleGroup.muscles.forEach((muscle) => {
              bodyParts.push(muscle.name as unknown as BodyPartEnum)
            })
          }
          bodyParts.push(muscleGroup.name as unknown as BodyPartEnum)
        })
        bodyParts.push(region.name as unknown as BodyPartEnum)
      }
    })
  }

  return bodyParts
}
