import { IonContent } from "@ionic/react"
import clsx from "clsx"
import React from "react"
import { LoadingProps } from "../Loading"

export interface ContentProps extends React.ComponentProps<typeof IonContent> {
  background?: string
  loading?: boolean
  loadingProps?: LoadingProps
}

const Content: React.FC<ContentProps> = ({
  children,
  background = "neutral-100",
  className,
  ...props
}) => {
  return (
    <IonContent className={clsx(`bg-${background}`, className)} {...props}>
      {children}
    </IonContent>
  )
}

export default Content
