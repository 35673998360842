import { useLazyQuery, useMutation } from "@apollo/client"
import {
  ActionSheetButton,
  useIonActionSheet,
  useIonRouter,
} from "@ionic/react"
import { isAfter, min } from "date-fns"
import isNil from "lodash/isNil"
import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { RiMoreLine } from "react-icons/ri"
import { RouteComponentProps } from "react-router-dom"
import Content from "../../components/Core/Content"

import Page from "../../components/Core/Page"
import { AgendaItemButtons } from "../../components/Movement/AgendaItem/AgendaItemButtons"
import { MovementDescriptionTextarea } from "../../components/Movement/Forms/Fields/MovementDescriptionTextarea"
import { MovementAgendaItemFormModal } from "../../components/Movement/Modals/MovementAgendaItemFormModal"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"
import { useLocaleContext } from "../../contexts/LocaleContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import {
  GetMovementAgendaItemDocument,
  GetMovementAgendaItemQuery,
  GetMovementAgendaItemQueryVariables,
  LogMovementSessionMutationVariables,
  MovementAgendaItem,
  MovementAgendaItemSuggestedBy,
  UpdateMovementAgendaItemDescriptionDocument,
  UpdateMovementAgendaItemDescriptionMutation,
  UpdateMovementAgendaItemDescriptionMutationVariables,
  SessionExpectedDuration,
  MovementStyle,
  MovementModality,
  SqualoFollowAlongVideo,
} from "../../generated/graphql"
import useToast from "../../hooks/useToast"
import { MOVEMENT, NAME_SPACES } from "../../locales/constants"
import { getWeekdayDate } from "../../utils"
import { AgendaItemPageHeader } from "../../components/Movement/AgendaItem/AgendaItemPageHeader"
import { FollowAlongPreview } from "../../components/Movement/FollowAlongPreview"
import { SuggestedExternalLinkPreview } from "../../components/Movement/SuggestedExternalLinkPreview"
import { AdventureTemplateProductCarousel } from "../../components/Adventure/AdventureTemplateProductCard"
import { useAdventureContext } from "../../contexts/AdventureContext"

export type AgendaItemPageProps = RouteComponentProps<
  { uuid: string },
  any,
  { timeAvailable?: SessionExpectedDuration }
>

const AgendaItemPage: React.FC<AgendaItemPageProps> = ({ match, location }) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.AGENDA_ITEM, { returnObjects: true })

  const router = useIonRouter()
  const [present] = useIonActionSheet()
  const { showError, showWarning } = useToast()

  const { formatDate } = useLocaleContext()

  const { isInitialized } = useAuthenticatedClientContext()

  const {
    today,
    selectedWeekUuid,
    selectedWeekStartDate,
    removeAgendaItem,
    modifyAgendaItem,
    agendaItemActionLoading,
    logSession,
    selectWeekday,
    sessionActionLoading,
  } = useAgendaSchedulingContext()

  const isSelectedWeekInFuture = isAfter(selectedWeekStartDate, today.date)

  const { captureEvent } = useAnalyticsContext()
  const { openModal, toggleLoading } = useModalOrchestrationContext()
  const { getAdventure } = useAdventureContext()

  const [agendaItem, setAgendaItem] =
    useState<GetMovementAgendaItemQuery["movementAgendaItem"]>()

  const agendaItemAdventure = getAdventure(agendaItem?.membershipAdventureUuid)

  const [removingAgendaItem, setRemovingAgendaItem] = useState<boolean>(false)

  const [getMovementAgendaItem, { loading }] = useLazyQuery<
    GetMovementAgendaItemQuery,
    GetMovementAgendaItemQueryVariables
  >(GetMovementAgendaItemDocument, {
    fetchPolicy: "cache-first",
    variables: { uuid: match.params.uuid },
    onCompleted: (data) => {
      if (data.movementAgendaItem) {
        selectWeekday(data.movementAgendaItem.weekday || undefined)

        setAgendaItem(data.movementAgendaItem as MovementAgendaItem)
      } else if (isNil(data.movementAgendaItem)) {
        showWarning(TEXT.SESSION_NOT_FOUND)

        router.push("/app/hub/agenda", "back", "pop")
      }
    },
    onError: (error) => {
      showError(error.message)
    },
  })

  const canEdit =
    !isNil(agendaItem) &&
    agendaItem.movementModality !== MovementModality.Knowledge

  const handleOpenForm = () => {
    if (!canEdit) {
      return
    }

    openModal(ModalOrchestrationName.MovementAgendaItemForm, {
      agendaItem,
    })
  }

  const handleRemove = async (onlyThisWeek: boolean) => {
    if (isNil(agendaItem)) {
      throw new Error("Item is required")
    }

    setRemovingAgendaItem(true)

    const instanceDate = formatDate(
      getWeekdayDate(selectedWeekStartDate, agendaItem.weekday),
      "yyyy-MM-dd"
    )

    const variables = {
      uuid: agendaItem.uuid,
      instanceDate: onlyThisWeek ? instanceDate : undefined,
      movementWeekUuid: onlyThisWeek ? selectedWeekUuid : undefined,
    }

    router.push("/app/hub/agenda", "back", "pop")

    removeAgendaItem(variables, () => {
      setRemovingAgendaItem(false)
    })
  }

  const handleLogSession = async () => {
    if (isNil(agendaItem)) {
      showError(TEXT.AN_ERROR_HAS_OCCURED)

      return
    }

    let date = getWeekdayDate(selectedWeekStartDate, agendaItem.weekday)

    // get the minimum date between the agenda item date and the current date
    date = min([date, today.date])

    const variables = {
      movementWeekUuid: agendaItem.movementWeekUuid || selectedWeekUuid,
      movementAgendaItemUuid: agendaItem.uuid,
      reportedDate: formatDate(date, "yyyy-MM-dd"),
    } as LogMovementSessionMutationVariables

    captureEvent(AnalyticsEvent.AgendaItemSessionLogged, {
      date: variables.reportedDate,
      movementModality: agendaItem.movementModality,
      movementStyle: agendaItem.movementStyle,
      adventureName: agendaItemAdventure?.squaloAdventure.name,
      adventureElement: agendaItemAdventure?.squaloAdventure.element,
      adventureUuid: agendaItemAdventure?.squaloAdventure.id,
    })

    await logSession(variables, true, () => {
      router.goBack()
    })
  }

  const [updateDescription, { loading: updatingDescription }] = useMutation<
    UpdateMovementAgendaItemDescriptionMutation,
    UpdateMovementAgendaItemDescriptionMutationVariables
  >(UpdateMovementAgendaItemDescriptionDocument, {
    refetchQueries: [
      {
        query: GetMovementAgendaItemDocument,
        variables: { uuid: match.params.uuid },
      },
    ],
  })

  const handleUpdateDescription = useCallback(
    async (description?: string | null) => {
      const variables = {
        uuid: agendaItem?.uuid,
        description,
      }

      await updateDescription({ variables })
    },
    [agendaItem]
  )

  const handleStart = () => {
    if (!agendaItem) {
      showError(TEXT.AN_ERROR_HAS_OCCURED)
      return
    }

    captureEvent(AnalyticsEvent.AgendaItemSessionStarted, {
      movementModality: agendaItem.movementModality,
      movementStyle: agendaItem.movementStyle,
      adventureName: agendaItemAdventure?.squaloAdventure.name,
      adventureElement: agendaItemAdventure?.squaloAdventure.element,
      adventureUuid: agendaItemAdventure?.squaloAdventure.id,
    })

    router.push(`/app/agenda/items/${agendaItem.uuid}/session`, "forward")
  }

  const handlePreviewClick = () => {
    captureEvent(AnalyticsEvent.AgendaItemFollowAlongPreviewClicked, {
      movementAgendaItemUuid: agendaItem?.uuid,
      followAlongUuid: agendaItem?.followAlong?.uuid,
    })

    handleStart()
  }

  const presentActions = () => {
    const TEXT = t(MOVEMENT.ITEM_PLANNING, { returnObjects: true })

    let removeActions = [
      {
        text: TEXT.DELETE_ITEM,
        role: "destructive",
        handler: async () => {
          await handleRemove(false)

          if (agendaItem) {
            captureEvent(AnalyticsEvent.AgendaItemDeleted, {
              movementAgendaItemUuid: agendaItem.uuid,
              weekday: agendaItem.weekday,
              movementStyle: agendaItem.movementStyle,
              movementModality: agendaItem.movementModality,
              isRecurring: false,
            })
          }
        },
      },
    ] as ActionSheetButton[]

    if (agendaItem?.isRecurring) {
      removeActions = [
        {
          text: TEXT.DELETE_ITEM_OCCURRENCE_MESSAGE,
          role: "destructive",
          handler: async () => {
            await handleRemove(true)

            captureEvent(AnalyticsEvent.RecurringAgendaItemSkipped, {
              movementAgendaItemUuid: agendaItem.uuid,
              weekday: agendaItem.weekday,
              movementStyle: agendaItem.movementStyle,
              movementModality: agendaItem.movementModality,
            })
          },
        },
        {
          text: TEXT.DELETE_RECURRING_ITEM_MESSAGE,
          role: "destructive",
          handler: async () => {
            await handleRemove(false)

            captureEvent(AnalyticsEvent.AgendaItemDeleted, {
              movementAgendaItemUuid: agendaItem.uuid,
              weekday: agendaItem.weekday,
              movementStyle: agendaItem.movementStyle,
              movementModality: agendaItem.movementModality,
              isRecurring: true,
            })
          },
        },
      ]
    }

    present({
      buttons: [
        ...(canEdit
          ? [
              {
                text: TEXT.MODIFY_SESSION,
                handler: handleOpenForm,
              },
            ]
          : []),
        ...removeActions,
        {
          text: TEXT.CANCEL,
          role: "cancel",
        },
      ],
    })
  }

  useEffect(() => {
    toggleLoading(loading)
  }, [loading])

  useEffect(() => {
    if (
      match &&
      match.params.uuid &&
      isInitialized &&
      !agendaItemActionLoading &&
      !removingAgendaItem
    ) {
      getMovementAgendaItem()

      if (
        location.state &&
        location.state.timeAvailable &&
        location.state.timeAvailable !== agendaItem?.expectedDuration &&
        selectedWeekUuid
      ) {
        const variables = {
          uuid: match.params.uuid,
          expectedDuration: location.state.timeAvailable,
          movementWeekUuid: selectedWeekUuid,
        }

        modifyAgendaItem(variables, false)
      }
    }
  }, [
    isInitialized,
    match,
    match.params.uuid,
    location,
    selectedWeekUuid,
    agendaItem,
    agendaItemActionLoading,
    removingAgendaItem,
  ])

  const isSuggestion =
    agendaItem?.suggestedByType == MovementAgendaItemSuggestedBy.Aris ||
    agendaItem?.suggestedByType == MovementAgendaItemSuggestedBy.Coach

  return (
    <Page>
      <Page.Header
        title={TEXT.PLANNED_SESSION}
        color={agendaItem?.movementModality}
        showBackButton
        toolbarButtons={[
          {
            icon: RiMoreLine,
            fill: "clear",
            slot: "end",
            onClick: presentActions,
          },
        ]}
      />

      <Content scrollY={false}>
        {agendaItem && (
          <div className="flex flex-col h-full">
            <div className="flex flex-col flex-grow overflow-y-scroll pb-36 gap-y-4 bg-neutral-100 text-neutral">
              <AgendaItemPageHeader
                agendaItem={agendaItem as MovementAgendaItem}
                canEdit={canEdit}
                handleOpenForm={handleOpenForm}
                isCoachSuggestion={isSuggestion}
              />

              {/* <div className="flex flex-col items-end justify-end w-full px-2 gap-y-2">
                <AgendaItemCalendarButton
                  agendaItem={agendaItem as MovementAgendaItem}
                />
              </div> */}

              <div className="flex flex-col w-full h-full max-w-3xl mx-auto gap-y-4">
                <div className="flex flex-col w-full h-full px-4 gap-y-2">
                  <MovementDescriptionTextarea
                    initialValue={agendaItem.description}
                    onChange={handleUpdateDescription}
                    disabled={isSuggestion}
                    placeholder={TEXT.DESCRIPTION.PLACEHOLDER}
                    loading={updatingDescription}
                    className="bg-neutral-300/30 text-neutral-700 placeholder-neutral-500"
                  />
                </div>

                <div className="flex flex-col gap-y-4 md:flex-row md:items-center">
                  {!isNil(agendaItem.followAlong) &&
                    !isNil(agendaItem.followAlong.thumbnailUrl) && (
                      <div className="flex flex-col w-full h-full px-4 gap-y-2">
                        <FollowAlongPreview
                          followAlong={
                            agendaItem.followAlong as SqualoFollowAlongVideo
                          }
                          handlePreviewClick={handlePreviewClick}
                        />
                      </div>
                    )}

                  {!isNil(agendaItem.suggestedExternalLink) && (
                    <div className="flex flex-col w-full h-full px-4 gap-y-2">
                      <SuggestedExternalLinkPreview
                        movementModality={agendaItem.movementModality}
                        suggestedExternalLink={agendaItem.suggestedExternalLink}
                        className="h-32"
                      />
                    </div>
                  )}

                  <div className="flex flex-col w-full h-full gap-y-2">
                    {!isNil(agendaItemAdventure) && (
                      <AdventureTemplateProductCarousel
                        products={agendaItemAdventure.squaloAdventure.products}
                        nSlidesToShow={1}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {agendaItem.movementStyle !== MovementStyle.Consultation && (
              <div className="absolute bottom-0 left-0 right-0 w-full bg-white/0">
                <div className="w-full h-full max-w-3xl p-4 mx-auto bg-transparent">
                  <AgendaItemButtons
                    agendaItem={agendaItem as MovementAgendaItem}
                    disabled={sessionActionLoading}
                    isLogEnabled={!isSelectedWeekInFuture}
                    handleStart={handleStart}
                    handleLogSession={handleLogSession}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </Content>

      <MovementAgendaItemFormModal />
    </Page>
  )
}

export default AgendaItemPage
