import { useMemo } from "react"
import { buildFormURL } from "../../utils/form"
import { ButtonProps } from "../Forms/Button"
import Loading from "../Loading"
import Modal, { ModalProps, ModalSize } from "./Modal"

export interface TallyModalProps extends ModalProps {
  formPath?: string
  formUrl?: string
  hiddenFields?: Record<string, string>
}

export const TallyModalBody: React.FC<TallyModalProps> = ({
  formPath,
  formUrl,
  hiddenFields = {},
}) => {
  const urlWithHiddenFields = useMemo(() => {
    const fields = {
      ...hiddenFields,
      hideTitle: "1",
    }

    return buildFormURL(formPath || formUrl || "", fields)
  }, [formPath, hiddenFields])

  return (
    <div className="relative flex flex-col items-center justify-center w-full h-full bg-primary-500">
      <Loading className="absolute inset-0 z-10" />
      {urlWithHiddenFields && (
        <iframe
          title="tally-form"
          src={urlWithHiddenFields}
          width="100%"
          height={"100%"}
          className="absolute inset-0 z-20"
        />
      )}
    </div>
  )
}

export const TallyModal: React.FC<TallyModalProps> = ({ name, ...props }) => {
  const toolbarActions = [] as ButtonProps[]

  return (
    <Modal
      name={name}
      initialSize={ModalSize.Full}
      isSheet
      background="primary"
      {...props}
    >
      <Modal.Header actions={toolbarActions} />
      <Modal.Body>{(props: any) => <TallyModalBody {...props} />}</Modal.Body>
    </Modal>
  )
}
