import { useCallback, useContext, useMemo } from "react"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"
import {
  MovementActivitySummaryFragment,
  MovementAgendaItemSummaryFragment,
  MovementSessionSummaryFragment,
} from "../../../generated/graphql"
import Modal, { ModalContext, ModalProps, ModalSize } from "../../Core/Modal"
import { Haptics } from "@capacitor/haptics"
import clsx from "clsx"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../../contexts/AnalyticsContext"
import {
  formatPhaseOfDay,
  formatSessionDuration,
  formatStyle,
  formatWeekday,
} from "../../../utils/format"
import { getModalityTextColor } from "../../../utils/movementUtils"
import Content from "../../Core/Content"
import { DurationIcon } from "../../Core/Icons"
import useActivityStyle from "../../../hooks/useActivityStyle"
import { useIonRouter } from "@ionic/react"
import {
  RiCheckLine,
  RiInformationLine,
  RiPencilLine,
  RiPlayCircleLine,
} from "react-icons/ri"
import { useAgendaSchedulingContext } from "../../../contexts/AgendaSchedulingContext"
import { lowerCase } from "lodash"

export const CoreHabitPreviewModalBody: React.FC<{
  activity:
    | MovementActivitySummaryFragment
    | MovementSessionSummaryFragment
    | MovementAgendaItemSummaryFragment
  canEdit?: boolean
}> = ({ activity, canEdit = true }) => {
  const router = useIonRouter()

  const { name } = useContext(ModalContext)
  const { captureEvent } = useAnalyticsContext()
  const { selectedWeekday } = useAgendaSchedulingContext()
  const { closeModal, openModal } = useModalOrchestrationContext()

  const isSession = activity.__typename === "MovementSession"
  const isAgendaItem = activity.__typename === "MovementAgendaItem"
  const isActivity = activity.__typename === "MovementActivity"

  const activityStyle = useActivityStyle(activity.movementStyle)

  const hasFollowAlong = useMemo(
    () =>
      isAgendaItem &&
      (activityStyle?.isFollowAlongVideoAvailable ||
        activity.followAlongCustomUrl),
    [activity, activityStyle, isAgendaItem]
  )

  const handleView = useCallback(() => {
    captureEvent(AnalyticsEvent.TodayActivityPreviewViewPressed, {
      resourceType: activity.__typename,
      resourceUuid: activity.uuid,
    })

    closeModal(name, "viewActivity")

    if (isSession) {
      router.push(`/app/hub/agenda/sessions/${activity.uuid}`)
    } else if (isAgendaItem) {
      router.push(`/app/hub/agenda/items/${activity.uuid}`)
    } else if (isActivity) {
      console.debug("Open activity form")
    }
  }, [activity, router, captureEvent, isSession, isAgendaItem, isActivity])

  const handleEdit = useCallback(() => {
    captureEvent(AnalyticsEvent.TodayActivityPreviewEditPressed, {
      resourceType: activity.__typename,
      resourceUuid: activity.uuid,
    })

    if (isSession) {
      openModal(ModalOrchestrationName.MovementSessionForm, {
        session: activity,
      })
    } else if (isAgendaItem) {
      openModal(ModalOrchestrationName.MovementAgendaItemForm, {
        agendaItem: activity,
      })
    } else if (isActivity) {
      openModal(ModalOrchestrationName.MovementActivityForm, {
        activity: activity,
      })
    }
  }, [activity, openModal, captureEvent, isSession, isAgendaItem, isActivity])

  const handleStartSession = useCallback(() => {
    captureEvent(AnalyticsEvent.TodayActivityPreviewStartSessionPressed, {
      resourceType: activity.__typename,
      resourceUuid: activity.uuid,
    })

    closeModal(name, "startSession")

    if (isAgendaItem) {
      router.push(`/app/agenda/items/${activity.uuid}/session`)
    }
  }, [activity, captureEvent, isAgendaItem])

  return (
    <Content scrollY={false}>
      <div
        className={clsx(
          "flex flex-col gap-y-2 h-full",
          `bg-${activity.movementModality}`,
          getModalityTextColor(activity.movementModality),
          isActivity &&
            `border-dashed border-4 border-${activity.movementModality}-700 rounded-t-lg`
        )}
      >
        <div className="flex flex-col h-full w-full max-w-3xl mx-auto">
          <div className="relative flex flex-col justify-between w-full p-4 mx-auto overflow-hidden gap-y-2 flex-shrink-0">
            {isActivity && (
              <span className="font-semibold italic text-center w-full">
                Usually done on {formatWeekday(selectedWeekday)}
                {activity.usualPhaseOfDay
                  ? ` ${lowerCase(formatPhaseOfDay(activity.usualPhaseOfDay))}`
                  : ""}
                s
              </span>
            )}
            <div className="flex flex-col justify-between w-full gap-y-1">
              <div
                className={clsx(
                  "flex flex-row items-end justify-between gap-x-2 w-full",
                  canEdit && "cursor-pointer"
                )}
                onClick={canEdit ? handleEdit : undefined}
              >
                <div
                  className={clsx(
                    "flex flex-row items-center justify-start gap-x-2 w-full pt-8"
                  )}
                >
                  {isSession && <RiCheckLine className="w-10 h-10" />}
                  <span
                    className={clsx("text-3xl font-semibold w-full truncate")}
                  >
                    {activity.title || formatStyle(activity.movementStyle)}
                  </span>
                </div>

                <RiPencilLine className="w-10 h-10" />
              </div>
            </div>

            <div className="flex flex-row items-center justify-start w-full">
              <div className="flex flex-row flex-wrap items-center justify-start w-full gap-y-2 gap-x-4">
                <div className={clsx("flex flex-row items-center gap-x-1")}>
                  <DurationIcon className="w-5 h-5" />
                  <span className="font-semibold">
                    {formatSessionDuration(
                      (activity as MovementSessionSummaryFragment).duration ||
                        (activity as MovementAgendaItemSummaryFragment)
                          .expectedDuration ||
                        (activity as MovementActivitySummaryFragment)
                          .usualDuration
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-row flex-grow gap-4 p-4 h-full">
            {(isSession || isAgendaItem) && (
              <button
                className={clsx(
                  "aspect-square rounded-md shadow-lg h-full w-full",
                  "flex items-start justify-end flex-col gap-4",
                  "p-8",
                  `bg-${activity.movementModality}-700`,
                  getModalityTextColor(activity.movementModality)
                )}
                onClick={handleView}
              >
                <RiInformationLine className="w-10 h-10" />
                <span className="text-xl font-semibold">See Details</span>
              </button>
            )}

            {hasFollowAlong && (
              <button
                className={clsx(
                  "aspect-square rounded-md shadow-lg h-full w-full",
                  "flex items-start justify-end flex-col gap-4",
                  "p-8",
                  `bg-${activity.movementModality}-700`,
                  getModalityTextColor(activity.movementModality)
                )}
                onClick={handleStartSession}
              >
                <RiPlayCircleLine className="w-10 h-10" />
                <span className="text-xl font-semibold">Start Moving</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </Content>
  )
}

export interface CoreHabitPreviewModalProps extends ModalProps {
  activity:
    | MovementActivitySummaryFragment
    | MovementSessionSummaryFragment
    | MovementAgendaItemSummaryFragment
}

export const CoreHabitPreviewModal: React.FC<Partial<ModalProps>> = () => {
  const handleDidPresent = async () => {
    await Haptics.selectionStart()
  }

  return (
    <Modal
      name={ModalOrchestrationName.TodayActivityPreview}
      isSheet
      initialSize={ModalSize.Most}
      onDidPresent={handleDidPresent}
    >
      <Modal.Body>
        {(props: any) => <CoreHabitPreviewModalBody {...props} />}
      </Modal.Body>
    </Modal>
  )
}
