import { useEffect, useMemo, useState } from "react"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"

import Modal, { CloseButton, ModalProps } from "../../Core/Modal"
import { MovementModality, MovementStyle } from "../../../generated/graphql"

import clsx from "clsx"
import {
  getModalityContrastColor,
  getModalityTextColor,
} from "../../../utils/movementUtils"
import { RiRefreshLine } from "react-icons/ri"
import useTimer from "../../../hooks/useMoveTimer"
import isNil from "lodash/isNil"

import {
  MoveSnackTemplate,
  MoveSnackFrame,
  MoveSnackFrameTimerType,
} from "./type"
import get from "lodash/get"
import { MoveSnackFrameContent } from "./MoveSnackFrameContent"
import { MoveSnackNotStarted } from "./MoveSnackNotStarted"
import Button from "../../Forms/Button"
import { useAgendaSchedulingContext } from "../../../contexts/AgendaSchedulingContext"
import { useLocaleContext } from "../../../contexts/LocaleContext"

enum MoveSnackTemplateName {
  BreathingEnergize = "breathing_energize",
  BreathingFocus = "breathing_focus",
  BreathingRelax = "breathing_relax",
  MobilityNeck = "mobility_neck",
  MobilityShoulders = "mobility_shoulders",
  MobilityHipFlexor = "mobility_hip_flexor",
  StrengthSquats = "strength_squats",
  StrengthPushups = "strength_pushups",
  StrengthHorseStance = "strength_horse_stance",
}

const DEFAULT_SNACK_DURATION = 60

const TEMPLATES = {
  [MoveSnackTemplateName.BreathingEnergize]: {
    name: "Breathing Energize",
    movementModality: MovementModality.Breathing,
    movementStyle: MovementStyle.Breathwork,
    duration: DEFAULT_SNACK_DURATION,
    autoStart: true,
    frames: [
      {
        name: "Inhale",
        duration: 6,
      },
      {
        name: "Exhale",
        duration: 2,
      },
    ],
  } as MoveSnackTemplate,
  [MoveSnackTemplateName.BreathingFocus]: {
    name: "Box Breathing",
    movementModality: MovementModality.Breathing,
    movementStyle: MovementStyle.Breathwork,
    intervalRestTimes: 4,
    duration: 20,
    autoStart: true,
    frames: [
      {
        name: "Inhale",
        duration: 4,
      },
      {
        name: "Hold",
        duration: 4,
      },
      {
        name: "Exhale",
        duration: 4,
      },
      {
        name: "Hold",
        duration: 4,
      },
    ] as MoveSnackFrame[],
  } as MoveSnackTemplate,
  [MoveSnackTemplateName.MobilityNeck]: {
    name: "Neck Mobility",
    movementModality: MovementModality.Mobility,
    movementStyle: MovementStyle.FunctionalStretching,
    duration: DEFAULT_SNACK_DURATION,
    autoStart: true,
    frames: [
      {
        name: "Neck Circles",
        description: "Move your neck in circles",
        duration: 20,
      },
    ] as MoveSnackFrame[],
  } as MoveSnackTemplate,
  [MoveSnackTemplateName.MobilityShoulders]: {
    name: "Neck Mobility",
    movementModality: MovementModality.Mobility,
    movementStyle: MovementStyle.FunctionalStretching,
    duration: DEFAULT_SNACK_DURATION,
    autoStart: true,
    frames: [
      {
        name: "Shoulder Circles",
        description: "Move your shoulders in circles",
        duration: 20,
      },
    ] as MoveSnackFrame[],
  } as MoveSnackTemplate,
  [MoveSnackTemplateName.StrengthSquats]: {
    name: "10 Squats",
    movementModality: MovementModality.Strength,
    movementStyle: MovementStyle.CircuitTraining,
    duration: 10,
    autoStart: true,
    frames: [
      {
        name: "10 Squats",
        description: "Do 10 squats",
        type: MoveSnackFrameTimerType.Repetitions,
        repetitionCount: 10,
        repetitionDuration: 1,
      },
    ] as MoveSnackFrame[],
  } as MoveSnackTemplate,
  [MoveSnackTemplateName.StrengthHorseStance]: {
    name: "Horse Stance",
    movementModality: MovementModality.Strength,
    movementStyle: MovementStyle.CircuitTraining,
    duration: 240,
    autoStart: true,
    frames: [
      {
        name: "Hold",
        description: "Hold horse stance",
        duration: 240,
      },
    ] as MoveSnackFrame[],
  } as MoveSnackTemplate,
}

export enum MoveSnackSessionStatus {
  NotStarted = "not_started",
  Started = "started",
  Paused = "paused",
  Completed = "completed",
}

export const MoveSnack: React.FC = () => {
  const { closeModal } = useModalOrchestrationContext()

  const [templateName, setTemplateName] = useState<MoveSnackTemplateName>(
    MoveSnackTemplateName.StrengthHorseStance
  )

  const { logSession, today } = useAgendaSchedulingContext()

  const { formatDate } = useLocaleContext()

  const template = useMemo(
    () =>
      get(
        TEMPLATES,
        templateName,
        TEMPLATES[MoveSnackTemplateName.BreathingFocus]
      ),
    [templateName]
  )

  const [sessionStatus, setSessionStatus] = useState(
    MoveSnackSessionStatus.NotStarted
  )

  const handleSessionFinished = async () => {
    // closeModal(ModalOrchestrationName.MoveSnackModal)
    await logSession(
      {
        title: template.name,
        movementStyle: template.movementStyle,
        reportedDuration: template.duration,
        reportedDate: formatDate(today.date, "yyyy-MM-dd"),
      },
      true,
      () => {
        closeModal(ModalOrchestrationName.MoveSnackModal)
      }
    )
  }

  const sessionTimer = useTimer(
    {
      delay: template.duration * 1000,
      runOnce: true,
    },
    handleSessionFinished
  )

  const [currentFrameIndex, setCurrentFrameIndex] = useState(0)
  const currentFrame = useMemo(
    () => template.frames[currentFrameIndex],
    [currentFrameIndex, template]
  )

  const goToNextFrame = () => {
    setCurrentFrameIndex((index) => (index + 1) % template.frames.length)
  }

  const shuffleTemplate = () => {
    const templateNames = Object.keys(TEMPLATES)

    const randomIndex = Math.floor(Math.random() * templateNames.length)

    const randomTemplateName = templateNames[randomIndex]

    setTemplateName(randomTemplateName as MoveSnackTemplateName)
  }

  const startSession = () => {
    console.debug("[GameplanSessionModal] start stopwatch")

    setSessionStatus(MoveSnackSessionStatus.Started)
  }

  const playSession = () => {
    console.debug("[GameplanSessionModal] pause stopwatch")

    setSessionStatus(MoveSnackSessionStatus.Started)
  }

  const pauseSession = () => {
    console.debug("[GameplanSessionModal] pause stopwatch")

    setSessionStatus(MoveSnackSessionStatus.Paused)
  }

  const restartSession = () => {
    console.debug("[GameplanSessionModal] restart stopwatch")

    sessionTimer.stop()

    setCurrentFrameIndex(0)

    setSessionStatus(MoveSnackSessionStatus.NotStarted)
  }

  const renderMoveSnackContent = () => {
    switch (sessionStatus) {
      case MoveSnackSessionStatus.NotStarted:
        return (
          <MoveSnackNotStarted
            template={template}
            startSession={startSession}
          />
        )

      default:
      case MoveSnackSessionStatus.Started:
        return (
          <MoveSnackFrameContent
            template={template}
            frame={currentFrame}
            goToNextFrame={goToNextFrame}
            playSession={playSession}
            pauseSession={pauseSession}
            currentSessionProgress={currentSessionProgress}
          />
        )
    }
  }

  useEffect(() => {
    switch (sessionStatus) {
      case MoveSnackSessionStatus.NotStarted:
        break
      case MoveSnackSessionStatus.Started:
        if (sessionTimer.isPaused()) {
          sessionTimer.resume()
        } else {
          sessionTimer.start()
        }
        break
      case MoveSnackSessionStatus.Paused:
        sessionTimer.pause()
        break
      case MoveSnackSessionStatus.Completed:
        sessionTimer.stop()
        break
    }
  }, [sessionStatus])

  useEffect(() => {
    restartSession()
  }, [templateName])

  const currentSessionProgress = useMemo(() => {
    if (isNil(template)) return 0

    return sessionTimer.secondsPassed / template.duration
  }, [template, sessionTimer.secondsPassed])

  return (
    <div
      className={clsx(
        `bg-${template.movementModality}`,
        "h-full w-full pt-4-safe"
      )}
    >
      <CloseButton
        color={getModalityContrastColor(template.movementModality)}
      />
      <Button
        color={getModalityContrastColor(template.movementModality)}
        onClick={shuffleTemplate}
        icon={RiRefreshLine}
        fill="clear"
        iconSlot="icon-only"
        className={clsx("absolute shadow-none right-4")}
      />
      <div
        className={clsx(
          "flex flex-col items-center justify-around w-full h-full",
          getModalityTextColor(template.movementModality)
        )}
      >
        {renderMoveSnackContent()}
      </div>
    </div>
  )
}

export const GameplanSessionModal: React.FC<Partial<ModalProps>> = ({
  name = ModalOrchestrationName.MoveSnackModal,
  ...props
}) => {
  return (
    <Modal
      name={name}
      background="primary"
      fullScreen
      keepContentsMounted={false}
      {...props}
    >
      <Modal.Body scrollY={false}>
        {(props: any) => <MoveSnack {...props} />}
      </Modal.Body>
    </Modal>
  )
}

export default MoveSnack
