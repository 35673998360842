import classNames from "clsx"
import isNil from "lodash/isNil"
import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { IconType } from "react-icons/lib"

import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"

export const DetailBlock: React.FC<{
  Icon: IconType
  label?: string
  value: string
  fill?: boolean
  scrollX?: boolean
  className?: string
  hidden?: boolean
  onClick?: () => void
}> = React.forwardRef(function DetailBlock(
  { Icon, label, value, className, fill = false, onClick, hidden, scrollX },
  ref
) {
  const Component = onClick ? "button" : "div"

  const { captureEvent } = useAnalyticsContext()

  const handleClick = () => {
    captureEvent(AnalyticsEvent.SessionDetailBlockClicked, {
      label,
    })

    onClick && onClick()
  }

  return (
    <Component
      className={classNames(
        "relative",
        "flex flex-col justify-between",
        "rounded-md shadow-sm",
        "py-3 h-24",
        fill ? "w-full" : "w-40",
        scrollX ? "" : "min-w-fit",
        "bg-white",
        onClick
          ? "text-primary-500 hover:text-primary-600 shadow-md"
          : "text-neutral",
        className,
        hidden && "hidden"
      )}
      ref={ref as any}
      disabled={!onClick}
      onClick={handleClick}
    >
      <div className="flex flex-row items-center justify-between w-full px-4 text-neutral gap-x-2">
        <span className={classNames("text-sm font-semilight")}>{label}</span>
        {Icon && <Icon className={classNames("w-6 h-6")} />}
      </div>
      <span
        className={classNames(
          "w-full text-base font-semibold text-left sm:text-lg",
          scrollX
            ? "overflow-x-scroll whitespace-nowrap px-4"
            : "max-w-max px-4"
        )}
      >
        {value}
      </span>
    </Component>
  )
})

export const DetailCarousel: React.FC<{
  className?: string
  grid?: boolean
}> = ({ grid = false, className, children }) => {
  const [slides, setSlides] = useState<React.ReactNode[]>([])
  const [activeIndex, setActiveIndex] = useState(0)

  const slideContainerRef = useRef<HTMLDivElement | null>(null)
  const slideRefs = useRef([]) as any

  useEffect(() => {
    if (children) {
      const newSlides = [] as React.ReactNode[]

      React.Children.forEach(children, (child: any, index: number) => {
        if (!isNil(child) && React.isValidElement(child)) {
          newSlides.push(
            React.cloneElement(child as any, {
              key: index,
              ref: (ref: any) => (slideRefs.current[index] = ref),
            })
          )
        }
      })

      setSlides(newSlides)
    }
  }, [children, slideContainerRef, slideRefs])

  useEffect(() => {
    if (slideContainerRef.current && slides.length > 0) {
      const observer = new window.IntersectionObserver(
        (entries) => {
          for (const entry of entries) {
            if (entry.isIntersecting) {
              setActiveIndex(slideRefs.current.indexOf(entry.target))
              break
            }
          }
        },
        {
          root: slideContainerRef.current,
          threshold: 0.6,
        }
      )

      for (const slide of slideRefs.current) {
        if (slide) {
          observer.observe(slide)
        }
      }

      return () => {
        observer.disconnect()
      }
    }
  }, [slides])

  return (
    <div
      className={classNames(
        "w-full flex flex-col gap-y-2 overflow-y-hidden",
        className
      )}
    >
      <div
        ref={slideContainerRef}
        className="w-full py-2 overflow-x-scroll carousel_container"
      >
        <div
          className={classNames(
            "w-full gap-x-2 gap-y-2 sm:py-0",
            "flex flex-row min-w-fit",
            grid
              ? "grid grid-cols-2 justify-items-center"
              : "flex-nowrap justify-start items-center px-4",
            "text-neutral-700 tracking-tighter"
          )}
        >
          {slides}
        </div>
      </div>
      {!grid && slides.length >= 3 && (
        <div className="flex justify-center gap-3 lg:hidden">
          {slides.map((_: any, idx: number) => (
            <button
              type="button"
              key={idx}
              className={classNames(
                "relative h-2 w-2 rounded-full",
                idx === activeIndex ? "bg-slate-500" : "bg-slate-300"
              )}
              aria-label={`Go to slide ${idx + 1}`}
              onClick={() => {
                slideRefs.current[idx].scrollIntoView({
                  block: "nearest",
                  inline: "nearest",
                })
              }}
            >
              <span className="absolute -inset-x-1.5 -inset-y-3" />
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
