import classNames from "clsx"
import { isNil } from "lodash"
import React from "react"

import { Maybe, MovementModality, MovementStyle } from "../../generated/graphql"
import { modalityLabels } from "../../labels"
import { getModalityTextColor } from "../../utils/movementUtils"
import { getIcon } from "../Core/Icons"

export const ModalitySpan: React.FC<{
  modality: MovementModality
  className?: string
}> = ({ modality }) => {
  return (
    <div className="flex flex-row items-center justify-center gap-x-1">
      <ModalityIcon modality={modality} side={5} />
      <span className="text-lg font-semilight text-neutral-700">
        {modalityLabels[modality]}
      </span>
    </div>
  )
}

interface IModalityIconProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  movementStyle?: Maybe<MovementStyle>
  modality?: Maybe<MovementModality>
  pale?: boolean
  withBackground?: boolean
  side?: number
}

export const ModalityIcon: React.FC<IModalityIconProps> = ({
  modality,
  movementStyle,
  pale = false,
  withBackground = true,
  side = 6,
  className,
  children,
  ...props
}) => {
  const sizeClassNames = `w-${side} h-${side}`

  if (isNil(modality)) {
    return (
      <div
        className={classNames(
          sizeClassNames,
          "bg-transparent flex items-center justify-center",
          className
        )}
      >
        {children}
      </div>
    )
  }

  const Icon = getIcon({
    movementModality: modality,
    movementStyle,
  })

  if (withBackground) {
    return (
      <div
        className={classNames(
          "rounded-sm p-1",
          `bg-${modality}`,
          sizeClassNames,
          pale ? "opacity-30" : "opacity-100",
          getModalityTextColor(modality),
          className
        )}
      >
        <Icon className="w-full h-full" {...props} />
      </div>
    )
  }

  return (
    <Icon
      className={classNames(
        `text-${modality}`,
        className,
        side ? `w-${side} h-${side}` : "w-6 h-6"
      )}
      {...props}
    />
  )
}
