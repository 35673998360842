import get from "lodash/get"
import createPersistedState from "use-persisted-state"

const useCountersState = createPersistedState<{ [key: string]: number }>(
  "counters"
)

const usePersistedCounter = (key: string) => {
  const [counters, setCounter] = useCountersState({})

  const incrementCounter = (value = 1) => {
    setCounter((counters) => ({
      ...counters,
      [key]: get(counters, key, 0) + value,
    }))
  }

  const decrementCounter = (value = 1) => {
    setCounter((counters) => ({
      ...counters,
      [key]: get(counters, key, 0) - value,
    }))
  }

  const getCounter = () => {
    return get(counters, key, 0)
  }

  return {
    incrementCounter,
    decrementCounter,
    getCounter,
  }
}

export default usePersistedCounter
