import { useQuery } from "@apollo/client"
import {
  GetStyleDocument,
  GetStyleQuery,
  MovementStyle,
} from "../generated/graphql"
import isNil from "lodash/isNil"

export const useActivityStyle = (style: MovementStyle | undefined | null) => {
  const { data } = useQuery<GetStyleQuery>(GetStyleDocument, {
    fetchPolicy: "cache-first",
    skip: isNil(style),
    variables: { style },
  })

  return data?.activityStyle
}

export default useActivityStyle
