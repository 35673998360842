import { defineCustomElements } from "@ionic/pwa-elements/loader"
import { IonApp, setupIonicReact } from "@ionic/react"
import { IonReactRouter } from "@ionic/react-router"

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"
import "@ionic/react/css/display.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/float-elements.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/typography.css"
import * as Sentry from "@sentry/capacitor"
import { HelmetProvider } from "react-helmet-async"

import "tailwindcss/tailwind.css"

import { CapacitorUpdater } from "@capgo/capacitor-updater"

import { AgendaSchedulingProvider } from "./contexts/AgendaSchedulingContext"
import { AnalyticsProvider } from "./contexts/AnalyticsContext"
import { AuthenticatedClientProvider } from "./contexts/AuthenticatedClientContext"
import { LocaleProvider } from "./contexts/LocaleContext"
import { NotificationProvider } from "./contexts/NotificationContext/NotificationProvider"

import { ModalProvider } from "./contexts/ModalOrchestrationContext"
import { PurchaseProvider } from "./contexts/SubscriptionContext"
import { AdventureProvider } from "./contexts/AdventureContext"

import NotificationListener from "./listeners/NotificationListener"
import { PremiumListener } from "./listeners/PremiumListener"

import { getCurrentEnvironment } from "./utils"

import MainRouter from "./routers/MainRouter"

import AppStateListener from "./listeners/AppStateListener"
import AppUrlListener from "./listeners/AppUrlListener"

import "./locales/i18n"

/* Theme variables */
import "./theme/variables.css"

CapacitorUpdater.notifyAppReady()

// CapacitorUpdater.addListener(
//   "downloadComplete",
//   (state: DownloadCompleteEvent) => {
//     if (!isNil(state.bundle)) {
//       CapacitorUpdater.set({ id: state.bundle.id })
//     }
//   }
// )

setupIonicReact({ mode: "ios" })
defineCustomElements()

Sentry.init({
  dsn: "https://d19f2e3c8b034e3ba7e521318525adb7@o1052187.ingest.sentry.io/6227648",
  // To set your release and dist versions
  environment: getCurrentEnvironment(),
  enabled: getCurrentEnvironment() !== "development",
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  tracesSampleRate: getCurrentEnvironment() === "production" ? 0.3 : 1.0,
})

const CrushApp: React.FC = () => {
  return (
    <ModalProvider>
      <AuthenticatedClientProvider>
        <LocaleProvider>
          <AnalyticsProvider>
            <NotificationProvider>
              <PurchaseProvider>
                <HelmetProvider>
                  <AdventureProvider>
                    <AgendaSchedulingProvider>
                      <IonApp>
                        <IonReactRouter>
                          <MainRouter />

                          {/* Listeners */}
                          <AppStateListener />
                          <AppUrlListener />
                          <PremiumListener />
                          <NotificationListener />
                        </IonReactRouter>
                      </IonApp>
                    </AgendaSchedulingProvider>
                  </AdventureProvider>
                </HelmetProvider>
              </PurchaseProvider>
            </NotificationProvider>
          </AnalyticsProvider>
        </LocaleProvider>
      </AuthenticatedClientProvider>
    </ModalProvider>
  )
}

export default CrushApp
