import { useQuery } from "@apollo/client"
import { IonSkeletonText } from "@ionic/react"
import classNames from "clsx"
import isNil from "lodash/isNil"

import {
  BodyPartEnum,
  BodyTree,
  GetStyleDocument,
  GetStyleQuery,
  MovementAgendaItemSummaryFragment,
  MovementModality,
  MovementSessionSummaryFragment,
  MovementStyle,
} from "../../generated/graphql"
import { formatStyle } from "../../utils/format"
import { getModalityTextColor } from "../../utils/movementUtils"
import { StyleOption } from "./Forms/Fields/MovementStyleSelect"
import { ModalityIcon } from "./Modality"

export const buildSessionLabel = ({
  title,
  movementStyle,
}: {
  title?: string | null
  movementStyle?: MovementStyle
  movementTargetBodyParts?: BodyTree | BodyPartEnum[] | null
  includeTargetBodyParts?: boolean
}) => {
  if (!isNil(title) && title.length > 0) {
    return title
  }

  const label = formatStyle(movementStyle)

  return label
}

export const MovementStyleSpan: React.FC<{
  className?: string
  iconSize?: number
  element?: MovementAgendaItemSummaryFragment | MovementSessionSummaryFragment
  activityStyle?: StyleOption
  includeTargetBodyParts?: boolean
}> = ({
  element,
  includeTargetBodyParts = true,
  activityStyle,
  iconSize,
  className,
}) => {
  if (!element && !activityStyle) {
    return <IonSkeletonText animated style={{ width: "100%" }} />
  }

  const { data } = useQuery<GetStyleQuery>(GetStyleDocument, {
    variables: { style: activityStyle?.name || element?.movementStyle },
  })

  const label = buildSessionLabel({
    title: element?.title,
    movementStyle: activityStyle?.name || element?.movementStyle,
    movementTargetBodyParts: element?.movementTargetBodyParts,
    includeTargetBodyParts:
      includeTargetBodyParts && data?.activityStyle.isTargetBodyPartAvailable,
  })

  const textColor =
    element?.movementModality === MovementModality.Breathing
      ? getModalityTextColor(MovementModality.Breathing)
      : `text-${activityStyle?.modality || element?.movementModality}-700`

  return (
    <div
      className={"flex flex-row items-center gap-x-2 overflow-hidden w-full"}
    >
      <ModalityIcon
        movementStyle={activityStyle?.name || element?.movementStyle}
        modality={activityStyle?.modality || element?.movementModality}
        side={iconSize}
        className="flex-shrink-0"
      />
      <span className={classNames("font-medium w-full", textColor, className)}>
        {label}
      </span>
    </div>
  )
}
