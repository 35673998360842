import isNil from "lodash/isNil"
import * as React from "react"
import { SqualoFollowAlongVideo } from "../../generated/graphql"
import { ThumbnailButton } from "../Core/ThumbnailButton"
import clsx from "clsx"

export const FollowAlongPreview: React.FC<{
  followAlong: SqualoFollowAlongVideo
  handlePreviewClick?: () => void
  className?: string
}> = ({ followAlong, handlePreviewClick, className }) => {
  if (isNil(followAlong) || isNil(followAlong.thumbnailUrl)) {
    return null
  }

  return (
    <ThumbnailButton
      url={followAlong.thumbnailUrl}
      background={followAlong.movementModality}
      onClick={handlePreviewClick}
      overlayOpacity={60}
      className={clsx("rounded-lg h-52", className)}
    >
      <div className="flex flex-col justify-between w-full h-full p-4">
        <div className="flex flex-row-reverse items-center justify-between w-full">
          <span className="text-sm font-bold text-white">OPEN MOVEMENT</span>
        </div>
        <div className="flex flex-col items-start w-full">
          <span className="font-semibold text-white/90">
            {followAlong.creatorName}
          </span>
          <span className="w-full text-xl font-bold text-left text-white">
            {followAlong.title}
          </span>
        </div>
      </div>
    </ThumbnailButton>
  )
}
