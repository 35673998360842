import isNil from "lodash/isNil"

import createPersistedState from "use-persisted-state"
const useFlagsState = createPersistedState<{ [key: string]: boolean }>("flags")

const usePersistedFlag = (key: string) => {
  const [flag, setFlag] = useFlagsState({})

  return {
    isDirty: !isNil(flag[key]),
    dirty: () => setFlag({ ...flag, [key]: true }),
    clean: () => setFlag({ ...flag, [key]: false }),
  }
}

export default usePersistedFlag
