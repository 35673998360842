import { Weekday } from "../../generated/graphql"
import { CALENDAR } from "../constants"

export default {
  [CALENDAR.RELATIVE]: {
    DATE: {
      lastWeek: "EEEE 'dernier'",
      yesterday: "'hier'",
      today: "'aujourd’hui'",
      tomorrow: "'demain'",
      nextWeek: "EEEE",
      other: "EEEE d MMMM",
    },
    DATETIME: {
      lastWeek: "EEE 'dernier à' p",
      yesterday: "'hier à' p",
      today: "'auj. à' p",
      tomorrow: "'demain à' p",
      nextWeek: "EEE 'à' p",
      other: "EEE 'à' p",
    },
  },
  [CALENDAR.WEEKDAYS]: {
    [Weekday.Monday]: "Lundi",
    [Weekday.Tuesday]: "Mardi",
    [Weekday.Wednesday]: "Mercredi",
    [Weekday.Thursday]: "Jeudi",
    [Weekday.Friday]: "Vendredi",
    [Weekday.Saturday]: "Samedi",
    [Weekday.Sunday]: "Dimanche",
    WEEKDAYS: "Jours de semaine",
    WEEKEND: "Week-end",
    NONE: "Jamais",
    EVERYDAY: "Tous les jours",
  },
}
