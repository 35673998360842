import * as React from "react"
import { useTranslation } from "react-i18next"
import { RiFlagLine } from "react-icons/ri"

import { SqualoFollowAlongVideo } from "../../generated/graphql"
import { MICO, NAME_SPACES } from "../../locales/constants"
import {
  formatBodyTree,
  formatEquipment,
  formatLanguage,
  formatSessionDuration,
} from "../../utils/format"
import { DetailBlock, DetailCarousel } from "../Movement/DetailCarousel"
import {
  DurationIcon,
  EquipmentIcon,
  LanguageIcon,
  TargetBodyPartIcon,
} from "../Core/Icons"

export const VideoDetailsCarousel: React.FC<{
  video: SqualoFollowAlongVideo
  openModal?: () => void
  grid?: boolean
  scrollX?: boolean
}> = ({ video, grid = false, scrollX = false, openModal }) => {
  const { t } = useTranslation(NAME_SPACES.MICO)
  const TEXT = t(MICO.VIEWER, { returnObjects: true })

  if (!video) {
    return <></>
  }

  return (
    <DetailCarousel grid={grid} className="h-full">
      {openModal && (
        <DetailBlock
          Icon={RiFlagLine}
          label={TEXT.FLAG_LABEL_ISSUE.LABEL}
          value={TEXT.FLAG_LABEL_ISSUE.VALUE}
          fill={grid}
          scrollX={scrollX}
          onClick={openModal}
          className="col-span-2 min-w-fit"
        />
      )}

      <DetailBlock
        Icon={TargetBodyPartIcon}
        label={TEXT.TARGET_AREAS}
        value={formatBodyTree(video.movementTargetBodyParts)}
        fill={grid}
        scrollX={scrollX}
      />

      <DetailBlock
        Icon={DurationIcon}
        label={TEXT.DURATION}
        value={formatSessionDuration(video.duration)}
        fill={grid}
        scrollX={scrollX}
      />

      <DetailBlock
        Icon={LanguageIcon}
        label={TEXT.LANGUAGE}
        value={formatLanguage(video.language)}
        fill={grid}
        scrollX={scrollX}
      />

      <DetailBlock
        Icon={EquipmentIcon}
        label={TEXT.EQUIPMENT}
        value={formatEquipment(video.environmentalEquipmentRequired)}
        fill={grid}
        scrollX={scrollX}
      />
    </DetailCarousel>
  )
}
