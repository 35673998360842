import { useMutation } from "@apollo/client"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import BaseAuthPage, {
  BaseAuthPageLinkProps,
} from "../../components/Auth/BaseAuthPage"
import { RegistrationForm } from "../../components/Auth/RegistrationForm"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { useLocaleContext } from "../../contexts/LocaleContext"
import { useModalOrchestrationContext } from "../../contexts/ModalOrchestrationContext"
import {
  RegisterUserDocument,
  RegisterUserMutation,
  RegisterUserMutationVariables,
  UserSession,
} from "../../generated/graphql"
import useToast from "../../hooks/useToast"
import { AUTH, NAME_SPACES } from "../../locales/constants"
import { getReferralCode } from "../../routers/AuthRouter"

type RegistrationPageProps = {
  onCompleted: (userSession: UserSession) => void
}

const RegistrationPage: React.FC<RegistrationPageProps> = ({ onCompleted }) => {
  const { t } = useTranslation(NAME_SPACES.AUTH)
  const TEXT = t(AUTH.REGISTRATION, {
    returnObjects: true,
  })

  const { locale } = useLocaleContext()
  const { captureEvent } = useAnalyticsContext()
  const { toggleLoading } = useModalOrchestrationContext()

  const { showError, showWarning } = useToast()

  const [mutateRegisterUser, { loading: submitting }] = useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(RegisterUserDocument)

  const registerUser = async (data: {
    firstName: string
    lastName: string
    email: string
    password: string
  }) => {
    await mutateRegisterUser({
      variables: {
        ...data,
        acceptsTerms: true,
        referralCode: await getReferralCode(),
        locale,
      },
      onCompleted: async (completedData) => {
        captureEvent(AnalyticsEvent.UserRegistered)

        onCompleted({
          ...completedData.registerUser,
          isFirstSession: true,
        } as UserSession)
      },
      onError: async (error) => {
        switch (error.message) {
          case "Email has already been taken":
            showWarning(TEXT.EMAIL_TAKEN)
            return

          default:
            showError(error)
            break
        }
      },
    })
  }

  const links = [
    {
      href: "/auth/login",
      routerDirection: "forward",
      label: TEXT.ALREADY_REGISTRED,
    },
  ] as BaseAuthPageLinkProps[]

  useEffect(() => {
    if (submitting) {
      toggleLoading(true, {
        background: "primary",
      })
    } else {
      toggleLoading(false)
    }
  }, [submitting])

  return (
    <BaseAuthPage links={links as BaseAuthPageLinkProps[]} showBackButton>
      <span className="text-2xl font-semibold text-center">{TEXT.TITLE}</span>
      <RegistrationForm registerUser={registerUser} />
    </BaseAuthPage>
  )
}

export default RegistrationPage
