import clsx from "clsx"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import { NAME_SPACES } from "../../locales/constants"
import Button from "../Forms/Button"
import { RiLockUnlockLine } from "react-icons/ri"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"

export const AdventureActivityLockedConversionHook: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.ADVENTURE)

  const { openModal } = useModalOrchestrationContext()
  const { captureEvent } = useAnalyticsContext()

  const handleClick = () => {
    captureEvent(
      AnalyticsEvent.AdventurePhaseLockedConversionHookCTAClicked,
      {}
    )

    openModal(ModalOrchestrationName.GetPremium)
  }

  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-center w-full h-full text-center text-neutral-700",
        "p-4",
        "absolute inset-0",
        "backdrop-filter backdrop-blur-lg rounded-lg bg-neutral-300 bg-opacity-20"
      )}
    >
      <span className="mt-4 text-2xl font-semibold">
        {t("CURRENT.CONVERSION.ACTIVITY_LOCKED.TITLE")}
      </span>

      <span className="hidden mt-2 font-medium">
        {t("CURRENT.CONVERSION.ACTIVITY_LOCKED.SUBTITLE")}
      </span>

      <Button
        className="mt-4"
        onClick={handleClick}
        icon={RiLockUnlockLine}
        color="primary"
        iconSlot="start"
        iconClassName="mr-1"
        label={t("CURRENT.CONVERSION.ACTIVITY_LOCKED.CTA")}
      />
    </div>
  )
}
