import { useQuery } from "@apollo/client"
import { useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ModalOrchestrationName } from "../../../../contexts/ModalOrchestrationContext"

import {
  BodyMuscleEnum,
  BodyMuscleGroupEnum,
  BodyPartEnum,
  BodyRegionEnum,
  BodyTree,
  ListBodyRegionsDocument,
  ListBodyRegionsQuery,
} from "../../../../generated/graphql"
import { MOVEMENT, NAME_SPACES } from "../../../../locales/constants"
import { formatBodyPart } from "../../../../utils/format"
import CardSelect, { CardSelectProps } from "../../../Forms/CardSelect"
import {
  GridFormField,
  GridFormFieldProps,
  RequiredFieldModalBodyProps,
} from "../../../Forms/GridFormField"
import Toggle from "../../../Forms/Toggle"
import { TargetBodyPartIcon } from "../../../Core/Icons"
import useActivityStyle from "../../../../hooks/useActivityStyle"

export interface BodyPartSelectState {
  isFullBody?: boolean
  region?: BodyRegionEnum
  muscleGroup?: BodyMuscleGroupEnum
  muscle?: BodyMuscleEnum
}

export type BodyPartSelectProps = CardSelectProps<BodyPartSelectState> &
  RequiredFieldModalBodyProps<BodyPartSelectState>

const buildEmptyBodyPartSelectState = (): BodyPartSelectState => ({
  isFullBody: false,
  region: undefined,
  muscleGroup: undefined,
  muscle: undefined,
})

export const flattenBodyTree = (bodyTree: BodyTree): BodyPartEnum[] => {
  const bodyParts: BodyPartEnum[] = []

  if (bodyTree?.isFullBody) {
    bodyParts.push(BodyPartEnum.FullBody)
  } else if (bodyTree && bodyTree.regions.length > 0) {
    bodyTree.regions.forEach((region) => {
      if (region.muscleGroups.length > 0) {
        region.muscleGroups.forEach((muscleGroup) => {
          if (muscleGroup.muscles.length > 0) {
            muscleGroup.muscles.forEach((muscle) => {
              bodyParts.push(muscle.name as unknown as BodyPartEnum)
            })
          }
          bodyParts.push(muscleGroup.name as unknown as BodyPartEnum)
        })
        bodyParts.push(region.name as unknown as BodyPartEnum)
      }
    })
  }

  return bodyParts
}

export const convertBodyTreeToBodyPartSelectState = (bodyTree?: BodyTree) => {
  const result = buildEmptyBodyPartSelectState()

  if (bodyTree?.isFullBody) {
    result.isFullBody = true
  } else if (bodyTree && bodyTree.regions.length > 0) {
    // only takes the first one of each type
    result["region"] = bodyTree.regions[0].name

    const { muscleGroups } = bodyTree.regions[0]

    if (muscleGroups.length > 0) {
      result["muscleGroup"] = muscleGroups[0].name

      const { muscles } = muscleGroups[0]

      if (muscles.length > 0) {
        result["muscle"] = muscles[0].name
      }
    }
  }

  return result
}

const BodyPartSelect: React.FC<BodyPartSelectProps> = ({ state, setState }) => {
  const { data } = useQuery<ListBodyRegionsQuery>(ListBodyRegionsDocument)

  const bodyRegions = [
    BodyRegionEnum.UpperBody,
    BodyRegionEnum.Core,
    BodyRegionEnum.LowerBody,
  ].map((region) => ({
    label: formatBodyPart(region),
    value: region,
  }))

  const bodyMuscleGroups = (regionName: BodyRegionEnum) => {
    const muscleGroups =
      data?.bodyRegions?.find((r) => r.name === regionName)?.muscleGroups || []

    return muscleGroups.map((mg) => ({
      label: formatBodyPart(mg.name),
      value: mg.name,
    }))
  }

  const coreMuscles = [
    BodyMuscleEnum.Abdominals,
    BodyMuscleEnum.Obliques,
    BodyMuscleEnum.LowerBack,
  ].map((muscle) => ({
    label: formatBodyPart(muscle),
    value: muscle,
  }))

  const toggleFullBody = (isFullBody: boolean) => {
    if (isFullBody) {
      setState({ ...buildEmptyBodyPartSelectState(), isFullBody: true })
    } else {
      setState({ ...buildEmptyBodyPartSelectState() })
    }
  }

  const selectRegion = (region: BodyRegionEnum) => {
    setState({ isFullBody: false, region })
  }

  const selectMuscleGroup = (muscleGroup: BodyMuscleGroupEnum) => {
    setState({ isFullBody: false, muscleGroup, region: state?.region })
  }

  const selectMuscle = (
    muscle: BodyMuscleEnum,
    muscleGroup: BodyMuscleGroupEnum | undefined = undefined
  ) => {
    setState({
      isFullBody: false,
      muscle,
      muscleGroup: muscleGroup || state?.muscleGroup,
      region: state?.region,
    })
  }

  return (
    <div className="flex flex-col w-full h-full">
      <Toggle
        isEnabled={state?.isFullBody}
        label={formatBodyPart(BodyPartEnum.FullBody)}
        className={"px-4 pt-4"}
        onToggle={toggleFullBody}
      />
      <CardSelect<BodyRegionEnum>
        options={bodyRegions}
        onSelect={selectRegion}
        selected={state?.region}
        sort={false}
      >
        {state && state.region && (
          <div className="w-full h-full p-3">
            {state.region === BodyRegionEnum.Core ? (
              <CardSelect<BodyMuscleEnum>
                options={coreMuscles}
                onSelect={(muscle) =>
                  selectMuscle(muscle, BodyMuscleGroupEnum.TrunkMuscles)
                }
                selected={state?.muscle}
                className="px-0"
              />
            ) : (
              <CardSelect<BodyMuscleGroupEnum>
                options={bodyMuscleGroups(state.region)}
                onSelect={selectMuscleGroup}
                selected={state?.muscleGroup}
                className="px-0"
              />
            )}
          </div>
        )}
      </CardSelect>
    </div>
  )
}

const formatBodyPartSelectState = (state?: BodyPartSelectState) => {
  const fullBody = state?.isFullBody ? BodyPartEnum.FullBody : undefined

  return formatBodyPart(
    (state?.muscle || state?.muscleGroup || state?.region || fullBody) as any
  )
}

export interface BodyPartGridFormFieldProps
  extends Omit<
    GridFormFieldProps<BodyPartSelectState>,
    "Body" | "label" | "formatValue" | "modalName"
  > {
  name: string
  multi?: boolean
  alwaysShow?: boolean
}

export const BodyPartGridFormField: React.FC<BodyPartGridFormFieldProps> = ({
  name,
  multi,
  alwaysShow = false,
  ...props
}) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)

  const { BODY_PART } = t(MOVEMENT.FORM, { returnObjects: true })

  const movementStyle = useWatch({ name: "movementStyle" })

  const activityStyle = useActivityStyle(movementStyle)

  const isAvailable = activityStyle?.isTargetBodyPartAvailable

  const Body = (props: any) => <BodyPartSelect multi={multi} {...props} />

  return (
    <GridFormField<BodyPartSelectState>
      name={name}
      modalName={ModalOrchestrationName.FormFieldBodyPartSelect}
      label={BODY_PART.LABEL}
      title={BODY_PART.TITLE}
      Body={Body}
      Icon={TargetBodyPartIcon}
      formatValue={formatBodyPartSelectState}
      showDismiss
      showClearButton
      hidden={!isAvailable && !alwaysShow}
      {...props}
    />
  )
}

export default BodyPartSelect
