import { clone, isNil } from "lodash"
import {
  createContext,
  Dispatch,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react"
import { useDebounce } from "use-debounce"
import Loading, { LoadingProps } from "../components/Loading"
import { v4 as uuidv4 } from "uuid"

export enum ModalOrchestrationName {
  MovementAgendaItemForm = "MovementAgendaItemForm",
  MovementSessionForm = "MovementSessionForm",
  AgendaSuggestionModal = "AgendaSuggestionModal",
  RejectedSuggestionFeedbackModal = "RejectedSuggestionFeedbackModal",
  VideoExplorerFilters = "VideoExplorerFilters",
  TallyAdventurePreparation = "TallyAdventurePreparation",
  DailyMoveAdaptation = "DailyMoveAdaptation",
  Settings = "Settings",
  FollowAlongPreview = "FollowAlongPreview",
  VideoFeedback = "VideoFeedback",
  FormFieldSchedulePicker = "FormFieldSchedulePicker",
  FormFieldTimePicker = "FormFieldTimePicker",
  FormFieldDurationSlider = "FormFieldDurationSlider",
  FormFieldBodyPartSelect = "FormFieldBodyPartSelect",
  FormFieldMovementStyleSelect = "FormFieldMovementStyleSelect",
  FormFieldRPESlider = "FormFieldRPESlider",
  FormFieldDistanceSlider = "FormFieldDistanceSlider",
  FormFieldMicoVideoSelect = "FormFieldMicoVideoSelect",
  FormFieldLanguageSelect = "FormFieldLanguageSelect",
  NotificationSettings = "NotificationSettings",
  CalendarSettings = "CalendarSettings",
  AccountSettings = "AccountSettings",
  GetPremium = "PremiumMembershipSubscription",
  NotificationPermission = "NotificationPermission",
  AdventureProgress = "AdventureProgress",
  PostSessionFeedback = "PostSessionFeedback",
  MoveSnackModal = "MoveStreamSession",
  AdventureActivity = "AdventureActivity",
  VideoExplorer = "VideoExplorer",
  BuddyProfile = "BuddyProfile",
  CalendarConnection = "CalendarConnection",
  FormFieldPhaseOfDaySelect = "FormFieldPhaseOfDaySelect",
  FormFieldCustomURLInput = "FormFieldCustomURLInput",
  FormFieldRepeatOnCompletionSlider = "FormFieldRepeatOnCompletionSlider",
  FormFieldWeekdaySelect = "FormFieldWeekdaySelect",
  MovementActivityForm = "MovementActivityForm",
  TodayActivityPreview = "TodayActivityPreview",
}

export interface ModalState {
  id: string
  name: ModalOrchestrationName

  isOpen: boolean

  initialProps?: any

  title?: string
  background?: string
  props: any

  ref: RefObject<HTMLIonModalElement>
  parentElementRef?: RefObject<HTMLElement>

  onClose?: ModalOnClose
}

export type ModalOnClose = (role?: string, data?: any) => void

export interface ModalOrchestrationContextState {
  // loading
  isLoadingOpen: boolean
  loadingProps?: LoadingProps

  activeModalStack: string[]

  modals: ModalState[]
}

export interface ModalOrchestrationContextActions {
  toggleLoading: (isLoading?: boolean, options?: Partial<LoadingProps>) => void

  showLoading: (options?: Partial<LoadingProps>) => void
  hideLoading: () => void

  registerModal: (
    name: ModalOrchestrationName,
    ref: RefObject<HTMLIonModalElement>,
    props?: any,
    onClose?: ModalOnClose
  ) => { id: string }

  updateModalState: (
    name: ModalOrchestrationName,
    state: Partial<ModalState>
  ) => void

  unregisterModal: (id: string) => void

  openModal: (
    name?: ModalOrchestrationName,
    props?: any,
    state?: any
  ) => Promise<void>

  closeModal: (
    name?: ModalOrchestrationName,
    role?: string,
    data?: any
  ) => Promise<void>

  isOpen: (name?: ModalOrchestrationName) => boolean
  isRegistered: (name?: ModalOrchestrationName) => boolean
}

export const ModalOrchestrationContext = createContext<
  | (ModalOrchestrationContextState & ModalOrchestrationContextActions)
  | undefined
>(undefined)

export const enum ModalOrchestrationReducerActionType {
  RegisterModal = "RegisterModal",
  OpenModal = "OpenModal",
  UpdateModalState = "UpdateModalState",
  CloseModal = "CloseModal",
  UnregisterModal = "UnregisterModal",
  ShowLoading = "ShowLoading",
  HideLoading = "HideLoading",
}

const modalReducer = (
  state: ModalOrchestrationContextState,
  action: {
    type: ModalOrchestrationReducerActionType
    payload: any
  }
): ModalOrchestrationContextState => {
  const { type, payload } = action

  switch (type) {
    case ModalOrchestrationReducerActionType.RegisterModal:
      return {
        ...state,
        modals: [
          ...state.modals,
          {
            id: payload.id,
            name: payload.name,
            isOpen: false,
            ref: payload.ref,
            initialProps: payload.initialProps,
            props: clone(payload.initialProps),
            onClose: payload.onClose,
          },
        ],
      }

    case ModalOrchestrationReducerActionType.OpenModal:
      return {
        ...state,
        modals: findFirstAndUpdate(state.modals, payload.name, {
          ...payload.state,
          isOpen: true,
        }),
      }

    case ModalOrchestrationReducerActionType.UpdateModalState:
      return {
        ...state,
        modals: findFirstAndUpdate(state.modals, payload.name, payload.state),
      }

    case ModalOrchestrationReducerActionType.CloseModal:
      return {
        ...state,
        modals: state.modals.map((modal) => {
          if (modal.name === payload.name) {
            return {
              ...modal,
              isOpen: false,
              props: clone(modal.initialProps),
            }
          } else {
            return modal
          }
        }),
      }

    case ModalOrchestrationReducerActionType.UnregisterModal:
      return {
        ...state,
        modals: state.modals.filter((m) => m.id !== payload.id),
      }

    case ModalOrchestrationReducerActionType.ShowLoading:
      return { ...state, isLoadingOpen: true, loadingProps: payload }

    case ModalOrchestrationReducerActionType.HideLoading:
      return { ...state, isLoadingOpen: false }

    default:
      return state
  }
}

export const useModalOrchestrationContext = () => {
  const context = useContext(ModalOrchestrationContext)

  if (context === undefined) {
    throw new Error(
      "useModalOrchestrationContext must be used within a AnalyticsContext provider"
    )
  }

  return context
}

const initialState: ModalOrchestrationContextState = {
  isLoadingOpen: false,
  loadingProps: undefined,
  modals: [],
  activeModalStack: [],
}

const findFirstModalByName = (
  modals: ModalState[],
  name?: ModalOrchestrationName
): ModalState | undefined => {
  return modals.find((m) => m.name === name)
}

const findFirstAndUpdate = (
  modals: ModalState[],
  name: ModalOrchestrationName,
  state: Partial<ModalState>
): ModalState[] => {
  const index = modals.findIndex((m) => m.name === name)

  if (index === -1) {
    return modals
  }

  return [
    ...modals.slice(0, index),
    {
      ...modals[index],
      ...state,
    },
    ...modals.slice(index + 1),
  ]
}

export const ModalProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, initialState) as [
    ModalOrchestrationContextState,
    Dispatch<{ type: ModalOrchestrationReducerActionType; payload?: any }>
  ]

  const [isLoading, { cancel: cancelLoadingDebounce }] = useDebounce(
    state.isLoadingOpen,
    500
  )

  const registerModal = (
    name: ModalOrchestrationName,
    ref: RefObject<HTMLIonModalElement>,
    initialProps?: any,
    onClose?: ModalOnClose
  ) => {
    if (isRegistered(name)) {
      console.warn("[ModalContext] modal already registered", name)
    }

    const id = uuidv4()

    console.debug("[ModalContext] register modal", name, id)

    dispatch({
      type: ModalOrchestrationReducerActionType.RegisterModal,
      payload: {
        id,
        name,
        ref,
        initialProps,
        onClose,
      },
    })

    return { id }
  }

  const isOpen = useCallback(
    (name?: ModalOrchestrationName) => {
      return findFirstModalByName(state.modals, name)?.isOpen || false
    },
    [state.modals]
  )

  const isRegistered = useCallback(
    (name?: ModalOrchestrationName) => {
      if (isNil(name)) return false

      return !isNil(findFirstModalByName(state.modals, name))
    },
    [state.modals]
  )

  const openModal = async (
    name?: ModalOrchestrationName,
    props?: any,
    modalState?: any
  ) => {
    if (isNil(name)) return

    const firstModal = findFirstModalByName(state.modals, name)

    if (isNil(firstModal)) {
      console.warn(
        "[ModalContext] failed open modal - modal not registered",
        name
      )
      return
    }

    if (firstModal.ref.current?.isOpen) {
      console.debug("[ModalContext] modal already open", name)
      return
    }

    if (!isNil(props)) {
      updateModalProps(name, props)
    }

    dispatch({
      type: ModalOrchestrationReducerActionType.OpenModal,
      payload: { name, state: modalState },
    })

    await firstModal.ref.current?.present()
  }

  const updateModalState = (
    name?: ModalOrchestrationName,
    state?: Partial<ModalState>
  ) => {
    if (isNil(name)) return

    if (!isRegistered(name)) {
      console.warn(
        "[ModalContext] failed update modal state - modal not registered",
        name
      )

      return
    }

    dispatch({
      type: ModalOrchestrationReducerActionType.UpdateModalState,
      payload: { name, state },
    })
  }

  const updateModalProps = (name?: ModalOrchestrationName, props?: any) => {
    if (isNil(name)) return

    const modalState = findFirstModalByName(state.modals, name)

    if (isNil(modalState)) {
      console.warn(
        "[ModalContext] fialed update modal props - modal not registered",
        name
      )

      return
    }

    dispatch({
      type: ModalOrchestrationReducerActionType.UpdateModalState,
      payload: {
        name,
        state: {
          props: {
            ...modalState.props,
            ...props,
          },
        },
      },
    })
  }

  const closeModal = async (
    name?: ModalOrchestrationName,
    reason?: string,
    data?: any
  ) => {
    if (isNil(name)) return

    const modalState = findFirstModalByName(state.modals, name)

    if (isNil(modalState)) {
      console.warn(
        "[ModalContext] failed close modal - modal not registered",
        name
      )

      return
    }

    if (!isOpen(name)) {
      console.debug("[ModalContext] modal not open", name)

      return
    }

    if (!isNil(reason)) {
      modalState?.onClose?.(reason, data)
    }

    await modalState.ref?.current?.dismiss()

    dispatch({
      type: ModalOrchestrationReducerActionType.CloseModal,
      payload: { name },
    })
  }

  const unregisterModal = (id: string) => {
    console.debug("[ModalContext] unregister modal", id)

    dispatch({
      type: ModalOrchestrationReducerActionType.UnregisterModal,
      payload: { id },
    })
  }

  const showLoading = useCallback(
    (props?: Partial<LoadingProps>) => {
      // if (isLoading) return

      console.debug("[ModalContext] show loading", props)

      dispatch({
        type: ModalOrchestrationReducerActionType.ShowLoading,
        payload: props,
      })
    },
    [isLoading]
  )

  const hideLoading = useCallback(() => {
    // if (!isLoading) return

    console.debug("[ModalContext] hide loading")

    dispatch({ type: ModalOrchestrationReducerActionType.HideLoading })
  }, [isLoading])

  const toggleLoading = (loading = true, props?: Partial<LoadingProps>) => {
    if (loading) {
      showLoading(props)
    } else {
      hideLoading()
    }
  }

  useEffect(() => {
    return () => {
      cancelLoadingDebounce()
    }
  }, [])

  const value = {
    ...state,
    registerModal,
    openModal,
    isOpen,
    updateModalState,
    isRegistered,
    closeModal,
    unregisterModal,
    showLoading,
    hideLoading,
    toggleLoading,
  }

  return (
    <ModalOrchestrationContext.Provider value={value}>
      {children}
      <Loading
        fullScreen
        overlay
        hidden={!state.isLoadingOpen}
        background="neutral-100"
        {...state.loadingProps}
      />
    </ModalOrchestrationContext.Provider>
  )
}
