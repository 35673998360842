import { useTranslation } from "react-i18next"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import { MEMBERSHIP, NAME_SPACES } from "../../locales/constants"
import { useLazyQuery } from "@apollo/client"
import {
  ListMovementWeeksDocument,
  ListMovementWeeksQuery,
  ListMovementWeeksQueryVariables,
} from "../../generated/graphql"
import { isBefore, parseISO } from "date-fns"
import { useEffect, useState } from "react"
import { isNil, meanBy } from "lodash"

const calculateActiveWeekStreak = (
  weeks: ListMovementWeeksQuery["listMovementWeeks"]
) => {
  let result = 0

  for (let i = weeks.length - 1; i >= 0; i--) {
    const week = weeks[i]

    if (week.completedSessions && week.completedSessions.length >= 1) {
      result++
    } else {
      break
    }
  }

  return result
}

export const ActivityStatistics: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)
  const TEXT = t(MEMBERSHIP.PROGRESS, { returnObjects: true })

  const { agenda } = useAgendaSchedulingContext()

  const currentWeekUuid = agenda?.currentWeek?.uuid

  const [weeks, setWeeks] = useState<
    ListMovementWeeksQuery["listMovementWeeks"]
  >([])

  const [listWeeks, { called: listWeeksCalled }] = useLazyQuery<
    ListMovementWeeksQuery,
    ListMovementWeeksQueryVariables
  >(ListMovementWeeksDocument, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onCompleted: (data) => {
      const pastOrPresentWeeks = data.listMovementWeeks.filter((week: any) =>
        isBefore(parseISO(week.startDate), new Date())
      )

      setWeeks(pastOrPresentWeeks)
    },
    onError: (error) => {
      console.error(error)
    },
  })

  useEffect(() => {
    if (isNil(currentWeekUuid)) return

    if (!listWeeksCalled) {
      listWeeks()
    }
  }, [currentWeekUuid, listWeeksCalled])

  const totalSessions = agenda?.nCompletedSessions || 0

  const activeWeeks = weeks.filter(
    (week) => (week.completedSessions?.length || 0) > 0
  )

  const activeWeekStreak = calculateActiveWeekStreak(weeks)

  const averageSessionsPerActiveWeek =
    activeWeeks.length > 0
      ? Math.ceil(
          meanBy(activeWeeks, (week) => week.completedSessions?.length || 0)
        )
      : undefined

  return (
    <div className="flex flex-col gap-y-3">
      <span className="font-medium text-neutral-500">{TEXT.STATISTICS}</span>
      <div className="grid w-full grid-cols-2 gap-4 md:grid-cols-4">
        <div className="flex flex-col items-start justify-end h-full px-4 py-3 text-white rounded-lg shadow-xs bg-CARDIO/80">
          <span className="text-6xl">
            {isNil(activeWeekStreak) ? "-" : activeWeekStreak}
          </span>
          <span className="text-xl font-medium leading-5">
            {t("PROGRESS.WEEKLY_STREAK")}
          </span>
        </div>

        <div className="flex flex-col items-start justify-end h-full px-4 py-3 text-white rounded-lg shadow-xs bg-primary/80">
          <span className="text-6xl">
            {isNil(averageSessionsPerActiveWeek)
              ? "-"
              : averageSessionsPerActiveWeek}
          </span>
          <span className="text-xl font-medium leading-5">
            {t("PROGRESS.AVERAGE_SESSIONS_PER_WEEK")}
          </span>
        </div>

        <div className="flex flex-col items-start justify-end h-full px-4 py-3 bg-white rounded-lg shadow-xs text-neutral-600">
          <span className="text-3xl">{activeWeeks.length || 0}</span>
          <span className="text-lg leading-5">
            {t("PROGRESS.TOTAL_ACTIVE_WEEKS")}
          </span>
        </div>
        <div className="flex flex-col items-start justify-end h-full px-4 py-3 bg-white rounded-lg shadow-xs text-neutral-600">
          <span className="text-3xl">{totalSessions || "-"}</span>
          <span className="text-lg leading-5">
            {t("PROGRESS.TOTAL_SESSIONS")}
          </span>
        </div>
      </div>
    </div>
  )
}
