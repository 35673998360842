import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../../contexts/AnalyticsContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"
import {
  AssessmentRatingOfPerceivedExertion,
  EditMovementSessionReportedDataMutationVariables,
  MovementModality,
  MovementSessionSatisfaction,
  MovementSessionStatus,
  MovementStyle,
} from "../../../generated/graphql"
import { MOVEMENT, NAME_SPACES } from "../../../locales/constants"
import Modal, { ModalContext, ModalProps, ModalSize } from "../../Core/Modal"
import clsx from "clsx"
import { YangBehaleIcon } from "../../BehaleIcon"
import { useAgendaSchedulingContext } from "../../../contexts/AgendaSchedulingContext"
import { RiCheckDoubleLine } from "react-icons/ri"
import {
  getModalityContrastColor,
  getModalityTextColor,
} from "../../../utils/movementUtils"
import { GridForm, GridFormSection } from "../../Forms/GridForm"
import { DistanceGridFormField } from "../Forms/Fields/DistanceSlider"
import { RPEGridFormField } from "../Forms/Fields/RPESlider"
import { MovementStyleGridFormField } from "../Forms/Fields/MovementStyleSelect"
import { DurationGridFormField } from "../Forms/Fields/DurationSlider"
import { MovementDescriptionTextareaFormField } from "../Forms/Fields/MovementDescriptionTextarea"
import { IoHeart, IoThumbsDown, IoThumbsUp } from "react-icons/io5"
import Button from "../../Forms/Button"
import { RadioGroup } from "@headlessui/react"
import { useFormContext, useWatch } from "react-hook-form"
import ConfettiExplosion from "react-confetti-explosion"
import { Haptics, NotificationType } from "@capacitor/haptics"

export interface PostSessionFeedbackModalBodyProps {
  uuid: string
  title: string
  status: MovementSessionStatus
  movementStyle?: MovementStyle
  movementModality: MovementModality
  sessionDuration?: number
  reportedDate?: string
}

interface PostSessionFeedbackData {
  movementStyle: MovementStyle
  title: string
  description?: string
  reportedSatisfaction?: MovementSessionSatisfaction
  reportedDuration?: number
  reportedDistance?: number
  reportedRatingOfPerceivedExertion?: AssessmentRatingOfPerceivedExertion
}

const SessionSatisfactionRadioGroup: React.FC<
  Pick<PostSessionFeedbackModalBodyProps, "movementModality">
> = ({ movementModality }) => {
  const name = "reportedSatisfaction"

  const [isExploding, setIsExploding] = React.useState(false)

  const { register, setValue } = useFormContext()
  const { ref } = register(name)

  const state = useWatch({ name })

  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.POST_SESSION_FEEDBACK, {
    returnObjects: true,
  })

  const { captureEvent } = useAnalyticsContext()

  const ratingOptions = [
    {
      value: MovementSessionSatisfaction.Love,
      icon: IoHeart,
    },
    {
      value: MovementSessionSatisfaction.Like,
      icon: IoThumbsUp,
    },
    {
      value: MovementSessionSatisfaction.Dislike,
      icon: IoThumbsDown,
    },
  ]

  const contrastColor = getModalityContrastColor(movementModality)

  const handleChange = (value: MovementSessionSatisfaction) => {
    captureEvent(AnalyticsEvent.PostSessionSatisfactionOptionClicked, {
      reportedSatisfaction: value,
    })

    setValue(name, value)
  }

  useEffect(() => {
    if (state == MovementSessionSatisfaction.Love) {
      setIsExploding(true)

      const timeout = setTimeout(() => {
        setIsExploding(false)
      }, 3000)

      return () => clearTimeout(timeout)
    }
  }, [state])

  return (
    <RadioGroup
      name={name}
      ref={ref}
      onChange={handleChange}
      className="flex flex-col col-span-2 gap-y-6"
    >
      <RadioGroup.Label className="text-2xl font-bold text-left">
        {TEXT.RATING_LABEL}
      </RadioGroup.Label>

      <div className="flex flex-row justify-around">
        {ratingOptions.map((option) => (
          <RadioGroup.Option value={option.value} key={option.value}>
            {({ checked }) => (
              <div className="flex flex-col items-center gap-y-1">
                {isExploding && (
                  <ConfettiExplosion force={0.8} duration={3000} />
                )}

                <Button
                  icon={option.icon}
                  iconSlot="icon-only"
                  iconClassName={clsx(
                    !checked && `text-${contrastColor}`,
                    checked &&
                      option.value === MovementSessionSatisfaction.Love &&
                      `text-red-500`,
                    checked &&
                      option.value === MovementSessionSatisfaction.Like &&
                      `text-primary-500`,
                    checked &&
                      option.value === MovementSessionSatisfaction.Dislike &&
                      `text-neutral-300`
                  )}
                  fill="clear"
                  className={clsx(
                    "rounded-full shadow-lg aspect-1",
                    `bg-${contrastColor} bg-opacity-25`,
                    checked && `ring-2 ring-${contrastColor}`
                  )}
                  paddingWidth="thin"
                  size="large"
                />

                <span
                  className={clsx(
                    "text-sm text-center",
                    checked ? "font-bold" : "font-semibold"
                  )}
                >
                  {TEXT.SATISFACTION.LABELS[option.value]}
                </span>
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>

      {/* {state == MovementSessionSatisfaction.Dislike && (
        <div
          className={`flex flex-col p-4 rounded-lg shadow-inner gap-y-4 bg-neutral/20`}
        >
          <span className="text-lg font-medium text-left">
            {TEXT.SATISFACTION.SUPPORT.LABEL}
          </span>
          <Button
            fill="solid"
            color="white"
            textColor={movementModality}
            className={clsx("shadow-xl opacity-90", `text-${contrastColor}`)}
            icon={RiChatSmile2Line}
            iconSlot="start"
            iconClassName="absolute left-0"
            onClick={openOliba}
          >
            {TEXT.SATISFACTION.SUPPORT.BUTTON}
          </Button>
        </div>
      )} */}
    </RadioGroup>
  )
}

export const PostSessionFeedbackModalBody: React.FC<
  PostSessionFeedbackModalBodyProps
> = ({
  uuid,
  title,
  movementStyle = MovementStyle.Yoga,
  movementModality = MovementModality.Mobility,
  reportedDate,
  sessionDuration,
}) => {
  const { name } = useContext(ModalContext)

  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.POST_SESSION_FEEDBACK, {
    returnObjects: true,
  })

  const { captureEvent } = useAnalyticsContext()
  const { closeModal } = useModalOrchestrationContext()
  const { editSessionReportedData } = useAgendaSchedulingContext()

  const handleContinue = async (data: PostSessionFeedbackData) => {
    captureEvent(AnalyticsEvent.PostSessionFeedbackModalContinueClicked)

    const variables = {
      uuid,
      title,
      description: data.description || undefined,
      movementStyle,
      reportedDate,
      reportedSatisfaction: data.reportedSatisfaction,
      reportedDuration: data.reportedDuration,
      reportedDistance: data.reportedDistance || undefined,
      reportedRatingOfPerceivedExertion:
        data.reportedRatingOfPerceivedExertion || undefined,
    } as EditMovementSessionReportedDataMutationVariables

    await editSessionReportedData(variables)

    closeModal(name, "continue")
  }

  const contrastColor = getModalityContrastColor(movementModality)

  const ringColor =
    movementModality === MovementModality.Breathing
      ? "ring-primary-700"
      : `ring-white`

  const placeholderColor =
    movementModality === MovementModality.Breathing
      ? "placeholder-primary-700"
      : `placeholder-white`

  const defaultValues = {
    title,
    movementStyle,
    reportedDuration: sessionDuration,
    reportedDistance: undefined,
    reportedRatingOfPerceivedExertion: undefined,
  }

  return (
    <div
      className={clsx(
        `bg-${movementModality}`,
        "text-center overflow-y-hidden",
        "h-full px-2 relative",
        `text-${contrastColor}`
      )}
    >
      <div
        className={clsx(
          "max-w-3xl flex flex-col items-center justify-center h-full mx-auto"
        )}
      >
        <div
          className={clsx(
            "relative flex flex-col items-start justify-start w-full px-4 py-6 gap-y-8 mt-safe"
          )}
        >
          <div className="flex flex-row items-end justify-between w-full">
            <YangBehaleIcon className={clsx("w-16 h-16", `fill-current`)} />

            {/* <div className="flex flex-col items-end text-right">
              <div className="flex float-right text-4xl font-bold gap-x-2">
                <span>{completedSessions}</span>
                <span className="opacity-30">/</span>
                <span className="opacity-30">
                  {agenda?.regularityIdealWeeklySessionGoal}
                </span>
              </div>

              <div className="flex flex-col items-center gap-y-1">
                <div className="flex space-x-1">
                  <span className="flex text-base font-semibold text-center">
                    {TEXT.WEEK_SESSION}
                  </span>
                  <RiCheckDoubleLine className="w-5 h-5" />
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <GridForm<PostSessionFeedbackData>
          defaultValues={defaultValues}
          textColor={getModalityTextColor(movementModality)}
          className={`bg-${movementModality} relative overflow-y-scroll`}
        >
          <div
            className={clsx(
              "flex flex-col items-start justify-start w-full h-full col-span-2 pb-2 mb-2 gap-y-4",
              `border-${contrastColor || "white"} border-opacity-20`
            )}
          >
            <span className="text-9xl">
              <RiCheckDoubleLine className="flex-shrink-0" />
            </span>
            <div className="flex flex-row items-center justify-between w-full">
              <span className="w-full text-5xl font-bold text-left">
                {title || TEXT.SESSION_COMPLETED}
              </span>
            </div>
          </div>

          {/* <div className="w-full col-span-2 -mx-1 text-left">
              <SessionTitleInput
                name="title"
                className={clsx(
                  "text-4xl font-bold whitespace-pre-wrap",
                  textColor
                )}
              />
            </div> */}

          <div
            className={clsx(
              "col-span-2 p-4 rounded-lg shadow-inner",
              `bg-${contrastColor} bg-opacity-10`
            )}
          >
            <SessionSatisfactionRadioGroup
              movementModality={movementModality}
            />
          </div>

          <GridFormSection>
            <MovementStyleGridFormField name="movementStyle" hidden />

            <DurationGridFormField
              name="reportedDuration"
              background={movementModality}
              className={clsx(`ring-2`, ringColor)}
            />
            <DistanceGridFormField
              name="reportedDistance"
              background={movementModality}
              className={clsx(`ring-2`, ringColor)}
            />
            <RPEGridFormField
              name="reportedRatingOfPerceivedExertion"
              background={movementModality}
              className={clsx(`ring-2`, ringColor)}
            />

            <div className="col-span-2 m-2">
              <MovementDescriptionTextareaFormField
                placeholder={TEXT.DESCRIPTION_PLACEHOLDER}
                className={clsx(
                  "w-full bg-white/20",
                  "focus:ring-white focus:ring-2",
                  placeholderColor,
                  "placeholder-opacity-80",
                  `text-${contrastColor}`,
                  "bg-neutral-300/20 font-medium"
                )}
              />
            </div>

            <GridForm.Submit
              label={TEXT.CONTINUE_BUTTON}
              fill="clear"
              className={clsx(
                `bg-${getModalityContrastColor(movementModality)}`
              )}
              labelClassName={`text-${movementModality} no-underline`}
              onSubmit={handleContinue}
            />
          </GridFormSection>
        </GridForm>
      </div>
    </div>
  )
}

export interface PostSessionFeedbackModalProps extends ModalProps {
  modality: MovementModality
}

export const PostSessionFeedbackModal: React.FC<Partial<ModalProps>> = ({
  name = ModalOrchestrationName.PostSessionFeedback,
  ...props
}) => {
  const handleDidPresent = async () => {
    await Haptics.notification({
      type: NotificationType.Success,
    })
  }

  return (
    <Modal
      name={name}
      initialSize={ModalSize.Full}
      fullScreen
      onDidPresent={handleDidPresent}
      {...props}
    >
      <Modal.Body>
        {(props: any) => <PostSessionFeedbackModalBody {...props} />}
      </Modal.Body>
    </Modal>
  )
}

export default PostSessionFeedbackModal
