import { App, URLOpenListenerEvent } from "@capacitor/app"
import React, { useEffect } from "react"

import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../contexts/AnalyticsContext"
import { useAuthenticatedClientContext } from "../contexts/AuthenticatedClientContext"
import { useSubscriptionContext } from "../contexts/SubscriptionContext"
import usePersistedCounter from "../hooks/usePersistedCounter"

export const getAppLaunchUrlQuery = async (data: URLOpenListenerEvent) => {
  const searchParams = data.url?.split("?")[1] || ""

  const query = new URLSearchParams(searchParams)

  return query
}

export const removeAppListeners = () => {
  App.removeAllListeners().then(() => {
    console.debug("[AppStateListener] removed listeners")
  })
}

const AppStateListener: React.FC<any> = () => {
  const { incrementCounter, getCounter } = usePersistedCounter("appLaunchCount")

  const { captureEvent, isEnabled, setPersonProperties } = useAnalyticsContext()
  const { restorePurchases } = useSubscriptionContext()
  const { isSessionActive, refetchActiveQueries } =
    useAuthenticatedClientContext()

  const handleResume = () => {
    captureEvent(AnalyticsEvent.AppOpened)

    incrementCounter()

    setPersonProperties({
      appLaunchCount: getCounter(),
      lastAppOpened: new Date().toISOString(),
    })

    restorePurchases()
    // refresh the client to get the latest data
    refetchActiveQueries()
  }

  const handlePause = () => {
    captureEvent(AnalyticsEvent.AppBackgrounded)

    setPersonProperties({
      lastAppBackgrounded: new Date().toISOString(),
    })
  }

  useEffect(() => {
    if (!isEnabled || !isSessionActive) {
      return
    }

    App.addListener("pause", handlePause)
    App.addListener("resume", handleResume)
  }, [isEnabled, isSessionActive])

  useEffect(() => {
    return () => {
      removeAppListeners()
    }
  }, [])

  return null
}

export default AppStateListener
