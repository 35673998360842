import isNil from "lodash/isNil"
import {
  BodyTree,
  MovementAgendaItem,
  MovementModality,
  MovementSession,
} from "../generated/graphql"
import { useAgendaSchedulingContext } from "../contexts/AgendaSchedulingContext"
import { useLocaleContext } from "../contexts/LocaleContext"

export const getModalityContrastColor = (
  modality: MovementModality | null | undefined
) => {
  if (modality == MovementModality.Breathing) {
    return "navy"
  } else {
    return "white"
  }
}

export const getModalityColorWithWhiteBackground = (
  modality: MovementModality | null | undefined
) => {
  if (modality == MovementModality.Breathing) {
    return "neutral"
  } else {
    return `${modality}`
  }
}

export const getModalityTextColor = (
  modality: MovementModality | null | undefined
) => {
  return `text-${getModalityContrastColor(modality)}`
}

export const isBodyTreeEmpty = (bodyTree: BodyTree | null | undefined) => {
  if (isNil(bodyTree)) return true

  return !bodyTree.isFullBody && bodyTree.regions.length === 0
}

export const useMovementDate = (
  movement?: MovementAgendaItem | MovementSession | null
) => {
  if (movement?.__typename === "MovementAgendaItem") {
    return useItemDate(movement)
  } else if (movement?.__typename === "MovementSession") {
    return useSessionDate(movement)
  } else {
    return null
  }
}

export const useItemDate = (agendaItem?: MovementAgendaItem | null) => {
  const { getDate } = useAgendaSchedulingContext()
  const { parseISODate } = useLocaleContext()

  if (isNil(agendaItem)) {
    return null
  } else if (isNil(agendaItem.instanceDate)) {
    return getDate(agendaItem.weekday)
  } else {
    return parseISODate(agendaItem.instanceDate)
  }
}

export const useSessionDate = (session?: MovementSession | null) => {
  const { parseISODate } = useLocaleContext()

  if (isNil(session)) {
    return null
  }

  return parseISODate(
    session.reportedDate || session.sessionEndTime || session.date
  )
}
