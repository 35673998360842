import { useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import {
  AccountsIdentityProvider,
  IdentifyUserWithProviderDocument,
  IdentifyUserWithProviderMutation,
  IdentifyUserWithProviderMutationVariables,
} from "../../../generated/graphql"
import { NAME_SPACES } from "../../../locales/constants"
import { ButtonProps } from "../../Forms/Button"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../../contexts/AnalyticsContext"
import useToast from "../../../hooks/useToast"
import { GoogleScope } from "./utils"
import ContinueWithGoogleButton from "./ContinueWithGoogleButton"
import { GoogleIcon } from "./icons"
import useRouterQueryParams from "../../../hooks/useRouterQueryParams"
import { getReferralCode } from "../../../routers/AuthRouter"

export const AuthenticateWithGoogleButton: React.FC<
  ButtonProps & {
    onCompleted: (data: any) => void
  }
> = ({ onCompleted, ...props }) => {
  const { t } = useTranslation(NAME_SPACES.AUTH)

  const { showError } = useToast()
  const query = useRouterQueryParams()

  const { captureEvent } = useAnalyticsContext()

  const [identifyWithProvider] = useMutation<
    IdentifyUserWithProviderMutation,
    IdentifyUserWithProviderMutationVariables
  >(IdentifyUserWithProviderDocument)

  const handleFullfilled = async (
    data: IdentifyUserWithProviderMutationVariables,
    onResolved?: () => void
  ) => {
    const variables = {
      ...data,
      referralCode: await getReferralCode(),
    }

    await identifyWithProvider({
      variables,
      onCompleted: async (completedData) => {
        onResolved?.()

        const resp = completedData.identifyUserWithProvider

        captureEvent(AnalyticsEvent.UserIdentifiedWithProvider, {
          provider: AccountsIdentityProvider.Google,
          isFirstSignIn: resp.isFirstSession,
        })

        onCompleted(resp)
      },
      onError: async (error) => {
        onResolved?.()

        let text

        switch (error.message) {
          case "Authentication failed":
            text = t("INCORRECT_CREDENTIALS")
            break

          default:
            text = `${t("AN_ERROR_HAS_OCCURED")}: ` + error.message
            break
        }

        showError(text)
      },
    })
  }

  return (
    <ContinueWithGoogleButton
      query={query}
      scope={GoogleScope.Authentication}
      forcePrompt={false}
      onFullfilled={handleFullfilled}
      label={t("CONTINUE_WITH_PROVIDER", { provider: "Google" })}
      icon={GoogleIcon}
      iconSlot="start"
      {...props}
    />
  )
}
