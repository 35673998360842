import { IonButtons, IonHeader, IonModal, IonToolbar } from "@ionic/react"
import clsx from "clsx"
import { closeOutline } from "ionicons/icons"
import isNil from "lodash/isNil"
import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import {
  ModalOnClose,
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import { enumKeys } from "../../utils"
import Button, { ButtonProps } from "../Forms/Button"
import Content from "./Content"

export enum ModalSize {
  "Less" = 0.4,
  "Most" = 0.83,
  "Full" = 1,
}

export const getSizeFromBreakpoint = (breakpoint: number): ModalSize => {
  if (breakpoint <= ModalSize.Less) {
    return ModalSize.Less
  } else if (breakpoint <= ModalSize.Most) {
    return ModalSize.Most
  } else {
    return ModalSize.Full
  }
}

export const MODAL_SIZES = enumKeys(ModalSize)

export const MODAL_SIZE_VALUES = enumKeys(ModalSize).map(
  (key) => ModalSize[key]
)

export const ModalFiller: React.FC<{
  currentSize: ModalSize
  background: string
}> = ({ currentSize, background }) => {
  const className = clsx(`bg-${background}`)

  switch (currentSize) {
    case ModalSize.Less:
      return <div className={clsx("h-3/5", className)}></div>

    case ModalSize.Most:
      return <div className={clsx("h-1/6", className)}></div>

    case ModalSize.Full:
    default:
      return <div className={clsx(className)}></div>
  }
}

export interface ModalContextState {
  modalId?: string
  name?: ModalOrchestrationName
  isSheet: boolean
  background?: string
  currentSize: ModalSize
  props: any
  title?: string | React.ReactNode
}

export const ModalContext = createContext<ModalContextState>({
  isSheet: false,
  currentSize: ModalSize.Most,
  background: "neutral-100",
  props: {},
})

export interface ModalProps
  extends React.PropsWithChildren<any>,
    Omit<React.ComponentPropsWithRef<typeof IonModal>, "title"> {
  name: ModalOrchestrationName
  isSheet?: boolean
  initialSize?: ModalSize
  background?: string
  initialProps?: any
  isReady?: boolean
  onClose?: ModalOnClose
  fullScreen?: boolean
  title?: string | React.ReactNode
}

export const Modal = ({
  name,
  isSheet = false,
  background = undefined,
  initialSize = ModalSize.Full,
  className = "",
  initialProps = {},
  isReady = true,
  onClose,
  children,
  fullScreen,
  title,
  ...props
}: ModalProps) => {
  const modalRef = useRef<HTMLIonModalElement>(null)

  const [modalId, setModalId] = useState<string | undefined>(undefined)

  const {
    registerModal,
    modals,
    updateModalState,
    unregisterModal,
    closeModal,
    toggleLoading,
    isOpen,
  } = useModalOrchestrationContext()

  isSheet = !fullScreen && isSheet

  const initialBreakpoint: number | undefined = isSheet
    ? initialSize.valueOf()
    : undefined

  const breakpoints: number[] | undefined = isSheet
    ? [0, initialBreakpoint as number]
    : undefined

  const [currentSize, setCurrentSize] = useState(initialSize)

  useEffect(() => {
    const { id } = registerModal(name, modalRef, initialProps, onClose)

    setModalId(id)

    return () => {
      unregisterModal(id)
    }
  }, [])

  useEffect(() => {
    if (isOpen(name)) {
      toggleLoading(!isReady, {
        background: "primary",
        fullScreen: true,
      })
    }
  }, [isReady, modals])

  const modalProps = modals.find((m) => m.id === modalId)?.props || {}

  return (
    <ModalContext.Provider
      value={{
        modalId,
        title,
        name,
        isSheet,
        currentSize,
        background,
        props: modalProps,
      }}
    >
      <IonModal
        ref={modalRef}
        animated
        canDismiss
        showBackdrop
        className={clsx(
          !isSheet && "h-screen w-screen rounded-none",
          fullScreen && "ion-modal-fullscreen",
          className
        )}
        breakpoints={breakpoints}
        initialBreakpoint={initialBreakpoint}
        onIonBreakpointDidChange={(e) => {
          setCurrentSize(getSizeFromBreakpoint(e.detail.breakpoint))
        }}
        onDidDismiss={(_e) => {
          closeModal(name, "dismiss")

          props.onDidDismiss?.(_e)
        }}
        onDidPresent={(_e) => {
          updateModalState(name, { isOpen: true })

          props.onDidPresent?.(_e)
        }}
        onWillPresent={(_e) => {
          setCurrentSize(getSizeFromBreakpoint(initialSize))

          props.onWillPresent?.(_e)
        }}
        style={{
          "--background": "initial",
        }}
        {...props}
      >
        <div
          className={clsx(
            "flex flex-col h-full max-h-full",
            background && `bg-${background}`
          )}
        >
          {children}
        </div>
      </IonModal>
    </ModalContext.Provider>
  )
}

export interface ModalHeaderProps {
  title?: string | React.ReactNode
  subtitle?: string | React.ReactNode
  background?: string
  className?: string
  showShadow?: boolean
  showCloseButton?: boolean
  actions?: ButtonProps[]
}

export const CloseButton: React.FC<ButtonProps> = ({ className, ...props }) => {
  const { name } = useContext(ModalContext)

  const { closeModal } = useModalOrchestrationContext()

  return (
    <Button
      onClick={() => {
        if (isNil(name)) return

        closeModal(name, "cancel")
      }}
      icon={closeOutline}
      fill="clear"
      iconSlot="icon-only"
      className={clsx("absolute shadow-none", className)}
      {...props}
    />
  )
}

Modal.Header = forwardRef(function ModalHeader(
  {
    title,
    subtitle,
    background = undefined,
    className,
    showShadow = true,
    showCloseButton = true,
    actions = [],
  }: ModalHeaderProps,
  ref
) {
  const {
    modalId,
    isSheet,
    title: modalTitle,
    background: modalBackground,
  } = useContext(ModalContext)

  const { modals } = useModalOrchestrationContext()

  const modalState = modals.find((m) => m.id === modalId)

  title = modalState?.title || modalTitle || title
  background = modalState?.background || background || modalBackground

  const showTitle = !isNil(title)

  const Title = () => (
    <div className="px-2">
      <div className={clsx("flex flex-col gap-y-2", "text-center")}>
        {title && (
          <h1 className="text-xl font-medium tracking-tight">{title}</h1>
        )}
        {subtitle && (
          <h2 className="-mt-2 text-sm tracking-tight font-semilight">
            {subtitle}
          </h2>
        )}
      </div>
    </div>
  )

  return (
    <IonHeader ref={ref as any}>
      <IonToolbar
        className={clsx(
          !isSheet && "pt-safe",
          "items-center justify-between select-none",
          `bg-${background || "white"}`,
          "px-1 border-none",
          // !background && "border-neutral-200/20 border-b",
          showShadow && "shadow-sm",
          className
        )}
        color={background || "white"}
        style={{
          "--background": "transparent",
          "--border-color": "transparent",
        }}
        mode="md"
      >
        {showTitle && <Title />}
        {showCloseButton && (
          <IonButtons slot="start">
            <CloseButton
              className={clsx(
                "absolute",
                `bg-${background}`,
                isNil(background) && "text-neutral-500"
              )}
            />
          </IonButtons>
        )}

        <IonButtons slot="end">
          {actions.map((props, index) => (
            <Button key={index} iconSlot="icon-only" {...props} />
          ))}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  )
})

export interface ModalBodyProps {
  scrollY?: boolean
  children: (props: any) => React.ReactNode
}

Modal.Body = forwardRef(function ModalBody(
  { children, scrollY = true }: ModalBodyProps,
  ref
) {
  const {
    props,
    background: modalBackground,
    currentSize,
    isSheet,
  } = useContext(ModalContext)

  const background = modalBackground || "neutral-100"

  return (
    <>
      <Content
        ref={ref as any}
        className={clsx("flex flex-grow w-full h-full overflow-hidden")}
        style={{
          "--background": "initial",
        }}
        scrollY={scrollY}
        background={background}
      >
        {children(props)}
      </Content>

      {isSheet && !isNil(currentSize) && (
        <ModalFiller currentSize={currentSize} background={background} />
      )}
    </>
  )
})

export default Modal
