import Button from "../Forms/Button"
import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"
import { useEffect, useMemo, useState } from "react"
import clsx from "clsx"
import { isNil, orderBy } from "lodash"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { Clipboard } from "@capacitor/clipboard"
import { buildURL, useBreakpoint } from "../../utils"
import { useShare } from "../../hooks/useShare"
import useToast from "../../hooks/useToast"
import { MEMBERSHIP, NAME_SPACES } from "../../locales/constants"
import { useTranslation } from "react-i18next"
import { RiUserAddLine } from "react-icons/ri"
import {
  AddBuddyDocument,
  AddBuddyMutation,
  GetCurrentMemberDocument,
  ListBuddiesDocument,
  ListBuddiesQuery,
  MemberPublicProfile,
  MovementAgendaItem,
  MovementSession,
} from "../../generated/graphql"
import useRouterQueryParams from "../../hooks/useRouterQueryParams"
import { useMutation, useQuery } from "@apollo/client"
import { MovementIcon } from "../Core/Icons"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import { BuddyProfileModal } from "./BuddyProfileModal"
import { getCombinedElementStyle } from "../../utils/elementUtils"
import { IonIcon, IonSkeletonText } from "@ionic/react"
import Carousel from "nuka-carousel"
import { DiscreteCarouselPagination } from "../Core/CarouselPagination"
import { useLocaleContext } from "../../contexts/LocaleContext"
import { useAgendaSchedulingContext } from "../../contexts/AgendaSchedulingContext"
import { useMovementDate } from "../../utils/movementUtils"
import { checkmarkDoneOutline } from "ionicons/icons"

export const ProfileAvatar: React.FC<{
  profile?: MemberPublicProfile
  className?: string
}> = ({ profile, className }) => {
  if (isNil(profile)) {
    return (
      <IonSkeletonText
        animated
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "100%",
        }}
      />
    )
  }

  return (
    <span
      className={clsx(
        "flex items-center justify-center",
        "rounded-full ring-4 ring-white",
        getCombinedElementStyle(profile.elements),
        className
      )}
    >
      {String(profile.displayName || "Behaler")
        .toUpperCase()
        .charAt(0)}
    </span>
  )
}

const ActivityInformation: React.FC<{
  type: "ITEM" | "SESSION"
  activity: MovementAgendaItem | MovementSession | null | undefined
}> = ({ type, activity }) => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)
  const TEXT = t(MEMBERSHIP.PROFILE, { returnObjects: true })

  const { formatDate } = useLocaleContext()

  const placeholder = TEXT.BUDDIES.CARD.PREVIEW.PLACEHOLDER

  const date = useMovementDate(activity)

  return (
    <>
      <span className="text-xs text-left text-neutral-500">
        {TEXT.BUDDIES.CARD.PREVIEW[type]}
      </span>

      {isNil(activity) ? (
        <span className="col-span-2 text-sm text-right text-neutral-600">
          {placeholder}
        </span>
      ) : (
        <div className="flex flex-row items-center justify-between w-full col-span-2 gap-1 py-1 truncate">
          <div className="flex flex-row items-center justify-center gap-1">
            <MovementIcon element={activity} />
            <span className="text-neutral-600">{activity?.title}</span>
          </div>

          {activity.__typename === "MovementSession" && (
            <div className="flex items-center justify-center opacity-80 gap-x-2">
              <IonIcon
                icon={checkmarkDoneOutline}
                className="w-6 h-6"
                color="neutral"
              />
            </div>
          )}

          {!isNil(date) && (
            <span className="opacity-75 text-neutral-600">
              {formatDate(date, "d/MM")}
            </span>
          )}
        </div>
      )}
    </>
  )
}

const BuddyCard = ({
  buddy,
}: {
  buddy: MemberPublicProfile
  index?: number
}) => {
  const { openModal } = useModalOrchestrationContext()
  const { captureEvent } = useAnalyticsContext()
  const { parseISODate } = useLocaleContext()
  const { getDate } = useAgendaSchedulingContext()

  const isModalEnabled = false

  const handleBuddyClick = () => {
    captureEvent(AnalyticsEvent.ProfileCardBuddyCardClicked)

    if (!isModalEnabled) {
      return
    }

    openModal(ModalOrchestrationName.BuddyProfile, {
      buddy,
    })
  }

  const latestSession = useMemo(() => {
    return orderBy(
      buddy.recentMovementSessions,
      (session) => parseISODate(session?.date || ""),
      "desc"
    )[0]
  }, [buddy.recentMovementSessions])

  const nextItem = useMemo(() => {
    return orderBy(
      buddy.imminentMovementAgendaItems,
      (item) => parseISODate(item.instanceDate) || getDate(item.weekday),
      "asc"
    )[0]
  }, [buddy.imminentMovementAgendaItems])

  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-center w-full px-4 sm:px-6 lg:px-8"
      )}
    >
      <button
        className={clsx(
          "flex flex-col items-center py-3 h-32 w-full gap-3",
          "px-4",
          "bg-white rounded-lg shadow-sm"
        )}
        onClick={handleBuddyClick}
      >
        <div className="flex flex-row items-center justify-start w-full gap-2">
          <ProfileAvatar
            profile={buddy}
            className="w-6 h-6 text-xl sm:h-8 sm:w-8"
          />
          <span className="text-lg font-medium text-neutral-700">
            {buddy.displayName}
          </span>
        </div>

        <div className="grid items-center justify-around w-full h-full grid-cols-3">
          <ActivityInformation type="SESSION" activity={latestSession} />
          <ActivityInformation type="ITEM" activity={nextItem} />
        </div>
      </button>
    </div>
  )
}

export const ProfileBuddiesSection: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)
  const TEXT = t(MEMBERSHIP.PROFILE, { returnObjects: true })

  const query = useRouterQueryParams()
  const { showSuccess, showWarning } = useToast()
  const { isSupported, share } = useShare()

  const { captureEvent } = useAnalyticsContext()
  const { currentMember } = useAuthenticatedClientContext()

  const breakpoint = useBreakpoint()
  const [nSlides, setNSlides] = useState(1)

  const { data } = useQuery<ListBuddiesQuery>(ListBuddiesDocument, {
    skip: isNil(currentMember),
  })

  const [addBuddy] = useMutation<AddBuddyMutation>(AddBuddyDocument, {
    onCompleted: () => {
      showSuccess(TEXT.BUDDIES.FEEDBACK.SUCCESS)
    },
    onError: () => {
      showWarning(TEXT.BUDDIES.FEEDBACK.ERROR)
    },
    refetchQueries: [GetCurrentMemberDocument, ListBuddiesDocument],
  })

  const publicProfile = currentMember?.publicProfile
  const buddies = data?.listBuddies

  const shareLink = useMemo(
    () =>
      isNil(publicProfile)
        ? buildURL("/")
        : buildURL(
            `/app/hub/profile?referralCode=${publicProfile.referralCode}`
          ),
    [publicProfile]
  )

  const handleShare = async () => {
    captureEvent(AnalyticsEvent.ProfileCardShareLinkClicked, {
      nBuddies: buddies?.length,
    })

    if (isSupported) {
      await share({
        url: shareLink,
        title: TEXT.BUDDIES.SHARE_LINK.TITLE,
        text: TEXT.BUDDIES.SHARE_LINK.TEXT,
      })
    } else {
      Clipboard.write({ string: shareLink }).then(() => {
        showSuccess(TEXT.BUDDIES.SHARE_LINK.SUCCESS)
      })
    }
  }

  useEffect(() => {
    if (isNil(currentMember)) return

    if (query.has("referralCode")) {
      const referralCode = query.get("referralCode") as string

      if (referralCode === publicProfile?.referralCode) return

      if (buddies?.some((buddy) => buddy.referralCode === referralCode)) return

      addBuddy({ variables: { referralCode } })
    }
  }, [currentMember, query])

  useEffect(() => {
    switch (breakpoint) {
      case "md":
        setNSlides(2)
        break

      case "lg":
      case "xl":
        setNSlides(3)
        break

      default:
        setNSlides(1)
        break
    }
  }, [breakpoint])

  if (isNil(publicProfile)) return null

  const InviteButton: React.FC<any> = ({ className }) => {
    return (
      <Button
        fill="clear"
        size="small"
        textColor="primary"
        label={TEXT.BUDDIES.SHARE_LINK.CTA}
        onClick={handleShare}
        icon={RiUserAddLine}
        iconSlot="end"
        labelClassName="no-underline"
        iconClassName="ml-1 text-primary"
        className={clsx(className)}
      />
    )
  }

  return (
    // container
    <div className="flex flex-col w-full h-full gap-y-1">
      <div className="flex flex-row items-center justify-between px-4 gap-x-2 sm:px-6 lg:px-8">
        <span className="font-medium text-neutral-500">
          {TEXT.BUDDIES.TITLE}
        </span>

        <InviteButton />
      </div>
      <div
        className={clsx("px-4 sm:px-6 lg:px-8", "w-full", "text-neutral-600")}
      >
        <div
          className={clsx(
            "flex flex-col items-start justify-between gap-y-3 w-full"
          )}
        >
          <span className="text-sm text-neutral-500">
            {TEXT.BUDDIES.SUBTITLE}
          </span>
        </div>
      </div>

      <Carousel
        className="pb-8 mt-4"
        renderCenterLeftControls={() => <></>}
        renderCenterRightControls={() => <></>}
        renderBottomCenterControls={DiscreteCarouselPagination}
        autoplay={false}
        swiping={true}
        slidesToShow={nSlides}
      >
        {buddies?.map((buddy, index) => (
          <BuddyCard buddy={buddy as MemberPublicProfile} key={index} />
        ))}
        <div className="flex flex-col items-center justify-center h-32 mx-4 rounded-lg shadow-inner gap-y-2 bg-neutral-200 sm:mx-6 lg:mx-8">
          <InviteButton className="w-auto" />
        </div>
      </Carousel>

      <BuddyProfileModal />
    </div>
  )
}
