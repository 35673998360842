import { useMutation } from "@apollo/client"
import { useIonRouter } from "@ionic/react"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import BaseAuthPage, {
  BaseAuthPageLinkProps,
} from "../../components/Auth/BaseAuthPage"
import { LoginForm } from "../../components/Auth/LoginForm"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { useModalOrchestrationContext } from "../../contexts/ModalOrchestrationContext"
import {
  CreateSessionDocument,
  CreateSessionMutation,
  CreateSessionMutationVariables,
  UserSession,
} from "../../generated/graphql"
import useToast from "../../hooks/useToast"
import { AUTH, NAME_SPACES } from "../../locales/constants"

type LoginPageProps = {
  onCompleted: (userSession: UserSession) => void
}

const LoginPage: React.FC<LoginPageProps> = ({ onCompleted }) => {
  const { t } = useTranslation(NAME_SPACES.AUTH)
  const TEXT = t(AUTH.LOGIN, {
    returnObjects: true,
  })

  const { present, dismiss } = useToast()
  const router = useIonRouter()

  const { captureEvent } = useAnalyticsContext()
  const { toggleLoading } = useModalOrchestrationContext()

  const [createSession, { loading: submitting }] = useMutation<
    CreateSessionMutation,
    CreateSessionMutationVariables
  >(CreateSessionDocument)

  const loginUser = async (data: CreateSessionMutationVariables) => {
    dismiss()

    await createSession({
      variables: {
        email: data.email,
        password: data.password,
      },
      onCompleted: async (completedData) => {
        captureEvent(AnalyticsEvent.UserLoggedIn)

        onCompleted({
          ...completedData.createSession,
          isFirstSession: false,
        } as UserSession)
      },
      onError: async (error) => {
        let text

        switch (error.message) {
          case "not_authenticated":
            text = t("INCORRECT_CREDENTIALS")
            break

          case "already_logged_in":
            console.warn("User is already logged in")
            router.push("/app/hub/home", "forward")

            return

          default:
            text = `${t("AN_ERROR_HAS_OCCURED")}: ` + error.message
            break
        }

        present({
          message: text,
          status: "danger",
        })
      },
    })
  }

  const links = [
    {
      routerLink: "/auth/forgot_password",
      routerDirection: "forward",
      label: TEXT.FORGOT_PASSWORD,
    },
    {
      routerLink: "/auth/home",
      routerDirection: "none",
      label: TEXT.SIGN_UP,
    },
  ]

  useEffect(() => {
    if (submitting) {
      toggleLoading(true, {
        background: "primary",
      })
    } else {
      toggleLoading(false)
    }
  }, [submitting])

  return (
    <BaseAuthPage links={links as BaseAuthPageLinkProps[]} showBackButton>
      <div className="flex flex-col px-2">
        <LoginForm loginUser={loginUser} />
      </div>
    </BaseAuthPage>
  )
}

export default LoginPage
