import { useIonRouter } from "@ionic/react"
import clsx from "clsx"
import { useMemo } from "react"
import { useMeasure } from "react-use"

import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"
import {
  ListAdventureTemplatesQuery,
  SqualoAdventure,
  SqualoAdventureType,
} from "../../generated/graphql"
import { getElementTextColor } from "../../utils/elementUtils"
import {
  formatAdventureDifficulty,
  formatAdventureType,
} from "../../utils/format"
import { ThumbnailButton, ThumbnailImage } from "../Core/ThumbnailButton"
import isNil from "lodash/isNil"
import { first } from "lodash"
import { buildURL } from "../../utils"

export const isAdventurePremium = (
  adventure: ListAdventureTemplatesQuery["listAdventures"][0]
) => {
  // it's premium if the type is not basic OR if the difficulty is greater than 1
  if (isNil(adventure.type)) {
    return adventure.difficulty >= 1
  }

  return adventure.type !== SqualoAdventureType.Basic
}

export const AdventurePreviewCard: React.FC<{
  adventure: ListAdventureTemplatesQuery["listAdventures"][0]
  onClick?: () => void
}> = ({ adventure, onClick }) => {
  const router = useIonRouter()

  const [ref, { width, height }] = useMeasure()

  const { isSessionActive } = useAuthenticatedClientContext()
  const { captureEvent } = useAnalyticsContext()

  const element = adventure.element

  const handleCardClick = () => {
    captureEvent(AnalyticsEvent.AdventurePreviewCardClicked, {
      adventureUuid: adventure.id,
      adventureElement: adventure.element,
      isPremium: isAdventurePremium(adventure),
    })

    onClick?.()

    const basePath = isSessionActive ? "/app/hub/discover" : "/app/mico"

    router.push(`${basePath}/adventures/${adventure.id}`)
  }

  const imageUrl = useMemo(() => {
    if (!adventure.imageUrl) {
      return ""
    }

    const queryParam = new URLSearchParams({})

    if (width > 0) {
      queryParam.append("w", "1280")
    }

    if (height > 0) {
      queryParam.append("h", "720")
    }

    const url = new URL(adventure.imageUrl)

    url.search = queryParam.toString()

    return url.toString()
  }, [adventure.imageUrl, width, height])

  const isPremium = useMemo(() => isAdventurePremium(adventure), [adventure])

  const partnership = first(adventure.partnerships) || {
    name: "behale",
    logoUrl: buildURL("/assets/icon/appicon-60.png"),
  }

  return (
    <a
      // type="button"
      className={clsx(
        "flex flex-col items-center justify-start h-full",
        "shadow-lg rounded-lg",
        `bg-${element}`,
        getElementTextColor(element)
      )}
      onClick={handleCardClick}
    >
      <ThumbnailButton
        url={imageUrl}
        background={element}
        overlayOpacity={0}
        backdropOpacity={0}
        backdropBlur="none"
        className="w-full rounded-t-lg shadow-sm h-52"
      >
        <div
          ref={ref as any}
          className={clsx("flex flex-col justify-between w-full h-full")}
        >
          <div
            className={clsx(
              "flex flex-row items-center justify-between",
              "w-full p-4",
              "font-semibold"
            )}
          >
            <div
              className={clsx(
                "flex flex-row items-center justify-center gap-x-1 p-1 rounded-lg",
                "text-white bg-neutral-300 bg-opacity-30 backdrop-blur-sm backdrop-filter"
              )}
            >
              <ThumbnailImage
                url={partnership.logoUrl}
                className="flex items-center justify-center w-6 h-6 rounded-full bg-white/20"
              />
              <span className="text-lg">{partnership.name}</span>
            </div>

            {isPremium && (
              <span
                className={clsx(
                  "h-full px-2 py-1 rounded-lg",
                  "text-white bg-gradient-to-br from-primary-400 to-primary-600 bg-opacity-75"
                )}
              >
                {formatAdventureType(adventure as SqualoAdventure)}
              </span>
            )}
          </div>

          <div className="flex flex-row items-center justify-start w-full px-4 py-4 gap-x-2 backdrop-blur-xs backdrop-filter">
            <span className="text-2xl font-bold text-left text-white">
              {adventure.name}
            </span>
          </div>
        </div>
      </ThumbnailButton>

      <div className="flex flex-col items-start w-full py-4 gap-y-2">
        <div className="flex flex-row items-center justify-between w-full px-4 opacity-80 gap-x-4">
          {/* <div className="flex flex-row items-center gap-x-1">
            <ElementIcon element={element} contrast />
            <span className="h-full">{formatElement(adventure.element)}</span>
          </div>
          <span className="font-medium">
            {formatAdventureDuration(adventure.phaseCount as number)}
          </span> */}
          <span className="font-medium">
            {formatAdventureDifficulty(adventure.difficulty)}
          </span>
        </div>

        <span className="px-4 font-medium text-left">{adventure.goal}</span>
      </div>
    </a>
  )
}
