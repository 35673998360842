import { Browser } from "@capacitor/browser"
import { isNil } from "lodash"
import { useState } from "react"

const useInlineBrowser = () => {
  const [isOpen, setIsOpen] = useState(false)

  const open = async (path: string | undefined | null) => {
    if (isOpen) {
      return
    }

    if (!path) {
      return
    }

    setIsOpen(true)

    await Browser.open({
      url: path,
      presentationStyle: "popover",
      windowName: "_blank",
    })
  }

  const close = async () => {
    if (!isOpen) {
      return
    }

    setIsOpen(false)
    await Browser.close()
  }

  return {
    openBrowser: open,
    isOpen,
    closeBrowser: close,
  }
}

export const openBrowser = async (
  path: string | undefined | null,
  utm?: {
    source?: string
    medium?: string
    campaign?: string
  }
) => {
  if (!path) {
    console.warn("No path provided to openBrowser")

    return
  }

  const cleanPath = path.trim()

  const url = new URL(cleanPath)

  if (!isNil(utm)) {
    const { source, medium, campaign } = utm

    if (!isNil(source)) {
      url.searchParams.set("utm_source", source)
    }

    if (!isNil(medium)) {
      url.searchParams.set("utm_medium", medium)
    }

    if (!isNil(campaign)) {
      url.searchParams.set("utm_campaign", campaign)
    }
  }

  await Browser.open({
    url: url.toString(),
    presentationStyle: "popover",
    windowName: "_blank",
  })
}

export default useInlineBrowser
