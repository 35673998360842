import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import { SqualoFollowAlongVideo } from "../../generated/graphql"
import { TallyModal } from "../Core/TallyModal"

export const VideoFeedbackModal: React.FC<{
  video?: SqualoFollowAlongVideo
}> = ({ video }) => {
  const { currentUser } = useAuthenticatedClientContext()

  const { closeModal } = useModalOrchestrationContext()

  const name = ModalOrchestrationName.VideoFeedback

  return (
    <TallyModal
      name={name}
      initialProps={{
        formPath: "videoFeedback",
        hiddenFields: {
          user_uuid: currentUser?.userUuid || "",
          video_uuid: video?.uuid,
          video_title: video?.title || "",
        },
      }}
      onClose={() => closeModal(name, "close")}
    />
  )
}
