import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useReducer,
  useMemo,
} from "react"
import { useAuthenticatedClientContext } from "./AuthenticatedClientContext"
import {
  AdventureStatus,
  ListMemberAdventuresDocument,
  ListMemberAdventuresQuery,
  MovementAgendaItemSummaryFragment,
  usePauseAdventureMutation,
} from "../generated/graphql"

import isNil from "lodash/isNil"
import { useQuery } from "@apollo/client"
import { reduce } from "lodash"
import { useLocaleContext } from "./LocaleContext"

export interface AdventureContextState {
  currentAdventures: Exclude<
    ListMemberAdventuresQuery["listMemberAdventures"],
    null | undefined
  >

  currentAdventuresActivityItems: {
    uuid: string
    items: MovementAgendaItemSummaryFragment[]
  }[]

  isQuerying: boolean
  isMutating: boolean
}

export interface AdventureContextActions {
  getAdventure: (
    uuid: string
  ) => AdventureContextState["currentAdventures"][0] | undefined | null

  getAdventureByTemplateId: (
    templateId: string
  ) => AdventureContextState["currentAdventures"][0] | undefined | null

  isCurrentPhaseActivity: (
    adventureUuid?: string | null,
    activityId?: string | null
  ) => boolean

  pauseAdventure: (adventureUuid?: string | null) => void
  // resumeAdventure: (resumeDate?: Date) => void

  refetchAdventures: () => void
}

export const AdventureContext = createContext<
  (AdventureContextState & AdventureContextActions) | undefined
>(undefined)

export enum AdventureReducerActionType {
  CurrentAdventureLoaded = "CurrentAdventureLoaded",
}

export enum AdventureContextErrors {}

const adventureReducer = (
  state: AdventureContextState,
  action: {
    type: AdventureReducerActionType
    payload?: any
  }
): AdventureContextState => {
  const { type } = action

  switch (type) {
    default:
      return state
  }
}

export const useAdventureContext = () => {
  const context = useContext(AdventureContext)

  if (context === undefined) {
    throw new Error(
      "useAdventureContext must be used within a AdventureContext provider"
    )
  }

  return context
}

const initialAdventureState: AdventureContextState = {
  currentAdventures: [],
  currentAdventuresActivityItems: [],
  isQuerying: false,
  isMutating: false,
}

export const AdventureProvider: React.FC = ({ children }) => {
  const [state] = useReducer(adventureReducer, initialAdventureState) as [
    AdventureContextState,
    Dispatch<{ type: string; payload?: any }>
  ]

  const { isSessionActive } = useAuthenticatedClientContext()
  const { isInitialized } = useLocaleContext()

  const { data, loading, refetch } = useQuery<ListMemberAdventuresQuery>(
    ListMemberAdventuresDocument,
    {
      skip: !isSessionActive || !isInitialized,
      nextFetchPolicy: "network-only",
    }
  )

  const [pauseAdventure] = usePauseAdventureMutation({
    refetchQueries: [ListMemberAdventuresDocument],
  })

  const allAdventures = data?.listMemberAdventures || []

  const currentAdventures = useMemo(
    () =>
      allAdventures.filter(
        (adventure) => adventure.status === AdventureStatus.Started
      ),
    [allAdventures]
  )
  const getAdventure = useCallback(
    (uuid: string) => {
      return allAdventures.find((adventure) => adventure.adventureUuid === uuid)
    },
    [allAdventures]
  )

  const getAdventureByTemplateId = useCallback(
    (templateId: string) => {
      return allAdventures.find(
        (adventure) => adventure.squaloAdventure.id === templateId
      )
    },
    [allAdventures]
  )

  const isCurrentPhaseActivity = useCallback(
    (adventureUuid?: string | null, activityId?: string | null) => {
      if (isNil(adventureUuid) || isNil(activityId)) {
        return false
      }

      const adventure = getAdventure(adventureUuid)

      if (
        isNil(adventure) ||
        isNil(adventure.currentPhaseRemainingActivities)
      ) {
        return false
      }

      return adventure.currentPhaseRemainingActivities?.includes(activityId)
    },
    [getAdventure]
  )

  const currentAdventuresActivityItems = useMemo(
    () =>
      reduce(
        currentAdventures,
        (result, adventure) => {
          return [
            ...result,
            {
              uuid: adventure.adventureUuid,
              name: adventure.squaloAdventure.name,
              element: adventure.squaloAdventure.element,
              items: adventure.currentPhaseRemainingActivityItems || [],
            },
          ]
        },
        [] as AdventureContextState["currentAdventuresActivityItems"]
      ),
    [currentAdventures]
  )

  const handlePauseAdventure = async (adventureUuid?: string | null) => {
    if (isNil(adventureUuid)) {
      return
    }

    if (
      !currentAdventures.some(
        (adventure) => adventure.adventureUuid === adventureUuid
      )
    ) {
      return
    }

    pauseAdventure({
      variables: {
        adventureUuid,
      },
    })
  }

  const value = {
    ...state,
    allAdventures,
    currentAdventures,
    currentAdventuresActivityItems,
    getAdventure,
    getAdventureByTemplateId,
    isCurrentPhaseActivity,
    pauseAdventure: handlePauseAdventure,
    isQuerying: loading,
    refetchAdventures: refetch,
  }

  return (
    <AdventureContext.Provider value={value}>
      {children}
    </AdventureContext.Provider>
  )
}
