import {
  LocalNotificationActionId,
  ReminderTemplate,
} from "../../contexts/NotificationContext/notificationTypes"

export default {
  LOCAL: {
    [ReminderTemplate.WeekAheadPreview]: {
      label: "Week Ahead Preview",
      title: "✅ Prioritize your wellness",
      body: "Take 5 seconds to commit to your first session of the week 👉",
    },
    [ReminderTemplate.MorningReminder]: {
      label: "Morning reminder",
      title: "🍃 Session of the Day",
      body: "{{ sessionTitle }} is on your agenda for today.",
    },
    [ReminderTemplate.SessionReminder]: {
      label: "Session reminder",
      title: "{{ sessionTitle }}",
      body: "Take {{ duration }} minutes to prioritize your wellness.",
    },
  },
  ACTIONS: {
    [LocalNotificationActionId.View]: "View",
    [LocalNotificationActionId.Dismiss]: "Dismiss",
  },
}
