import clsx from "clsx"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../contexts/AnalyticsContext"
import {
  SqualoAdventure,
  SqualoAdventureProduct,
} from "../../generated/graphql"
import { openBrowser } from "../../hooks/useInlineBrowser"
import { ThumbnailImage } from "../Core/ThumbnailButton"
import { ADVENTURE, NAME_SPACES } from "../../locales/constants"
import { useTranslation } from "react-i18next"
import { RiExternalLinkLine } from "react-icons/ri"
import { DiscreteCarouselPagination } from "../Core/CarouselPagination"
import Carousel from "nuka-carousel"
import { useBreakpoint } from "../../utils"
import { useEffect, useState } from "react"

export const AdventureTemplateProductCard: React.FC<{
  product?: SqualoAdventureProduct | null
}> = ({ product }) => {
  const { t } = useTranslation(NAME_SPACES.ADVENTURE)
  const TEXT = t(ADVENTURE.TEMPLATE, { returnObjects: true })

  const { captureEvent } = useAnalyticsContext()

  if (!product) {
    return null
  }

  const handleClick = () => {
    captureEvent(AnalyticsEvent.AdventureTemplateProductClicked, {
      productId: product.id,
    })

    openBrowser(product.url, {
      source: "behale",
      medium: "adventure",
      campaign: encodeURIComponent(`product-${product.id}`),
    })
  }

  return (
    <button
      type="button"
      className={clsx(
        "flex flex-col items-start justify-between w-full p-4 gap-y-4",
        "bg-white text-navy rounded-lg shadow-lg"
      )}
      onClick={handleClick}
    >
      <div className="flex flex-row items-start justify-between w-full gap-x-2">
        {/* <div className="flex flex-row items-center justify-start w-full truncate gap-x-2">
            
          </div> */}

        <ThumbnailImage
          url={product.imageUrl}
          className="w-20 h-20 rounded-lg shadow-sm"
        />
        <span className="inline-flex items-center text-sm font-semibold tracking-tighter gap-x-1 text-neutral-500">
          {TEXT.PRODUCTS.CTA}

          <RiExternalLinkLine />
        </span>
      </div>

      <div className="flex flex-row items-center justify-between w-full gap-x-2">
        <div className="flex flex-col items-start justify-center flex-grow w-full gap-y-2">
          <span className="text-xl font-semibold tracking-tighter text-left">
            {product.name}
          </span>
          <span className="text-sm text-left whitespace-pre-wrap">
            {product.description}
          </span>
        </div>
      </div>
    </button>
  )
}

export const AdventureTemplateProductCarousel: React.FC<{
  products?: SqualoAdventure["products"]
  nSlidesToShow?: number
}> = ({ products, nSlidesToShow = 1 }) => {
  const breakpoint = useBreakpoint()
  const [nSlides, setNSlides] = useState(nSlidesToShow)

  useEffect(() => {
    if (nSlidesToShow > 0) {
      return
    }

    switch (breakpoint) {
      case "lg":
      case "xl":
      case "md":
        setNSlides(2)
        break

      default:
        setNSlides(1)
        break
    }
  }, [breakpoint])

  if (!products || products.length === 0) {
    return null
  }

  return (
    <Carousel
      className={clsx("h-full", products.length > 1 ? "pb-8" : "pb-4")}
      renderCenterLeftControls={() => <></>}
      renderCenterRightControls={() => <></>}
      renderBottomCenterControls={DiscreteCarouselPagination}
      autoplay={false}
      wrapAround={false}
      slidesToShow={nSlides}
      swiping
    >
      {products.map((product, index) => (
        <div key={index} className="w-full h-full px-4">
          <AdventureTemplateProductCard product={product} />
        </div>
      ))}
    </Carousel>
  )
}
