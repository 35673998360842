import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import YupPassword from "yup-password"

import { AUTH, NAME_SPACES } from "../../locales/constants"
import { Form } from "../Forms/Form"
import Input from "../Forms/Input"
import { getPasswordSchema } from "./RegistrationForm"

YupPassword(Yup)

export const ResetPasswordForm: React.FC<{
  resetPassword: (data: any) => Promise<void>
  CTA: string
}> = ({ resetPassword, CTA }) => {
  const { t } = useTranslation(NAME_SPACES.AUTH)
  const TEXT_VALIDATION: any = t(AUTH.VALIDATION, {
    returnObjects: true,
  })
  const TEXT: any = t(AUTH.RESET_PASSWORD, {
    returnObjects: true,
  })
  const formSchema = Yup.object().shape({
    password: getPasswordSchema().required(TEXT_VALIDATION.PASSWOD_REQUIRED),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref("password")],
      TEXT_VALIDATION.UNMATCHED_PASSWORDS
    ),
  })

  return (
    <Form
      mode="onTouched"
      schema={formSchema}
      onSubmit={resetPassword}
      submitButtonProps={{
        label: CTA,
        color: "secondary",
        disabled: false,
      }}
    >
      <Input
        name="password"
        label={TEXT.NEW_PASSWORD}
        type="password"
        color="white"
        required
      />
      <Input
        name="passwordConfirmation"
        label={TEXT.CONFIRM_NEW_PASSWORD}
        type="password"
        color="white"
        required
      />
    </Form>
  )
}
