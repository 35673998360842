import { range } from "lodash"
import { ModalOrchestrationName } from "../../../../contexts/ModalOrchestrationContext"

import {
  GridFormField,
  GridFormFieldProps,
  RequiredFieldModalBodyProps,
} from "../../../Forms/GridFormField"
import { Slider, SliderProps } from "../../../Forms/Slider"
import { useCallback, useEffect, useState } from "react"
import { IoRepeat } from "react-icons/io5"
import { MovementAgendaItemRepeatsAfterUnit } from "../../../../generated/graphql"
import { formatDuration } from "date-fns"

export type RepeatOnCompletionSliderState = {
  instanceRepeatsOnCompletion?: boolean | null
  instanceRepeatsAfterFrequency?: number | null
  instanceRepeatsAfterUnit?: string | null
}

export type RepeatsOnSliderProps = SliderProps<RepeatOnCompletionSliderState> &
  RequiredFieldModalBodyProps<RepeatOnCompletionSliderState>

export const RepeatsOnSlider: React.FC<RepeatsOnSliderProps> = ({
  state,
  setState,
}) => {
  const [unit, setUnit] = useState(MovementAgendaItemRepeatsAfterUnit.Days)

  const handleSliderChange = (value: number) => {
    setState({
      ...state,
      instanceRepeatsOnCompletion: value > 0,
      instanceRepeatsAfterFrequency: value,
      instanceRepeatsAfterUnit: unit,
    })
  }

  const formatValue = useCallback(
    (value: number) => {
      if (value === 0) {
        return "Never"
      }

      return formatDuration({ days: value })
    },
    [unit]
  )

  useEffect(() => {
    setUnit(MovementAgendaItemRepeatsAfterUnit.Days)
  }, [])

  return (
    <div className="flex flex-col items-center justify-center w-full h-full p-4 gap-16">
      <Slider<number>
        onChange={handleSliderChange}
        values={range(0, 8)}
        formatValue={formatValue}
        initialValue={state?.instanceRepeatsAfterFrequency || 0}
      />
    </div>
  )
}

export interface RepeatsOnCompletionGridFormFieldProps
  extends Omit<
    GridFormFieldProps<RepeatOnCompletionSliderState>,
    "Body" | "label" | "modalName"
  > {
  name: string
}

export const RepeatsOnCompletionGridFormField: React.FC<
  RepeatsOnCompletionGridFormFieldProps
> = ({ name, ...props }) => {
  const Body = RepeatsOnSlider

  const formatValue = (state: RepeatOnCompletionSliderState | undefined) => {
    if (state?.instanceRepeatsOnCompletion) {
      return formatDuration({
        days: state?.instanceRepeatsAfterFrequency || 0,
      })
    }
    return "Never"
  }

  return (
    <GridFormField<RepeatOnCompletionSliderState>
      name={name}
      modalName={ModalOrchestrationName.FormFieldRepeatOnCompletionSlider}
      label={"Repeats after"}
      title={"Repeats"}
      Body={Body}
      formatValue={formatValue}
      Icon={IoRepeat}
      showDismiss
      required={false}
      {...props}
    />
  )
}
