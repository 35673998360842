import { useTranslation } from "react-i18next"
import { RiSettings2Line } from "react-icons/ri"
import Content from "../../components/Core/Content"

import Page from "../../components/Core/Page"
import { ButtonProps } from "../../components/Forms/Button"
import { MEMBERSHIP, NAME_SPACES } from "../../locales/constants"
import { useAuthenticatedClientContext } from "../../contexts/AuthenticatedClientContext"
import TabBar, { TabBarElement } from "../../components/Core/TabBar"
import { useEffect, useState } from "react"
import useRouterQueryParams from "../../hooks/useRouterQueryParams"
import { ActivityStatistics } from "../../components/Profile/ActivityStatistics"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../contexts/ModalOrchestrationContext"
import { SettingsModal } from "../../components/Settings/SettingsModal"
import { IoBarChart, IoCompassOutline } from "react-icons/io5"
import clsx from "clsx"
import {
  ProfileAvatar,
  ProfileBuddiesSection,
} from "../../components/Profile/ProfileBuddiesSection"
import { isNil } from "lodash"
import { useLocaleContext } from "../../contexts/LocaleContext"
import { WeekLogCarousel } from "../../components/Profile/WeekLogCarousel"
import { Link } from "react-router-dom"
import { ElementIcon } from "../../components/Core/Icons"
import { TagButton, TagRow } from "../../components/Core/Tag"
import { getElementContrastColor } from "../../utils/elementUtils"
import { formatElement } from "../../utils/format"
import { Maybe, MemberPublicProfile } from "../../generated/graphql"

export const ProfileHeader: React.FC<{
  profile?: Maybe<MemberPublicProfile>
}> = ({ profile }) => {
  const ProfileDetails: React.FC<{ className?: string }> = ({ className }) => {
    const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)
    const { formatDate, parseISODate } = useLocaleContext()

    const { currentMember } = useAuthenticatedClientContext()

    const isCurrentUser = profile?.memberUuid === currentMember?.memberUuid

    return (
      <div
        className={clsx("flex-1 min-w-0 mt-6 flex flex-col gap-y-2", className)}
      >
        <div className="flex flex-col items-start justify-start gap-2 mt-1">
          <h1 className="text-2xl font-bold truncate text-gray-800 sm:text-white">
            {profile?.displayName}
          </h1>
          <Link to={isCurrentUser ? "/app/profile/elements" : "#"}>
            <TagRow className={clsx("flex flex-row gap-x-2")}>
              {profile?.elements?.map((element) => (
                <TagButton
                  key={element}
                  className={clsx(
                    "flex flex-row items-center gap-x-2 flex-shrink-0 shadow-sm",
                    `bg-${element}-500 text-${getElementContrastColor(element)}`
                  )}
                >
                  <ElementIcon element={element} contrast />
                  <span>{formatElement(element)}</span>
                </TagButton>
              ))}
              {profile?.elements && profile?.elements.length === 0 && (
                <TagButton
                  className={clsx(
                    "flex flex-row items-center gap-x-2 flex-shrink-0 shadow-sm",
                    "bg-gray-500 text-gray-100"
                  )}
                >
                  <span>{t("PROFILE.PUBLIC_PROFILE.NO_ELEMENTS")}</span>
                </TagButton>
              )}
            </TagRow>
          </Link>
        </div>

        {!isNil(profile?.joinedAt) && (
          <p className="text-sm font-medium text-gray-500 mt-4">
            {t("PROFILE.PUBLIC_PROFILE.JOINED_AT", {
              date: formatDate(
                parseISODate(profile?.joinedAt) as Date,
                "MMMM yyyy"
              ),
            })}
          </p>
        )}
      </div>
    )
  }

  return (
    <div className="pb-3">
      <div>
        <div
          className={clsx(
            "object-cover w-full h-32 lg:h-48",
            "bg-primary"
            // `bg-${first(profile?.elements)}`
          )}
        />
      </div>
      <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div className="flex">
            <ProfileAvatar
              profile={profile as MemberPublicProfile}
              className="w-24 h-24 text-5xl sm:h-32 sm:w-32"
            />
          </div>
          <div className="mt-6 sm:mt-0 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <ProfileDetails className="sm:hidden 2xl:block" />
          </div>
        </div>
        <ProfileDetails className="hidden sm:flex 2xl:hidden" />
      </div>
    </div>
  )
}

const ProfileHomePage: React.FC = () => {
  const { t } = useTranslation(NAME_SPACES.MEMBERSHIP)

  const query = useRouterQueryParams()

  const { refreshCurrentUser, currentMember } = useAuthenticatedClientContext()

  const PROFILE = t(MEMBERSHIP.PROFILE, { returnObjects: true })

  const { openModal } = useModalOrchestrationContext()

  const openSettings = async () => {
    refreshCurrentUser()

    await openModal(ModalOrchestrationName.Settings)
  }

  const toolbarButtons = [
    {
      icon: RiSettings2Line,
      onClick: openSettings,
      slot: "end",
      paddingWidth: "thin",
      color: "white",
      fill: "clear",
    },
  ] as ButtonProps[]

  const journeyTabs = [
    {
      name: "ADVENTURE",
      label: PROFILE.TABS.ADVENTURE.TITLE,
      icon: IoCompassOutline,
    },
    {
      name: "PROGRESS",
      label: PROFILE.TABS.PROGRESS.TITLE,
      icon: IoBarChart,
    },
  ] as TabBarElement[]

  const [activeTab, setActiveTab] = useState<TabBarElement["name"]>("PROGRESS")

  useEffect(() => {
    if (query && query.has("tab")) {
      const tab = query.get("tab") as string

      if (Object.keys(PROFILE.TABS).includes(tab)) {
        setActiveTab(tab)
      }
    }
  }, [query])

  const profile = currentMember?.publicProfile

  return (
    <Page>
      <Page.Header
        color="primary"
        toolbarButtons={toolbarButtons}
        title={PROFILE.TITLE}
      >
        <TabBar
          tabs={journeyTabs}
          activeTab={activeTab}
          onTabChange={(tab) => setActiveTab(tab)}
          className={clsx(
            "overflow-x-scroll overflow-y-hidden shadow-lg",
            "hidden"
          )}
        />
      </Page.Header>

      <Content slot="fixed" scrollY={true} scrollX={false}>
        <article className="flex flex-col divide-y divide-neutral-300 xl:divide-y-0">
          {/* Profile header */}
          <ProfileHeader profile={profile} />

          <div className="w-full max-w-5xl py-6 mx-auto">
            <ProfileBuddiesSection />
          </div>

          <div className="w-full max-w-5xl px-4 py-6 mx-auto sm:px-6 lg:px-8">
            <ActivityStatistics />
          </div>

          <div className="w-full max-w-5xl py-6 mx-auto">
            <WeekLogCarousel />
          </div>
        </article>
      </Content>

      <SettingsModal />
    </Page>
  )
}

export default ProfileHomePage
