import { useLazyQuery, useMutation } from "@apollo/client"
import { Device } from "@capacitor/device"
import { Preferences } from "@capacitor/preferences"
import {
  Locale as DateFNSLocale,
  formatISO,
  parseISO,
  parse,
  intervalToDuration,
  formatDuration,
  Duration,
  getMonth,
} from "date-fns"
import { enGB, fr } from "date-fns/esm/locale"
import format from "date-fns/format"
import formatRelative from "date-fns/formatRelative"
import { isNil } from "lodash"
import get from "lodash/get"
import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useReducer,
} from "react"
import { useTranslation } from "react-i18next"

import {
  ContactPreferredLanguage,
  GetCurrentContactDocument,
  GetCurrentContactQuery,
  GetCurrentContactQueryVariables,
  SetPreferredLanguageDocument,
  SetPreferredLanguageMutation,
  SetPreferredLanguageMutationVariables,
} from "../generated/graphql"
import { CALENDAR } from "../locales/constants"
import enCalendar from "../locales/en/calendar"
import frCalendar from "../locales/fr/calendar"
import i18n from "../locales/i18n"
import {
  dateFromTime,
  Time,
  timeFromDate,
} from "./AgendaSchedulingContext/agendaTimeUtils"
import { useAuthenticatedClientContext } from "./AuthenticatedClientContext"

export type Locale = "en" | "fr"

const convertLanguageToLocale = (
  language: ContactPreferredLanguage | null | undefined
): Locale => {
  if (language === ContactPreferredLanguage.Fr) {
    return "fr"
  }

  return "en"
}

const localeToDateFNSLocale = {
  en: enGB,
  fr: fr,
}

const calendarLocales = {
  en: enCalendar[CALENDAR.RELATIVE],
  fr: frCalendar[CALENDAR.RELATIVE],
}

export const getDateFNSLocale = (
  locale: Locale,
  withTime = false
): DateFNSLocale => {
  const tokens = get(
    calendarLocales[locale],
    withTime ? "DATETIME" : "DATE",
    {}
  )

  const formatRelative = (token: string) => get(tokens, token)

  return {
    ...localeToDateFNSLocale[locale],
    formatRelative,
  }
}

export interface LocaleContextState {
  // state
  contact: GetCurrentContactQuery["currentContact"]
  isInitialized: boolean
  locale: Locale
  language: ContactPreferredLanguage

  // actions
  formatTime: (time: Time, timeStr?: string) => string
  formatRecurringDate: (date: Date, time?: Time) => string
  formatDate: (date: Date, formatStr: string, time?: Time) => string

  formatRelativeDate: (date: Date, baseDate: Date, time?: Time) => string

  formatDateInterval: (start: Date, end: Date) => string

  parseISODate: (
    dateStr?: string,
    options?: {
      dropTime?: boolean
    }
  ) => Date | undefined

  parseISOTime: (timeStr?: string) => Time | undefined
}

export const LocaleContext = createContext<LocaleContextState | undefined>(
  undefined
)

const localeReducer = (state: LocaleContextState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case "setLanguage":
      return {
        ...state,
        language: payload.language,
        locale: convertLanguageToLocale(payload.language),
        isInitialized: true,
      }

    case "setContact":
      return {
        ...state,
        contact: payload.contact,
      }

    default:
      return state
  }
}

export const useLocaleContext = () => {
  const context = useContext(LocaleContext)

  if (context === undefined) {
    throw new Error(
      "useLocaleContextContext must be used within a LocaleContext provider"
    )
  }

  return context
}

export const formatTime = (
  time: Time | string | undefined,
  timeFormat?: string
): string => {
  if (!time) {
    return ""
  }

  if (typeof time === "string") {
    return formatTime(parseISOTime(time), timeFormat)
  }

  const date = dateFromTime(time)

  if (isNil(timeFormat)) {
    return formatISO(date, { representation: "time" })
  }

  return format(date, timeFormat)
}

export const parseISOTime = (timeStr?: string): Time | undefined => {
  if (isNil(timeStr)) {
    return undefined
  }

  const date = parse(timeStr, "HH:mm:ss", new Date())

  return timeFromDate(date)
}

const initialLocaleState: LocaleContextState = {
  contact: undefined,
  isInitialized: false,
  locale: "en",
  language: ContactPreferredLanguage.En,
  formatRecurringDate: (date, _) => format(date, "EEEE's'"),
  formatDate: (date, formatStr, _) => format(date, formatStr),
  formatRelativeDate: (date, baseDate, _time) => formatRelative(date, baseDate),
  formatTime,
  parseISOTime,
  formatDateInterval: (start, end) => {
    const duration = intervalToDuration({ start, end })

    return formatDuration(duration, {
      delimiter: ", ",
    })
  },

  parseISODate: (dateStr?: string) => (dateStr ? parseISO(dateStr) : undefined),
}

export const dropTimeFromDate = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

export const addWeeks = (duration: Duration) => {
  if (duration.days && duration.days > 6) {
    if (!duration.weeks) {
      duration.weeks = (duration.days / 7) | 0
      duration.days = duration.days - duration.weeks * 7
    }
  }
}

export const LocaleProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(localeReducer, initialLocaleState) as [
    LocaleContextState,
    Dispatch<{ type: string; payload?: any }>
  ]

  const { t } = useTranslation()
  const { currentMember } = useAuthenticatedClientContext()

  const [getCurrentContact, { data, loading }] = useLazyQuery<
    GetCurrentContactQuery,
    GetCurrentContactQueryVariables
  >(GetCurrentContactDocument, {
    onCompleted: (data) => {
      if (isNil(data.currentContact)) {
        return
      }

      dispatch({
        type: "setContact",
        payload: { contact: data.currentContact },
      })
    },
  })

  const [setPreferredLanguage] = useMutation<
    SetPreferredLanguageMutation,
    SetPreferredLanguageMutationVariables
  >(SetPreferredLanguageDocument)

  const checkDeviceLanguage = async () => {
    const { value: languageCode } = await Device.getLanguageCode()

    switch (languageCode) {
      case "fr-FR":
      case "fr":
        i18n.changeLanguage("fr")

        dispatch({
          type: "setLanguage",
          payload: { language: ContactPreferredLanguage.Fr },
        })

        await Preferences.set({ key: "i18nextLng", value: "fr" })

        break
      default:
        dispatch({
          type: "setLanguage",
          payload: { language: ContactPreferredLanguage.En },
        })

        await Preferences.set({ key: "i18nextLng", value: "en" })
    }
  }

  const formatDate = (date: Date, formatStr: string) => {
    const dateLocale = getDateFNSLocale(state.locale)

    return format(date, formatStr, { locale: dateLocale })
  }

  const formatRecurringDate = (date: Date, time?: Time) => {
    const weekday = formatDate(date, "EEE")
    const everyWeekday = t("EVERY", { value: weekday })

    if (time) {
      return t("WITH_TIME", { value: everyWeekday, time: formatTime(time) })
    }

    return everyWeekday
  }

  const formatDateInterval = (startDate: Date, endDate: Date) => {
    const formattedEndDate = formatDate(endDate, "dd MMM yyyy")

    const isSameMonth = getMonth(startDate) === getMonth(endDate)
    const isSameYear = startDate.getFullYear() === endDate.getFullYear()

    let label = `${formatDate(startDate, "dd")} - ${formattedEndDate}`

    if (!isSameMonth) {
      label = `${formatDate(startDate, "dd MMM")} - ${formattedEndDate}`

      if (!isSameYear) {
        label = `${formatDate(startDate, "dd MMM yyyy")} - ${formattedEndDate}`
      }
    }

    return label
  }

  const formatRelativeDate = (date: Date, baseDate: Date, time?: Time) => {
    if (!isNil(time)) {
      date = dateFromTime(time, date)
    }

    const dateLocale = getDateFNSLocale(state.locale, !!time)

    return formatRelative(date, baseDate, {
      locale: dateLocale,
      weekStartsOn: 1,
    })
  }

  const parseISODate: LocaleContextState["parseISODate"] = (
    dateStr,
    options = { dropTime: false }
  ) => {
    if (isNil(dateStr)) return

    const date = parseISO(dateStr)

    if (options.dropTime) {
      return dropTimeFromDate(date)
    }

    return date
  }

  useEffect(() => {
    if (!state.language) return
    if (!state.isInitialized) return

    if (isNil(data?.currentContact)) return

    const preferredLanguage = data?.currentContact.preferredLanguage

    if (state.language.toString() !== preferredLanguage?.toString()) {
      setPreferredLanguage({
        variables: { preferredLanguage: state.language },
      })
    }
  }, [state.language, state.isInitialized])

  useEffect(() => {
    if (!state.isInitialized) return

    if (isNil(currentMember)) return

    if (loading || !isNil(data?.currentContact)) return

    getCurrentContact()
  }, [currentMember, state.isInitialized, loading])

  useEffect(() => {
    checkDeviceLanguage()
  }, [])

  const value = {
    ...state,
    formatDate,
    formatRelativeDate,
    parseISODate,
    formatRecurringDate,
    formatDateInterval,
  }

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  )
}
