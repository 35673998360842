import { isNil } from "lodash"
import * as React from "react"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"

import {
  AgendaDay,
  useAgendaSchedulingContext,
} from "../../../contexts/AgendaSchedulingContext"
import { Time } from "../../../contexts/AgendaSchedulingContext/agendaTimeUtils"
import {
  AnalyticsEvent,
  useAnalyticsContext,
} from "../../../contexts/AnalyticsContext"
import { formatTime, useLocaleContext } from "../../../contexts/LocaleContext"
import {
  ModalOrchestrationName,
  useModalOrchestrationContext,
} from "../../../contexts/ModalOrchestrationContext"
import {
  AddMovementAgendaItemMutationVariables,
  BodyPartEnum,
  GetMovementAgendaItemQuery,
  ModifyMovementAgendaItemMutationVariables,
  MovementActivity,
  MovementAgendaItem,
  MovementAgendaItemSummaryFragment,
  MovementSession,
  MovementStyle,
  PhaseOfDay,
  SessionExpectedDuration,
  Weekday,
} from "../../../generated/graphql"
import { MOVEMENT, NAME_SPACES } from "../../../locales/constants"
import { formatStyle } from "../../../utils/format"
import Modal, { ModalContext, ModalProps } from "../../Core/Modal"
import { GridForm, GridFormSection } from "../../Forms/GridForm"
import { buildSessionLabel } from "../Style"
import { DurationGridFormField } from "../Forms/Fields/DurationSlider"
import {
  MicoVideoSelectData,
  MicoVideoSelectGridFormField,
} from "../Forms/Fields/MicoVideoSelect"
import { MovementStyleGridFormField } from "../Forms/Fields/MovementStyleSelect"
import { AgendaItemTitleInput } from "../Forms/Fields/MovementTitleInput"
import { ScheduleGridFormField } from "../Forms/Fields/SchedulePicker"
import { TimeGridFormField } from "../Forms/Fields/TimeSlider"
import { getWeekdayDate } from "../../../utils"
import usePersistedFlag from "../../../hooks/usePersistedFlag"
import { PhaseOfDayGridFormField } from "../Forms/Fields/PhaseOfDaySelect"
import { CustomURLInputGridFormField } from "../Forms/Fields/CustomURLInput"
import {
  MultiBodyPartSelectGridFormField,
  MultiBodyPartSelectState,
} from "../Forms/Fields/MultiBodyPartSelect"
import { flattenBodyTree } from "../../../utils/body"
import {
  RepeatOnCompletionSliderState,
  RepeatsOnCompletionGridFormField,
} from "../Forms/Fields/RepeatOnCompletionSlider"
import { DistanceGridFormField } from "../Forms/Fields/DistanceSlider"

export type MovementAgendaItemFormData = {
  title?: string | null
  micoVideo?: MicoVideoSelectData
  movementStyle?: MovementStyle
  movementTargetBodyParts?: MultiBodyPartSelectState
  plannedSchedule: {
    isRecurring: boolean
    weekStartDate?: Date
    weekday: Weekday
  }
  instanceRepetition?: RepeatOnCompletionSliderState
  plannedDistance?: number | null
  plannedPhaseOfDay?: PhaseOfDay | null
  plannedStartTime?: Time
  duration: SessionExpectedDuration
  followAlongCustomUrl?: string
}

export interface MovementAgendaItemFormProps {
  agendaItem?:
    | MovementAgendaItemSummaryFragment
    | GetMovementAgendaItemQuery["movementAgendaItem"]
  activity?: MovementActivity
  followAlong?: MovementSession["followAlong"]
  day?: AgendaDay
  phaseOfDay?: PhaseOfDay
  openWithField?: keyof MovementAgendaItemFormData
}

const MovementAgendaItemForm: React.FC<MovementAgendaItemFormProps> = ({
  day,
  phaseOfDay,
  agendaItem,
  followAlong,
  activity,
}) => {
  const { name } = React.useContext(ModalContext)

  const formAction = isNil(agendaItem) ? "add" : "modify"

  const { captureEvent } = useAnalyticsContext()
  const { parseISOTime, formatDate } = useLocaleContext()
  const {
    getDay,
    selectedWeekStartDate,
    selectedWeekUuid,
    selectWeekDate,
    addAgendaItem,
    modifyAgendaItem,
    agendaItemActionLoading,
  } = useAgendaSchedulingContext()

  const { toggleLoading, openModal, closeModal, updateModalState } =
    useModalOrchestrationContext()

  const { isDirty: hasShownCalendarConnectionModal } = usePersistedFlag(
    "hasShownCalendarConnectionModal"
  )

  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const TEXT = t(MOVEMENT.ITEM_PLANNING, { returnObjects: true })

  const buildDefaultValues = useCallback((): MovementAgendaItemFormData => {
    if (!isNil(agendaItem)) {
      agendaItem = agendaItem as MovementAgendaItem

      return {
        title: agendaItem.title,
        movementStyle: agendaItem.movementStyle,
        movementTargetBodyParts: flattenBodyTree(
          agendaItem.movementTargetBodyParts
        ) as BodyPartEnum[],
        plannedSchedule: {
          weekday: getDay(agendaItem.weekday).weekday,
          weekStartDate: selectedWeekStartDate,
          isRecurring: agendaItem.isRecurring,
        },
        plannedDistance: agendaItem.plannedDistance,
        plannedPhaseOfDay: agendaItem.plannedPhaseOfDay,
        plannedStartTime: parseISOTime(agendaItem?.plannedStartTime),
        duration: agendaItem.expectedDuration,
        followAlongCustomUrl: agendaItem?.followAlongCustomUrl || undefined,
        instanceRepetition: {
          instanceRepeatsOnCompletion: agendaItem.instanceRepeatsOnCompletion,
          instanceRepeatsAfterFrequency:
            agendaItem.instanceRepeatsAfterFrequency,
          instanceRepeatsAfterUnit: agendaItem.instanceRepeatsAfterUnit,
        },
      }
    } else if (!isNil(followAlong)) {
      return {
        title: `${formatStyle(followAlong.movementStyle)} | ${
          followAlong.creatorName
        }`,
        movementStyle: followAlong.movementStyle,
        movementTargetBodyParts: flattenBodyTree(
          followAlong.movementTargetBodyParts
        ),
        plannedSchedule: {
          weekday: getDay(day?.weekday).weekday,
          weekStartDate: selectedWeekStartDate,
          isRecurring: false,
        },
        plannedPhaseOfDay: undefined,
        plannedStartTime: undefined,
        duration: followAlong.expectedDuration,
        micoVideo: followAlong,
      }
    } else if (!isNil(activity)) {
      return {
        title: buildSessionLabel(activity),
        movementStyle: activity.movementStyle,
        duration: activity.usualDuration || SessionExpectedDuration.Short,
        plannedDistance: activity.usualDistance,
        plannedSchedule: {
          weekday: getDay(day?.weekday).weekday,
          weekStartDate: selectedWeekStartDate,
          isRecurring: false,
        },
        plannedPhaseOfDay: activity.usualPhaseOfDay,
      }
    } else {
      return {
        movementStyle: undefined,
        plannedSchedule: {
          weekday: getDay(day?.weekday).weekday,
          weekStartDate: selectedWeekStartDate,
          isRecurring: false,
        },
        instanceRepetition: {
          instanceRepeatsOnCompletion: false,
          instanceRepeatsAfterFrequency: 0,
        },
        plannedPhaseOfDay: phaseOfDay,
        duration: SessionExpectedDuration.Short,
      }
    }
  }, [agendaItem, followAlong, activity, day, phaseOfDay])

  const mapFormDataToVariables = (
    data: MovementAgendaItemFormData,
    isRecurring: boolean
  ) => {
    let movementTargetBodyParts: BodyPartEnum[] | undefined = []

    if (data.movementTargetBodyParts?.length === 0) {
      movementTargetBodyParts = []
    } else {
      movementTargetBodyParts = data.movementTargetBodyParts as BodyPartEnum[]
    }

    const title = buildSessionLabel({
      title: data.title,
      movementStyle: data.movementStyle,
    })

    const instanceDate = formatDate(
      getWeekdayDate(
        data.plannedSchedule.weekStartDate || selectedWeekStartDate,
        data.plannedSchedule.weekday
      ),
      "yyyy-MM-dd"
    )

    const result = {
      title,
      movementTargetBodyParts,
      expectedDuration: data.duration,
      movementStyle: data.movementStyle,
      plannedStartTime: data.plannedStartTime
        ? formatTime(data.plannedStartTime)
        : null,
      plannedPhaseOfDay: data.plannedPhaseOfDay || undefined,
      plannedDistance: data.plannedDistance || undefined,
      squaloVideoUuid: data.micoVideo?.uuid,
      recurringWeekday: isRecurring ? data.plannedSchedule.weekday : null,
      movementWeekUuid: isRecurring ? undefined : selectedWeekUuid,
      instanceDate: isRecurring ? null : instanceDate,
      followAlongCustomUrl: data.followAlongCustomUrl || undefined,
      ...data.instanceRepetition,
    }

    // add weekday to variables if we are modifying an item
    if (formAction === "modify") {
      return {
        ...result,
        weekday: data.plannedSchedule.weekday,
      }
    }

    return result
  }

  const handleAdd = async (data: MovementAgendaItemFormData) => {
    const variables = mapFormDataToVariables(
      data,
      data.plannedSchedule.isRecurring
    ) as AddMovementAgendaItemMutationVariables

    await addAgendaItem(variables, false, (result) => {
      captureEvent(AnalyticsEvent.MovementAgendaItemAdded, { ...data })

      if (!isNil(data.plannedSchedule.weekStartDate)) {
        selectWeekDate(data.plannedSchedule.weekStartDate)
      }

      if (!hasShownCalendarConnectionModal) {
        openModal(ModalOrchestrationName.CalendarConnection)
      }

      closeModal(name, "added", result.addMovementAgendaItem)
    })
  }

  const handleModify = async (
    data: MovementAgendaItemFormData,
    isRecurring: boolean
  ) => {
    const variables = {
      uuid: agendaItem?.uuid,
      ...mapFormDataToVariables(data, isRecurring),
    } as ModifyMovementAgendaItemMutationVariables

    await modifyAgendaItem(variables, true, (result) => {
      captureEvent(AnalyticsEvent.MovementAgendaItemModified, {
        ...data,
        onlyThisWeek: !isRecurring,
      })

      if (!isNil(data.plannedSchedule.weekStartDate)) {
        selectWeekDate(data.plannedSchedule.weekStartDate)
      }

      if (!hasShownCalendarConnectionModal) {
        openModal(ModalOrchestrationName.CalendarConnection)
      }

      closeModal(name, "modified", result.modifyMovementAgendaItem)
    })
  }

  const handleSubmit = useCallback(
    async (data: MovementAgendaItemFormData) => {
      if (formAction === "add") {
        await handleAdd(data)
      } else {
        await handleModify(data, agendaItem?.isRecurring || false)
      }
    },
    [formAction, agendaItem]
  )

  const submitCTA = isNil(agendaItem)
    ? TEXT.ADD_TO_AGENDA
    : TEXT.SAVE_MODIFICATION

  const defaultValues = React.useMemo(
    () => buildDefaultValues(),
    [buildDefaultValues]
  )

  const isAdventureActivity = !isNil(agendaItem?.membershipAdventureUuid)

  React.useEffect(() => {
    toggleLoading(agendaItemActionLoading)
  }, [agendaItemActionLoading])

  React.useEffect(() => {
    if (isNil(name)) return undefined

    updateModalState(name, {
      title: isNil(agendaItem)
        ? TEXT.TITLE.PLAN_NEW
        : TEXT.TITLE.ADAPT_EXISTING,
    })
  }, [name, agendaItem])

  return (
    <GridForm<MovementAgendaItemFormData>
      onValidSubmit={handleSubmit}
      submitCTA={submitCTA}
      disableSubmit={agendaItemActionLoading}
      defaultValues={defaultValues}
    >
      <div className="col-span-2">
        <AgendaItemTitleInput
          name="title"
          disabled={isAdventureActivity}
          className="text-2xl"
        />
      </div>
      {isNil(followAlong) ? null : (
        <MicoVideoSelectGridFormField name="micoVideo" disabled={true} />
      )}
      <GridFormSection>
        <MovementStyleGridFormField
          required
          name="movementStyle"
          disabled={!isNil(followAlong) || isAdventureActivity}
          recommend="favorite"
        />
        <MultiBodyPartSelectGridFormField
          name="movementTargetBodyParts"
          disabled={!isNil(followAlong) || isAdventureActivity}
        />
        <DistanceGridFormField name="plannedDistance" />
        <CustomURLInputGridFormField
          name="followAlongCustomUrl"
          disabled={!isNil(followAlong) || isAdventureActivity}
        />
      </GridFormSection>
      <GridFormSection>
        <ScheduleGridFormField
          name="plannedSchedule"
          hideRecurringToggle={formAction === "modify"}
        />
        <RepeatsOnCompletionGridFormField name="instanceRepetition" hidden />
        <PhaseOfDayGridFormField name="plannedPhaseOfDay" />
        <DurationGridFormField
          name="duration"
          type="approximate"
          disabled={isAdventureActivity}
        />
        <TimeGridFormField name="plannedStartTime" window={"future"} />
      </GridFormSection>
    </GridForm>
  )
}

export const MovementAgendaItemFormModal: React.FC<Partial<ModalProps>> = ({
  name = ModalOrchestrationName.MovementAgendaItemForm,
  ...props
}) => {
  const { t } = useTranslation(NAME_SPACES.MOVEMENT)
  const ITEM_PLANNING = t(MOVEMENT.ITEM_PLANNING, { returnObjects: true })

  return (
    <Modal name={name} isSheet {...props}>
      <Modal.Header title={ITEM_PLANNING.TITLE.PLAN_NEW} />

      <Modal.Body>
        {(props: any) => <MovementAgendaItemForm {...props} />}
      </Modal.Body>
    </Modal>
  )
}

export default MovementAgendaItemFormModal
